import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AsanaApi = {
  /**
   * Create tasks for CSM
   * @param {string} customerName - Name of the customer we're creating the tasks for
   * @param {string} csm - Name of the csm
   * @param {string} product - Product we are onboarding the customer to
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved certificates - and `success`
   */
  createCsmTasks: async (customerName, csm, product, cancelToken) => {
    const body = {
      csm,
      customerName,
      product,
    };

    const res = await axios.post(
      `${apiUrl}/admin/create-csm-tasks/`,
      body,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default AsanaApi;
