import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const adminPass = process.env.REACT_APP_ADMIN_PASS;

const AdminUsersApi = {
  /**
   * Retrieves all admin users
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `users` property - the retrieved users - and `success`
   */
  getAdminUsers: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/admin-users`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get CSM Users
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved available CSM users
   */
  getCsmUsers: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/get-csm-users/`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates a user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user to edit
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateAdminUser: async (
    cancelToken,
    id,
    {
      role,
      asanaUserId,
      asanaProjectId,
      exactOnlineUserName,
      permissions,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/admin-users/${id}`, {
        pw: adminPass,
        role,
        asanaUserId,
        asanaProjectId,
        exactOnlineUserName,
        permissions,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates a new admin user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} role - role of the user
   * @param {string} username - Asana Username for this user
   * @param {string} password - Asana Password for this user
   * @param {string} asanaUserId - Asana UserID for this user (only for CSM)
   * @param {string} asanaProjectId - Asana ProjectID for this user (only for CSM)
   * @return {object} An object with `result` property - always true - and `success`
   */
  createAdminUser: async (
    cancelToken,
    {
      role,
      asanaUserId,
      asanaProjectId,
      username,
      password,
      permissions,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/admin-users/create`, {
        pw: adminPass,
        role,
        asanaUserId,
        asanaProjectId,
        username,
        password,
        permissions,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default AdminUsersApi;
