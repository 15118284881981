import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const CertificatesAPI = {
  /**
   * Get certificates
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved certificates - and `success`
   */
  getCertificates: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/certificates`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default CertificatesAPI;
