import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, Dropdown } from 'semantic-ui-react';

import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import '../style.scss';
import MultiSelect from '../../AcademyTrainings/atoms/MultiSelect';

const UserModal = ({
  isOpen, username, setUserName, password, setPassword, handleCancel, handleSave, handleChangePassword, setName, name,
  isAdmin, setIsAdmin, eventType, orgId, setOrgId, organisations, paidExamIds, setPaidExamIds, exams,
  isPaid, setIsPaid, group, setGroup, expirationDate, setExpirationDate,
}) => {
  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (paidExamIds?.length === 1) {
      return `${Util.returnPropertyNameById(exams, paidExamIds[0])}`;
    } if (paidExamIds?.length > 1) {
      return `${paidExamIds.length} Exams Selected`;
    } if (!paidExamIds?.length) {
      return 'Select Exam';
    }

    return '';
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="certification-user-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Create Certifications User
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
            style={{ minHeight: '10rem' }}
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-user-name">
                Username
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-user-name"
                    placeholder="Enter the username in email format"
                    className="slds-input"
                    onChange={event => setUserName(event.target.value)}
                    value={username}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-password">
                Password
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-password"
                    placeholder="Enter the password"
                    className="slds-input"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    disabled={eventType === Constants.EVENT__TYPE__UPDATE}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    placeholder="Enter the name"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-organisation">
                Organisation
                <Dropdown
                  placeholder="Choose organisation"
                  fluid
                  search
                  selection
                  options={organisations.map(organisation => ({
                    key: organisation._id,
                    value: organisation._id,
                    flag: organisation._id,
                    text: organisation.name,
                  }))}
                  onChange={(_, data) => setOrgId(data.value)}
                  value={orgId}
                />
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-group">
                Group
                <Popup
                  content="Please note this field is similar to organisation name. Allows to fill in free text in cases
                  the organisation name is not specific enough."
                  // eslint-disable-next-line spellcheck/spell-checker
                  trigger={<Icon name="info circle" className="isAdmin-info-tooltip" />}
                  on={['hover']}
                  className="isAdmin-popup"
                  position="right center"
                />
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-group"
                    placeholder="Enter the group"
                    className="slds-input"
                    onChange={event => setGroup(event.target.value)}
                    value={group}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="slds-form-element__label" htmlFor="multi-select">
                Paid Certification Exams
                <MultiSelect
                  multiSelectPlaceholder={returnMultiSelectPlaceholder()}
                  value={paidExamIds}
                  data={exams}
                  setData={setPaidExamIds}
                />
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="expirationDate">
                Expiration Date
                <div>
                  <input
                    type="date"
                    onChange={event => setExpirationDate(event.target.value)}
                    value={expirationDate}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <div className="slds-form-element__label">
                Is Admin
                <Popup
                  content="Please note this Admin flag only refers to certification management.
                  Certification admin users can see exams marked with the onlyForAdmin flag in the application"
                  // eslint-disable-next-line spellcheck/spell-checker
                  trigger={<Icon name="info circle" className="isAdmin-info-tooltip" />}
                  on={['hover']}
                  className="isAdmin-popup"
                  position="right center"
                />
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setIsAdmin(!isAdmin)}
                      type="checkbox"
                      name="onlyForAdmin"
                      id="onlyForAdmin"
                      checked={isAdmin}
                    />
                    <label className="slds-checkbox__label" htmlFor="onlyForAdmin">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isAdmin ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="slds-form-element">
              <div className="slds-form-element__label">
                Is Paid
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setIsPaid(!isPaid)}
                      type="checkbox"
                      name="isPaid"
                      id="isPaid"
                      checked={isPaid}
                    />
                    <label className="slds-checkbox__label" htmlFor="isPaid">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isPaid ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

UserModal.propTypes = {
  /* Open the modal */
  isOpen: PropTypes.bool.isRequired,
  /** Label for username */
  username: PropTypes.string.isRequired,
  /** set state for username */
  setUserName: PropTypes.func.isRequired,
  /** label for password */
  password: PropTypes.string.isRequired,
  /** set state for password */
  setPassword: PropTypes.func.isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** handles the change password button */
  handleChangePassword: PropTypes.func.isRequired,
  /** set state for name */
  setName: PropTypes.func.isRequired,
  /** Label for name */
  name: PropTypes.string.isRequired,
  /** defines whether the user is an admin */
  isAdmin: PropTypes.bool.isRequired,
  /** function for setting isAdmin flag */
  setIsAdmin: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string.isRequired,
  /** function for setting organisation Id */
  setOrgId: PropTypes.func.isRequired,
  /** id of the organisation assigned to the user */
  orgId: PropTypes.string,
  /** array with all available organisations */
  organisations: PropTypes.instanceOf(Array).isRequired,
  /** array with exams assigned for the user in paidForCertification property */
  paidExamIds: PropTypes.instanceOf(Array).isRequired,
  /** function for setting paidExamIds  */
  setPaidExamIds: PropTypes.func.isRequired,
  /** array will all exams  */
  exams: PropTypes.instanceOf(Array).isRequired,
  /** defines whether the user paid or not */
  isPaid: PropTypes.bool.isRequired,
  /** function for setting isPaid flag */
  setIsPaid: PropTypes.func.isRequired,
  /** defines the group the user belongs to */
  group: PropTypes.string,
  /** function for setting the group property */
  setGroup: PropTypes.func,
  /** defines the date when the access for the user should be revoked */
  expirationDate: PropTypes.string,
  /** function for setting expirationDate property */
  setExpirationDate: PropTypes.func,
};

export default UserModal;
