import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../../constants/constants';
import './style.scss';

const CompanyOverview = ({
  company,
  handleRefresh,
}) => {
  const [refreshing, setRefreshing] = useState(false);

  /**
   * Handle clicking refresh button
   * @returns {void}
   */
  const clickRefresh = async () => {
    setRefreshing(true);
    await handleRefresh();
    setRefreshing(false);
  };

  return (
    company.properties ?
      (
        <>
          <article className="slds-card">
            <div className="slds-card__header slds-grid">
              <header className="slds-media slds-media_center slds-has-flexi-truncate">
                <div className="slds-media__figure">
                  <span className="slds-icon_container slds-icon-standard-account" title="account">
                    <svg
                      className={`slds-icon slds-icon_small ${refreshing ?
                        'fa-spin' :
                        ''} `}
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#account" />
                    </svg>
                    <span className="slds-assistive-text">company</span>
                  </span>
                </div>
                <div className="slds-media__body">
                  <h2 className="slds-card__header-title">
                    <span>Company Highlights</span>
                  </h2>
                </div>
                <div className="slds-no-flex">
                  <button
                    className="slds-button slds-button_neutral"
                    type="button"
                    onClick={clickRefresh}
                  >
                    {refreshing ? 'Loading...' : 'Refresh'}
                  </button>
                </div>
              </header>
            </div>
            <div className="slds-card__body slds-card__body_inner">
              <div className="slds-form-element__label">
                Name:
                &nbsp;
                {company.properties.name}
                <br />
                Legal Name:
                &nbsp;
                {company.properties.legal_company_name}
                {company.properties.legal_company_name ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Address:
                &nbsp;
                {company.properties.address}
                ,
                &nbsp;
                {company.properties.zip}
                &nbsp;
                {company.properties.city}
                {company.properties.address && company.properties.zip && company.properties.city ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        Some address information is missing.
                      </font>
                    </>
                  )}
                <br />
                Country:
                &nbsp;
                {company.properties.country_code}
                {' '}
                {company.properties.country_code ?
                  null :
                  (
                    <>
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Currency:
                &nbsp;
                {company.properties.currency}
                {company.properties.currency ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                VAT Number:
                &nbsp;
                {company.properties.vat_number}
                {company.properties.vat_number ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Invoice To:
                &nbsp;
                {company.properties.invoice_details}
                {company.properties.invoice_details ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Needs PO?:
                &nbsp;
                {company.properties.needs_po}
                {company.properties.needs_po ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Payment Terms:
                &nbsp;
                {company.properties.payment_terms}
                {company.properties.payment_terms ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
              </div>
            </div>
            <footer className="slds-card__footer">
              <a
                href={`${Constants.HUBSPOT__COMPANY__BASE_URL}/${company.id}`}
                type="button"
                target="_blank"
                // eslint-disable-next-line react/jsx-props-no-multi-spaces, spellcheck/spell-checker
                rel="noreferrer"
                style={{ fontSize: 14 }}
              >
                View in Hubspot
              </a>
            </footer>
          </article>
        </>
      ) :
      null
  );
};

CompanyOverview.propTypes = {
  /* company object from Hubspot */
  // eslint-disable-next-line react/forbid-prop-types
  company: PropTypes.object.isRequired,
  /* function to handle clicking on Refresh button */
  handleRefresh: PropTypes.func.isRequired,
};

export default CompanyOverview;
