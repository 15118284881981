import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const GuidanceTipsAPI = {
  /**
   * Retrieves all Guidance Tips
   * @param {Object} cancelToken - The cancel token from Axios
   * @returns {Promise<Object>} An object with `data` property - the retrieved Guidance Tips - and `success`
   */
  getGuidanceTips: async (cancelToken) => {
    const { data: { data } } = await axios.get(
      `${apiUrl}/admin/guidance-tips`,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return data;
  },

  /**
   * Creates a Guidance Tip
   * @param {Object} cancelToken - The cancel token from Axios
   * @param {Object} guidanceTip - Guidance Tip Object
   * @return {Promise<Object>} An object with `data` property - the created Guidance Tip - and `success`
   */
  createGuidanceTip: async (
    cancelToken,
    guidanceTip,
  ) => {
    const { data: { data } } = await axios.post(
      `${apiUrl}/admin/guidance-tips`, guidanceTip, {
        cancelToken,
        withCredentials: true,
      },
    );
    return data;
  },

  /**
   * Updates a Guidance Tip
   * @param {Object} cancelToken - The cancel token from Axios
   * @param {String} _id - ID of the Guidance Tip to update
   * @param {Object} guidanceTipProperties - Properties to update
   * @return {Promise<Object>} An object with `data` property - the updated Guidance Tip - and `success`
   */
  updateTip: async (
    cancelToken,
    _id,
    guidanceTipProperties,
  ) => {
    const { data: { data } } = await axios.put(
      `${apiUrl}/admin/guidance-tips/${_id}`,
      guidanceTipProperties, {
        cancelToken,
        withCredentials: true,
      },
    );

    return data;
  },

  /**
   * Deletes a Guidance Tip
   * @param {Object} cancelToken - The cancel token from Axios
   * @param {String} _id - ID of the Guidance Tip to delete
   * @returns {Promise<Object>} An object with `data` property - the deleted Guidance Tip - and `success`
   */
  deleteGuidanceTip: async (cancelToken, _id) => {
    const { data } = await axios.delete(
      `${apiUrl}/admin/guidance-tips/${_id}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return data;
  },
};

export default GuidanceTipsAPI;
