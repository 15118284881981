import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AlertsAPI = {
  /**
   * Retrieves all notifications
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved notifications - and `success`
   */
  getAlerts: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/alerts`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Creates an alert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} title - Title of the alert
   * @param {string} message - Message of the alert
   * @param {string} type - Type of the alert
   * @param {string} recipient - Recipient of the alert (all or specific organization)
   * @param {array} organisations - Specific organizations to send email to
   * @return {object} An object with `data` property - the created alert - and `success`
   */
  createAlert: async (
    cancelToken,
    {
      title,
      message,
      type,
      recipient,
      organisations,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/alerts`, {
        title,
        type,
        message,
        recipient,
        organisations,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates an alert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the alert you want to update
   * @param {string} title - Title of the alert
   * @param {string} message - Message of the alert
   * @param {string} type - Type of the alert
   * @return {object} An object with `data` property - the updated alert - and `success`
   */
  updateAlert: async (
    cancelToken,
    id,
    {
      title,
      message,
      type,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/alerts/${id}`, {
        title,
        message,
        type,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes an alert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the alert you want to delete
   * @returns {object} An object with `data` property - the deleted alert - and `success`
   */
  deleteAlert: async (cancelToken, id) => {
    const res = await axios.post(
      `${apiUrl}/admin/alerts/${id}/delete`, null, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default AlertsAPI;
