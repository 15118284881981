import React, {
  useState,
} from 'react';

import OrganisationsAPI from '../../../../api/organisations';

import './style.scss';

const Features = () => {
  const [criteria, setCriteria] = useState();
  const [feature, setFeature] = useState();
  const [defaultValue, setDefaultValue] = useState('true');
  const [defaultValueOther, setDefaultValueOther] = useState();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  /**
   * Handle changing Criteria input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeCriteria = (e) => {
    setCriteria(e.target.value);
  };

  /**
   * Handle changing Feature input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeFeature = (e) => {
    setFeature(e.target.value);
  };

  /**
   * Handle changing Default value input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeDefaultValue = (e) => {
    setDefaultValue(e.target.value);
  };

  /**
   * Handle changing Default value other input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeDefaultValueOther = (e) => {
    setDefaultValueOther(e.target.value);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Validate if valid JSON was passed
    let criteriaObject;
    try {
      criteriaObject = JSON.parse(criteria);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('No valid JSON passed for criteria');
      return;
    }

    // Disable submit button
    setSubmitButtonDisabled(true);
    try {
      let chosenDefaultValue;
      // Set as boolean true or false
      if (defaultValue === 'true') {
        chosenDefaultValue = true;
      } else if (defaultValue === 'false') {
        chosenDefaultValue = false;
      // or set as string
      } else if (defaultValue === 'other') {
        chosenDefaultValue = defaultValueOther;
      }

      const res = await OrganisationsAPI.newFeatureFlag(null, feature, chosenDefaultValue, criteriaObject);
      const resultText = res.data.join('\n');
      // eslint-disable-next-line no-alert
      alert(`Feature flag ${feature} successfully set to ${chosenDefaultValue} ` +
      `for ${res.data.length} organisations: \n` + resultText);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Package Installation Failed: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  return (
    <>
      Set (new) feature flag for selected organisations
      <br />

      <form id="form" onSubmit={submit}>

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="criteria">
            Input for mongo query to select organisation that need to get this feature flag set, eg
            &#123; &quot;edition&quot;: &quot;plus&quot; &#125;
          </label>
          <div className="slds-form-element__control">
            <textarea
              id="criteria"
              placeholder=""
              className="slds-textarea"
              onChange={e => handleChangeCriteria(e)}
            />
          </div>
        </div>

        <br />

        <div className="slds-form-element">
          <label
            className="slds-form-element__label"
            htmlFor="feature"
          >
            <abbr className="slds-required" title="required">* </abbr>
            Feature

          </label>
          <div className="slds-form-element__control">
            <input
              type="text"
              id="feature"
              className="slds-input"
              onChange={e => handleChangeFeature(e)}
            />
          </div>
        </div>

        <br />

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="defaultValue">
            <abbr className="slds-required" title="required">* </abbr>
            Default Value
          </label>

          <fieldset className="slds-form-element">
            <div className="slds-form-element__control">
              <span className="slds-radio">
                <input
                  type="radio"
                  id="default-value-true"
                  value="true"
                  name="defaultValue"
                  checked={defaultValue === 'true'}
                  onChange={e => handleChangeDefaultValue(e)}
                />
                <label className="slds-radio__label" htmlFor="default-value-true">
                  <span className="slds-radio_faux" />
                  <span className="slds-form-element__label">True</span>
                </label>
              </span>
              <span className="slds-radio">
                <input
                  type="radio"
                  id="default-value-false"
                  value="false"
                  name="defaultValue"
                  checked={defaultValue === 'false'}
                  onChange={e => handleChangeDefaultValue(e)}
                />
                <label className="slds-radio__label" htmlFor="default-value-false">
                  <span className="slds-radio_faux" />
                  <span className="slds-form-element__label">False</span>
                </label>
              </span>
              <span className="slds-radio">
                <input
                  type="radio"
                  id="default-value-other"
                  value="other"
                  name="defaultValue"
                  checked={defaultValue === 'other'}
                  onChange={e => handleChangeDefaultValue(e)}
                />
                <label className="slds-radio__label" htmlFor="default-value-other">
                  <span className="slds-radio_faux" />
                  <span className="slds-form-element__label">
                    Other:&nbsp;
                    <input
                      type="text"
                      id="otherDefaultValue"
                      className="slds-input"
                      onChange={e => handleChangeDefaultValueOther(e)}
                    />
                  </span>
                </label>
              </span>
            </div>
          </fieldset>
        </div>

        <br />

        <button type="submit" className="slds-button slds-button_brand" id="submit" disabled={submitButtonDisabled}>
          Submit
        </button>

      </form>
    </>
  );
};

export default Features;
