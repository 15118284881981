import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import SunEditor from 'suneditor-react';

import SearchApi from '../../../../api/search';
import './style.scss';
import Swal from '../../../../helpers/Swal';
import Util from '../../../../util';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Constants from '../../../../constants/constants';

const useHomeMessage = () => {
  const [message, setMessage] = useState({ text: '', isActive: false });
  const [loading, setLoading] = useState(false);
  const editor = useRef();

  /**
   * save search home message
   * @returns {void}
   */
  const getHomeMessage = async () => {
    try {
      setLoading(true);
      const res = await SearchApi.getHomeMessage(null);
      setLoading(false);
      if (res.success) {
        setMessage(res.data);
        editor.current?.setContents(res.data?.text);
      }
    } catch (error) {
      setLoading(false);
      Util.handleError(error);
    }
  };

  /**
   * save search home message
   * @returns {void}
   */
  const updateHomeMessage = async () => {
    try {
      setLoading(true);
      const res = await SearchApi.updateHomeMessage(null, message);
      setLoading(false);
      if (res.success) {
        Swal.fireSuccess('Saved!', 'Home page message has been saved successfully');
      }
    } catch (error) {
      setLoading(false);
      Util.handleError(error);
    }
  };

  return {
    editor,
    message,
    setMessage,
    loading,
    getHomeMessage,
    updateHomeMessage,
  };
};

const SearchHomeMessage = () => {
  const {
    editor, message, setMessage, loading, getHomeMessage, updateHomeMessage,
  } = useHomeMessage();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  useEffect(() => {
    getHomeMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__MARKETING,
      Constants.USER__PERMISSION__WRITE,
    );

  // class names for material switch
  const materialSwitchClassName = classNames(
    'label-primary',
    // eslint-disable-next-line quote-props
    { 'material-switch-active': message?.isActive },
  );

  const activeSwitchClassName = classNames(
    'Active-switch mt-2',
    { 'Active-switch--disabled': !userHasEditRights },
  );

  return (
    <>
      <div className="col-md-10 col-lg-8 form-container">

        <h4>
          Update Search Home Page Message
        </h4>
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          id="form-element-Search-Home-Page-Message"
          setContents={message?.text}
          onChange={text => setMessage(Message => ({ ...Message, text }))}
          disable={loading || !userHasEditRights}
          autoFocus
          width="100%"
          height="300px"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'align'],
              ['fontSize', 'formatBlock', 'fontColor'],
              ['bold', 'underline', 'italic', 'strike'],
              ['link'],
              ['lineHeight', 'list', 'table', 'fullScreen', 'codeView'],
            ],
          }}
        />

        <div className={activeSwitchClassName}>
          <span className="Active-label">
            Active
          </span>
          <div className="material-switch">

            <label
              htmlFor="material-switch"
              className={materialSwitchClassName}
            >
              <input
                id="material-switch"
                checked={message?.isActive}
                onChange={() => setMessage({ ...message, isActive: !message?.isActive })}
                disabled={!userHasEditRights}
                // eslint-disable-next-line spellcheck/spell-checker
                name="adcheckbox"
                type="checkbox"
              />
            </label>
          </div>
        </div>

        {
          loading ?
            <div className="spinner-border text-primary mt-3 mr-3 h6 float-right" /> :
            (
              <button
                type="submit"
                disabled={loading || !userHasEditRights}
                className="slds-button slds-button_brand mt-2 float-right save-search-btn"
                onClick={updateHomeMessage}
              >
                Save
              </button>
            )
        }

      </div>
    </>
  );
};

export default SearchHomeMessage;
