import Swal from 'sweetalert2';

const _Swal = {
  /**
   * Get timer options
   * @param {number} timer - Timer in ms
   * @param {boolean} [showProgressBar=true] - True if the progress bar should be visible
   * @param {string} [selector=b] - Selector to find the text to replace with the time left
   * @returns {object} Timer options: timer, timerProgressBar, onBeforeOpen, onClose
   */
  TIMER: (timer, showProgressBar = true, selector = 'b') => {
    let timerInterval;
    return {
      timer,
      timerProgressBar: showProgressBar,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const $ = content.querySelector(selector);
            if ($) $.textContent = Math.round(Swal.getTimerLeft() / 1000);
          }
        }, 1000);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    };
  },

  /**
   * Fire a SweetAlert2
   * @param {string} type - The type of the alert
   * @param {string|object} titleOrOpt - The title or options for it
   * @param {string} [text] - Optional text if titleOrOpt is a string too
   * @returns {Promise<SweetAlertResult>} A Promise of SweetAlertResult
   * @see https://sweetalert2.github.io/#configuration for the available options
   */
  _fire: async (type, titleOrOpt, text) => {
    if (typeof titleOrOpt === 'string') {
      return Swal.fire({
        icon: type,
        title: titleOrOpt,
        text,
      });
    }
    return Swal.fire({
      icon: type,
      ...titleOrOpt,
      text,
    });
  },

  /**
   * Fire a SweetAlert2 success
   * @param {string|object} titleOrOpt - The title of the success or options for it
   * @param {string} [text] - Optional text if titleOrOpt is a string too
   * @returns {Promise<SweetAlertResult>} A Promise of SweetAlertResult
   * @see https://sweetalert2.github.io/#configuration for the available options
   */
  fireSuccess: async (titleOrOpt, text) => _Swal._fire('success', titleOrOpt, text),

  /**
   * Fire a SweetAlert2 error
   * @param {string|object} titleOrOpt - The title of the error or options for it
   * @param {string} [text] - Optional text if titleOrOpt is a string too
   * @returns {Promise<SweetAlertResult>} A Promise of SweetAlertResult
   * @see https://sweetalert2.github.io/#configuration for the available options
   */
  fireError: async (titleOrOpt, text) => _Swal._fire('error', titleOrOpt, text),

  /**
   * Fire a SweetAlert2 warning
   * @param {string|object} titleOrOpt - The title of the warning or options for it
   * @param {string} [text] - Optional text if titleOrOpt is a string too
   * @returns {Promise<SweetAlertResult>} A Promise of SweetAlertResult
   * @see https://sweetalert2.github.io/#configuration for the available options
   */
  fireWarning: async (titleOrOpt, text) => _Swal._fire('warning', titleOrOpt, text),
};

Object.assign(Swal, _Swal);

export default Swal;
