import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const DealOverview = ({
  deal,
  handleRefresh,
}) => {
  const [refreshing, setRefreshing] = useState(false);

  /**
   * Handle clicking refresh button
   * @returns {void}
   */
  const clickRefresh = async () => {
    setRefreshing(true);
    await handleRefresh();
    setRefreshing(false);
  };

  return (
    deal.properties ?
      (
        <>
          <article className="slds-card">
            <div className="slds-card__header slds-grid">
              <header className="slds-media slds-media_center slds-has-flexi-truncate">
                <div className="slds-media__figure">
                  <span className="slds-icon_container slds-icon-standard-opportunity" title="deal">
                    <svg
                      className={`slds-icon slds-icon_small ${refreshing ?
                        'fa-spin' :
                        ''} `}
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#opportunity" />
                    </svg>
                    <span className="slds-assistive-text">deal</span>
                  </span>
                </div>
                <div className="slds-media__body">
                  <h2 className="slds-card__header-title">
                    <span>Deal Highlights</span>
                  </h2>
                </div>
                <div className="slds-no-flex">
                  <button
                    className="slds-button slds-button_neutral"
                    type="button"
                    onClick={clickRefresh}
                  >
                    {refreshing ? 'Loading...' : 'Refresh'}
                  </button>
                </div>
              </header>
            </div>
            <div className="slds-card__body slds-card__body_inner">
              <div className="slds-form-element__label">
                Name:
                &nbsp;
                {deal.properties.dealname}
                <br />
                Amount:
                &nbsp;
                {Number(deal.properties.amount).toLocaleString()}
                &nbsp;
                {deal.properties.deal_currency_code}
                <br />
                Deal Stage:
                &nbsp;
                {deal.properties.dealstage}
                <br />
                Deal Type:
                &nbsp;
                {deal.properties.dealtype}
                {deal.properties.dealtype ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Deal SubType:
                &nbsp;
                {deal.properties.deal_subtype}
                {(deal.properties.deal_subtype || deal.properties.dealtype !== 'upsell') ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Contract Specifics:
                &nbsp;
                {deal.properties.contract_specifics}
                {deal.properties.contract_specifics ?
                  null :
                  (
                    <>
                      <span>
                        No contract specifics
                      </span>
                    </>
                  )}
                <br />
                PO Number:
                &nbsp;
                {deal.properties.po_number}
                {deal.properties.po_number ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                {deal.properties.order_number ?
                  (
                    <>

                      Invoice Order Number:
                      &nbsp;
                      {deal.properties.order_number}
                      &nbsp;
                      <font className="highlight-deal-prop">
                        An Invoice Order Number already exists. Invoice creation was triggered before
                      </font>
                      <br />
                    </>
                  ) :
                  null}
                {deal.properties.invoice_number ?
                  (
                    <>
                      Invoice Number:
                      &nbsp;
                      {deal.properties.invoice_number}
                      &nbsp;
                      <font className="highlight-deal-prop">
                        An Invoice Number already exists. Invoice was sent out before.
                      </font>
                      <br />
                    </>
                  ) :
                  null}
                Invoice From DESelect Company:
                &nbsp;
                {deal.properties.deselect_company}
                &nbsp;
                {deal.properties.deselect_company ?
                  null :
                  (
                    <font className="highlight-deal-prop">
                      This field is required.
                    </font>
                  )}
                <br />

                Contract Start Date:
                &nbsp;
                {deal.properties.contract_start_date}
                {deal.properties.contract_start_date ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                Terms (months):
                &nbsp;
                {deal.properties.term_months_}
                {deal.properties.term_months_ ?
                  null :
                  (
                    <>
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This field is required.
                      </font>
                    </>
                  )}
                <br />
                {deal.properties.stamp_date ?
                  (
                    <>
                      Stamp Date:
                      &nbsp;
                      {deal.properties.stamp_date}
                      &nbsp;
                      <font className="highlight-deal-prop">
                        This deal has been stamped before!
                      </font>
                    </>
                  ) :
                  null}

              </div>
            </div>
            <footer className="slds-card__footer">
              <a
                href={'https://app.hubspot.com/contacts/5443771/deal/' + deal.id}
                type="button"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 14 }}
              >
                View in Hubspot
              </a>
            </footer>
          </article>
          <br />

          {deal.properties?.stamp_date && deal.properties?.dealtype.includes('upsell') ?
            (
              <div
                className="slds-scoped-notification
                  slds-media slds-media_center slds-theme_warning"
                role="status"
              >
                <div className="slds-media__figure">
                  <span className="slds-icon_container slds-icon-utility-warning" title="warning">
                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning" />
                    </svg>
                    <span className="slds-assistive-text">warning</span>
                  </span>
                </div>
                <div className="slds-media__body">
                  <p className="notification">
                    This deal has been stamped before.
                    {' '}
                    <br />
                    Do not restamp the Company as for an Upsell deal that will mess up
                    the auto-calculated fields on the Company.
                  </p>
                </div>
              </div>
            ) :
            null}
        </>
      ) :
      null
  );
};

DealOverview.propTypes = {
  /* deal object from Hubspot */
  deal: PropTypes.object.isRequired,
  /* function to handle clicking on Refresh button */
  handleRefresh: PropTypes.func.isRequired,
};

export default DealOverview;
