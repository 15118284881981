import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const adminPass = process.env.REACT_APP_ADMIN_PASS;

const UsersAPI = {
  /**
   * Retrieves all users
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `users` property - the retrieved users - and `success`
   */
  getUsers: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/users`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves all users for one organisation
   * @param {object} orgId - Id of the organisation for which we request the users
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `users` property - the retrieved users - and `success`
   */
  getUsersForOrg: async (orgId, cancelToken) => {
    const res = await axios.get(`${apiUrl}/admin/users/${orgId}`, {
      cancelToken,
      withCredentials: true,
    });
    return res.data;
  },

  /**
   * Updates a user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user to edit
   * @param {boolean} isActive - Should this user be active
   * @param {boolean} isAdmin - Should this user be an admin
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateUser: async (
    cancelToken,
    id,
    {
      isActive,
      isAdmin,
      zendeskID,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/users/${id}`, {
        pw: adminPass,
        isActive,
        isAdmin,
        zendeskID,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default UsersAPI;
