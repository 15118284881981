import React, { useContext } from 'react';
import { CSVLink } from 'react-csv';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';

const GeneratedExams = () => {
  const [results] = useContext(ResultsContext);
  const { certificationsGeneratedExams, certificationsUsersData, exams } = results;

  const exportDetails = (exam) => {
    // Initialize map
    const questionsMap = new Map();

    /**
     * Add each question id inside the map
     * As value, set an object with all the question details (question, array of answers and correct answer)
     */
    exam.questionsIds.forEach((question) => {
      questionsMap.set(question._id,
        {
          question: question.question,
          answers: question.answers,
          correctAnswer: question.correctAnswer,
        });
    });

    // Loop through the generated exam's answers to build the rows
    const examDetails = exam.answers.reduce((csv, answer) => {
      // Find question details inside map
      const question = questionsMap.get(answer.questionId);

      // Generate a row with the details of each answer
      csv.push([
        question?.question, // Question
        question?.answers[0], // First Answer
        question?.answers[1], // Second Answer
        question?.answers[2], // Third Answer
        question?.answers[3], // Fourth Answer
        question?.answers[4], // Fifth Answer
        question?.answers[question.correctAnswer], // Correct Answer
        question?.answers[answer.response], // Exam response for this question
        answer?.laterReviewQuestion, // Was this marked for later review?
        answer?.isUnclear, // Was this unclear?
        question?.correctAnswer === answer?.response, // Was the response the correct answer?
      ]);

      return csv;
    }, [Constants.GENERATED_EXAM__CSV__COLUMNS]);

    return examDetails;
  };

  /**
   * Function called to show the user completion time for the exam
   * @param {date} startTime - start date of the exam
   * @param {date} endTime - end date of the exam
   * @returns {string} time of completion of the exam in the format mm:ss
   */
  const calculateEndTimeByUser = (startTime, endTime) => {
    // trigger function if user ends the exam
    if (endTime) {
      let minutes;
      let seconds;

      // calculate minutes and seconds
      const getTime = new Date(endTime) - new Date(startTime);
      const calculatedMinutes = Math.floor((getTime % (1000 * 60 * 60 * 60)) / (1000 * 60));
      const calculatedSeconds = Math.floor((getTime % (1000 * 60)) / 1000);

      // add a zero before any digit less than 10
      if (calculatedMinutes < 10) {
        minutes = `0${calculatedMinutes}`;
      } else {
        minutes = calculatedMinutes;
      }

      // add a zero before any digit less than 10
      if (calculatedSeconds < 10) {
        seconds = `0${calculatedSeconds}`;
      } else {
        seconds = calculatedSeconds;
      }

      // return time in format mm:ss
      return `${minutes}:${seconds}`;
    }

    return false;
  };

  return (
    <div className="generated-exams">
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="User Id">
                User Id / Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Exam Id">
                Exam Id / Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Questions">
                Questions
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Answers">
                Answers
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Score [%]">
                Score [%]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Status">
                Status
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Exam time [mm:ss]">
                Exam time [mm:ss]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Time spent [mm:ss]">
                Time spent [mm:ss]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Export to csv">
                Export
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {certificationsGeneratedExams && certificationsGeneratedExams.length > 0 &&
          certificationsGeneratedExams.map(el => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div className="slds-truncate" title={el._id}>
                  {el._id}
                </div>
              </td>

              <td data-label="User Id">
                <div
                  className="slds-truncate"
                  title={`${el.userId} / ${Util.returnPropertyNameById(
                    certificationsUsersData, el.userId, true,
                  )}`}
                >
                  {`${el.userId} / ${Util.returnPropertyNameById(certificationsUsersData, el.userId, true)}`}
                </div>
              </td>

              <td data-label="Exam Id">
                <div
                  className="slds-truncate"
                  title={`${el.examId} / ${Util.returnPropertyNameById(
                    exams, el.examId,
                  )}`}
                >
                  {`${el.examId} / ${Util.returnPropertyNameById(exams, el.examId)}`}
                </div>
              </td>

              <td data-label="Questions">
                <div className="slds-truncate" title={el.questionsIds.length}>
                  {el.questionsIds.length}
                </div>
              </td>

              <td data-label="Answers">
                <div className="slds-truncate" title={el.answers.length}>
                  {el.answers.length}
                </div>
              </td>

              <td data-label="Score">
                <div className="slds-truncate" title={el.score ? Math.round(el.score) : ''}>
                  {el.score ? Math.round(el.score) : ''}
                </div>
              </td>

              <td data-label="Status">
                <div className="slds-truncate" title={el.status ? el.status : ''}>
                  {el.status === 'passed' ?
                    (
                      <>
                        <svg className="slds-icon slds-icon_small exam-success" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
                        </svg>
                      </>
                    ) :
                    ''}
                  {el.status === 'failed' ?
                    (
                      <>
                        <svg className="slds-icon slds-icon_small exam-failed" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear" />
                        </svg>
                      </>
                    ) :
                    ''}
                </div>
              </td>

              <td data-label="Exam Time">
                <div className="slds-truncate" title={el.time < 10 ? `0${el.time}:00` : `${el.time}:00`}>
                  {el.time < 10 ? `0${el.time}:00` : `${el.time}:00`}
                </div>
              </td>

              <td data-label="Time spent">
                <div className="slds-truncate" title={calculateEndTimeByUser(el.startTime, el.endTime) || ''}>
                  {calculateEndTimeByUser(el.startTime, el.endTime) || ''}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                  {new Date(el.updatedAt).toLocaleString()}
                </div>
              </td>

              <td title="Delimiter used: #">
                <CSVLink data={exportDetails(el)} filename={`${el._id}.csv`} separator="#">
                  <svg className="slds-icon slds-icon_small export" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#download" />
                  </svg>
                </CSVLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeneratedExams;
