import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import MultiSelect from '../atoms/MultiSelect';
import '../style.scss';

const TrainingModal = ({
  isOpen, name, setName, organisationIds, setOrganisationIds, startDate, setStartDate,
  handleCancel, handleSave, eventType, organisationsData, hiddenToNoOrgUsers, setHiddenToNoOrgUsers,
}) => {
  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (organisationIds?.length === 1) {
      return `${Util.returnPropertyNameById(organisationsData, organisationIds[0])}`;
    } if (organisationIds?.length > 1) {
      return `${organisationIds.length} Organisations Selected`;
    } if (!organisationIds?.length) {
      return 'Select Organisation';
    }

    return '';
  };

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="trainings-self-learning-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {eventType === Constants.EVENT__TYPE__NEW ? 'Create Training' : 'Edit Training'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-self-learning"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    placeholder="Title"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </div>
              </label>
            </div>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="slds-form-element__label" htmlFor="multi-select">
              Organisations
              <MultiSelect
                multiSelectPlaceholder={returnMultiSelectPlaceholder()}
                value={organisationIds}
                data={organisationsData}
                setData={setOrganisationIds}
              />
            </label>
            <div className="slds-form-element">
              <span
                className="slds-form-element__label"
              >
                Is hidden to users without organisation
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setHiddenToNoOrgUsers(!hiddenToNoOrgUsers)}
                      type="checkbox"
                      name="hiddenToNoOrgUsers"
                      id="hiddenToNoOrgUsers"
                      checked={hiddenToNoOrgUsers}
                    />
                    <label className="slds-checkbox__label" htmlFor="hiddenToNoOrgUsers">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {hiddenToNoOrgUsers ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </span>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="startDate">
                Start date
                <div>
                  <input
                    type="date"
                    onChange={event => setStartDate(event.target.value)}
                    value={startDate}
                    min={new Date().toLocaleDateString('en-CA')}
                  />
                </div>
              </label>
            </div>
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

TrainingModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** Label for module name */
  name: PropTypes.string.isRequired,
  /** sets module name in state */
  setName: PropTypes.func.isRequired,
  /** array with organisation Ids for which we assign the training */
  organisationIds: PropTypes.instanceOf(Array).isRequired,
  /** sets array with organisation Ids in state */
  setOrganisationIds: PropTypes.func.isRequired,
  /** starting date of the training */
  startDate: PropTypes.string.isRequired,
  /** sets startDate for training in state  */
  setStartDate: PropTypes.func.isRequired,
  /** an array with organizations data */
  organisationsData: PropTypes.instanceOf(Array).isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string,
  /** informs if we should hide the training from users without organisation */
  hiddenToNoOrgUsers: PropTypes.bool.isRequired,
  /** sets hiddenToNoOrgUsers in state */
  setHiddenToNoOrgUsers: PropTypes.func.isRequired,
};

export default TrainingModal;
