import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [menuSelected, setMenuSelected] = useState('');
  const [menuParameters, setMenuParameters] = useState();

  return (
    <MenuContext.Provider value={
      {
        menuSelected: [menuSelected, setMenuSelected],
        menuParameters: [menuParameters, setMenuParameters],
      }
    }
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export { MenuProvider };
