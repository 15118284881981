import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import { MenuProvider } from './MenuContext';
import { ResultsProvider } from './ResultsContext';
import { UtilsProvider } from './UtilsContext';

// Components
import ProtectedRoute from './components/Route/ProtectedRoute';
import Login from './components/Login/Login';
import Main from './components/Main/Main';

import Constants from './constants/constants';

function App() {
  return (
    <MenuProvider>
      <ResultsProvider>
        <UtilsProvider>
          <Router>
            <Switch>
              {/* This route is for '/main' */}
              <ProtectedRoute
                exact
                path={Constants.ROUTE__OVERVIEW}
                redirectTo={Constants.ROUTE__LOGIN} // Redirect to login if user is not auth.
                component={Main}
              />
              {/* This route is for  '/' and '/login' */}
              <ProtectedRoute
                exact
                path={Constants.ROUTE__LOGIN}
                notAuthOnly
                redirectTo={Constants.ROUTE__OVERVIEW} // Redirect to overview if user is already auth.
                component={Login}
              />
              {/* If no route could handle the URI, redirect to root */}
              <Redirect to="/" />
            </Switch>
          </Router>
        </UtilsProvider>
      </ResultsProvider>
    </MenuProvider>
  );
}

export default App;
