import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Icon } from 'semantic-ui-react';
import Swal from '../../../../../helpers/Swal';

const FeaturesModal = ({
  isOpen, handleExit, features, setFeatures, saveFeature, updateFeature, deleteFeature,
}) => {
  const [newFeature, setNewFeature] = useState('');
  const [editedFeature, setEditedFeature] = useState('');
  const [currentlyEditedID, setCurrentlyEditedID] = useState(false);

  /**
   * Function for checking if typed value is valid, and show warning swal if needed.
   * @param {string} value - The value the user typed for the feature
   * @returns {boolean} - True if value was valid, false otherwise
   */
  const isValueValid = (value) => {
    // Create a message for swal in case of invalid values
    let message;

    // Make sure value is not empty
    if (value?.trim() === '') message = 'Empty values are not accepted.';
    else {
      // check if the value already exists in another feature
      const duplicateAnswer =
        features.find(feature => (
          feature?.name?.trim()?.toUpperCase() === value?.trim()?.toUpperCase()
        ));

      // In case user did not change at all the value, just exit edit mode
      if (duplicateAnswer?._id === currentlyEditedID) {
        // Reset input value
        setEditedFeature('');
        setCurrentlyEditedID(null);

        return false;
      }

      // If duplicate value was found show custom message
      if (duplicateAnswer) message = 'This feature already exists. Please enter another one.';
    }

    // Check if an invalid value was entered
    if (message) {
      /*
       * Show swal message.
       * Add a small delay before showing the modal so that the modal will not close after pressing enter
       */
      setTimeout(() => {
        Swal.fireWarning('', message);
      }, 50);

      // Value is not valid so return false
      return false;
    }

    // Value is valid so return true
    return true;
  };

  /**
   * Function for adding features
   * @returns {void}
   */
  const addFeature = async () => {
    // First check if a valid value was typed
    if (isValueValid(newFeature)) {
      // Send request to save the new feature value
      const savedFeature = await saveFeature(newFeature);

      // Add new feature to state
      setFeatures([...features, savedFeature]);

      // Reset input value
      setNewFeature('');
    }

    return false;
  };

  /**
   * Function for editing an existing feature
   * @param {string} editedFeatureId - currently edited feature id
   * @returns {void}
   */
  const handleEditClick = async (editedFeatureId) => {
    // First check if a valid value was typed
    if (isValueValid(editedFeature)) {
      // Send request to the feature value
      const updatedFeature = await updateFeature(editedFeatureId, editedFeature);

      // Update features in state with the new value
      setFeatures(
        features.map((feature) => {
          if (feature._id === updatedFeature._id) {
            return updatedFeature;
          }
          return feature;
        }),
      );

      // Reset input value
      setEditedFeature('');
      setCurrentlyEditedID(null);
    }
  };

  /**
   * Function for removing feature
   * @param {string} featureId - removed feature with given id
   * @returns {void}
   */
  const removeFeature = async (featureId) => {
    // Send request to delete feature
    await deleteFeature(featureId);

    // Remove feature from state
    setFeatures(features.filter(feature => featureId !== feature._id));
  };

  /**
   * Function which sets the state before editing the feature
   * @param {string} feature - edited feature
   * @returns {void}
   */
  const editIconFunction = (feature) => {
    // set the edited feature to be shown in the input field
    setCurrentlyEditedID(feature._id);
    setEditedFeature(feature.name);
  };

  /**
   * Function that shows the input after pressing the icon to edit
   * @param {string} feature - feature being edited
   * @returns {void}
   */
  const showInput = feature => (
    <>
      <input
        className="slds-input add-answer-input add-questions-input"
        id={feature._id}
        placeholder="Add a new feature value"
        value={editedFeature}
        onKeyDown={e => (e.key === 'Enter' && handleEditClick(feature._id))}
        onChange={e => setEditedFeature(e.target.value)}
      />
      <button
        type="button"
        className="slds-button slds-button_icon slds-button_neutral
        add-questions-button"
        onClick={() => handleEditClick(feature._id)}
      >
        Edit
      </button>
    </>
  );

  return (
    isOpen ?
      (
        <>
          <section
            role="dialog"
            tabIndex="-1"
            aria-labelledby="modal-heading-01"
            aria-modal="true"
            aria-describedby="modal-content-id-1"
            className="slds-modal slds-fade-in-open"
          >
            <div className="slds-modal__container" id="access-to-certification-modal" style={{ fontSize: '12px' }}>
              <header className="slds-modal__header">
                <h2
                  id="modal-heading-01"
                  className="slds-modal__title slds-hyphenate"
                >
                  Features
                </h2>
              </header>
              <div
                className="slds-modal__content slds-p-around_medium"
                id="modal-content-id-1"
                style={{ minHeight: '10rem' }}
              >
                <div className="slds-form-element__control questions-list">
                  {features.map((feature, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className="question-item" key={feature._id}>
                      <div className="question-icon-button-box">
                        {currentlyEditedID === feature._id ?
                          (
                            showInput(feature)
                          ) :
                          (
                            <p>
                              {index + 1}
                              .
                              {' '}
                              {feature.name}
                            </p>
                          )}
                        {currentlyEditedID !== feature._id && (
                          <button
                            type="button"
                            className="icon-button-add-questions"
                            onClick={() => editIconFunction(feature)}
                          >
                            <Icon name="edit" />
                          </button>
                        )}
                        <button
                          type="button"
                          className="icon-button-add-questions"
                          onClick={() => removeFeature(feature._id)}
                        >
                          <Icon name="trash" />
                        </button>
                      </div>
                    </li>
                  ))}
                </div>
                <div className="slds-form-element input-button-add-question">
                  <input
                    className="slds-input add-answer-input placeholder add-questions-input"
                    type="text"
                    placeholder="Add a new feature value"
                    onKeyDown={e => (e.key === 'Enter' && addFeature())}
                    value={newFeature}
                    title="Add a new feature value"
                    onChange={(e) => { setNewFeature(e.target.value); }}
                  />
                  <button
                    type="button"
                    onClick={addFeature}
                    className="slds-button slds-button_icon slds-button_neutral add-answer-button"
                    title="Add Feature"
                  >
                    Add Feature
                  </button>
                </div>
              </div>
              <footer className="slds-modal__footer">
                <button
                  type="button"
                  className="slds-button slds-button_neutral"
                  onClick={handleExit}
                >
                  Exit
                </button>
              </footer>
            </div>
          </section>
          <div className="slds-backdrop slds-backdrop_open" />
        </>
      ) :
      null
  );
};

FeaturesModal.propTypes = {
  /* existing features */
  features: PropTypes.instanceOf(Array),
  /* informs if the modal is open */
  isOpen: PropTypes.bool.isRequired,
  /* handles closing the modal */
  handleExit: PropTypes.func.isRequired,
  /* sets features to state */
  setFeatures: PropTypes.func.isRequired,
  /* handles saving a new feature */
  saveFeature: PropTypes.func.isRequired,
  /* handles updating a feature */
  updateFeature: PropTypes.func.isRequired,
  /* handles deleting a feature */
  deleteFeature: PropTypes.func.isRequired,
};

export default FeaturesModal;
