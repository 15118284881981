import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const TopicQuestionsAPI = {
  /**
   * Retrieves all topic questions
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved topic questions - and `success`
   */
  getAllTopicQuestions: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/topic-questions`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates topic question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} topicQuestionData - object with topic question data
   * @param {string} topicQuestionData.question - the content of the question
   * @param {array} topicQuestionData.answers - array with answers
   * @param {number} topicQuestionData.correctAnswer - index number of the correct answer
   * @param {string} topicQuestionData.topicId - topic Id
   * @return {object} An object with `data` property - the created topic question - and `success`
   */
  createTopicQuestion: async (
    cancelToken,
    {
      question,
      answers,
      correctAnswer,
      topicId,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/topic-questions`, {
        question,
        answers,
        correctAnswer,
        topicId,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates topic question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the topic question you want to update
   * @param {object} topicQuestionData - object with topic question data
   * @param {string} topicQuestionData.question - the content of the question
   * @param {array} topicQuestionData.answers - array with answers
   * @param {number} topicQuestionData.correctAnswer - index number of the correct answer
   * @param {string} topicQuestionData.topicId - topic Id
   * @return {object} An object with `data` property - the updated topic question - and `success`
   */
  updateTopicQuestion: async (
    cancelToken,
    id,
    {
      question,
      answers,
      correctAnswer,
      topicId,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/topic-questions/${id}`, {
        question,
        answers,
        correctAnswer,
        topicId,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Deletes topic question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the topic question you want to delete
   * @returns {object} An object with `data` property - the deleted topic question - and `success`
   */
  deleteTopicQuestion: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/topic-questions/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default TopicQuestionsAPI;
