import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import ModuleModal from './Modals/ModuleModal';
import './style.scss';
import ModulesAPI from '../../../../api/academyTrainings/modules';
import TrainingsAPI from '../../../../api/academyTrainings/trainings';
import TopicsAPI from '../../../../api/academyTrainings/topics';
import Spinner from './atoms/Spinner';

const Modules = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    modules, searchTextForModules, trainings, topicQuestions,
  } = results;

  // variables for module
  const [name, setName] = useState('');
  const [orders, setOrders] = useState([]);
  const [trainingIds, setTrainingIds] = useState([]);

  // variables for search bar
  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchModules, setSearchModules] = useState([]);
  const [searchBy, setSearchBy] = useState(Constants.SEARCH_BY__MODULE_NAME);

  const [deletedId, setDeletedId] = useState(null);

  /**
   * Get all modules from database
   * @returns {void}
   */
  const getModules = async () => {
    try {
      // get all modules
      const res = await ModulesAPI.getAllModules(null);

      // define trainings data
      let trainingRes = { data: trainings };

      if (!trainings?.length) {
        // get all trainings only if they have not been fetched
        trainingRes = await TrainingsAPI.getAllTrainings(null);
      }

      setState({
        ...state,
        modules: res.data,
        searchTextForModules: '',
        trainings: trainingRes.data,
      });

      // set a variable to search for data
      setSearchModules(res.data);

      const valuesToUpdate = {};
      valuesToUpdate.modules = res.data;
      valuesToUpdate.trainings = trainingRes.data;
      valuesToUpdate.searchTextForModules = '';
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    // when the field is not empty and there will be a switch between windows then get modules
    if (searchTextForModules !== '' || !modules?.length || !trainings?.length) {
      getModules();
    } else if (modules?.length) {
      setSearchModules(modules);
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === '' && isSearchFieldUsed) {
      // get all modules if the search field is empty and if it's used
      getModules();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      let filteredModules;

      /**
       * Search for modules by training name
       * @param {array} array - array to filter results
       * @returns {array} filtered array with search results
       */
      const searchByTrainingsName = array => (
        array.filter((mod) => {
          // get all trainings selected in the module
          const selectedTrainingsInModule = trainings.filter((tr) => {
            // get training by ID
            const trainingInModule = mod.trainingIds.find(trainingId => trainingId === tr._id);

            // if training is found - return it
            if (trainingInModule) return tr;

            return null;
          });

          // match the name of the training assigned to the modules with the search results
          if (selectedTrainingsInModule?.length) {
            const findTrainingByName = selectedTrainingsInModule.find(training => (
              training.name.toString().toLowerCase().includes(
                searchText.toString().toLowerCase(),
              )));

            if (findTrainingByName) { return mod; }
          }

          return null;
        }));

      // depends of searchBy
      if (searchBy === Constants.SEARCH_BY__MODULE_NAME) {
        // Filter the modules by module name
        filteredModules = searchModules?.length ?
          Util.searchByName(searchModules, searchText) :
          Util.searchByName(modules, searchText);
      } else {
        // Filter the modules by training name
        filteredModules = searchModules?.length ?
          searchByTrainingsName(searchModules) :
          searchByTrainingsName(modules);
      }

      // Set filtered modules and search text
      setState({
        ...state,
        modules: filteredModules,
        searchTextForModules: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};
      valuesToUpdate.modules = filteredModules;
      valuesToUpdate.searchTextForModules = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText, searchBy]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setName('');
    setTrainingIds([]);
    setOrders([]);
  };

  /**
   * Handler called when saving module
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      name,
      trainingIds,
      orders,
    };

    /**
     * Function that checks the validity of the entered data
     * @returns {void}
     */
    const validateResult = () => {
      const errors = [];

      // validate module name label
      const validateForLabel = name.trim() === '';
      // eslint-disable-next-line no-unused-expressions
      validateForLabel && errors.push('Please select module name');

      // validate trainings
      const validateTraining = trainingIds.length === 0;
      // eslint-disable-next-line no-unused-expressions
      validateTraining && errors.push('Please select at least one training');

      // validate order
      const validateOrder = orders?.length !== trainingIds?.length;
      // eslint-disable-next-line no-unused-expressions
      validateOrder && errors.push('Please select order for training');

      let trainingId;

      // for each selected training
      trainingIds.forEach((tr) => {
        // get reduced array with order numbers
        const orderNumbersForTraining = modules.reduce((array, mod) => {
          // find order object for training
          const findTrainingOrder = mod.orders.find(o => o.trainingId === tr);

          // order number
          const order = findTrainingOrder?.order;

          if (order) {
            // get selected training Id
            trainingId = findTrainingOrder?.trainingId;

            // put order number to an array
            return [...array, order];
          }

          return array;
        }, []);

        if (orderNumbersForTraining?.length) {
          // if a new module is being created
          if (state.eventType === Constants.EVENT__TYPE__NEW) {
            // push new order number to order numbers
            const newOrder = orders.find(el => el.trainingId === tr)?.order;
            orderNumbersForTraining.push(newOrder);
          }

          // check if there is duplicate number in orders
          const duplicate = orderNumbersForTraining.some((element, index) => (
            orderNumbersForTraining.indexOf(element) !== index));

          if (duplicate) {
            // show swal message that this order exists for selected training
            errors.push(`This order already exists for ${Util.returnPropertyNameById(trainings, trainingId)}`);
          }
        }
      });

      return errors;
    };

    if (validateResult().length > 0) {
      Swal.fireWarning('', validateResult()[0]);
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // create new module
          const res = await ModulesAPI.createModule(null, newData);

          // set the data from response
          setResults({
            ...results,
            modules: [
              ...modules,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            modules: [
              ...modules,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The module has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // update the module
          const res = await ModulesAPI.updateModule(null, state.id, newData);

          // set the data for the selected index
          const newArray = [...modules];
          newArray[state.index] = res.data;
          setResults({ ...results, modules: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            modules: newArray,
          });
          Swal.fireSuccess('Updated!', 'The module has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing a module
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { target: { dataset: { index, id } } } = event;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = modules.filter(m => m._id === id)[0];

    setName(singleItem.name);
    setTrainingIds(singleItem.trainingIds);
    setOrders(singleItem.orders);
  };

  /**
   * Handler called when deleting training
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html
    const { target: { dataset: { index, id } } } = event;

    // set id of the deleted module to start loading data
    setDeletedId(id);

    // fetch or get topics to checks which topic is linked to removed module
    const fetchedTopics = await TopicsAPI.getAllTopics(null);

    // get data for removed module
    const dataForRemovedModule = await Util.getAndRemoveOrUpdateTopics(id, fetchedTopics.data);

    // stop loading data
    setDeletedId(null);

    // create an array with topics name linked to removed module
    const topicsName = fetchedTopics.data.reduce((array, topic) => {
      // if removed module has linked topics
      if (dataForRemovedModule?.linkedTopics?.length) {
        // find topic and return its name in the array
        const findLinkedTopicInModule = dataForRemovedModule.linkedTopics.find(top => top._id === topic._id);
        if (findLinkedTopicInModule) { return [...array, topic.name]; }

        return array;
      }

      return array;
    }, []);

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure you want to delete this module?',
        html: dataForRemovedModule?.linkedTopics?.length > 0 ?
          `This module is selected in the topics <b class="swal_bold">${topicsName.map(
            topicName => ' ' + topicName,
          )}</b>.
        If you remove it, these topics will be updated or removed along with the questions` :
          'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        // remove the module
        await ModulesAPI.deleteModule(null, id);

        // Delete results
        const newModules = [...modules];
        newModules.splice(index, 1);

        // if there are topics assigned to the removed module
        if (dataForRemovedModule?.linkedTopics?.length > 0) {
          // get all topics data, remove or update topics and questions for removed module
          const data = await Util.getAndRemoveOrUpdateTopics(id, fetchedTopics.data, true);

          let newTopics = fetchedTopics.data;
          let newQuestions = data?.questionsData?.questions || topicQuestions;
          const removedTopics = [];
          const updatedTopics = [];

          // if topics have been removed
          if (data?.removedTopics?.length) {
            // get array without removed topics
            newTopics = newTopics.filter((top) => {
              const removedTopic = data.removedTopics.find(remTop => remTop._id === top._id);

              // if topic is removed, then do not return it, push its name into the removedTopics array
              if (removedTopic) { removedTopics.push(top.name); return null; }

              return top;
            });
          }

          // if topics have been updated
          if (data?.updatedTopics?.length) {
            // get array with updated topics
            newTopics = newTopics.map((top) => {
              // get updated topic data from updatedTopics property
              const updatedTopic = data.updatedTopics.find(updated => updated._id === top._id);

              // return updated data if exists, push topic name into the updatedTopics array
              if (updatedTopic) { updatedTopics.push(top.name); return updatedTopic; }

              return top;
            });
          }

          // if there are questions that are assigned to the removed topic
          if (data?.questionsData?.length) {
            // loop through each question data
            data.questionsData.forEach((questionData) => {
              newQuestions = newQuestions.filter((question) => {
                // find the removed question and don't return it in newQuestions
                const removedQuestion = questionData.removedQuestions.find(q => q._id === question._id);

                if (removedQuestion) { return null; }

                return question;
              });
            });
          }

          // Update modules, topic and topic questions
          setResults({
            ...results, topicQuestions: newQuestions, topics: newTopics, modules: newModules,
          });
          setState({
            ...state, topicQuestions: newQuestions, topics: newTopics, modules: newModules,
          });

          /**
           * Returns proper message after removing the module
           * @param {array} removedData - array with removed object names
           * @param {boolean} isRemoved - indicates whether the object is deleted or not
           * @returns {string} message showing in swal after removing the module
           */
          const returnMessage = (removedData, isRemoved) => {
            const removedOrUpdated = isRemoved ? 'removed along with the questions' : 'updated';
            return (
              removedData?.length ?
                (
                  `<p>
                  Topics: <b class="swal_bold">${removedData.map(removedName => ' ' + removedName)}</b>
                  ${removedData?.length === 1 ? 'has' : 'have'} been ${removedOrUpdated}.
              </p>`
                ) :
                ' '
            );
          };

          // show swal message about removed/updated data (modules and topics)
          // eslint-disable-next-line max-len
          Swal.fireSuccess({
            icon: 'success',
            title: 'Deleted!',
            html: `<p>The module has been deleted.</p>
            ${returnMessage(removedTopics, true)}
            ${returnMessage(updatedTopics)}`,
          });
        } else {
          // Update modules
          setResults({
            ...results, modules: newModules,
          });
          setState({
            ...state, modules: newModules,
          });

          Swal.fireSuccess('Deleted!', 'The module has been deleted.');
        }
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling Training modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  return (
    <div className="self-learning-modules">
      <div className="self-learning-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={e => handleSearch(e)}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by"
        />
        <div className="slds-form-element">
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select
                className="slds-select"
                onChange={event => setSearchBy(event.target.value)}
                id="select-type"
                value={searchBy}
              >
                <option value={Constants.SEARCH_BY__MODULE_NAME}>Module name</option>
                <option value={Constants.SEARCH_BY__TRAINING_NAME}>Training name</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-modules">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Trainings">
                Trainings
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Order">
                Order
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="self-learning-modules-body-table">
          {modules?.length > 0 ?
            modules.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    <a
                      href="/#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Name">
                  <div className="slds-truncate" title={el.name}>
                    {el.name}
                  </div>
                </td>

                <td data-label="Trainings">
                  {el?.trainingIds?.length ?
                    (
                      <>
                        {el?.trainingIds?.length > 1 ?
                          (
                            <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                              <button
                                type="button"
                                className="slds-button slds-button_icon slds-button_icon-border-filled"
                                aria-haspopup="true"
                              >
                                <svg className="slds-button__icon" aria-hidden="true">
                                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                                </svg>
                                <span className="slds-assistive-text">Show Trainings</span>
                              </button>
                              <div className="slds-dropdown slds-dropdown_left">
                                <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                                  {el.trainingIds.map(training => (
                                    <li key={training} className="slds-dropdown__item" role="presentation">
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,spellcheck/spell-checker */}
                                      <a href="#" role="menuitem">
                                        <span
                                          className="slds-truncate slds-p-right--x-small"
                                          title={Util.returnPropertyNameById(trainings, training)}
                                        >
                                          {Util.returnPropertyNameById(trainings, training)}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) :
                          (
                            <div
                              className="slds-truncate"
                              title={Util.returnPropertyNameById(trainings, el.trainingIds[0])}
                            >
                              {Util.returnPropertyNameById(trainings, el.trainingIds[0])}
                            </div>
                          )}
                      </>
                    ) :
                    null}
                </td>

                <td data-label="Orders">
                  {el?.orders?.length > 1 ?
                    (
                      <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                        <button
                          type="button"
                          className="slds-button slds-button_icon slds-button_icon-border-filled"
                          aria-haspopup="true"
                        >
                          <svg className="slds-button__icon" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                          </svg>
                          <span className="slds-assistive-text">Show Orders</span>
                        </button>
                        <div className="slds-dropdown slds-dropdown_left">
                          <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                            {el.orders.map(order => (
                              <li key={order.trainingId} className="slds-dropdown__item" role="presentation">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" role="menuitem">
                                  <span
                                    className="slds-truncate slds-p-right--x-small"
                                    title={`${Util.returnPropertyNameById(
                                      trainings, order.trainingId,
                                    )} - ${order.order}`}
                                  >
                                    {Util.abbreviate(Util.returnPropertyNameById(trainings, order.trainingId), 20)}
                                    {' '}
                                    -
                                    {' '}
                                    <strong>{order.order}</strong>
                                  </span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) :
                    (
                      el?.orders[0]?.order?.toString()
                    )}
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                    {new Date(el.updatedAt).toLocaleString()}
                  </div>
                </td>

                <td data-label="Actions">
                  <div className="slds-truncate" title="Delete">
                    {deletedId === el._id ?
                      <Spinner size="x-small" additionalClassName="delete-self-learning-item" /> :
                      (
                        <svg
                          className="slds-icon slds-icon-text-error trash-delete-icon"
                          aria-hidden="true"
                          data-id={el._id}
                          data-index={i}
                          onClick={handleDelete}
                        >
                          <use
                            xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                            data-id={el._id}
                            data-index={i}
                          />
                        </svg>
                      )}
                  </div>
                </td>
              </tr>
            )) :
            null}
        </tbody>
      </table>
      <ModuleModal
        isOpen={state.isOpen}
        name={name}
        setName={setName}
        trainingIds={trainingIds}
        setTrainingIds={setTrainingIds}
        orders={orders}
        setOrders={setOrders}
        handleCancel={handleCancel}
        handleSave={handleSave}
        eventType={state.eventType}
        trainings={trainings}
        modules={modules}
      />
    </div>
  );
};

export default Modules;
