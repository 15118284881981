import React, { useState, useContext, useEffect } from 'react';
import {
  Dropdown, Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import AlertsAPI from '../../../../api/alert-users';
import OrganisationsAPI from '../../../../api/organisations';

import Swal from '../../../../helpers/Swal';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

import './style.scss';

const Alerts = ({ validate }) => {
  const [results, setResults] = useContext(ResultsContext);
  const { alertsData } = results;
  const [state, setState] = useContext(UtilsContext);

  // User Email Alert variables
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState('info');
  const [recipient, setRecipient] = useState('all');
  const [orgOptions, setOrgOptions] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setTitle('');
    setMessage('');
    setType('info');
    setRecipient('all');
    setOrgOptions([]);
    setOrganisations([]);
    setShowTemplate(false);
  };
  /**
   * Check if recipient is specific organizations and not empty
   */
  const validateOrganizations = recipient === 'specific' && organisations.length === 0;

  /**
   * Alert title
   */
  const alertTitle = `Alert ${recipient === 'all' ? 'all DESelect Users' : 'Specified Organizations'}`;

  /**
   * Alert text
   */
  const alertText = `Please note that this message will be sent as an email to
  ${recipient === 'all' ? 'all DESelect users. \n' : 'the specified organizations.'}
  Are you sure you want to confirm?`;

  /**
   * Get all the organisations
   * @returns {void}
   */
  const getOrganisations = async () => {
    const res = await OrganisationsAPI.getOrganisations(null, { isActive: true });
    const fetchedOrganizations = res.data.map(organization => (
      {
        key: organization._id,
        value: organization._id,
        text: organization.name,
      }
    ));
    setOrgOptions(fetchedOrganizations);
  };

  /**
   * Handler called when saving an alert
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      title,
      message,
      type,
      recipient,
      organisations,
    };

    const validateResult = validate(newData);

    if (validateOrganizations) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
      return;
    }

    if (validateResult.length > 0) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          const confirmAlertUsers = await Swal.fireSuccess({
            icon: 'warning',
            title: alertTitle,
            text: alertText,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonColor: '#d33',
          });
          if (confirmAlertUsers.value) {
            const res = await AlertsAPI.createAlert(null, newData);
            setResults({
              ...results,
              alertsData: [
                ...alertsData,
                res.data,
              ],
            });

            setState({
              ...state,
              isOpen: false,
              eventType: '',
              alertsData: [
                ...alertsData,
                res.data,
              ],
            });
            cleanFields();
          }
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          const res = await AlertsAPI.updateAlert(null, state.id, newData);

          const newArray = [...alertsData];
          newArray[state.index] = res.data;
          setResults({ ...results, alertsData: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            alertsData: newArray,
          });
          Swal.fireSuccess('Updated!', 'The alert has been updated.');
        }
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when canceling an alert modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing a notification
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = async (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__READ_ONLY,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = alertsData.filter(e => e._id === id)[0];
    setTitle(singleItem.title);
    setMessage(singleItem.message);
    setType(singleItem.type);
    setRecipient(singleItem.recipient);
    setOrganisations(singleItem.organisations);
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
    );

  // class names for alert modal
  const alertModalClassName = classNames(
    'slds-modal',
    { 'slds-fade-in-open': state.isOpen },
  );

  // class names for alert backdrop
  const alertBackdropClassName = classNames(
    { 'slds-backdrop slds-backdrop_open': state.isOpen },
  );

  // class names for checkbox container

  const checkBoxClassName = classNames(
    'slds-form-element specific-org-container',
    { expand_selection: recipient === 'specific' },
  );

  useEffect(() => {
    if (recipient === 'specific') {
      getOrganisations();
    }
  }, [recipient]);

  return (
    <div className="alert">
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout alert-table"
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>
            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>
            <th scope="col">
              <div className="slds-truncate" title="Title">
                Subject
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Message">
                Message
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Type">
                Type
              </div>
            </th>

          </tr>
        </thead>
        <tbody>
          {alertsData.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>
              <td>
                <div className="slds-truncate" title={el.createdAt}>
                  {el.createdAt}
                </div>
              </td>

              <td data-label="Title">
                <div className="slds-truncate" title={el.title}>
                  {el.title}
                </div>
              </td>

              <td data-label="Message">
                <div className="slds-truncate" title={el.message}>
                  {el.message}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  <span
                    className={classNames('slds-badge',
                      { 'slds-theme_success': el.type === 'info' },
                      { 'slds-theme_error': el.type === 'error' },
                      { 'slds-theme_warning': el.type === 'warning' })}
                  >
                    {el.type}
                  </span>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      {/* Dataset Section */}

      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={alertModalClassName}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Alert DESelect users
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-title">
                Subject
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-title"
                    placeholder="Subject"
                    className="slds-input"
                    onChange={event => setTitle(event.target.value)}
                    value={title}
                    disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-message">
                Message
                {' '}
                <span className="slds-form-element__label_highlight">
                  (Please type only the body of the email message)

                </span>
                <span
                  className="info_icon"
                  onClick={() => setShowTemplate(!showTemplate)}
                >
                  Sample Email
                </span>
                <div className="slds-form-element__control">
                  <textarea
                    id="form-element-message"
                    placeholder="Message"
                    className="slds-input"
                    rows={4}
                    onChange={event => setMessage(event.target.value)}
                    value={message}
                    disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                  />
                </div>
              </label>
              {
                showTemplate && (
                  <dialog
                    className="alert-template-dialog"
                    style={{ position: 'relative' }}
                    open
                  >
                    <Icon onClick={() => setShowTemplate(false)} name="close" size="large" />
                    <img
                      className="alert-template-dialog__image"
                      src="/assets/alert-template.png"
                      alt="alert email template"
                    />
                  </dialog>
                )
              }
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Type
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setType(event.target.value)}
                      id="select-type"
                      value={type}
                      disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                    >
                      <option value="info" defaultValue>Info</option>
                      <option value="error">Error</option>
                      <option value="warning">Warning</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <p className="slds-form-text">Send email to: </p>

            <div className="slds-form-element">
              <div className="slds-form-element__checkbox">
                <input
                  type="checkbox"
                  id="alert-all"
                  className="slds-form-element__checkbox__input"
                  name="alert-all"
                  value="all"
                  onChange={e => setRecipient(e.target.value)}
                  checked={recipient === 'all'}
                  disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                />
                <label className="slds-form-element__checkbox__label" htmlFor="alert-all"> All Customers </label>
              </div>
            </div>

            <div className={checkBoxClassName}>
              <div className="slds-form-element__checkbox">
                <input
                  type="checkbox"
                  id="alert-specific"
                  className="slds-form-element__checkbox__input"
                  name="alert-specific"
                  value="specific"
                  onChange={e => setRecipient(e.target.value)}
                  checked={recipient === 'specific'}
                  disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                  required={recipient === 'specific'}
                />
                <label className="slds-form-element__checkbox__label" htmlFor="alert-all"> Specific Orgs </label>
              </div>
              {' '}
              {
                recipient === 'specific' && (

                  <Dropdown
                    placeholder="Choose Organizations"
                    multiple
                    search
                    selection
                    options={orgOptions}
                    defaultValue={organisations}
                    onChange={(e, { value }) => setOrganisations(value)}
                    disabled={state.eventType === Constants.EVENT__TYPE__READ_ONLY}
                  />
                )

              }

            </div>
          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights || state.eventType === Constants.EVENT__TYPE__READ_ONLY}
              className="slds-button slds-button_brand"
            >
              Send
            </button>
          </footer>
        </div>
      </section>
      <div
        className={alertBackdropClassName}
      />
      {/* Modal Section */}
    </div>
  );
};

Alerts.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
};

export default Alerts;
