import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import AddQuestions from '../atoms/AddQuestions';
import MultiSelect from '../../AcademyTrainings/atoms/MultiSelect';

import '../style.scss';

const QuestionModal = ({
  isOpen, question, setQuestion, answers, setAnswers, correctAnswer, setCorrectAnswer, handleCancel,
  handleSave, eventType, topicId, setTopicId, topicSection, topics, modules, features, relatedFeatures,
  setRelatedFeatures,
}) => {
  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (relatedFeatures?.length === 1) {
      return `${Util.returnPropertyNameById(features, relatedFeatures[0])}`;
    } if (relatedFeatures?.length > 1) {
      return `${relatedFeatures?.length} Related Features Selected`;
    } if (!relatedFeatures?.length) {
      return 'Select Related Features';
    }

    return '';
  };

  /**
   * Returns array with modules linked to selected topic
   * @param {string} selectedTopicId - Id of the selected topic
   * @returns {array} array with modules
   */
  const getModulesOfSelectedTopic = selectedTopicId => modules.filter((mod) => {
    // gets data for selected topic
    const findSelectedTopic = topics.find(top => top._id === selectedTopicId);

    if (findSelectedTopic) {
      // find linked modules in selected topic
      const findModuleInSelectedTopic = findSelectedTopic.moduleIds.find(id => id === mod._id);

      // return only the module found
      return findModuleInSelectedTopic ? mod : false;
    }

    return false;
  });

  /**
   * Returns array with modules linked to selected topic sorted by updatedAt property
   * @param {string} selectedTopicId - Id of the selected topic
   * @returns {array} sorted array with modules by updatedAt property
   */
  const sortedModulesByUpdateDate = selectedTopicId => getModulesOfSelectedTopic(selectedTopicId)?.length ?
    getModulesOfSelectedTopic(selectedTopicId).sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt))) :
    [];

  /**
   * Returns the module name for the topic option in dropdown
   * @param {string} selectedTopicId - Id of the selected topic
   * @returns {string} module name
   */
  const moduleNameForTopicDropdown = (selectedTopicId) => {
    if (sortedModulesByUpdateDate(selectedTopicId)?.length > 1) {
      // when the topic has more than 1 module
      return `(${sortedModulesByUpdateDate(selectedTopicId)[0].name}, ...)`;
    }
    if (sortedModulesByUpdateDate(selectedTopicId)?.length) {
      // when topic has only one module
      return `(${sortedModulesByUpdateDate(selectedTopicId)[0].name})`;
    }

    // in other case
    return '';
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {eventType === Constants.EVENT__TYPE__NEW ? 'Create Question' : 'Edit Question'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id={topicSection ? 'modal-content-self-learning' : 'modal-content-id-1'}
            style={{ minHeight: '15rem' }}
          >
            {topicSection ?
              (
                <>
                  <div className="slds-form-element">
                    <label className="slds-form-element__label" htmlFor="select-type-topic">
                      Select Topic
                      <div className="slds-form-element__control">
                        <div className="slds-select_container">
                          <select
                            className="slds-select"
                            onChange={event => setTopicId(event.target.value)}
                            id="select-type-topic"
                            value={topicId}
                          >
                            <option value="" hidden>
                              Please select topic
                            </option>
                            {topics.map((top, index) => (
                              <option
                                value={top._id}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${index}-${top._id}`}
                                title={`${Util.returnPropertyNameById(topics, top._id)}
                                (${moduleNameForTopicDropdown(top._id)})`}
                              >
                                {Util.abbreviate(Util.returnPropertyNameById(topics, top._id), 100)}
                                {' '}
                                {moduleNameForTopicDropdown(top._id)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  {sortedModulesByUpdateDate(topicId)?.length ?
                    (
                      <>
                        <ul>Linked to the module: </ul>
                        {sortedModulesByUpdateDate(topicId).map(mod => (
                          <li key={mod._id}>{mod.name}</li>
                        ))}
                      </>
                    ) :
                    null}
                </>
              ) :
              null}

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-question">
                Question
                <div className="slds-form-element__control">
                  <textarea
                    type="text"
                    id="form-element-question"
                    placeholder="Question"
                    className="slds-input"
                    onChange={event => setQuestion(event.target.value)}
                    value={question}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <AddQuestions answers={answers} setAnswers={setAnswers} />
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type-answer">
                Correct Answer
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setCorrectAnswer(event.target.value)}
                      id="select-type-answer"
                      value={correctAnswer}
                    >
                      <option value="" hidden>
                        Please select
                      </option>
                      {answers.map((answer, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <option value={index} key={`${index}-${answer}`} title={answer}>
                          {index + 1}
                          .
                          {' '}
                          {Util.abbreviate(answer, 100)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {topicSection ?
              null :
              (
                <>
                  <div className="slds-form-element">
                    <label className="slds-form-element__label" htmlFor="multi-select">
                      Related Features
                      <MultiSelect
                        multiSelectPlaceholder={returnMultiSelectPlaceholder()}
                        value={relatedFeatures}
                        data={features}
                        setData={setRelatedFeatures}
                      />
                    </label>
                  </div>
                </>
              )}
          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

QuestionModal.propTypes = {
  /* Open the modal */
  isOpen: PropTypes.bool.isRequired,
  /** Label for question */
  question: PropTypes.string.isRequired,
  /** set state for question */
  setQuestion: PropTypes.func.isRequired,
  /** array with answers */
  answers: PropTypes.instanceOf(Array).isRequired,
  /** set state for answers */
  setAnswers: PropTypes.func.isRequired,
  /** correct answer for the question - it's index number of the answer */
  correctAnswer: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** set correct answer for the question */
  setCorrectAnswer: PropTypes.func.isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string,
  /** id of the created topic in self-learning */
  topicId: PropTypes.string,
  /** set id of the created topic in self-learning */
  setTopicId: PropTypes.func,
  /** defines whether the component is used in the topic section in self-learning */
  topicSection: PropTypes.bool,
  /** array with all topics created in self-learning */
  topics: PropTypes.instanceOf(Array),
  /** array with all modules created in self-learning */
  modules: PropTypes.instanceOf(Array),
};

export default QuestionModal;
