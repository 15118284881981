import React, {
  useState,
  useEffect,
} from 'react';

import HubspotApi from '../../../../api/hubspot';

import DealOverview from '../DealOverview/DealOverview';
import CompanyOverview from '../CompanyOverview/CompanyOverview';

import './style.scss';

const StampDeal = () => {
  const [deal, setDeal] = useState({});
  const [inputDealId, setInputDealId] = useState('');
  const [deals, setDeals] = useState([]);
  const [dealsMap, setDealsMap] = useState(new Map());
  const [company, setCompany] = useState({});
  const [stampCompany, setStampCompany] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  /**
   * Get all Deals
   * @returns {void}
   */
  const getDeals = async () => {
    const hubspotDeals = await HubspotApi.getDeals('hs_is_closed', [false]);
    setDeals(hubspotDeals);
    // Create map with key id and value the Deal object
    const dMap = new Map();
    hubspotDeals.forEach((d) => {
      dMap.set(d.id, d);
    });
    setDealsMap(dMap);
  };

  /**
   * Gets the Deal and Company details given the selected deal
   * @param {Number} dealId - DealId for which we want to get the Deal and Company details
   * @returns {void}
   */
  const getDealAndCompany = async (dealId) => {
    const result = await HubspotApi.getDealAndCompany(dealId);
    setCompany(result.company);
    setDeal(result.deal);
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    getDeals();
  }, [setDeals]);

  /**
   * Handle changing Deal input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeDeal = (e) => {
    const newDealId = e.target.value;
    const d = dealsMap.get(newDealId);
    setDeal(d);
    getDealAndCompany(newDealId);
    setSubmitButtonDisabled(false);
  };

  /**
   * Handle entering a deal id manually
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleInputDealIdChange = (e) => {
    const newDealId = e.target.value;
    setInputDealId(newDealId);
    getDealAndCompany(newDealId);
    setSubmitButtonDisabled(false);
  };

  /**
   * Handle clicking refresh button
   * @returns {void}
   */
  const clickRefresh = async () => {
    setRefreshing(true);
    await getDeals();
    setRefreshing(false);
  };

  /**
   * Handle clicking refresh button on Company or Deal
   * @returns {void}
   */
  const handleRefresh = async () => {
    // Refresh Deal and Company details
    await getDealAndCompany(deal.id);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);

    try {
      // Stamp Deal
      await HubspotApi.stampDeal(deal.id, stampCompany);

      const confirmation = `Metrics stamped on Deal '${deal.properties.dealname}'` +
        (stampCompany ? ' and related Company' : '') +
        '.';
      // eslint-disable-next-line no-alert
      alert(confirmation);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error stamping deal: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  return (
    <>
      <form id="form" onSubmit={submit}>
        Select Deal to stamp.
        <br />

        <div className="slds-form-element">
          <div className="slds-form-element__label" htmlFor="criteria">
            Choose Hubspot Deal to stamp metrics for on Deal and related Company. Only open deals
            appear in the list below.
          </div>

          <label className="slds-form-element__label" htmlFor="deal"> Deal:</label>
          <div className="slds-form-element__control">
            <select
              id="deal"
              style={{ width: '800px' }}
              className="slds-select"
              onChange={e => handleChangeDeal(e)}
            >
              <option value="" defaultValue>Please select</option>
              {deals ?
                (deals || []).map(d => (
                  <option key={d.id} value={d.id}>
                    {d.properties.dealname}
                    &nbsp;
                    (
                    {Number(d.properties.amount).toLocaleString()}
                    &nbsp;
                    {d.properties.deal_currency_code}
                    )
                  </option>
                )) :
                null}
            </select>

            <button
              type="button"
              className="slds-button slds-button_icon slds-button_icon-brand"
              id="refresh-button"
              onClick={clickRefresh}
              title="Refresh Deals"
            >
              <svg
                aria-hidden="true"
                className={`slds-button__icon ${refreshing ?
                  'fa-spin' :
                  ''
                }`}
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
              </svg>
            </button>

            <br />

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="input-deal-id">
                Or select a Deal by its ID:
              </label>
              <div className="slds-form-element__control">
                <input
                  className="slds-input"
                  type="text"
                  id="input-deal-id"
                  value={inputDealId}
                  onChange={handleInputDealIdChange}
                  style={{ width: '200px' }}
                />
              </div>
            </div>

            <br />
            {
              deal ?
                (
                  <>
                    <div className="slds-grid slds-gutters">
                      <div className="slds-col slds-size_1-of-2">
                        <DealOverview
                          deal={deal}
                          handleRefresh={handleRefresh}
                        />
                      </div>

                      <div className="slds-col slds-size_1-of-2">
                        {company ?
                          (
                            <CompanyOverview
                              company={company}
                              handleRefresh={handleRefresh}
                            />
                          ) :
                          (
                            <font className="highlight-deal-prop">
                              This Deal is not correctly linked to a Company. In Hubspot the Deal should be
                              linked to either 1 Company, or multiple Companies with
                              one of them marked as the Primary Company.
                            </font>
                          )}
                      </div>
                    </div>

                    <div className="slds-form-element">
                      <label className="slds-form-element__label" htmlFor="stampCompany">
                        Stamp Company as well
                        <div className="slds-form-element__control">
                          <div className="slds-checkbox">
                            <input
                              onChange={() => setStampCompany(!stampCompany)}
                              type="checkbox"
                              name="stampCompany"
                              id="stampCompany"
                              checked={stampCompany}
                            />
                            <label className="slds-checkbox__label" htmlFor="stampCompany">
                              <span className="slds-checkbox_faux" />
                              <span className="slds-form-element__label">
                                {stampCompany ? 'True' : 'False'}
                              </span>
                            </label>
                          </div>
                        </div>
                      </label>
                    </div>

                    <br />
                    <br />
                    <button
                      type="submit"
                      className="slds-button slds-button_brand"
                      id="submit"
                      disabled={submitButtonDisabled}
                    >
                      Stamp
                    </button>
                    {' '}

                  </>
                ) :
                null
            }
          </div>
        </div>
      </form>
    </>
  );
};

export default StampDeal;
