import React, { useContext, useState, useEffect } from 'react';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';
import Constants from '../../../../constants/constants';

import SelectionRunLogsAPI from '../../../../api/selectionRunLogs';

import Filter from './Filter';
import Swal from '../../../../helpers/Swal';

const SelectionRunLogDataSet = () => {
  const [results, setResults] = useContext(ResultsContext);
  const { selectionRunLogsData, filteredResults } = results;
  const [logsList, setLogsList] = useState([]);
  const [state, setState] = useContext(UtilsContext);
  const { showingFilteredResults, filter } = state;
  const [loading, setLoading] = useState(false);

  const filterResultsHandler = async (e, filterBy, searchText) => {
    setLoading(true);
    const filterCriteria = {};

    // If filtering by organisation
    if (filterBy === Constants.FILTER_BY__ORG) {
      filterCriteria.orgId = searchText;
    } else {
      // Filtering by selection
      filterCriteria.selectionId = searchText;
    }

    try {
      // Get filtered logs
      const res = await SelectionRunLogsAPI.getFilteredLogs(null, filterCriteria);

      // Set state
      setState({ ...state, showingFilteredResults: true, filter: searchText });

      // Show filtered logs
      setResults({ ...results, filteredResults: res.data });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `Something unexpected happened: ${error.message || error}`,
      );
    }
  };

  /**
   * Stops the filtering of logs
   * @returns {void}
   */
  const handleStopFiltering = () => {
    // Reset values
    setLogsList(selectionRunLogsData);
    setState({ ...state, showingFilteredResults: false, filter: null });
    setResults({ ...results, filteredResults: [] });
  };

  // UseEffect for setting logs' lists
  useEffect(() => {
    // If filtering...
    if ((filteredResults && filteredResults.length) || showingFilteredResults) {
      setLogsList(filteredResults);
    } else {
      // Show unfiltered data
      setLogsList(selectionRunLogsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRunLogsData, filteredResults]);

  return (
    <div className="selectionRunLog">
      <Filter
        handleStopFiltering={handleStopFiltering}
        showingFilteredItems={showingFilteredResults}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filter}
      />

      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="orgId">
                Org Id
              </div>
            </th>

            <th className="id-column" scope="col">
              <div className="slds-truncate" title="Selection Id">
                Selection Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Trigger">
                Trigger
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Query">
                Query
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Start Date Time">
                Start Date Time
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="End Date Time">
                End Date Time
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Success">
                Success
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Error">
                Error
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {logsList && logsList.length > 0 ?
            logsList.map(el => (
              <tr key={el.orgId} className="slds-hint-parent">
                <td data-label="orgId">
                  <div title={el.orgId}>
                    {el.orgId}
                  </div>
                </td>

                <td data-label="Selection Id">
                  <div title={el.selectionId}>
                    {el.selectionId}
                  </div>
                </td>

                <td data-label="Trigger">
                  <div className="slds-badge" title={el.trigger}>
                    {el.trigger}
                  </div>
                </td>

                <td data-label="Query">
                  <div className="slds-truncate" title={el.query}>
                    {el.query}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.startDateTime}>
                    { el.startDateTime ? new Date(el.startDateTime).toLocaleString() : 'N/A'}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.endDateTime}>
                    {el.endDateTime ? new Date(el.endDateTime).toLocaleString() : 'N/A'}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    {
                      el.success ?
                        <span className="slds-badge slds-theme_success">True</span> :
                        <span className="slds-badge slds-theme_error">False</span>
                    }
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.error}>
                    {el.error}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.createdAt}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.updatedAt}>
                    {new Date(el.updatedAt).toLocaleString()}
                  </div>
                </td>
              </tr>
            )) :
            (
              <tr>
                <td colSpan="9" className="no-results-found">No results found</td>
              </tr>
            )}
        </tbody>
      </table>
      {/* Dataset Section */}
    </div>
  );
};

SelectionRunLogDataSet.propTypes = {};

export default SelectionRunLogDataSet;
