import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import TransferList from '../atoms/TransferList';
import './style.scss';

const AccessToCertificationModal = ({
  setExamId, examId, exams, handleCancel, handleSave, isOpen, selectedUsers, usersToSelect, allUsers,
  setUsersToSelect, setSelectedUsers,
}) => {
  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" id="access-to-certification-modal" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Grant access to Certification
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
            style={{ minHeight: '10rem' }}
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Choose an exam
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setExamId(event.target.value)}
                      id="select-type"
                      value={examId}
                    >
                      <option value="" hidden>
                        Please select
                      </option>
                      {exams?.length ?
                        exams.map(exam => (
                          <option key={exam._id} value={exam._id}>{exam.name}</option>
                        )) :
                        null}
                    </select>
                  </div>
                </div>
              </label>
            </div>
            {examId && (
              <TransferList
                isOpen
                openMultiSelect
                selectedData={selectedUsers}
                dataForSelect={usersToSelect}
                allData={allUsers}
                setDataForSelect={setUsersToSelect}
                setSelectedData={setSelectedUsers}
              />
            )}
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights || !examId}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

AccessToCertificationModal.propTypes = {
  /* sets selected exam id */
  setExamId: PropTypes.func.isRequired,
  /** id of the selected exam */
  examId: PropTypes.string,
  /** array with all created exams */
  exams: PropTypes.instanceOf(Array).isRequired,
  /** array with selected users */
  selectedUsers: PropTypes.instanceOf(Array).isRequired,
  /** array with users available to select */
  usersToSelect: PropTypes.instanceOf(Array).isRequired,
  /** all certification users */
  allUsers: PropTypes.instanceOf(Array).isRequired,
  /** function for setting users to be select */
  setUsersToSelect: PropTypes.func.isRequired,
  /** function for setting selected users */
  setSelectedUsers: PropTypes.func.isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /* informs if the modal is open */
  isOpen: PropTypes.bool.isRequired,
};

export default AccessToCertificationModal;
