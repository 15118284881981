import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ size, assistiveText, additionalClassName }) => (
  <div role="status" className={`slds-spinner slds-spinner_${size} ${additionalClassName}`}>
    <span className="slds-assistive-text">{assistiveText || ''}</span>
    <div className="slds-spinner__dot-a" />
    <div className="slds-spinner__dot-b" />
  </div>
);

Spinner.propTypes = {
  /**
   * Determines the size of the spinner
   */
  size: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large']).isRequired,
  /**
   * Assistive text that is read out loud to screen readers.
   */
  assistiveText: PropTypes.string,
  /**
   * Additional class name
   */
  additionalClassName: PropTypes.string,
};

export default Spinner;
