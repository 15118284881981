import React, { useContext } from 'react';

import { MenuContext } from '../../MenuContext';

import LeftNav from './LeftNav/LeftNav';
import RightNav from './RightNav/RightNav';

import './Main.scss';

const Main = () => {
  const menuContext = useContext(MenuContext);
  const [menuSelected] = menuContext.menuSelected;

  return (
    <div className="main-container">
      <div className="slds-grid slds-gutters">
        <div className="slds-col">
          <LeftNav />
        </div>
        <div className="slds-col rightNav">
          {menuSelected === '' ?
            (
              <div className="slds-row">
                <div className="welcome-text">Welcome</div>
              </div>
            ) :
            ''}
          <div className="slds-row">
            <RightNav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
