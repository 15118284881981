import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const IntegrationsAPI = {
  /**
   * Get integrations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved integrations - and `success`
   */
  getIntegrations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/integrations`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default IntegrationsAPI;
