import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const TrainingsAPI = {
  /**
   * Retrieves all trainings
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved trainings - and `success`
   */
  getAllTrainings: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/trainings`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates training
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} trainingData - object with training data
   * @param {string} trainingData.name - training name
   * @param {Array} trainingData.organisationIds - array with organisation IDs
   * @param {Date} trainingData.startDate - start date of the training
   * @return {object} An object with `data` property - the created training - and `success`
   */
  createTraining: async (
    cancelToken,
    {
      name,
      organisationIds,
      startDate,
      hiddenToNoOrgUsers,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/trainings`, {
        name,
        organisationIds,
        startDate,
        hiddenToNoOrgUsers,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates training
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the training you want to update
   * @param {object} trainingData - object with training data
   * @param {string} trainingData.name - training name
   * @param {Array} trainingData.organisationIds - array with organisation IDs
   * @param {Date} trainingData.startDate - start date of the training
   * @return {object} An object with `data` property - the updated training - and `success`
   */
  updateTraining: async (
    cancelToken,
    id,
    {
      name,
      organisationIds,
      startDate,
      hiddenToNoOrgUsers,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/trainings/${id}`, {
        name,
        organisationIds,
        startDate,
        hiddenToNoOrgUsers,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Deletes training
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the training you want to delete
   * @returns {object} An object with `data` property - the deleted training - and `success`
   */
  deleteTraining: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/trainings/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default TrainingsAPI;
