import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const ExamsAPI = {
  /**
   * Retrieves all exams
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved exams - and `success`
   */
  getExams: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/exams`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Creates an exam
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} name - exam name
   * @param {number} numberOfQuestions - Number of questions
   * @param {number} numberOfRetries - Number of retries
   * @param {number} time - number of minutes for the exam
   * @param {number} threshold - threshold for passing the exam
   * @param {array} availableQuestions - array with available questions attach to exam
   * @return {object} An object with `data` property - the created exam - and `success`
   */
  createExam: async (
    cancelToken,
    {
      name,
      numberOfQuestions,
      numberOfRetries,
      time,
      threshold,
      availableQuestions,
      endDate,
      onlyForAdmin,
      isARefresherExam,
      mainExamId,
      generatesCertificate,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/exams`, {
        name,
        numberOfQuestions,
        numberOfRetries,
        time,
        threshold,
        availableQuestions,
        endDate,
        onlyForAdmin,
        isARefresherExam,
        mainExamId,
        generatesCertificate,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates an exam
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the exam you want to update
   * @param {string} name - exam name
   * @param {number} numberOfQuestions - Number of questions
   * @param {number} numberOfRetries - Number of retries
   * @param {number} time - number of minutes for the exam
   * @param {number} threshold - threshold for passing the exam
   * @param {array} availableQuestions - array with available questions attach to exam
   * @return {object} An object with `data` property - the updated exam - and `success`
   */
  updateExam: async (
    cancelToken,
    id,
    {
      name,
      numberOfQuestions,
      numberOfRetries,
      time,
      threshold,
      availableQuestions,
      endDate,
      onlyForAdmin,
      isARefresherExam,
      mainExamId,
      generatesCertificate,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/exams/${id}`, {
        name,
        numberOfQuestions,
        numberOfRetries,
        time,
        threshold,
        availableQuestions,
        endDate,
        onlyForAdmin,
        isARefresherExam,
        mainExamId,
        generatesCertificate,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes an exam
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the exam you want to delete
   * @returns {object} An object with `data` property - the deleted exam - and `success`
   */
  deleteExam: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/exams/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default ExamsAPI;
