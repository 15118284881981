import React, {
  useState,
  useEffect,
} from 'react';

import CurrencyRatesApi from '../../../../api/currencyRates';

import './style.scss';

const CurrencyRates = () => {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [usdToEur, setUsdToEur] = useState();
  const [audToEur, setAudToEur] = useState();

  /**
   * Handle changing Usd To Eur input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeUsdToEur = (e) => {
    setUsdToEur(e.target.value);
  };

  /**
   * Handle changing Aud to Eur input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeAudToEur = (e) => {
    setAudToEur(e.target.value);
  };

  /**
   * Get the current currency rates
   * @returns {void}
   */
  const getCurrencyRates = async () => {
    const currencyRates = await CurrencyRatesApi.getCurrencyRates();
    setUsdToEur(currencyRates?.usdToEur);
    setAudToEur(currencyRates?.audToEur);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);

    try {
      await CurrencyRatesApi.saveCurrencyRates(null, usdToEur, audToEur);
      // eslint-disable-next-line no-alert
      alert('Changes saved');
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error stamping deal: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    getCurrencyRates();
  }, []);

  return (
    <>
      <form id="form" onSubmit={submit}>
        Set Currency Rates to use while converting Deal Amounts and Company ACV to Euros.
        <br />

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="usd">USD / EUR  (1 USD = X EUR)</label>
          <div className="slds-form-element__control">
            <input
              type="number"
              step="0.01"
              id="usd"
              size="50"
              required="required"
              className=""
              value={usdToEur}
              onChange={e => handleChangeUsdToEur(e)}
            />
          </div>

          <label className="slds-form-element__label" htmlFor="aud"> AUD / EUR (1 AUD = X EUR)</label>
          <div className="slds-form-element__control">
            <input
              type="number"
              step="0.01"
              id="aud"
              size="50"
              required="required"
              className=""
              value={audToEur}
              onChange={e => handleChangeAudToEur(e)}
            />
          </div>
        </div>

        <br />

        <button
          type="submit"
          className="slds-button slds-button_brand"
          id="submit"
          disabled={submitButtonDisabled}
        >
          Save
        </button>
      </form>
    </>
  );
};

export default CurrencyRates;
