import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SetTestTokensApi = {
  /**
   * Updates a user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} cookie - Cookie to be set
   * @return {object} An object with `result` property - always true - and `success`
   */
  setCookie: async (cancelToken, cookie) => {
    const body = {
      cookie,
    };
    const res = await axios.post(
      `${apiUrl}/admin/test-tokens/`,
      body,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default SetTestTokensApi;
