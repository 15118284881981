import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';

const TransferList = ({
  loadingData, selectedData, dataForSelect, destination, allData, setDataForSelect,
  setSelectedData,
}) => {
  /**
   * Renders the Select All or Remove All Elements
   * @param {string} label - Label of the element
   * @param {string} id - id of the element
   * @returns {React.ReactElement} The HTML of the Element
   */
  const renderMultipleSelectorOrRemover = (label, id) => (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>{label}</label>
      <input
        type="checkbox"
        id={id}
        className="questions-for-select-checkbox"
        onChange={e => Util.handleSelectItemInTransferList(
          dataForSelect, selectedData, allData, id,
          e.target.checked, setDataForSelect, setSelectedData,
        )}
      />
    </>
  );

  return (
    <div
      className="slds-modal__content slds-p-around_medium"
      id="modal-content-id-1"
    >
      <div className="exam-modal-questions-table">
        {loadingData ?
          (
            <div>
              <div className="demo-only">
                <div role="status" className="slds-spinner slds-spinner_medium">
                  <span className="slds-assistive-text">Loading</span>
                  <div className="slds-spinner__dot-a" />
                  <div className="slds-spinner__dot-b" />
                </div>
              </div>
            </div>
          ) :
          (
            <>
              <table
                className="slds-table slds-table_cell-buffer
                        slds-table_bordered slds-table_fixed-layout
                        exam-modal-table question-for-select-table"
                style={{ marginBottom: '10px', fontSize: '12px' }}
              >
                <thead>
                  <tr className="slds-line-height_reset">
                    <th scope="col">
                      <div
                        className="slds-truncate"
                        title={destination === Constants.TRANSFER_LIST__DESTINATION__QUESTIONS ?
                          'Available Questions' :
                          'Available Users'}
                      >
                        {destination === Constants.TRANSFER_LIST__DESTINATION__QUESTIONS ?
                          'Available Questions' :
                          'Available Users'}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataForSelect.length > 0 ?
                    (
                      <tr className="slds-hint-parent">
                        <td data-label="Select All">
                          {renderMultipleSelectorOrRemover('Select All', 'all')}
                        </td>
                      </tr>
                    ) :
                    null}
                  {dataForSelect?.length ?
                    dataForSelect.map(el => (
                      <tr key={el._id} className="slds-hint-parent">
                        <td data-label="Question" className="question-table-td">
                          <label className="slds-truncate" title={el?.question || el?.username} htmlFor={el._id}>
                            {el?.question || el?.username}
                          </label>
                          <input
                            type="checkbox"
                            className="questions-for-select-checkbox"
                            value={el._id}
                            id={el._id}
                            onChange={e => Util.handleSelectItemInTransferList(
                              dataForSelect, selectedData, allData, e.target.value, e.target.checked,
                              setDataForSelect, setSelectedData,
                            )}
                          />
                        </td>
                      </tr>
                    )) :
                    (
                      <div className="no-item">No available item to show</div>
                    )}
                </tbody>
              </table>
              <table
                className="slds-table slds-table_cell-buffer
                        slds-table_bordered slds-table_fixed-layout
                        exam-modal-table selected-questions-table"
                style={{ marginBottom: '10px', fontSize: '12px' }}
              >
                <thead>
                  <tr className="slds-line-height_reset">
                    <th scope="col">
                      <div
                        className="slds-truncate"
                        title={destination === Constants.TRANSFER_LIST__DESTINATION__QUESTIONS ?
                          'Selected Questions' :
                          'Selected Users'}
                      >
                        {destination === Constants.TRANSFER_LIST__DESTINATION__QUESTIONS ?
                          'Selected Questions' :
                          'Selected Users'}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedData.length > 0 ?
                    (
                      <tr className="slds-hint-parent">
                        <td data-label="Remove All">
                          {renderMultipleSelectorOrRemover('Remove All', 'remove-all')}
                        </td>
                      </tr>
                    ) :
                    null}
                  {selectedData?.length ?
                    selectedData.map(el => (
                      <tr key={el._id} className="slds-hint-parent">
                        <td data-label="Question">
                          <label className="slds-truncate" title={el?.question || el?.username} htmlFor={el._id}>
                            {el?.question || el?.username}
                          </label>
                          <input
                            className="selected-questions-checkbox"
                            type="checkbox"
                            value={el._id}
                            id={el._id}
                            checked={selectedData.find(sq => sq._id === el._id)}
                            onChange={e => Util.handleSelectItemInTransferList(dataForSelect, selectedData, allData,
                              e.target.value, e.target.checked, setDataForSelect, setSelectedData)}
                          />
                        </td>
                      </tr>
                    )) :
                    (
                      <div className="no-item">No item has been selected</div>
                    )}
                </tbody>
              </table>
            </>
          )}
      </div>
    </div>
  );
};

TransferList.propTypes = {
  /* indicates if data are loading */
  loadingData: PropTypes.bool,
  /** defines where component is used */
  destination: PropTypes.string,
  /** array with all data */
  allData: PropTypes.instanceOf(Array).isRequired,
  /** array with selected data */
  selectedData: PropTypes.instanceOf(Array).isRequired,
  /** array with items available to select */
  dataForSelect: PropTypes.instanceOf(Array).isRequired,
  /** function for setting items to be select */
  setDataForSelect: PropTypes.func.isRequired,
  /** function for setting selected items */
  setSelectedData: PropTypes.func.isRequired,
};

export default TransferList;
