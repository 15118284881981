import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import MultiSelect from '../atoms/MultiSelect';
import '../style.scss';

const ModuleModal = ({
  isOpen, name, setName, trainingIds, setTrainingIds, orders, setOrders,
  handleCancel, handleSave, eventType, trainings, modules,
}) => {
  useEffect(() => {
    // after removing the training in multiSelect, update the ordinal numbers in the array with orders
    if (orders?.length) {
      orders.forEach((o) => {
        const findTrainingIdInOrder = trainingIds.find(training => training === o.trainingId);

        if (!findTrainingIdInOrder) {
          // if no training has been found for which the order is saved - delete it
          setOrders(orders.filter((order) => {
            if (trainingIds.find(training => training === order.trainingId)) return order;
            return null;
          }));
        }
      });
    }
    /* eslint-disable-next-line */
  }, [trainingIds, setTrainingIds]);

  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (trainingIds?.length === 1) {
      return `${Util.returnPropertyNameById(trainings, trainingIds[0])}`;
    } if (trainingIds?.length > 1) {
      return `${trainingIds.length} Trainings Selected`;
    } if (!trainingIds?.length) {
      return 'Select Training';
    }

    return '';
  };

  /**
   * Handles changing ordinal numbers for selected trainings
   * @param {object} e - JS Event
   * @param {string} trainingId - training Id
   * @returns {void}
   */
  const handleChangeOrders = (e, trainingId) => {
    // define value - could be event from input or passed value
    const value = parseInt(e?.target?.value) || e;

    // the value needs to be higher than 0
    if (value > 0) {
      // create new object with order for selected training
      const newOrder = {
        order: value,
        trainingId,
      };

      let newArray;

      // check if this training is already defined in the orders array
      const findOrderByTrainingId = orders.find(o => o.trainingId === trainingId);

      if (findOrderByTrainingId) {
        // update ordinal number
        newArray = orders.map((o) => {
          if (o.trainingId === trainingId) {
            // eslint-disable-next-line no-param-reassign
            o.order = value;
          }

          return o;
        });
      } else {
        // add a new order object to an array
        newArray = [...orders, newOrder];
      }

      // update orders state
      setOrders(newArray);
    } else {
      // do not add an order with a value below 0
      setOrders(orders.filter(o => o.trainingId !== trainingId));
    }
  };

  /**
   * Search by given Id and check the next possible ordinal number
   * @param {string} trainingId - training Id
   * @returns {number} next possible ordinal number
   */
  const returnNextOrdinalNumber = (trainingId) => {
    const ordersForGivenId = [];

    // get all orders for this trainingId
    modules.forEach((arr) => {
      arr.orders.forEach((o) => {
        if (o.trainingId === trainingId) {
          ordersForGivenId.push(o.order);
        }
      });
    });

    // get next ordinal number and save in orders
    const orderNumber = Util.findFirstMissingOrderNumber(ordersForGivenId, ordersForGivenId.length);
    handleChangeOrders(orderNumber, trainingId);

    return orderNumber;
  };

  /**
   * Sets the arrays for trainingIds and orders
   * @param {array} newTrainingIds - array with new trainingIds
   * @param {string} selectedTrainingId - id of the selected training
   * @returns {void}
   */
  const handleSetTrainingsAndOrders = (newTrainingIds, selectedTrainingId) => {
    returnNextOrdinalNumber(selectedTrainingId);
    setTrainingIds(newTrainingIds);
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="trainings-self-learning-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {eventType === Constants.EVENT__TYPE__NEW ? 'Create Module' : 'Edit Module'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-self-learning"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    placeholder="Title"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </div>
              </label>
            </div>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="slds-form-element__label" htmlFor="multi-select">
              Trainings
              <MultiSelect
                multiSelectPlaceholder={returnMultiSelectPlaceholder()}
                value={trainingIds}
                data={trainings}
                setData={handleSetTrainingsAndOrders}
              />
            </label>

            {trainingIds?.length ?
              trainingIds.map(training => (
                <div className="slds-form-element" key={training}>
                  <label className="slds-form-element__label" htmlFor={training}>
                    Order for
                    {' '}
                    {Util.returnPropertyNameById(trainings, training)}
                    <div className="slds-form-element__control">
                      <input
                        type="number"
                        id={training}
                        placeholder="Enter the order"
                        className="slds-input"
                        onChange={event => handleChangeOrders(event, training)}
                        onBlur={event => event.target.value < 1 ?
                          handleChangeOrders(event, training) :
                          event.preventDefault()}
                        value={orders.find(o => o.trainingId === training)?.order ||
                          returnNextOrdinalNumber(training)}
                        min="1"
                        step="1"
                      />
                    </div>
                  </label>
                </div>
              )) :
              null}

          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

ModuleModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** Label for module name */
  name: PropTypes.string.isRequired,
  /** sets module name in state */
  setName: PropTypes.func.isRequired,
  /** array with training Ids for which we assign the module */
  trainingIds: PropTypes.instanceOf(Array).isRequired,
  /** sets array with training Ids in state */
  setTrainingIds: PropTypes.func.isRequired,
  /** an array with objects that contain an order and training id */
  orders: PropTypes.instanceOf(Array).isRequired,
  /** sets array with objects containing order and training id  */
  setOrders: PropTypes.func.isRequired,
  /** an array with trainings created in self-learning section */
  trainings: PropTypes.instanceOf(Array).isRequired,
  /** an array with modules created in self-learning section */
  modules: PropTypes.instanceOf(Array).isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string,
};

export default ModuleModal;
