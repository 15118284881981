import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const RelationsAPI = {
  /**
   * Get relations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved relations - and `success`
   */
  getRelations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/relations`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default RelationsAPI;
