import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import NotificationsAPI from '../../../../api/notifications';

import Swal from '../../../../helpers/Swal';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

const NotificationsDataset = ({ validate }) => {
  const [results, setResults] = useContext(ResultsContext);
  const { notificationsData } = results;
  const [state, setState] = useContext(UtilsContext);

  // User Notification variables
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [isDismissible, setIsDismissible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setTitle('');
    setMessage('');
    setType('');
    setIsDismissible(false);
    setStartDate('');
    setEndDate('');
  };

  /**
   * Handler called when saving a notification
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      title,
      message,
      type,
      isDismissible,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };

    const validateResult = validate(newData);

    if (validateResult.length > 0) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          const res = await NotificationsAPI.createNotification(null, newData);
          setResults({
            ...results,
            notificationsData: [
              ...notificationsData,
              res.data,
            ],
          });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            notificationsData: [
              ...notificationsData,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The notification has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          const res = await NotificationsAPI.updateNotification(null, state.id, newData);

          const newArray = [...notificationsData];
          newArray[state.index] = res.data;
          setResults({ ...results, notificationsData: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            notificationsData: newArray,
          });
          Swal.fireSuccess('Updated!', 'The notification has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when canceling a notification modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing a notification
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = notificationsData.filter(e => e._id === id)[0];
    setTitle(singleItem.title);
    setMessage(singleItem.message);
    setType(singleItem.type);
    setIsDismissible(singleItem.isDismissible);

    const _startDate = new Date(singleItem.startDate)
      .toISOString()
      .split('T')[0]
      .replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3');

    const _endDate = new Date(singleItem.endDate)
      .toISOString()
      .split('T')[0]
      .replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3');
    setStartDate(_startDate);
    setEndDate(_endDate);
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
      Constants.USER__PERMISSION__WRITE,
    );

  /**
   * Handler called when deleting a user notification
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure to delete this notification?',
        text: 'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        const { id } = event.target.dataset;
        const { index } = event.target.dataset;

        await NotificationsAPI.deleteNotification(null, id);

        // Delete results
        const newArray = [...notificationsData];
        newArray.splice(index, 1);
        setResults({ ...results, notificationsData: newArray });
        setState({ ...state, notificationsData: newArray });

        Swal.fireSuccess('Deleted!', 'The notification has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  return (
    <div className="notification">
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Title">
                Title
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Message">
                Message
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Type">
                Type
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is dismissible">
                Is dismissible
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Start Date">
                Start Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="End Date">
                End Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {notificationsData?.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Title">
                <div className="slds-truncate" title={el.title}>
                  {el.title}
                </div>
              </td>

              <td data-label="Message">
                <div className="slds-truncate" title={el.message}>
                  {el.message}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  <span
                    className={((notificationType) => {
                      switch (notificationType) {
                        case 'info': return 'slds-badge slds-theme_success';
                        case 'error': return 'slds-badge slds-theme_error';
                        case 'warning': return 'slds-badge slds-theme_warning';
                        default: return 'slds-badge';
                      }
                    })(el.type)}
                  >
                    {el.type}
                  </span>
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  {el.isDismissible ?
                    (
                      <span className="slds-badge slds-theme_success">
                        True
                      </span>
                    ) :
                      <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>
              <td>
                <div className="slds-truncate">
                  {el.startDate
                    .split('T')[0]
                    .replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1')}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  {el.endDate
                    .split('T')[0]
                    .replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1')}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={el.createdAt}>
                  {el.createdAt}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={el.updatedAt}>
                  {el.updatedAt}
                </div>
              </td>

              <td data-label="Actions">
                <div className="slds-truncate" title="Delete">
                  <svg
                    className="slds-icon slds-icon-text-error"
                    aria-hidden="true"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <use
                      xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                      data-id={el._id}
                      data-index={i}
                      onClick={handleDelete}
                      style={{ cursor: 'pointer' }}
                    />
                  </svg>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Dataset Section */}

      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={state.isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Notification Form
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-title">
                Title
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-title"
                    placeholder="Title"
                    className="slds-input"
                    onChange={event => setTitle(event.target.value)}
                    value={title}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-message">
                Message
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-message"
                    placeholder="Message"
                    className="slds-input"
                    onChange={event => setMessage(event.target.value)}
                    value={message}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Type
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setType(event.target.value)}
                      id="select-type"
                      value={type}
                    >
                      <option value="" defaultValue>
                        Please select
                      </option>
                      <option value="info">Info</option>
                      <option value="error">Error</option>
                      <option value="warning">Warning</option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="isDismissible">
                Is dismissible
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setIsDismissible(!isDismissible)}
                      type="checkbox"
                      name="isDismissible"
                      id="isDismissible"
                      checked={isDismissible}
                    />
                    <label className="slds-checkbox__label" htmlFor="isDismissible">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isDismissible ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="startDate">
                Start date
                <div>
                  <input
                    type="date"
                    onChange={event => setStartDate(event.target.value)}
                    value={startDate}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="endDate">
                End date
                <div>
                  <input
                    type="date"
                    onChange={event => setEndDate(event.target.value)}
                    value={endDate}
                  />
                </div>
              </label>
            </div>
          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
      {/* Modal Section */}
    </div>
  );
};

NotificationsDataset.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
};

export default NotificationsDataset;
