import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const UserNotificationsAPI = {
  /**
   * Retrieves all user notifications
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - Criteria to filter results
   * @returns {object} An object with `data` property - the retrieved user notifications - and `success`
   */
  getUserNotifications: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/user-notifications`,
      criteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Creates a user notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} notificationId - Notification ID of the user notification
   * @param {string} organisationQuery - TOrganisation Query of the user notification
   * @param {string} usersQuery - Users Query of the user notification
   * @return {object} An object with `data` property - the created user notifications - and `success`
   */
  createUserNotification: async (
    cancelToken,
    {
      notificationId,
      organisationQuery,
      usersQuery,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/user-notifications/create`, {
        notificationId,
        organisationQuery,
        usersQuery,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates a user notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user notification you want to update
   * @param {string} notificationId - Notification ID of the user notification
   * @param {string} organisationQuery - TOrganisation Query of the user notification
   * @param {string} usersQuery - Users Query of the user notification
   * @param {boolean} isDismissed - True if the user notification is dismissed
   * @return {object} An object with `data` property - the updated user notification - and `success`
   */
  updateUserNotification: async (
    cancelToken,
    id,
    {
      notificationId,
      organisationQuery,
      usersQuery,
      isDismissed,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/user-notifications/${id}`, {
        notificationId,
        organisationQuery,
        usersQuery,
        isDismissed,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes a notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user notification you want to delete
   * @returns {object} An object with `data` property - the deleted notification - and `success`
   */
  deleteUserNotification: async (cancelToken, id) => {
    const res = await axios.post(
      `${apiUrl}/admin/user-notifications/${id}/delete`, null, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default UserNotificationsAPI;
