import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const ResultsContext = createContext();

const ResultsProvider = ({ children }) => {
  const [results, setResults] = useState({
    organisationsData: [],
    usersData: [],
    dashboardData: [],
    engagementDashboardData: [],
    essentialsDashboardData: [],
    unmappedTicketsData: [],
    adoptionDashboardData: [],
    changeLogsData: [],
    notificationsData: [],
    alertsData: [],
    integrationsData: [],
    relationsData: [],
    userNotificationsData: [],
    certificatesData: [],
    selectionRunLogsData: [],
    filteredLogs: [],
    certificationsUsersData: [],
    exams: [],
    searchTextForExams: '',
    questions: [],
    searchTextForQuestions: '',
    certificationsGeneratedExams: [],
    topicQuestions: [],
    topics: [],
    searchTextForTopics: '',
    searchTextForTopicQuestions: '',
    trainings: [],
    searchTextForTrainings: '',
    modules: [],
    searchTextForModules: '',
    customerQuestions: [],
    filteredCustomerQuestions: [],
    filterCriteriaForCustomerQuestions: null,
    trainingResults: [],
    filteredTrainingResults: [],
    filterCriteriaForTrainingResults: null,
    featureAdvertsData: [],
    guidanceTips: [],
  });

  return (
    <ResultsContext.Provider value={[results, setResults]}>
      {children}
    </ResultsContext.Provider>
  );
};

ResultsProvider.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export { ResultsProvider };
