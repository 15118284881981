import React, {
  useState,
  useContext,
} from 'react';
import { ResultsContext } from '../../../../ResultsContext';

import SettingsApi from '../../../../api/settings';

import './style.scss';

const Settings = () => {
  const [results] = useContext(ResultsContext);
  const { settings } = results;

  // set to null as initial value
  const [processingEnabled, setProcessingEnabled] = useState(null);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);
    try {
      await SettingsApi.update(null, processingEnabled);
      // eslint-disable-next-line no-alert
      alert('Setting updated correctly');
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error updating setting: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  /**
   * Renders Last Update statement
   * @returns {String} last update statement
   */
  const lastUpdate = () => settings ?
    `Last modified by ${settings.updatedBy}
    at ${new Date(settings.updatedAt).toLocaleString()}` :
    null;

  /**
   * Renders Last Shutdown statement
   * @returns {String} last shutdown statement
   */
  const lastShutdownOfProcessing = () => settings ?
    `Last shutdown of Processing:
    ${new Date(settings.lastShutdownOfProcessing).toLocaleString()}` :
    null;

  /**
   * Renders Last Start statement
   * @returns {String} last start statement
   */
  const lastStartOfProcessing = () => settings ?
    `Last start of Processing:
    ${new Date(settings.lastStartOfProcessing).toLocaleString()}` :
    null;

  // once settings are loaded, give ProcessingEnabled correct value
  if (settings && settings.processingEnabled !== processingEnabled) {
    setProcessingEnabled(settings.processingEnabled);
  }

  /**
   * Sets initial value of processingEnabled
   * @param {Boolean} newValue - New value for processingEnabled
   * @returns {void}
   */
  const changeProcessingEnabled = (newValue) => {
    setProcessingEnabled(newValue);
    settings.processingEnabled = newValue;
  };

  return (
    <>
      <font color="red">
        Careful: Setting the toggle below to Disabled will stop any automated
        processing of selections / picklist refreshes, ...
        <br />
        <font style={{ fontSize: 15 }}>
          Disable this temporarily before updating processing to new version,
          then don&apos;t forget to switch it on again.
        </font>
      </font>

      <br />
      <br />

      <form id="form" onSubmit={submit}>
        <div style={{ width: '200px' }}>
          { processingEnabled == null ?
            'Loading' :
            (
              <div className="slds-form-element">
                <label className="slds-checkbox_toggle slds-grid">
                  <span className="slds-form-element__label slds-m-bottom_none">Processing Enabled</span>
                  <br />
                  <input
                    type="checkbox"
                    name="processing-enabled-toggle"
                    onChange={() => changeProcessingEnabled(!processingEnabled)}
                    value="processing-enabled-toggle"
                    checked={processingEnabled}
                    aria-describedby="processing-enabled-toggle"
                  />
                  <span id="processing-enabled-toggle" className="slds-checkbox_faux_container" aria-live="assertive">
                    <span className="slds-checkbox_faux" />
                    <span className="slds-checkbox_on">Enabled</span>
                    <span className="slds-checkbox_off">Disabled</span>
                  </span>
                </label>
              </div>

            ) }
        </div>

        <br />

        <button type="submit" className="slds-button slds-button_brand" id="submit" disabled={submitButtonDisabled}>
          Submit
        </button>

      </form>

      <div style={{ fontSize: 15 }}>
        <br />
        <br />
        {lastUpdate()}
        <br />
        {lastShutdownOfProcessing()}
        <br />
        {lastStartOfProcessing()}
      </div>
    </>
  );
};

export default Settings;
