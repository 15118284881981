import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Input,
  Label,
  Icon,
} from 'semantic-ui-react';

import './style.scss';

const FilterByOrg = ({
  orgs,
  handleChangeSelectedOrg,
  handleStopFiltering,
  showingFilteredUsers,
  selectedOrg,
}) => {
  const ref = useRef(null);
  // Dropdown state
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredOrgs, setFilteredOrgs] = useState([]);

  useEffect(() => {
    if (orgs && orgs.length && !filteredOrgs.length) setFilteredOrgs(orgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs]);
  useEffect(() => {
    /**
     * Hides the filter users dropdown
     * @param {object} event - The event handler object
     * @returns {void}
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropdown(false);
        setSearchText('');
      }
    }

    // Bind the event listener
    // eslint-disable-next-line spellcheck/spell-checker
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      // eslint-disable-next-line spellcheck/spell-checker
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleFilterOrgs = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    if (searchText !== '') {
      // Filter the orgs
      const regex = new RegExp(`^${searchText}`, 'i');
      const filteredOrganisations = orgs.filter(c => regex.test(c.name));
      // Set filtered orgs
      setFilteredOrgs(filteredOrganisations);
    } else setFilteredOrgs(orgs);
  }, [searchText, orgs]);

  return (
    <div
      className="filter-by-org"
      ref={ref}
    >
      {(selectedOrganisation || selectedOrg) ?
        (
          <Label className="selected-org-label">
            {selectedOrganisation || selectedOrg.name}
            <Icon
              name="delete"
              onClick={() => {
                setSelectedOrganisation('');
                handleStopFiltering();
                setSearchText('');
              }}
            />
          </Label>
        ) :
        null}
      <Dropdown
        text="Filter"
        icon="filter"
        floating
        labeled
        disabled={showingFilteredUsers}
        button
        className="icon filter-dropdown"
        clearable
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <Dropdown.Menu
          open={openDropdown}
        >
          <Input
            onClick={(e) => {
              // Stop the dropdown's click handle from running when input is clicked
              e.stopPropagation();
            }}
            value={searchText}
            onChange={handleFilterOrgs}
            icon="search"
            iconPosition="left"
            className="search"
          />
          <Dropdown.Divider />
          {filteredOrgs.length ?
            (
              <Dropdown.Menu scrolling>
                {
                  filteredOrgs.map(option => (
                    <Dropdown.Item
                      key={option._id}
                      text={option.name}
                      value={option._id}
                      onClick={(e, data) => {
                        handleChangeSelectedOrg(data);
                        setOpenDropdown(false);
                        setSelectedOrganisation(data.text);
                        setSearchText('');
                      }}
                    />
                  ))
                }
              </Dropdown.Menu>
            ) :
              <Dropdown.Text className="no-orgs-found" content="No orgs found" />}
        </Dropdown.Menu>

      </Dropdown>
    </div>
  );
};

FilterByOrg.propTypes = {
  /**
   * Array consisting of orgs to render
   */
  orgs: PropTypes.instanceOf(Array).isRequired,
  /**
   * Handles filtering of users by organisation
   */
  handleChangeSelectedOrg: PropTypes.func.isRequired,
  /**
   * Stops the filtering of users
   */
  handleStopFiltering: PropTypes.func.isRequired,
  /**
   * indicates whether we are showing filtered users
   * for an organisation (when the user is routed from organisations)
   */
  showingFilteredUsers: PropTypes.bool,
  /**
   * The org whose users are being shown (when the user is routed from organisations)
   */
  selectedOrg: PropTypes.instanceOf(Object),
};

export default FilterByOrg;
