import React, { useState } from 'react';
import {
  // eslint-disable-next-line spellcheck/spell-checker
  Dropzone, FileItem, FullScreenPreview, VideoPreview,
} from '@dropzone-ui/react';
import PropTypes from 'prop-types';

function MediaUpload({
  setMediaType, setMedia, mediaType, files, setFiles,
}) {
  const [imageSrc, setImageSrc] = useState(undefined);
  const [videoSrc, setVideoSrc] = useState(undefined);

  /**
   * Returns the media type
   * @param {String} fileType - The file type
   * @returns {String} The media type; image or video
   */
  const getMediaType = (fileType) => {
    if (fileType?.includes('image')) {
      return 'image';
    }
    if (fileType?.includes('video')) {
      return 'video';
    }
    return '';
  };

  /**
   * Updates the files, media and media type
   * @param {Array} incomingFiles - File uploads
   * @returns {void}
   */
  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
    setMedia(incomingFiles[0]?.file);
    setMediaType(getMediaType(incomingFiles[0]?.file?.type));
  };

  /**
   * Delete the uploaded file
   * @param {String} id - The id of the file to be deleted
   * @returns {void}
   */
  const onDelete = (id) => {
    setFiles(files.filter(x => x.id !== id));
    setMedia(undefined);
    setMediaType('');
  };

  /**
   * Preview uploaded image in fullscreen
   * @param {String} imageSource - image to be previewed
   * @returns {void}
   */
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  /**
   * Preview uploaded video
   * @param {String} vidSrc  - video to be previewed
   * @returns {void}
   */
  const handleWatch = (vidSrc) => {
    setVideoSrc(vidSrc);
  };

  return (
    <>
      <Dropzone
        onChange={updateFiles}
        accept="image/*,video/*"
        value={files}
        maxFiles={1}
        fakeUploading
        disableScroll
      >
        {files.map(file => (
          <FileItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...file}
            key={file.id}
            onDelete={onDelete}
            onSee={handleSee}
            onWatch={handleWatch}
            resultOnTooltip
            preview
            info
            hd
          />
        ))}
      </Dropzone>
      {
        mediaType === 'image' && imageSrc && (
        <FullScreenPreview
          imgSource={imageSrc}
          openImage={imageSrc}
          onClose={() => handleSee(undefined)}
        />
        )
      }
      {
        mediaType === 'video' && videoSrc && (
          <VideoPreview
            videoSrc={videoSrc}
            openVideo={videoSrc}
            onClose={() => handleWatch(undefined)}
            controls
            autoplay
          />
        )
      }

    </>
  );
}

MediaUpload.propTypes = {
  setMediaType: PropTypes.func.isRequired,
  setMedia: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Array).isRequired,
  setFiles: PropTypes.func.isRequired,
};

export default MediaUpload;
