import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Icon,
  Button,
  Dropdown,
  Loader,
  Modal,
} from 'semantic-ui-react';

import '../style.scss';
import Constants from '../../../../../constants/constants';

const Filter = ({
  handleStopFiltering,
  filterResultsHandler,
  loading,
  filter,
  options,
}) => {
  // Dropdown state
  const [showFilterInputs, setShowFilterInputs] = useState(false);
  const [filterBy, setFilterBy] = useState(Constants.FILTER_BY__ORGANISATION_ID);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  /**
   * Function that handles change of the filter option in the dropdown
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleOnChange = (e, { value }) => {
    if (value === Constants.FILTER_BY__ANSWERED) {
      setSearchText(true);
    } else {
      setSearchText('');
    }

    setFilterBy(value);
  };

  /**
   * Function that handles change of the searchText in the search input
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleAnswerChange = (e, { value }) => {
    setSearchText(value);
  };

  /**
   * Function that returns proper component depending on the selected filter option (filterBy)
   * @returns {Object} HTML Object
   */
  const renderComponentForSelectedFilterOption = () => (
    <>
      {(filterBy === Constants.FILTER_BY__ORGANISATION_ID || filterBy === Constants.FILTER_BY__USER_ID ||
          filterBy === Constants.FILTER_BY__TOPIC_ID) ?
        (
          <Input
            labelPosition="right"
            type="text"
            placeholder="Search..."
            onChange={e => setSearchText(e.target.value)}
            value={searchText}
          />
        ) :
        null}
      {filterBy === Constants.FILTER_BY__ANSWERED ?
        (
          <Label className="checkbox-label">
            <Dropdown
              options={[
                {
                  value: true,
                  text: 'True',
                  key: 'true',
                },
                {
                  value: false,
                  text: 'False',
                  key: 'false',
                },
              ]}
              defaultValue
              value={searchText}
              onChange={handleAnswerChange}
            />
          </Label>
        ) :
        null}
      {filterBy === Constants.FILTER_BY__DATE ?
        (
          <Label className="filter-by-date-label">
            <div className="slds-form-element">
              <label className="slds-form-element__label startDate date-filter-label" htmlFor="startDate">
                From
                <div>
                  <input
                    type="date"
                    onChange={event => setStartDate(event.target.value)}
                    value={startDate}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label endDate date-filter-label" htmlFor="endDate">
                To
                <div>
                  <input
                    type="date"
                    onChange={event => setEndDate(event.target.value)}
                    value={endDate}
                  />
                </div>
              </label>
            </div>
          </Label>
        ) :
        null}
    </>
  );

  /**
   * Function that returns proper label for selected filter criteria
   * @param {object} filterCriteria - data with filter criteria
   * @returns {Object} HTML Object
   */
  const renderSelectedFilterCriteria = (filterCriteria) => {
    if (filterCriteria?.orgId) {
      return (
        <div className="filter-data-label">
          Organisation Id:
          {' '}
          <strong>{filterCriteria.orgId}</strong>
        </div>
      );
    } if (filterCriteria?.userId) {
      return (
        <div className="filter-data-label">
          User Id:
          {' '}
          <strong>{filterCriteria.userId}</strong>
        </div>
      );
    } if (filterCriteria?.answered || filterCriteria.answered === false) {
      return (
        <div className="filter-data-label">
          Answered:
          {' '}
          <strong>{filterCriteria.answered.toString()}</strong>
        </div>
      );
    } if (filterCriteria?.startDate || filterCriteria?.createdAt) {
      return (
        <>
          <div className="filter-data-label">
            From
            {' '}
            <strong>
              {new Date(filterCriteria?.startDate?.$gte || filterCriteria?.createdAt?.$gte)
                .toLocaleDateString()}
            </strong>
          </div>
          {' '}
          <div className="filter-data-label">
            To
            {' '}
            <strong>
              {new Date(filterCriteria?.startDate?.$lte || filterCriteria?.createdAt?.$lte)
                .toLocaleDateString()}
            </strong>
          </div>
        </>
      );
    } if (filterCriteria?.topicId) {
      return (
        <div className="filter-data-label">
          Topic Id:
          {' '}
          <strong>{filterCriteria.topicId}</strong>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className="filter"
      >
        {filter ?
          (
            <Label className="selected-filter-label">
              {renderSelectedFilterCriteria(filter)}
              <Icon
                name="delete"
                onClick={() => {
                  handleStopFiltering();

                  if (filterBy === Constants.FILTER_BY__ANSWERED) {
                    setSearchText(true);
                  } else {
                    setSearchText('');
                  }
                }}
              />
            </Label>
          ) :
          null}
        <Button
          onClick={() => setShowFilterInputs(!showFilterInputs)}
          icon
          labelPosition="left"
          className="filter-button"
        >
          <Icon name="filter" />
          Filter
        </Button>
      </div>
      <div>
        {
          showFilterInputs && (
            <div className="filter-inputs">
              <Label basic>Filter by</Label>
                {renderComponentForSelectedFilterOption()}
              <Label>
                <Dropdown
                  options={options}
                  defaultValue={Constants.FILTER_BY__ORGANISATION_ID}
                  onChange={handleOnChange}
                  value={filterBy}
                />
              </Label>
              <Button
                primary
                onClick={e => filterResultsHandler(e, filterBy, searchText, startDate, endDate)}
                disabled={(!searchText && searchText !== false &&
                    filterBy !== Constants.FILTER_BY__DATE) ||
                  (filterBy === Constants.FILTER_BY__DATE && (startDate === '' || endDate === ''))}
              >
                Submit
              </Button>
            </div>
          )
        }
        <Modal
          open={loading}
          size="tiny"
          centered
          className="modal"
        >
          <Loader size="large">Loading...</Loader>
        </Modal>
      </div>
    </>
  );
};

Filter.propTypes = {
  /* Stops the filtering of results */
  handleStopFiltering: PropTypes.func.isRequired,
  /** Handles the filtering of results */
  filterResultsHandler: PropTypes.func.isRequired,
  /** Loading state of the modal */
  loading: PropTypes.bool.isRequired,
  /** The object with criteria results for the filter */
  filter: PropTypes.instanceOf(Object),
  /** The array with filter options to select in the dropdown */
  options: PropTypes.instanceOf(Array).isRequired,
};

export default Filter;
