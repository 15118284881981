import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SFMCOnboardingAPI = {

  /**
   * Create new business unit
   *
   * @param {*} firstName firstName
   * @param {*} lastName lastName
   * @param {*} email email
   * @param {*} environment environment
   * @returns {Object} Result
   */
  createBusinessUnit: async (firstName, lastName, email, environment) => {
    const body = {
      firstName,
      lastName,
      email,
      environment,
    };

    const res = await axios.post(
      `${apiUrl}/admin/sfmc-onboarding/business-unit/create`,
      body,
      { withCredentials: true },
    );
    return res.data;
  },

  /**
   * Create new user account
   *
   * @param {String} firstName firstName
   * @param {String} lastName lastName
   * @param {String} email email
   * @param {String} newBU newBU
   * @param {String} environment environment
   * @returns {Object} Result
   */
  createUserAccount: async (firstName, lastName, email, newBU, environment) => {
    const body = {
      firstName,
      lastName,
      email,
      newBU,
      environment,
    };

    const res = await axios.post(
      `${apiUrl}/admin/sfmc-onboarding/user-account/create`,
      body,
      { withCredentials: true },
    );
    return res.data;
  },

};

export default SFMCOnboardingAPI;
