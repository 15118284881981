import React, { useContext, useState } from 'react';
import SunEditor from 'suneditor-react';
import { Player } from 'video-react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { ResultsContext } from '../../../../../ResultsContext';

import './style.scss';
import MediaUpload from './MediaUpload';
import Swal from '../../../../../helpers/Swal';
import FeatureAdvertsAPI from '../../../../../api/feature-adverts';
import Util from '../../../../../util';
import { UtilsContext } from '../../../../../UtilsContext';
import Constants from '../../../../../constants/constants';
import ToggleButton from '../../../../shared/ToggleButton/ToggleButton';

function DisabledFeaturesModal({ showModal, toggleAdvertList, features }) {
  const [results, setResults] = useContext(ResultsContext);
  const [showFeatureAdvertModal, setShowFeatureAdvertModal] = useState(false);
  const [state, setState] = useContext(UtilsContext);
  const [mode, setMode] = useState('');
  const [updateMedia, setUpdateMedia] = useState(false);

  // Feature Advert Variables
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(true);
  const [feature, setFeature] = useState('');
  const [media, setMedia] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [files, setFiles] = useState([]);
  const [advertId, setAdvertId] = useState('');

  const { featureAdvertsData } = results;

  // class names for advert modal container
  const disabledFeaturesModalClassName = classNames(
    'slds-modal',
    { 'slds-fade-in-open': showModal },
  );

  // class names for advert modal
  const advertModalClassName = classNames(
    'slds-modal',
    { 'slds-fade-in-open': showFeatureAdvertModal },
  );

  // Toggle advert feature modal
  const toggleFeatureAdvertModal = () => {
    setShowFeatureAdvertModal(!showFeatureAdvertModal);
  };

  /**
   *Toggle to either show feature list modal or create/edit modal
   * @returns {void} - Shows or Hides feature list or create/edit modal
   */
  const toggleModals = () => {
    toggleAdvertList();
    toggleFeatureAdvertModal();
  };

  /**
   * Validate an object so ensure no property is empty
   * @param {object} properties - The object to check
   * @returns {array} - All the errors `Please enter PROPERTY_NAME` as string
   */
  const validate = (properties) => {
    const errors = [];
    Object.entries(properties).forEach((property) => {
      if (property[1] === '') errors.push(`Please enter ${property[0]}`);
    });
    return errors;
  };

  /**
   * validate for existing feature advert
   * @param {string} featureToCheck - The feature to check
   * @returns {boolean} - true if feature advert exists
   * @returns {boolean} - false if feature advert does not exist
   *
   */
  const validateExistingFeatureAdvert = (featureToCheck) => {
    const existingFeatureAdvert = featureAdvertsData.find(advert => advert.feature === featureToCheck);
    return existingFeatureAdvert;
  };

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setTitle('');
    setDescription('');
    setFeature('');
    setStatus(true);
    setMedia('');
    setMediaType('');
    setFiles([]);
    setUpdateMedia(false);
    setMode('');
    setAdvertId('');
  };

  /**
   * Handler called when saving an advert
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      title,
      media,
      mediaType,
      description,
      feature,
      status,
    };

    const requiredFields = { title, feature, description };

    const validateResult = validate(requiredFields);

    if (validateResult.length > 0) {
      Swal.fireWarning('', validateResult[0]);
    } else {
      try {
        if (mode === Constants.EVENT__TYPE__NEW) {
          if (validateExistingFeatureAdvert(feature)) {
            Swal.fireWarning('', `Feature ${feature} already has an advert`);
            return;
          }
          const confirmCreateAdvert = await Swal.fireSuccess({
            icon: 'warning',
            title: `Create Advert Modal for ${feature}`,
            text: `Are you sure you want to create this advert for ${feature}?`,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonColor: '#d33',
          });
          if (confirmCreateAdvert.value) {
            const res = await FeatureAdvertsAPI.createAdvert(null, newData);
            setResults({
              ...results,
              featureAdvertsData: [
                ...featureAdvertsData,
                res.data,
              ],
            });

            setState({
              ...state,
              isOpen: false,
              eventType: '',
              featureAdvertsData: [
                ...featureAdvertsData,
                res.data,
              ],
            });

            Swal.fireSuccess('Created!', 'The advert has been successfully created.');
            cleanFields();
            toggleModals();
          }
        } else if (mode === Constants.EVENT__TYPE__UPDATE) {
          const res = await FeatureAdvertsAPI.updateAdvert(null, advertId, newData);

          const newArray = featureAdvertsData.map((advert) => {
            if (advert._id === advertId) {
              return res.data;
            }
            return advert;
          });
          setResults({ ...results, featureAdvertsData: newArray });

          setState({
            ...state,
            featureAdvertsData: newArray,
          });
          Swal.fireSuccess('Updated!', 'The advert has been updated.');
          cleanFields();
          toggleModals();
        }
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when creating an advert
   * @returns {void} - Sets mode to new
   */
  const handleCreate = async () => {
    toggleModals();
    setMode(Constants.EVENT__TYPE__NEW);
  };

  /**
   * Handler called when cancel creation/editing an advert
   * @returns {void}
   */
  const handleCancel = async () => {
    const cancelModal = await Swal.fireWarning({
      icon: 'warning',
      title: `Are you sure you want to cancel ${mode === Constants.EVENT__TYPE__NEW ?
        'creation' :
        'updating'}  of this advert?`,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    });
    if (cancelModal.value) {
      cleanFields();
      toggleModals();
    }
  };

  /**
   * Handler called when editing an advert
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = async (event) => {
    event.preventDefault();
    setMode(Constants.EVENT__TYPE__UPDATE);
    const { id } = event.target.dataset;
    setAdvertId(id);

    // Prefilled textbox
    const singleItem = featureAdvertsData.filter(e => e._id === id)[0];
    setTitle(singleItem.title);
    setDescription(singleItem.description);
    setFeature(singleItem.feature);
    setStatus(singleItem.status);
    setMedia(singleItem.media);
    setMediaType(singleItem.mediaType);

    toggleModals();
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const featureToDelete = featureAdvertsData.find(advert => advert._id === id);
    const confirmDeleteAdvert = await Swal.fireWarning({
      icon: 'warning',
      title: `Delete Advert Modal for ${featureToDelete.feature}`,
      text: `Are you sure you want to delete this advert for ${featureToDelete.feature}?`,
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    });

    if (confirmDeleteAdvert.value) {
      try {
        await FeatureAdvertsAPI.deleteAdvert(null, id);
        const newArray = featureAdvertsData.filter(e => e._id !== id);
        setResults({ ...results, featureAdvertsData: newArray });
        setState({
          ...state,
          featureAdvertsData: newArray,
        });
        Swal.fireSuccess('Deleted!', 'The advert has been deleted.');
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  return (
    <>
      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={disabledFeaturesModalClassName}
      >
        <div className="slds-modal__container feature-adverts-container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Disabled Feature Advert Modals
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="new-feature-advert-btn">
              <button
                type="button"
                className="slds-button slds-button_brand"
                onClick={handleCreate}
                style={{ width: '150px' }}
              >
                Create New Advert
              </button>
            </div>

            <div>
              <table
                className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout alert-table"
              >
                <thead>
                  <tr className="slds-line-height_reset">
                    <th className="id-column" scope="col">
                      <div className="slds-truncate" title="_id">
                        _id
                      </div>
                    </th>
                    <th scope="col">
                      <div className="slds-truncate" title="Created at">
                        Created At
                      </div>
                    </th>
                    <th scope="col">
                      <div className="slds-truncate" title="Last modified date">
                        Last Modified Date
                      </div>
                    </th>
                    <th scope="col">
                      <div className="slds-truncate" title="Feature">
                        Feature
                      </div>
                    </th>

                    <th scope="col">
                      <div className="slds-truncate" title="Status">
                        Status
                      </div>
                    </th>

                    <th scope="col">
                      <div className="slds-truncate" title="Actions">
                        Actions
                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {featureAdvertsData.map((el, i) => (
                    <tr key={el._id} className="slds-hint-parent">
                      <td data-label="_id">
                        <div title={el._id}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            href="#"
                            onClick={handleEdit}
                            data-id={el._id}
                            data-index={i}
                          >
                            {el._id}
                          </a>
                        </div>
                      </td>
                      <td data-label="Created At">
                        <div className="slds-truncate" title={el.createdAt}>
                          {el.createdAt}
                        </div>
                      </td>
                      <td>
                        <div className="slds-truncate" title={el.updatedAt}>
                          {el.updatedAt}
                        </div>
                      </td>
                      <td data-label="Feature">
                        <div className="slds-truncate" title={el.feature}>
                          {el.feature}
                        </div>
                      </td>

                      <td>
                        <div className="slds-truncate">
                          <span
                            className={classNames('slds-badge',
                              { 'slds-theme_success': el.status },
                              { 'slds-theme_error': !el.status })}
                          >
                            {el.status ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                      </td>
                      <td data-label="Actions">
                        <div className="slds-truncate" title="Delete">
                          <svg
                            className="slds-icon slds-icon-text-error"
                            aria-hidden="true"
                            data-id={el._id}
                            onClick={handleDelete}
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          >
                            <use
                              xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                              data-id={el._id}
                              data-index={i}
                              style={{ cursor: 'pointer' }}
                            />
                          </svg>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_brand"
              onClick={toggleAdvertList}
            >
              Back
            </button>
          </footer>
        </div>
      </section>
      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={advertModalClassName}
      >
        <div className="slds-modal__container feature-advert-modal" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {mode === Constants.EVENT__TYPE__NEW ? 'New Feature Advert' : `Feature Advert for ${feature}`}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="status-container">
              <span className="status-label">
                Advert
                {' '}
                {`${status ? 'Active' : 'Disabled'}`}
              </span>
              <ToggleButton
                checked={status}
                onChange={() => setStatus(!status)}
              />
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Feature
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="select-type"
                      onChange={event => setFeature(event.target.value)}
                      value={feature}
                    >
                      <option defaultValue value="">Select feature</option>
                      {
                        features.map(el => (
                          <option key={el.feature} value={el.feature}>{el.feature}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-title">
                Title
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-title"
                    placeholder="Subject"
                    className="slds-input"
                    onChange={event => setTitle(event.target.value)}
                    value={title}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-message">
                Description

                <div className="slds-form-element__control">
                  <SunEditor
                    id="form-element-answer"
                    setContents={description}
                    onChange={event => setDescription(event)}
                    autoFocus
                    width="100%"
                    height="300px"
                    setOptions={{
                      buttonList: [
                        ['undo', 'redo'],
                        ['font', 'align'],
                        ['fontSize', 'formatBlock', 'fontColor'],
                        ['bold', 'underline', 'italic', 'strike'],
                        ['link'],
                        ['lineHeight', 'list', 'table', 'fullScreen'],
                      ],
                      linkTargetNewWindow: true,
                    }}
                  />
                </div>
              </label>
            </div>
            {
              (mode === Constants.EVENT__TYPE__UPDATE) && (
                <>
                  <p className="slds-form-element__label">Feature Advert Media</p>
                  <div className="update-feature-btn">
                    <button
                      type="button"
                      className={
                        classNames(
                          'slds-button',
                          { 'slds-button_neutral': updateMedia },
                          { 'slds-button_brand': !updateMedia },
                        )
                      }
                      onClick={() => setUpdateMedia(!updateMedia)}
                    >
                      {updateMedia ? 'Cancel Update Media' : 'Update Media'}
                    </button>
                  </div>

                  {
                    !updateMedia && (
                      <div className="feature-media">
                        {
                          mediaType === 'video' && (
                            <div className="feature-video">
                              <Player
                                playsInline
                                poster="/assets/feature_image.jpg"
                                src={media}
                              />
                            </div>
                          )
                        }
                        {
                          mediaType === 'image' && (
                            <div className="feature-image">
                              <img src={media} alt="Feature advert" />
                            </div>
                          )
                        }
                      </div>
                    )
                  }

                </>
              )
            }
            {
              (mode === Constants.EVENT__TYPE__NEW || (mode === Constants.EVENT__TYPE__UPDATE && updateMedia)) && (
                <div className="slds-form-element">
                  <p className="slds-form-element__label">Upload an Image/Video</p>
                  <MediaUpload
                    setMediaType={setMediaType}
                    setMedia={setMedia}
                    mediaType={mediaType}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
              )
            }

          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div
        className={showModal || showFeatureAdvertModal ? 'slds-backdrop slds-backdrop_open' : ''}
      />
    </>
  );
}

DisabledFeaturesModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleAdvertList: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    feature: PropTypes.string,
    status: PropTypes.bool,
  })).isRequired,
};

export default DisabledFeaturesModal;
