import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const CurrencyRatesAPI = {
  /**
   * Update Currency Rates
   * @param {object} cancelToken - The cancel token from Axios
   * @param {number} usdToEur - Usd to Euro conversion
   * @param {number} audToEur - Aud to Euro conversion
   * @returns {object} An object with `data` property - the retrieved changelogs - and `success`
   */
  saveCurrencyRates: async (cancelToken, usdToEur, audToEur) => {
    const res = await axios.post(
      `${apiUrl}/admin/currency-rates`,
      {
        usdToEur,
        audToEur,
      },
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get Currency Rates
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} The retrieved currency rates
   */
  getCurrencyRates: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/currency-rates`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.currencyRates;
  },
};

export default CurrencyRatesAPI;
