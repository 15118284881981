/* eslint-disable spellcheck/spell-checker */
import React, { useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import { Typography } from '@mui/material';
import SFMCOffboardingAPI from '../../../../api/sfmcOffboarding';

import './style.scss';

const SFMCUserOffboarding = () => {
  const eBU = { id: 100017920, label: 'Demo' };
  const [allBUs, setAllBUs] = React.useState([]);
  const [selectedBU, setSelectedBU] = React.useState({ id: 0, label: 'Select BU' });
  const [associatedUsers, setAssociatedUsers] = React.useState([]);
  const [action, setAction] = React.useState('remove');
  const [showAssociatedUsers, setShowAssociatedUsers] = React.useState(false);
  const [loadingBar, setLoadingBar] = React.useState(false);
  const [showSucessfullDeletion, setShowSucessfullDeletion] = React.useState(false);
  const [showSucessfullDissociation, setShowSucessfullDissociation] = React.useState(false);

  /**
   * Get all BUs
   * @returns {Array[]} Array of BUs
   */
  const getallBUs = async () => {
    setLoadingBar(true);
    setSelectedBU({ id: 0, label: 'Select BU' });
    const allBUsResponse = await SFMCOffboardingAPI.getAllBUs();
    setAllBUs(allBUsResponse.data.map(bu => (
      { id: bu.id, label: bu.name + ' (' + bu.id + ')', customerKey: bu.CustomerKey })));
    setLoadingBar(false);
  };

  /**
   * List all associated user for a BU
   * @param {Integer} buId BU ID
   * @returns {Array[]} Array of associated users
   */
  const getAssociatedUsers = async (buId) => {
    setLoadingBar(true);
    const apiResponse = await SFMCOffboardingAPI.getAssociatedUsers(buId);
    setAssociatedUsers(apiResponse.data);
    setShowAssociatedUsers(true);
    setLoadingBar(false);
  };

  /**
   * Dissociate users from a BU and optionally delete the BU
   * @returns {Boolean} True if successful
   */
  const submit = async () => {
    if (selectedBU !== eBU) {
      setLoadingBar(true);

      // Always dissociate first
      const dissociateResponse = await SFMCOffboardingAPI.dissociateUsers(
        selectedBU.id,
        associatedUsers.map(user => user.id),
      );
      if (dissociateResponse.success) setShowSucessfullDissociation(true);

      // If the delete action is selected, delete the BU as well
      let deleteResponse;
      if (action === 'remove') {
        deleteResponse = await SFMCOffboardingAPI.deleteBU(selectedBU.customerKey);
        if (deleteResponse.success) setShowSucessfullDeletion(deleteResponse.success);
      }

      setLoadingBar(false);
    }
  };

  /**
   * Load all BUs on page load
   */
  useEffect(() => {
    getallBUs();
  }, []);

  /**
   * Get all associated users when a BU is selected
   * @returns {JSX.Element} JSX Element
   */
  const AssociatedUsersCard = () => (
    <>
      <Paper sx={{ my: '16px', padding: '10px', minHeight: '150px' }}>
        <Typography variant="h5" margin={1}>
          {associatedUsers.length === 0 ?
            'There are no users associated with this BU.' :
            'The following users are associated with ' + selectedBU.label + '.'}
        </Typography>
        {associatedUsers.length !== 0 &&
          associatedUsers
            .map(user => (
              <Typography key={user.id} variant="body1" margin={1}>
                {user.name + ' (' + (user.email || 'N/A') + ')'}
              </Typography>
            ))}
      </Paper>
      <Stack direction="row" spacing={2} mt={2} mb={4}>
        <ToggleButtonGroup
          color="primary"
          value={action}
          onChange={(event, newAction) => setAction(newAction)}
          exclusive
          aria-label="Platform"
          size="small"
          fullWidth
        >
          <ToggleButton value="remove">Remove BU</ToggleButton>
          <ToggleButton value="dissociate">Dissociate Only</ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="contained"
          sx={{ mt: '16px', width: '30%' }}
          onClick={submit}
          fullWidth
        >
          Submit
        </Button>
      </Stack>
      { showSucessfullDeletion && <Alert severity="success">Yay, the BU was deleted sucessfully!</Alert>}
      { (showSucessfullDissociation && !showSucessfullDeletion) &&
        <Alert severity="success">Yay, the dissociation was completed!</Alert>}
    </>
  );

  return (
    <>
      <Typography variant="h3" my={4}>Business Unit Manager </Typography>
      <Typography variant="body1" my={2} sx={{ width: '60%' }}>
        This module allows admins to remove a given business unit in SalesForce Marketing Cloud.
        Select the business unit, verify you want to dissociate.
        You can also decide wether to remove the bussiness unit following the dissociation.
      </Typography>
      <FormControl id="form" sx={{ width: '60%' }}>
        <Stack direction="row" spacing={2} my={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: '80%' }}
            options={allBUs}
            value={selectedBU}
            onChange={(event, newBU) => {
              setShowAssociatedUsers(false);
              setShowSucessfullDeletion(false);
              setShowSucessfullDissociation(false);
              setSelectedBU(newBU);
              getAssociatedUsers(newBU.id);
            }}
            getOptionLabel={option => option.label || ''}
            getOptionDisabled={optionDisabled => optionDisabled.id === 100017920}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={params => <TextField {...params} label="Business Units..." />}
            disabled={allBUs.length === 0}

          />
          <Button
            variant="contained"
            onClick={getallBUs}
            sx={{ width: '20%' }}
          >
            REFRESH BU LIST
          </Button>
        </Stack>

        { loadingBar && <LinearProgress m={4} />}
        { showAssociatedUsers && <AssociatedUsersCard /> }

      </FormControl>
    </>
  );
};

export default SFMCUserOffboarding;
