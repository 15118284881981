import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SearchApi = {
  /**
   * Updates DESelect Search home message
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} message.text - message text we want to update
   * @param {boolean} message.isActive - is message should be active or not
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateHomeMessage: async (cancelToken, { text, isActive }) => {
    const res = await axios.put(
      `${apiUrl}/admin/home-message`,
      { text, isActive },
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Get DESelect Search home message
   * @param {object} cancelToken - The cancel token from Axios
   * @return {object} Search message Object
   */
  getHomeMessage: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/home-message`,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default SearchApi;
