import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SelectionRunLogsAPI = {
  /**
   * Get selectionRunLogs
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved selectionRunLogs - and `success`
   */
  getSelectionRunLogs: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/selection-run-logs`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get selectionRunLogs based on filter criteria
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - The filter criteria
   * @returns {object} An object with `data` property - the retrieved filtered selectionRunLogs - and `success`
   */
  getFilteredLogs: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/selection-run-logs/search`, {
        criteria,
      },
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default SelectionRunLogsAPI;
