import React, { useContext } from 'react';

import { ResultsContext } from '../../../../ResultsContext';

const CertificatesDataset = () => {
  const [results] = useContext(ResultsContext);
  const { certificatesData } = results;

  return (
    <div className="certificates">
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="Id">
                Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Certification User Id">
                Certification User Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Student">
                Student
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Exam">
                Exam
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created At">
                Created At
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {certificatesData.map(el => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="Id">
                <div title={el._id}>
                  {el._id}
                </div>
              </td>

              <td data-label="Certification User Id">
                <div className="slds-truncate" title={el.certificationUserId}>
                  {el.certificationUserId}
                </div>
              </td>

              <td data-label="Student">
                <div className="slds-truncate" title={el.studentName}>
                  {el.studentName}
                </div>
              </td>

              <td data-label="Exam">
                <div className="slds-truncate" title={el.examName}>
                  {el.examName}
                </div>
              </td>

              <td data-label="Created At">
                <div className="slds-truncate" title={el.createdAt}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CertificatesDataset;
