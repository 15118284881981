import React from 'react';
import PropTypes from 'prop-types';
import Util from '../../../../util';

import './style.scss';

const FeatureRequestReport = ({
  featureRequests,
}) => (
  <div className="slds-grid">
    <div className="slds-size_4-of-4">
      <div className="slds-box">
        <span className="box-title slds-has-flexi-truncate">Feature Requests</span>
        <div className="box-content list" style={{ height: '200px', overflowY: 'scroll', marginTop: '0.5rem' }}>
          <table
            className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            style={{ fontSize: '12px' }}
          >
            <thead>
              <tr className="slds-line-height_reset">
                <th scope="col" style={{ width: '75%' }}>
                  <div className="slds-truncate" title="Subject">
                    Title
                  </div>
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  <div className="slds-truncate" title="Status">
                    Status
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate" title="Votes">
                    Votes
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                  featureRequests ?
                    featureRequests.map(request => (
                      <tr key={request.requestId} className="slds-hint-parent">
                        <th data-label="Title" scope="row">
                          <div className="slds-truncate" title={request.title}>
                            <a
                              href={'https://feedback.us.pendo.io/app/#/case/' + request.requestId}
                              target="_blank"
                              // eslint-disable-next-line spellcheck/spell-checker
                              rel="noreferrer"
                            >
                              {request.title}
                            </a>
                          </div>
                        </th>
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={request.status}>
                            {Util.renderFeatureRequestStatus(request.status)}
                          </div>
                        </th>
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={request.votes}>
                            {request.votes}
                          </div>
                        </th>
                      </tr>
                    )) :
                    null
                }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

FeatureRequestReport.propTypes = {
  featureRequests: PropTypes.arrayOf(PropTypes.object),
};

export default FeatureRequestReport;
