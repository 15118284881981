import React, { useState, useEffect, useContext } from 'react';
import ApexChart from 'react-apexcharts';

import StatsAPI from '../../../../api/stats';

import './style.scss';
import './animation.scss';
import Util from '../../../../util';
import NPSReport from '../Engagement Dashboard/NPSReport';
import SupportTicketReport from '../Engagement Dashboard/SupportTicketReport';
import FeatureRequestReport from '../Engagement Dashboard/FeatureRequestReport';
import { MenuContext } from '../../../../MenuContext';
import Constants from '../../../../constants/constants';

const AdoptionDashboard = () => {
  const [organisation, setOrganisation] = useState({});
  const [rankedUsers, setRankedUsers] = useState([]);
  const [healthScoreChartSeries, setHealthScoreChartSeries] = useState([]);
  const [usedFeaturesChartSeries, setUsedFeaturesChartSeries] = useState([]);
  const [healthScoreLabels, setHealthScoreLabels] = useState([]);
  const [activityChartSeries, setActivityChartSeries] = useState([]);
  const [npsOverview, setNPSOverview] = useState({});
  const [rankedBUs, setRankedBUs] = useState([]);
  const [npsResponses, setNPSResponses] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [supportTickets, setSupportTickets] = useState([]);
  const [metabaseFrameUrl, setMetabaseFrameUrl] = useState('');
  const [isAdoptionTabSelected, setAdoptionTabSelected] = useState(true);
  const [featureRequests, setFeatureRequests] = useState([]);

  // Dashboard variable
  const menuContext = useContext(MenuContext);
  const [menuParameters] = menuContext.menuParameters;
  const { orgId } = menuParameters;

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    async function getOrgStats() {
      const orgStats = await StatsAPI.getOrganisationStats(orgId);
      setOrganisation(orgStats);
      setRankedUsers(orgStats.healthScore?.users);
      setRankedBUs(orgStats.businessUnitsHealthScores);
      setNPSOverview(orgStats.npsOverview);
      setNPSResponses(orgStats.npsOverview.responses.sort((a, b) => b.dataWeek - a.dataWeek));
      setSupportTickets(orgStats.supportTicketsOverview.sort((a, b) => new Date(b.openDate) - new Date(a.openDate)));
      setMetabaseFrameUrl(orgStats.metabaseToken);
      setFeatureRequests(
        orgStats.featureRequestOverview.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)),
      );

      // adoption metrics graph

      const { adoptionMetricsHistory } = orgStats;

      const numberOfSelections = [];
      const datesAdoptionMetricsHistory = [];
      const activeUsers = [];
      const loggedInUsers = [];
      if (adoptionMetricsHistory) {
        for (let a = 0; a < adoptionMetricsHistory.length; a += 1) {
          const ahm = adoptionMetricsHistory[a];

          numberOfSelections.push(ahm.numberOfSelections);
          datesAdoptionMetricsHistory.push(ahm.createdAt);

          activeUsers.push(ahm.activeUsers);

          loggedInUsers.push(ahm.loggedInUsers);
        }
      }

      // health score graph
      const { healthScoreHistory } = orgStats;

      if (healthScoreHistory) {
        const { healthScoreChartData, weeksHealthScoreHistory } = healthScoreHistory
          .reverse()
          .reduce((result, hsm) => (
            ({
              healthScoreChartData:
                result.healthScoreChartData.concat(hsm.healthScore.toFixed(2)),
              weeksHealthScoreHistory:
                result.weeksHealthScoreHistory.concat(hsm.week),
            })
          ), { healthScoreChartData: [], weeksHealthScoreHistory: [] });

        setHealthScoreChartSeries(healthScoreChartData);
        setHealthScoreLabels(weeksHealthScoreHistory);
      }

      // used features graph
      const { usedFeaturesHistory } = orgStats;

      if (usedFeaturesHistory) {
        const { usedFeaturesChartData } = usedFeaturesHistory
          .reverse()
          .reduce((result, hsm) => (
            ({
              usedFeaturesChartData:
                result.usedFeaturesChartData.concat(Math.round(hsm.usedFeatures)),
            })
          ), { usedFeaturesChartData: [] });

        setUsedFeaturesChartSeries(usedFeaturesChartData);
      }

      // activity graph
      const { activityHistory } = orgStats;

      if (activityHistory) {
        const { activityChartData } = activityHistory
          .reverse()
          .reduce((result, hsm) => (
            ({
              activityChartData:
                result.activityChartData.concat(Math.round(hsm.usedFeatures)),
            })
          ), { activityChartData: [] });

        setActivityChartSeries(activityChartData);
      }

      setLoadingData(false);
    }

    try {
      getOrgStats();
    } catch (error) {
      Util.handleError(error);
    }
  }, [orgId]);

  const healthScoreChart = {
    series: [
      {
        data: healthScoreChartSeries,
        type: 'column',
        name: 'Health Score',
      },
      {
        data: usedFeaturesChartSeries,
        type: 'line',
        name: 'Used Features',
      },
      {
        data: activityChartSeries,
        type: 'line',
        name: 'Activity',
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      dataLabels: {
        enabled: false,
      },
      labels: healthScoreLabels,
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'MMM yyyy',
        },
      },
      yaxis: [{
        title: {
          text: 'Health Score',
        },
        min: 0,
        max: 100,
        tickAmount: 4,
      }, {
        seriesName: 'Used Features',
        opposite: true,
        axisBorder: {
          show: true,
          color: Constants.DESELECT__COLOR,
        },
        labels: {
          style: {
            colors: Constants.DESELECT__COLOR,
          },
          // eslint-disable-next-line spellcheck/spell-checker
          formatter(val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: 'Used Features',
          style: {
            color: Constants.DESELECT__COLOR,
          },
        },
      }, {
        seriesName: 'Activity',
        opposite: true,
        axisBorder: {
          show: true,
          color: Constants.DESELECT__SECUNDARY_COLOR,
        },
        labels: {
          style: {
            colors: Constants.DESELECT__SECUNDARY_COLOR,
          },
          // eslint-disable-next-line spellcheck/spell-checker
          formatter(val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: 'Activity (min)',
          style: {
            color: Constants.DESELECT__SECUNDARY_COLOR,
          },
        },
      }],
      tooltip: {
        shared: true,
        intersect: false,
      },
      colors: [
        function color({ value, seriesIndex }) {
          if (seriesIndex === 0) {
            return Util.getHealthScoreColor(value, Constants.HS__MAX_SCORE);
          }

          if (seriesIndex === 1) {
            return Constants.DESELECT__COLOR;
          }

          return Constants.DESELECT__SECUNDARY_COLOR;
        },
      ],
    },
  };

  return loadingData ?
    (
      <div>
        <div>
          <b>{organisation.name}</b>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${Constants.HUBSPOT__COMPANY__BASE_URL}/${organisation.hubspotID}/`}
            title={`Click to view ${organisation.name} on Hubspot`}
          >
            <img
              src="/assets/hubspot-logo.svg"
              alt="hubspot"
              className="hubspot-icon"
            />
          </a>
        </div>
        <br />
        <br />
        <div>
          <div className="demo-only">
            <div role="status" className="slds-spinner slds-spinner_medium">
              <span className="slds-assistive-text">Loading</span>
              <div className="slds-spinner__dot-a" />
              <div className="slds-spinner__dot-b" />
            </div>
          </div>
        </div>
      </div>
    ) :

    (
      <div>
        <div>
          <table
            className="slds-table slds-table_cell-buffer slds-table_bordered"
          >
            <thead>
              <tr>
                <th className="organisation-header">
                  {organisation.name}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${Constants.HUBSPOT__COMPANY__BASE_URL}/${organisation.hubspotID}/`}
                    title={`Click to view ${organisation.name} on Hubspot`}
                  >
                    <img
                      src="/assets/hubspot-logo.svg"
                      alt="hubspot"
                      className="hubspot-icon"
                    />
                  </a>
                </th>
                <th className="id-header">{organisation._id}</th>
              </tr>
            </thead>
          </table>
        </div>
        <br />
        <br />
        <ul id="adoption-tabs">
          <li
            className={`tab${isAdoptionTabSelected ? ' active' : ''}`}
            onClick={() => setAdoptionTabSelected(true)}
          >
            Adoption Tab
          </li>
          <li
            className={`tab${isAdoptionTabSelected ? '' : ' active'}`}
            onClick={() => setAdoptionTabSelected(false)}
          >
            Metabase Tab
          </li>
        </ul>
        <br />
        <br />

        { isAdoptionTabSelected ?
          (
            <div>
              <div>
                <span className="box title slds-has-flexi-truncate">Health Score</span>
                <div className="box-content chart">
                  {
                    healthScoreChartSeries.length ?
                      (
                        <ApexChart
                          series={healthScoreChart.series}
                          options={healthScoreChart.options}
                          type="bar"
                          width="100%"
                          height="200px"
                        />
                      ) :
                      (
                        <span className="slds-align_absolute-center no-data">No data available</span>
                      )
                  }
                </div>
              </div>
              <div className="dashboard">
                <div className="slds-grid">
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Users</span>
                      <div className="box-content number">{organisation.numberOfUsers}</div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Active Users</span>
                      <div className="box-content number">{organisation.numberOfActiveUsers}</div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Logged-In Users</span>
                      <div className="box-content number">{organisation.numberOfLoggedInUsers}</div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">% Logged-In Users</span>
                      <div className="box-content number">
                        {organisation.percentageOfLoggedInUsers}
                        %
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slds-grid">
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Licenses</span>
                      <div className="box-content number">{organisation.licenses}</div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Active Users vs Licenses</span>
                      <div className="box-content number">
                        {organisation.activeUsersVsLicense}
                        %
                      </div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Logged-In Users vs Licenses</span>
                      <div className="box-content number">
                        {organisation.loggedInUsersVsLicense}
                        %
                      </div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-4">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Selections</span>
                      <div className="box-content number">{organisation.numberOfSelections}</div>
                    </div>
                  </div>
                </div>
                <div className="slds-grid">
                  <div className="slds-size_1-of-2">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Ranked Users by Health Score</span>
                      <div className="box-content list" style={{ height: '300px' }}>
                        <table
                          className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
                          style={{ fontSize: '12px' }}
                        >
                          <thead>
                            <tr className="slds-line-height_reset">
                              <th scope="col" style={{ width: '50px' }}>
                                <div className="slds-truncate" title="Rank">
                                  #
                                </div>
                              </th>
                              <th scope="col" style={{ width: '200px' }}>
                                <div className="slds-truncate" title="ID">
                                  ID
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Name">
                                  Name
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Health Score">
                                  Health Score
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rankedUsers ?
                              rankedUsers.map((rankedUser, index) => (
                                <tr key={rankedUser.id} className="slds-hint-parent">
                                  <th data-label="Rank" scope="row">
                                    <div className="slds-truncate" title={index + 1}>
                                      {index + 1}
                                    </div>
                                  </th>
                                  <th data-label="ID" scope="row">
                                    <div className="slds-truncate" title={rankedUser.id}>
                                      {rankedUser.id}
                                    </div>
                                  </th>
                                  <th data-label="Name" scope="row">
                                    <div className="slds-truncate" title={rankedUser.username}>
                                      {rankedUser.username}
                                    </div>
                                  </th>
                                  <th data-label="Health Score" scope="row">
                                    <div className="slds-truncate" title={rankedUser.healthScore?.toFixed(2)}>
                                      {Util.renderHealthScoreColor(
                                        rankedUser.healthScore?.toFixed(2),
                                        Constants.HS__MAX_SCORE,
                                      )}
                                    </div>
                                  </th>
                                </tr>
                              )) :
                              null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="slds-size_1-of-2">
                    <div className="slds-box">
                      <span className="box-title slds-has-flexi-truncate">Detailed Health Score View</span>
                      <div className="box-content list detailedReportSize">
                        <table
                          className="
                            slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout tableSection
                          "
                        >
                          <thead>
                            <tr className="slds-line-height_reset">
                              <th className="mainColumn" scope="col">
                                <div className="slds-truncate" title="Section">
                                  Section
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  1 Sp. vs 4 Sp.
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  2 Sp. vs 4 Sp.
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  Score
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key="Used Features" className="slds-hint-parent">
                              <th data-label="Section" scope="row">
                                <div className="slds-truncate" title="Used Features">
                                  Used Features
                                </div>
                              </th>
                              <th data-label="1W vs 4W" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {
                                    (
                                      organisation?.healthScore?.usedFeatures?.vsThemselvesTrend?.last1WVS4W * 100
                                    ).toFixed(2)
                                  }
                                  %
                                </div>
                              </th>
                              <th data-label="2W vs 4W" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {
                                    (
                                      organisation?.healthScore?.usedFeatures?.vsThemselvesTrend?.last2WVS4W * 100
                                    ).toFixed(2)
                                  }
                                  %
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    organisation?.healthScore?.usedFeatures?.healthScore?.toFixed(2),
                                    Constants.HS__USED_FEATURES_MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                            <tr key="Activity" className="slds-hint-parent">
                              <th data-label="Section" scope="row">
                                <div className="slds-truncate" title="Activity">
                                  Activity
                                </div>
                              </th>
                              <th data-label="1W vs 4W" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {
                                    (
                                      organisation?.healthScore?.activity?.vsThemselvesTrend?.last1WVS4W * 100
                                    ).toFixed(2)
                                  }
                                  %
                                </div>
                              </th>
                              <th data-label="2W vs 4W" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {
                                    (
                                      organisation?.healthScore?.activity?.vsThemselvesTrend?.last2WVS4W * 100
                                    ).toFixed(2)
                                  }
                                  %
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    organisation?.healthScore?.activity?.healthScore?.toFixed(2),
                                    Constants.HS__ACTIVITY_MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          className="
                            slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout sectionTable
                          "
                        >
                          <thead>
                            <tr className="slds-line-height_reset">
                              <th className="mainColumn" scope="col">
                                <div className="slds-truncate" title="Section">
                                  Section
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  Last
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  AVG
                                </div>
                              </th>
                              <th scope="col">
                                <div className="slds-truncate" title="Score">
                                  Score
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key="CSM Sentiment" className="slds-hint-parent">
                              <th data-label="Section" scope="row">
                                <div className="slds-truncate" title="Sentiment">
                                  CSM Sentiment
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {organisation?.healthScore?.sentiment?.lastTouchpointScore}
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {organisation?.healthScore?.sentiment?.avgSentimentTouchpoint}
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    (
                                      organisation?.healthScore?.sentiment?.lastTouchpointScore +
                                      organisation?.healthScore?.sentiment?.avgSentimentTouchpoint
                                    ).toFixed(2),
                                    Constants.HS__SENTIMENT_CSM_MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                            <tr key="Ticket Sentiment" className="slds-hint-parent">
                              <th data-label="Section" scope="row">
                                <div className="slds-truncate" title="Sentiment">
                                  Ticket Sentiment
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  -
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {organisation?.healthScore?.sentiment?.avgSentimentTickets}
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    organisation?.healthScore?.sentiment?.avgSentimentTickets.toFixed(2),
                                    Constants.HS__SENTIMENT_TICKETS_MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                            <tr key="NPS Score" className="slds-hint-parent">
                              <th data-label="Section" scope="row">
                                <div className="slds-truncate" title="NPS">
                                  NPS Score
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  -
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {organisation?.healthScore?.surveys?.avgResponse}
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    organisation?.healthScore?.surveys?.healthScore?.toFixed(2),
                                    Constants.HS__SURVEYS_MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                            <tr
                              key="Total Score"
                              className="slds-hint-parent totalRow"
                            >
                              <th data-label="Section" scope="row" colSpan={3}>
                                <div className="slds-truncate" title="Total Score">
                                  <b>Total Score</b>
                                </div>
                              </th>
                              <th data-label="Score" scope="row">
                                <div className="slds-truncate" title="Score">
                                  {Util.renderHealthScoreColor(
                                    organisation?.healthScore?.healthScore?.toFixed(2),
                                    Constants.HS__MAX_SCORE,
                                  )}
                                </div>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slds-grid">
                  <div className="slds-box">
                    <span className="box-title slds-has-flexi-truncate">Health Score by BU</span>
                    <div className="box-content list buSection">
                      <table
                        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
                        style={{ fontSize: '12px' }}
                      >
                        <thead>
                          <tr className="slds-line-height_reset">
                            <th scope="col" style={{ width: '50px' }}>
                              <div className="slds-truncate" title="Rank">
                                #
                              </div>
                            </th>
                            <th scope="col" style={{ width: '200px' }}>
                              <div className="slds-truncate" title="ID">
                                ID
                              </div>
                            </th>
                            <th scope="col">
                              <div className="slds-truncate" title="Name">
                                Name
                              </div>
                            </th>
                            <th scope="col">
                              <div className="slds-truncate" title="Health Score">
                                Health Score
                              </div>
                            </th>
                            <th scope="col">
                              <div className="slds-truncate" title="Health Score">
                                Number of Users
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rankedBUs ?
                            rankedBUs.map((rankedBU, index) => (
                              <tr key={rankedBU.id} className="slds-hint-parent">
                                <th data-label="Rank" scope="row">
                                  <div className="slds-truncate" title={index + 1}>
                                    {index + 1}
                                  </div>
                                </th>
                                <th data-label="ID" scope="row">
                                  <div className="slds-truncate" title={rankedBU.id}>
                                    {rankedBU.id}
                                  </div>
                                </th>
                                <th data-label="Name" scope="row">
                                  <div className="slds-truncate" title={rankedBU.name}>
                                    {rankedBU.name}
                                  </div>
                                </th>
                                <th data-label="Health Score" scope="row">
                                  <div className="slds-truncate" title={rankedBU.healthScore?.toFixed(2)}>
                                    {Util.renderHealthScoreColor(
                                      rankedBU.healthScore?.toFixed(2),
                                      Constants.HS__MAX_SCORE,
                                    )}
                                  </div>
                                </th>
                                <th data-label="Number of Users" scope="row">
                                  <div className="slds-truncate" title={rankedBU.numberOfActiveUsers}>
                                    {rankedBU.numberOfActiveUsers}
                                  </div>
                                </th>
                              </tr>
                            )) :
                            null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <SupportTicketReport
                context={Constants.MENU__NAME__ADOPTION_DASHBOARD}
                supportTickets={supportTickets}
              />
              <NPSReport
                context={Constants.MENU__NAME__ADOPTION_DASHBOARD}
                promoters={npsOverview?.promoters}
                passives={npsOverview?.passives}
                detractors={npsOverview?.detractors}
                npsResponses={npsResponses}
              />
              <FeatureRequestReport
                featureRequests={featureRequests}
              />
            </div>
          ) :
          (
            <div>
              <iframe
                title="Metabase Customer Dashboard"
                src={
                  Constants.METABASE__BASE_URL + '/embed/dashboard/' + metabaseFrameUrl + '#bordered=true&titled=false'
                }
                frameBorder="0"
                width="100%"
                height="1100px"
                allowtransparency
              />
            </div>
          )}
      </div>
    );
};

export default AdoptionDashboard;
