import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const OrganisationsAPI = {
  /**
   * Retrieves single organisation by id
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} id - Organisation id
   * @returns {object} - Retrieved organisation
   */
  getOrganisation: async (cancelToken, id) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/${id}`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves all organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - Criteria to filter the results on
   * @returns {object} An object with `organisations` property - the retrieved organisations -
   */
  getOrganisations: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/orgs/search`,
      criteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves all segment active organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `organisations` property - the retrieved organisations -
   */
  getSegmentActiveOrganisations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/segment`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data?.data;
  },

  /**
   * Retrieves all organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {array} Array of orgs and their BUs
   */
  getEngageOrganisations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/engage`,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data.data;
  },

  /**
   * Updates an organisation
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the organisation you want to update
   * @param {string} name - Name of the organisation
   * @param {string} hubspotID - Hubspot ID of the organisation
   * @param {string} zendeskID - Zendesk ID of the organisation
   * @param {string} type - Type of the organisation
   * @param {string} token - Token of the organisation
   * @param {string} authenticationBaseUrl - authenticationBaseUrl of the installed package
   * @param {array} businessUnits - Business units of the organisation
   * @param {boolean} isActive - Should this organisation be active
   * @param {string} edition - Edition of the organisation
   * @param {number} licenses - The number of licenses the org possesses
   * @param {boolean} latePayment - Indicates if customer is late on payments
   * @param {boolean} hasServer2ServerInstalledPackage - Indicates if
   * customer has the DESelect Automations package installed
   * @param {string} server2ServerClientId - Client Id of DESelect Automations package
   * @param {string} server2ServerClientSecret - Client Secret of DESelect Automations packages
   * @param {string} connectClientId - Client Id of DESelect Connect package
   * @param {string} connectClientSecret - Client Secret of DESelect Connect package
   * @param {string} connectServer2ServerClientId - Client Id of DESelect Connect S2S package
   * @param {string} connectServer2ServerClientSecret - Client Secret of DESelect S2S Connect package
   * @param {object} features - Features object
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateOrganisation: async (
    cancelToken,
    id,
    {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      licenses,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
      connectClientId,
      connectClientSecret,
      connectServer2ServerClientId,
      connectServer2ServerClientSecret,
      features,
      certificationUsersLimit,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/orgs/${id}`, {
        name,
        hubspotID,
        zendeskID,
        type,
        token,
        authenticationBaseUrl,
        businessUnits,
        isActive,
        edition,
        licenses,
        latePayment,
        hasServer2ServerInstalledPackage,
        server2ServerClientId,
        server2ServerClientSecret,
        connectClientId,
        connectClientSecret,
        connectServer2ServerClientId,
        connectServer2ServerClientSecret,
        features,
        certificationUsersLimit,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Insert an organisation
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} name - Name of the organisation
   * @param {string} hubspotID - Hubspot ID of the organisation
   * @param {string} zendeskID - Zendesk ID of the organisation
   * @param {string} type - Type of the organisation
   * @param {string} token - Token of the organisation
   * @param {string} authenticationBaseUrl - authenticationBaseUrl of the installed package
   * @param {array} businessUnits - Business units of the organisation
   * @param {boolean} isActive - Should this organisation be active
   * @param {string} edition - Edition of the organisation
   * @param {number} licenses - The number of licenses the org possesses
   * @param {boolean} latePayment - Indicates if customer is late on payments
   * @param {boolean} hasServer2ServerInstalledPackage - Indicates if customer
   * has the DESelect Automations package installed
   * @param {string} server2ServerClientId - Client Id of DESelect Automations package
   * @param {string} server2ServerClientSecret - Client Secret of DESelect Automations packages
   * @param {string} connectClientId - Client Id of DESelect Connect package
   * @param {string} connectClientSecret - Client Secret of DESelect Connect package
   * @param {string} connectServer2ServerClientId - Client Id of DESelect Connect S2S package
   * @param {string} connectServer2ServerClientSecret - Client Secret of DESelect S2S Connect package
   * @return {object} An object with `result` property - always true - and `success`
   */
  insertOrganisation: async (
    cancelToken,
    {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      licenses,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
      connectClientId,
      connectClientSecret,
      connectServer2ServerClientId,
      connectServer2ServerClientSecret,
      certificationUsersLimit,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/orgs/`, {
        name,
        hubspotID,
        zendeskID,
        type,
        token,
        authenticationBaseUrl,
        businessUnits,
        isActive,
        edition,
        licenses,
        latePayment,
        hasServer2ServerInstalledPackage,
        server2ServerClientId,
        server2ServerClientSecret,
        connectClientId,
        connectClientSecret,
        connectServer2ServerClientId,
        connectServer2ServerClientSecret,
        certificationUsersLimit,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Set new feature flag
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} feature - name of the feature flag
   * @param {string} defaultValue - default value of the feature flag
   * @param {string} criteria - MongoDB query for identifying organisations to set this for
   * @return {object} An object with `result` property - always true - and `success`
   */
  newFeatureFlag: async (
    cancelToken,
    feature,
    defaultValue,
    criteria,
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/new-feature`, {
        feature,
        defaultValue,
        criteria,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default OrganisationsAPI;
