import React from 'react';
import ApexChart from 'react-apexcharts';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

import './style.scss';

const NPSReport = ({
  context,
  promoters,
  passives,
  detractors,
  npsResponses,
}) => {
  // NPS Chart
  const customerNPSChart = {
    series: [promoters, passives, detractors],
    options: {
      labels: ['Promoters', 'Passives', 'Detractors'],
      legend: {
        show: false,
      },
      colors: ['#04844B', '#FFB75D', '#C23934'],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: { show: false },
              value: { show: true },
              total: {
                show: true,
                showAlways: true,
                formatter(w) {
                  // eslint-disable-next-line spellcheck/spell-checker
                  const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  // eslint-disable-next-line spellcheck/spell-checker
                  const promoterPCT = (w.globals.series[0] / total) * 100;
                  // eslint-disable-next-line spellcheck/spell-checker
                  const detractorPCT = (w.globals.series[2] / total) * 100;
                  return Math.round((promoterPCT - detractorPCT));
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="slds-grid">
      <div className="slds-size_1-of-4">
        <div className="slds-box">
          <span className="box title slds-has-flexi-truncate">NPS</span>
          <div className="box-content chart" style={{ height: '200px' }}>
            {
              customerNPSChart.series[0] !== 0 ||
              customerNPSChart.series[1] !== 0 ||
              customerNPSChart.series[2] !== 0 ?
                (
                  <ApexChart
                    series={customerNPSChart.series}
                    options={customerNPSChart.options}
                    type="donut"
                    width="100%"
                    height="200px"
                  />
                ) :
                  <span className="slds-align_absolute-center no-data">No data available</span>
            }
          </div>
        </div>
      </div>
      <div className="slds-size_3-of-4">
        <div className="slds-box">
          <span className="box-title slds-has-flexi-truncate">NPS Responses</span>
          <div className="box-content list" style={{ height: '200px', overflowY: 'scroll', marginTop: '0.5rem' }}>
            <table
              className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
              style={{ fontSize: '12px' }}
            >
              <thead>
                <tr className="slds-line-height_reset">
                  <th scope="col" style={{ width: '100px' }}>
                    <div className="slds-truncate" title="ID">
                      ID
                    </div>
                  </th>
                  <th scope="col" style={{ width: '250px' }}>
                    <div className="slds-truncate" title="Name">
                      Name
                    </div>
                  </th>
                  {
                    (
                      context === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD ||
                      context === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD
                    ) ?
                      (
                        <th scope="col" style={{ width: '250px' }}>
                          <div className="slds-truncate" title="Name">
                            Company
                          </div>
                        </th>
                      ) :
                      null
                  }
                  <th scope="col">
                    <div className="slds-truncate" title="Date">
                      Date
                    </div>
                  </th>
                  <th scope="col">
                    <div className="slds-truncate" title="Score">
                      Score
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  npsResponses ?
                    npsResponses.map(nps => (
                      <tr key={nps.visitorID + nps.date} className="slds-hint-parent">
                        <th data-label="ID" scope="row">
                          <div className="slds-truncate" title={nps.visitorID}>
                            {nps.visitorID}
                          </div>
                        </th>
                        <th data-label="Name" scope="row">
                          <div className="slds-truncate" title={nps.visitorName}>
                            {nps.visitorName}
                          </div>
                        </th>
                        {
                          (
                            context === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD ||
                            context === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD
                          ) ?
                            (
                              <th data-label="Name" scope="row">
                                <div className="slds-truncate" title={nps.org}>
                                  {nps.org}
                                </div>
                              </th>
                            ) :
                            null
                        }
                        <th data-label="Date" scope="row">
                          <div className="slds-truncate" title={nps.date}>
                            {Util.beautifyDate(nps.date)}
                          </div>
                        </th>
                        <th data-label="Score" scope="row">
                          <div className="slds-truncate" title={nps.score}>
                            {Util.renderNPSColor(nps.score)}
                          </div>
                        </th>
                      </tr>
                    )) :
                    null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NPSReport;
