/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
} from 'react';

import HubspotApi from '../../../../api/hubspot';
import ExactApi from '../../../../api/exact';
import Util from '../../../../util';

import DealOverview from '../DealOverview/DealOverview';
import CompanyOverview from '../CompanyOverview/CompanyOverview';

import './style.scss';

const CreateInvoice = () => {
  const [deal, setDeal] = useState({});
  const [deals, setDeals] = useState([]);
  const [dealsMap, setDealsMap] = useState(new Map());
  const [company, setCompany] = useState({});
  const [stampCompany, setStampCompany] = useState(true);
  const step1StartText = 'Stamp Deal';
  const step2StartText = 'Create Invoice';
  const step3StartText = 'Stamp Company';
  const [step1Text, setStep1Text] = useState(step1StartText);
  const [step2Text, setStep2Text] = useState(step2StartText);
  const [step3Text, setStep3Text] = useState(step3StartText);
  const [step1Status, setStep1Status] = useState('');
  const [step2Status, setStep2Status] = useState('');
  const [step3Status, setStep3Status] = useState('');

  const [activeSession, setActiveSession] = useState(null);
  const [oAuth2LoginURL, setOauth2LoginURL] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  /**
   * Get all Deals in the right Finance Stages
   * @returns {void}
   */
  const getDeals = async () => {
    const hubspotDeals = await HubspotApi.getDeals('finance_stage', ['Ready to be invoiced']);
    setDeals(hubspotDeals);
    // Create map with key id and value the Deal object
    const dMap = new Map();
    hubspotDeals.forEach((d) => {
      dMap.set(d.id, d);
    });
    setDealsMap(dMap);
  };

  /**
   * Gets the Deal and Company details given the selected deal
   * @param {Number} dealId - DealId for which we want to get the Deal and Company details
   * @returns {void}
   */
  const getDealAndCompany = async (dealId) => {
    const result = await HubspotApi.getDealAndCompany(dealId);
    setCompany(result.company);
    setDeal(result.deal);
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    const checkSession = async () => {
      // Check if user has an active session with ExactOnline
      const session = await ExactApi.checkActiveUserSession();
      setActiveSession(session);
      // If so, get the Deals
      if (session) {
        getDeals();
      } else {
        // If not, send user through login flow
        const url = await ExactApi.getOAuth2LoginURL();
        // eslint-disable-next-line spellcheck/spell-checker
        setOauth2LoginURL(url);
      }
    };

    try {
      checkSession();
    } catch (error) {
      Util.handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDeals]);

  /**
   * Reset Progress Indicators
   * @returns {void}
   */
  const resetProgressIndicators = () => {
    setStep1Text(step1StartText);
    setStep2Text(step2StartText);
    setStep3Text(step3StartText);
    setStep1Status('');
    setStep2Status('');
    setStep3Status('');
  };

  /**
   * Handle changing Deal input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeDeal = (e) => {
    const dealId = e.target.value;
    const d = dealsMap.get(dealId);
    setDeal(d);

    resetProgressIndicators();

    // Avoid double stamping
    if (d?.properties?.stamp_date && d?.properties?.dealtype.includes('upsell')) {
      setStampCompany(false);
    } else {
      setStampCompany(true);
    }
    getDealAndCompany(dealId);
    setSubmitButtonDisabled(false);
  };

  const renderProgressMarker = (status) => {
    let marker;
    if (status === 'slds-is-completed') {
      marker = (
        <span
          className="slds-icon_container slds-icon-utility-success
         slds-progress__marker slds-progress__marker_icon slds-progress__marker_icon-success"
          title="Complete"
        >
          <svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
          </svg>
          <span className="slds-assistive-text">Complete</span>
        </span>
      );
    } else if (status === 'slds-has-error') {
      marker = (
        <span
          className="slds-icon_container slds-icon-utility-error
        slds-progress__marker slds-progress__marker_icon"
          title="Error"
        >
          <svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#error" />
          </svg>
          <span className="slds-assistive-text">Error</span>
        </span>
      );
    } else {
      marker = <div className="slds-progress__marker" />;
    }

    return marker;
  };

  /**
   * Handle clicking Stamp Company button
   * @returns {void}
   */
  const clickStampCompany = () => {
    if (stampCompany) {
      // First ask confirmation
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you don't want to stamp the Company metrics?" +
        'You should only NOT stamp the company if this is an upsell deal AND the ' +
        'creation of the invoice failed before while stamping was successful.')) {
        setStampCompany(!stampCompany);
      }
    } else {
      setStampCompany(!stampCompany);
    }
  };

  /**
   * Handle clicking refresh button next to list of Deals
   * @returns {void}
   */
  const clickRefresh = async () => {
    setRefreshing(true);
    // Refresh list of Deals
    await getDeals();
    setRefreshing(false);
  };

  /**
   * Handle clicking refresh button on Company or Deal
   * @returns {void}
   */
  const handleRefresh = async () => {
    // Refresh Deal and Company details
    await getDealAndCompany(deal.id);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);

    resetProgressIndicators();

    try {
      setStep1Status('slds-is-active');
      // Stamp only Deal
      await HubspotApi.stampDeal(deal.id, false, null);
      setStep1Text('Metrics stamped on Deal');
      setStep1Status('slds-is-completed');
    } catch (error) {
      setStep1Text('Error stamping deal: ' + error.response.data.error);
      setStep1Status('slds-has-error');
      // Restore submit button
      setSubmitButtonDisabled(false);
      return;
    }

    try {
      setStep2Status('slds-is-active');
      // Create Invoice
      const invoice = await ExactApi.createInvoice(deal.id);
      // eslint-disable-next-line no-alert
      setStep2Text(`Invoice created. Invoice has Order Number ${invoice.OrderNumber}.`);
      setStep2Status('slds-is-completed');
    } catch (error) {
      setStep2Text('Error creating invoice: ' + error.response.data.error);
      setStep2Status('slds-has-error');
      // Restore submit button
      setSubmitButtonDisabled(false);
      return;
    }

    if (stampCompany) {
      try {
        setStep3Status('slds-is-active');
        // Stamp Deal & Company
        await HubspotApi.stampDeal(deal.id, true);
        setStep3Text('Metrics stamped on Company');
        setStep3Status('slds-is-completed');
      } catch (error) {
        setStep3Text('Error stamping company: ' + error.response.data.error);
        setStep3Status('slds-has-error');
        // Restore submit button
        setSubmitButtonDisabled(false);
        return;
      }
    } else {
      setStep3Text('Company stamping skipped');
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  return (
    <>
      {activeSession === null ?
        (<p>Checking for active ExactOnline session</p>) :
        (activeSession === false ?
          (
            <p>
              Click the button below to log in to ExactOnline, then reload this page to continue.
              <br />

              <button
                onClick={() => window.open(oAuth2LoginURL, '_blank')}
                id="login"
                type="button"
                className="slds-button slds-button_brand"
              >
                Login
              </button>
            </p>
          ) :
          (
            <form id="form" onSubmit={submit}>
              Select Deal to create Invoice:
              <br />

              <div className="slds-form-element">
                <div className="slds-form-element__label" htmlFor="criteria">
                  Choose Hubspot Deal for which invoice needs to be created in ExactOnline.
                  Only deals in Finance Stage
                  &nbsp;
                  <i>Ready to be invoiced</i>
                  &nbsp;
                  appear in the list below.
                </div>

                <label className="slds-form-element__label" htmlFor="deal"> Deal:</label>
                <div className="slds-form-element__control">
                  <select
                    id="deal"
                    style={{ width: '800px' }}
                    className="slds-select"
                    onChange={e => handleChangeDeal(e)}
                    required
                  >
                    <option value="" defaultValue>Please select</option>
                    {deals ?
                      (deals || []).map(d => (
                        <option key={d.id} value={d.id}>
                          {d.properties.dealname}
                          &nbsp;
                          (
                          {Number(d.properties.amount).toLocaleString()}
                          &nbsp;
                          {d.properties.deal_currency_code}
                          )
                        </option>
                      )) :
                      null}
                  </select>
                  <button
                    type="button"
                    className="slds-button slds-button_icon slds-button_icon-brand"
                    id="refresh-button"
                    onClick={clickRefresh}
                    title="Refresh Deals"
                  >
                    <svg
                      aria-hidden="true"
                      className={`slds-button__icon ${refreshing ?
                        'fa-spin' :
                        ''
                      }`}
                    >
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
                    </svg>
                  </button>
                  <br />
                  {
                    deal ?
                      (
                        <>
                          <div className="slds-grid slds-gutters">
                            <div className="slds-col slds-size_1-of-2">
                              <DealOverview
                                deal={deal}
                                handleRefresh={handleRefresh}
                              />
                            </div>

                            <div className="slds-col slds-size_1-of-2">
                              {company ?
                                (
                                  <CompanyOverview
                                    company={company}
                                    handleRefresh={handleRefresh}
                                  />
                                ) :
                                (
                                  <font className="highlight-deal-prop">
                                    This Deal is not correctly linked to a Company. In Hubspot the Deal should be
                                    linked to either 1 Company, or multiple Companies with
                                    one of them marked as the Primary Company.
                                  </font>
                                )}
                            </div>
                          </div>

                          <div className="slds-form-element">
                            <label className="slds-form-element__label" htmlFor="stampCompany">
                              Stamp Company as well (leave checked unless you&apos;re trying to create an invoice for an
                              {' '}
                              <b>upsell</b>
                              {' '}
                              deal that failed before.)
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    onChange={() => clickStampCompany()}
                                    type="checkbox"
                                    name="stampCompany"
                                    id="stampCompany"
                                    checked={stampCompany}
                                  />
                                  <label className="slds-checkbox__label" htmlFor="stampCompany">
                                    <span className="slds-checkbox_faux" />
                                    <span className="slds-form-element__label">
                                      {stampCompany ? 'True' : 'False'}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div>
                            {
                              typeof deal.properties?.deal_subtype === 'string' &&
                                deal.properties?.deal_subtype.includes('edition_upgrade') ?
                                (

                                  <div
                                    className="slds-scoped-notification
                                  slds-media slds-media_center slds-theme_warning"
                                    role="status"
                                  >
                                    <div className="slds-media__figure">
                                      <span className="slds-icon_container slds-icon-utility-warning" title="warning">
                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning" />
                                        </svg>
                                        <span className="slds-assistive-text">warning</span>
                                      </span>
                                    </div>
                                    <div className="slds-media__body">
                                      <p className="notification">
                                        Make sure to double check the discount on the line items in this
                                        Edition Upgrade deal.
                                        <br />
                                        The discount should reflect the difference
                                        in price the customer is paying for the edition to which they&lsquo;re
                                        upgrading, and the price the customer already paid for the old edition.
                                        <br />
                                        Please check on the previous deal how much the customer actually paid
                                        for the license of the previous edition.
                                      </p>
                                    </div>
                                  </div>

                                ) :
                                null
                            }
                          </div>

                          <br />
                          <br />

                          <button
                            type="submit"
                            className="slds-button slds-button_brand"
                            id="submit"
                            disabled={submitButtonDisabled}
                          >
                            Create Invoice & Stamp Deal
                          </button>
                          <br />
                          <div className="slds-progress slds-progress_vertical invoicing-progress">
                            <ol className="slds-progress__list">

                              <li className={'slds-progress__item ' + step1Status}>
                                {renderProgressMarker(step1Status)}
                                <div className="slds-progress__item_content
                                 slds-grid slds-grid_align-spread notification"
                                >
                                  {step1Text}
                                </div>
                              </li>

                              <li className={'slds-progress__item ' + step2Status}>
                                {renderProgressMarker(step2Status)}
                                <div className="slds-progress__item_content
                                slds-grid slds-grid_align-spread notification"
                                >
                                  {step2Text}

                                </div>
                              </li>

                              <li className={'slds-progress__item ' + step3Status}>
                                {renderProgressMarker(step3Status)}
                                <div className="slds-progress__item_content
                                slds-grid slds-grid_align-spread notification"
                                >
                                  {step3Text}

                                </div>
                              </li>
                            </ol>
                          </div>
                          <br />
                        </>
                      ) :
                      null
                  }
                </div>
              </div>
            </form>
          )
        )}
    </>
  );
};

export default CreateInvoice;
