import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const UtilsContext = createContext();

const UtilsProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    id: '',
    index: '',
    eventType: '',
  });

  return (
    <UtilsContext.Provider value={[state, setState]}>
      {children}
    </UtilsContext.Provider>
  );
};

UtilsProvider.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export { UtilsProvider };
