import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import './style.scss';
import Filter from './atoms/Filter';
import CertificationsUserAPI from '../../../../api/academyCertifications/certifications-users';
import OrganisationsAPI from '../../../../api/organisations';
import CustomerQuestionModal from './Modals/CustomerQuestionModal';
import CustomerQuestionsAPI from '../../../../api/academyTrainings/customerQuestions';
import TopicsAPI from '../../../../api/academyTrainings/topics';
import Spinner from './atoms/Spinner';

const CustomerQuestions = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    customerQuestions, organisationsData,
    filteredCustomerQuestions, filterCriteriaForCustomerQuestions,
    certificationsUsersData, topics,
  } = results;

  // Customer Questions variables
  const [question, setQuestion] = useState('');
  const [userId, setUserId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [date, setDate] = useState('');
  const [answered, setAnswered] = useState(false);
  const [topicId, setTopicId] = useState('');
  const [answer, setAnswer] = useState('');

  // variables for filter
  const [loading, setLoading] = useState(false);
  const [resultsList, setResultsList] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  /**
   * Handles filtering of results
   * @param {object} e - JS Event
   * @param {string} filterBy - the name of the criterion by which we filter
   * @param {string} searchText - the search text entered in the search input
   * @param {date} startDate - start date selected in input date
   * @param {date} endDate - end date selected in input date
   * @returns {void}
   */
  const filterResultsHandler = async (e, filterBy, searchText, startDate, endDate) => {
    setLoading(true);
    const filterCriteria = {};

    // depending of filtering assign filterCriteria properties
    if (filterBy === Constants.FILTER_BY__ORGANISATION_ID) {
    // Filtering by ID of the organisation
      filterCriteria.orgId = searchText.trim();
    } else if (filterBy === Constants.FILTER_BY__USER_ID) {
      // Filtering by ID of the user
      filterCriteria.userId = searchText.trim();
    } else if (filterBy === Constants.FILTER_BY__ANSWERED) {
      // Filtering by answered
      filterCriteria.answered = searchText;
    } else if (filterBy === Constants.FILTER_BY__DATE) {
      // Filtering by start and end date
      filterCriteria.createdAt = {
        $gte: startDate,
        $lte: endDate,
      };
    } else if (filterBy === Constants.FILTER_BY__TOPIC_ID) {
      // Filtering by ID of the topic
      filterCriteria.topicId = searchText.trim();
    }

    try {
      // get the customer questions based on filter criteria
      const res = await CustomerQuestionsAPI.getCustomerQuestions(null, filterCriteria);

      // Show filtered results
      setResults({
        ...results,
        filteredCustomerQuestions: res.data,
        filterCriteriaForCustomerQuestions: filterCriteria,
      });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `${error?.response?.data?.error || error}`,
      );
    }
  };

  /**
   * Stops the filtering of results
   * @returns {void}
   */
  const handleStopFiltering = () => {
    setLoading(true);

    // reset values
    setResults({ ...results, filteredCustomerQuestions: [], filterCriteriaForCustomerQuestions: null });

    setLoading(false);
  };

  // UseEffect for setting results lists
  useEffect(() => {
    // If filtering...
    if ((filteredCustomerQuestions?.length) || filterCriteriaForCustomerQuestions) {
      setResultsList(filteredCustomerQuestions);
    } else {
      // Show unfiltered data
      setResultsList(customerQuestions);
    }

    // eslint-disable-next-line
  }, [customerQuestions, filteredCustomerQuestions, filterCriteriaForCustomerQuestions]);

  /**
   * Get all data from database
   * @returns {void}
   */
  const fetchAllData = async () => {
    try {
      /**
       * Function returns new Promise after retrieving customer questions data
       * @returns {Promise<Void>} void
       */
      const getCustomerQuestions = () => new Promise((resolve) => {
        if (customerQuestions?.length) { resolve({ data: customerQuestions }); } else {
          resolve(CustomerQuestionsAPI.getCustomerQuestions(null, {}));
        }
      });

      /**
       * Function returns new Promise after retrieving organisation data
       * @returns {Promise<Void>} void
       */
      const getOrganisations = () => new Promise((resolve) => {
        if (organisationsData?.length) { resolve({ data: organisationsData }); } else {
          resolve(OrganisationsAPI.getOrganisations(null));
        }
      });

      /**
       * Function returns new Promise after retrieving certification users data
       * @returns {Promise<Void>} void
       */
      const getCertificationUsers = () => new Promise((resolve) => {
        if (certificationsUsersData?.length) { resolve({ data: certificationsUsersData }); } else {
          resolve(CertificationsUserAPI.getCertificationUsers(null));
        }
      });

      /**
       * Function returns new Promise after retrieving topics data
       * @returns {Promise<Void>} void
       */
      const getTopicsData = () => new Promise((resolve) => {
        if (topics?.length) { resolve({ data: topics }); } else {
          resolve(TopicsAPI.getAllTopics(null));
        }
      });

      // start loading data
      setLoadingData(true);

      // invoke fetching functions asynchronously
      const fetchedData = await Promise.all([getOrganisations(), getCertificationUsers(), getTopicsData(),
        getCustomerQuestions()]);

      // get fetched data
      const orgData = fetchedData[0];
      const usersData = fetchedData[1];
      const topicsData = fetchedData[2];
      const questionData = fetchedData[3];

      // stop loading data
      setLoadingData(false);

      // set fetched data in state and results
      setState({
        ...state,
        customerQuestions: questionData.data,
        certificationsUsersData: usersData.data,
        organisationsData: orgData.data,
        topics: topicsData.data,
      });

      const valuesToUpdate = {};
      valuesToUpdate.customerQuestions = questionData.data;
      valuesToUpdate.certificationsUsersData = usersData.data;
      valuesToUpdate.organisationsData = orgData.data;
      valuesToUpdate.topics = topicsData.data;
      setResults({ ...results, ...valuesToUpdate });
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    // get all necessary data when loading a view
    fetchAllData();

    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called when updating customer question
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleAnsweredUpdate = async (e) => {
    const { checked } = e.target;
    let { id } = e.target;

    // variables for updating answer
    let res;
    let newData;

    if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
      // when answered checkbox is updating in the modal
      res = { value: true };

      // assign newData, use variables set by opening the modal
      newData = {
        orgId,
        userId,
        question,
        answered,
        topicId,
        answer,
      };
    } else {
      // when answered checkbox is updating in the overview, show confirmation message
      res = await Swal.fire({
        icon: 'info',
        title: 'Are you sure?',
        text: 'Do you want to change the answer for this question?',
        confirmButtonText: 'Yes, change it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      // find selected customer question
      const selectedCustomerQuestion = customerQuestions.find(q => q._id === id);

      // define new data from selected customer question
      newData = {
        orgId: selectedCustomerQuestion?.orgId,
        userId: selectedCustomerQuestion?.userId,
        question: selectedCustomerQuestion?.question,
        answered: checked,
        topicId: selectedCustomerQuestion?.topicId,
        answer: selectedCustomerQuestion?.answer,
      };
    }

    if (res.value) {
      try {
        // define id, depending on whether it has been set in state (update in modal or in overview)
        if (!id) id = state.id;

        // update the customer question
        const response = await CustomerQuestionsAPI.updateCustomerQuestion(null, id, newData);

        // set the data for the selected index
        const newArray = customerQuestions.map(customerQuestion => customerQuestion._id === id ?
          response.data :
          customerQuestion);

        // set updated results
        setResults({ ...results, customerQuestions: newArray });

        setState({
          ...state,
          isOpen: false,
          eventType: '',
          customerQuestions: newArray,
        });

        Swal.fireSuccess('Updated!', 'The answer has been updated.');
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setQuestion('');
    setOrgId('');
    setUserId('');
    setDate('');
    setAnswered(false);
    setTopicId('');
    setAnswer('');
  };

  /**
   * Handler called when canceling modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false, eventType: '' });
    cleanFields();
  };

  /**
   * Handler called when editing a module
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { target: { dataset: { index, id } } } = event;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = customerQuestions.filter(m => m._id === id)[0];

    setQuestion(singleItem.question);
    setUserId(singleItem.userId);
    setOrgId(singleItem.orgId);
    setDate(new Date(singleItem.createdAt).toISOString().split('T')[0]);
    setTopicId(singleItem.topicId);
    setAnswered(singleItem.answered);
    setAnswer(singleItem.answer);
  };

  // define options for Filter component
  const optionsForFilter = [
    {
      key: Constants.FILTER_BY__ORGANISATION_ID,
      text: 'Organisation Id',
      value: Constants.FILTER_BY__ORGANISATION_ID,
    },
    {
      key: Constants.FILTER_BY__USER_ID,
      text: 'User Id',
      value: Constants.FILTER_BY__USER_ID,
    },
    {
      key: Constants.FILTER_BY__ANSWERED,
      text: 'Answered',
      value: Constants.FILTER_BY__ANSWERED,
    },
    {
      key: Constants.FILTER_BY__DATE,
      text: 'Date',
      value: Constants.FILTER_BY__DATE,
    },
    {
      key: Constants.FILTER_BY__TOPIC_ID,
      text: 'Topic Id',
      value: Constants.FILTER_BY__TOPIC_ID,
    },
  ];

  return (
    <div className="self-learning-customer-questions">
      <Filter
        handleStopFiltering={handleStopFiltering}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filterCriteriaForCustomerQuestions}
        options={optionsForFilter}
      />
      {loadingData ? <Spinner size="large" assistiveText="Loading..." /> : null}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-self-learning-customer-questions">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Question">
                Question
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="User Id">
                User Id / Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Organisation Id">
                Organisation Id / Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Topic Id">
                Topic Id / Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Date">
                Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Answered">
                Answered
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Reply">
                Reply
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="self-learning-customer-questions-body-table">
          {!loadingData && resultsList?.length > 0 ?
            resultsList.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    <a
                      data-id={el._id}
                      data-index={i}
                      href="/#"
                      onClick={handleEdit}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Name">
                  <div className="slds-truncate" title={Util.abbreviate(el.question, 200)}>
                    {Util.abbreviate(el.question, 200)}
                  </div>
                </td>

                <td data-label="User Id">
                  <div
                    className="slds-truncate"
                    title={`${el.userId}/${
                      Util.returnPropertyNameById(certificationsUsersData, el.userId, true)}`}
                  >
                    {el.userId}
                    {' '}
                    /
                    {' '}
                    {Util.returnPropertyNameById(certificationsUsersData, el.userId, true)}
                  </div>
                </td>

                <td data-label="Organisation Id">
                  <div
                    className="slds-truncate"
                    title={`${el.orgId}/${Util.returnPropertyNameById(
                      organisationsData, el.orgId,
                    )}`}
                  >
                    {el.orgId}
                    {' '}
                    /
                    {' '}
                    {Util.returnPropertyNameById(organisationsData, el.orgId)}
                  </div>
                </td>

                <td data-label="Topic Id">
                  <div
                    className="slds-truncate"
                    title={`${el.topicId} / ${
                      Util.returnPropertyNameById(topics, el.topicId)}`}
                  >
                    {el.topicId}
                    {' '}
                    /
                    {' '}
                    {Util.returnPropertyNameById(topics, el.topicId)}
                  </div>
                </td>

                <td data-label="Date">
                  <div className="slds-truncate" title={new Date(el.date).toLocaleString()}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>

                <td data-label="Answered">
                  <label className="slds-form-element__label" htmlFor={el._id}>
                    <div className="slds-form-element__control">
                      <div className="slds-checkbox">
                        <input
                          onChange={e => handleAnsweredUpdate(e)}
                          type="checkbox"
                          name="Answered"
                          id={el._id}
                          checked={el.answered}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="slds-checkbox__label" htmlFor={el._id}>
                          <span className="slds-checkbox_faux" />
                        </label>
                      </div>
                    </div>
                  </label>
                </td>

                <td data-label="Reply">
                  {el.answer && Util.convertToPlain(el.answer).trim() !== '' ?
                    (
                      <div title={Util.convertToPlain(el.answer)}>
                        <button
                          type="button"
                          className="slds-button slds-button_neutral"
                          aria-haspopup="true"
                          data-id={el._id}
                          data-index={i}
                          onClick={handleEdit}
                        >
                          Reply
                        </button>
                      </div>
                    ) :
                    null}
                </td>

                <td>
                  <div className="slds-truncate" title={el?.updatedAt ? new Date(el.updatedAt).toLocaleString() : ''}>
                    {el?.updatedAt ? new Date(el.updatedAt).toLocaleString() : ''}
                  </div>
                </td>
              </tr>
            )) :
            (
              <tr>
                {customerQuestions?.length ?
                  (
                    <td colSpan="8" className="no-results-found">No results found</td>
                  ) :
                  null}
              </tr>
            )}
        </tbody>
      </table>
      <CustomerQuestionModal
        isOpen={state.isOpen}
        question={question}
        userId={userId}
        orgId={orgId}
        date={date}
        answered={answered}
        setAnswered={setAnswered}
        handleCancel={handleCancel}
        handleSave={handleAnsweredUpdate}
        organisationsData={organisationsData}
        certificationsUsersData={certificationsUsersData}
        topicId={topicId}
        topics={topics}
        answer={answer}
        setAnswer={setAnswer}
      />
    </div>
  );
};

export default CustomerQuestions;
