import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const TrainingResultsAPI = {
  /**
   * Get training results - all or based on filter criteria
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - The filter criteria (optional - passed when user use Filter)
   * @returns {object} An object with `data` property - the retrieved training results - and `success`
   */
  getTrainingResults: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/training-results`,
      criteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
  /**
   * Updates training results
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} postData - object with post data
   * @param {string} postData.id - training result id
   * @param {string} postData.expirationDate - expirationDate propery to update
   * @returns {object} An object with `data` property - the updated training results - and `success`
   */
  updateTrainingResults: async (cancelToken, {
    id, expirationDate,
  }) => {
    const res = await axios.put(
      `${apiUrl}/admin/training-results/${id}`, {
        expirationDate,
      },
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default TrainingResultsAPI;
