import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import AddRelatedLinks from '../atoms/AddRelatedLinks';
import MultiSelect from '../atoms/MultiSelect';
import '../style.scss';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './style.scss';

const TopicModal = ({
  isOpen, name, setName, moduleIds, setModuleIds, orders, setOrders, videoLink, setVideoLink,
  handleCancel, handleSave, eventType, modules, topics, relatedLinks, setRelatedLinks,
  content, setContent, saving, enableContent, setEnableContent,
}) => {
  /**
   * Function for changing the size of the image and uploading it
   * @param {array} files - array with uploaded images
   * @param {Object} info - information about the file
   * @param {func} uploadHandler - function for uploading the image
   * @returns {void}
   */
  const resizeImage = (files, info, uploadHandler) => {
    // get upload file data
    const uploadFile = files[0];

    // define images property
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
      img.onload = () => {
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // set the maximum dimensions of the image that will be rendered
        const MAX_WIDTH = 720;
        const MAX_HEIGHT = 480;

        // the original dimensions of the picture
        let { width, height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            // calculate dimensions based on max width
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            // calculate dimensions based on max height
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        // assign width and height for the image
        canvas.width = width;
        canvas.height = height;

        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          // upload the image with reduced size
          uploadHandler([new File([blob], uploadFile.name)]);
        }, uploadFile.type, 1);
      };
    };

    // read the content
    reader.readAsDataURL(uploadFile);
  };

  /**
   * Function that checks if the size of the image has not been exceeded
   * @param {array} files - array with uploaded images
   * @param {Object} info - information about the file
   * @param {func} uploadHandler - function for uploading the image
   * @returns {void}
   */
  const sizeValidation = (files, info, uploadHandler) => {
    const uploadFile = files[0];

    // if the limit of 500KB is exceeded
    if (uploadFile.size > 500000) {
      // throw error message
      uploadHandler('Limit exceeded! The picture cannot be larger than 500 KB.');
    } else {
      // resize and add image
      resizeImage(files, info, uploadHandler);
    }
  };

  /**
   * Function called before the image is uploaded.
   * @param {array} files - array with uploaded images
   * @param {Object} info - information about the file
   * @param {func} uploadHandler - function for uploading the image
   * @returns {void}
   */
  const onImageUploadBefore = (files, info, uploadHandler) => {
    try {
      // check the size of the picture
      sizeValidation(files, info, uploadHandler);
    } catch (err) {
      uploadHandler(err.toString());
    }
  };

  useEffect(() => {
    // after removing the module in multiSelect, update the ordinal numbers in the array with orders
    if (orders?.length) {
      orders.forEach((o) => {
        const findTrainingIdInOrder = moduleIds.find(mod => mod === o.moduleId);

        if (!findTrainingIdInOrder) {
          // if no training has been found for which the order is saved - delete it
          setOrders(orders.filter((order) => {
            if (moduleIds.find(m => m === order.moduleId)) return order;
            return null;
          }));
        }
      });
    }
    /* eslint-disable-next-line */
  }, [moduleIds, setModuleIds]);

  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (moduleIds?.length === 1) {
      return `${Util.returnPropertyNameById(modules, moduleIds[0])}`;
    } if (moduleIds?.length > 1) {
      return `${moduleIds.length} Modules Selected`;
    } if (!moduleIds?.length) {
      return 'Select Module';
    }

    return '';
  };

  /**
   * Handles changing ordinal numbers for selected modules
   * @param {object} e - JS Event
   * @param {string} moduleId - module Id
   * @returns {void}
   */
  const handleChangeOrders = (e, moduleId) => {
    // define value - could be event from input or passed value
    const value = parseInt(e?.target?.value) || e;

    // the value needs to be higher than 0
    if (value > 0) {
      // create new object with order for selected module
      const newOrder = {
        order: value,
        moduleId,
      };

      let newArray;

      // check if this module is already defined in the orders array
      const findOrderByModuleId = orders.find(o => o.moduleId === moduleId);

      if (findOrderByModuleId) {
        // update ordinal number
        newArray = orders.map((o) => {
          if (o.moduleId === moduleId) {
            // eslint-disable-next-line no-param-reassign
            o.order = value;
          }

          return o;
        });
      } else {
        // add a new order object to an array
        newArray = [...orders, newOrder];
      }

      // update orders state
      setOrders(newArray);
    } else {
      // do not add an order with a value below 0
      setOrders(orders.filter(o => o.moduleId !== moduleId));
    }
  };

  /**
   * Search by given Id and check the next possible ordinal number
   * @param {string} moduleId - module Id
   * @returns {number} next possible ordinal number
   */
  const returnNextOrdinalNumber = (moduleId) => {
    const ordersForGivenId = [];

    // get all orders for this moduleId
    topics.forEach((arr) => {
      arr.orders.forEach((o) => {
        if (o.moduleId === moduleId) {
          ordersForGivenId.push(o.order);
        }
      });
    });

    // get next ordinal number and save in orders
    const orderNumber = Util.findFirstMissingOrderNumber(ordersForGivenId, ordersForGivenId.length);
    handleChangeOrders(orderNumber, moduleId);

    return orderNumber;
  };

  /**
   * Sets the arrays for moduleIds and orders
   * @param {array} newModuleIds - array with new moduleIds
   * @param {string} selectedModuleId - id of the selected module
   * @returns {void}
   */
  const handleSetModulesAndOrders = (newModuleIds, selectedModuleId) => {
    returnNextOrdinalNumber(selectedModuleId);
    setModuleIds(newModuleIds);
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="topics-self-learning-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {eventType === Constants.EVENT__TYPE__NEW ? 'Create Topic' : 'Edit Topic'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-self-learning"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    placeholder="Title"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </div>
              </label>
            </div>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="slds-form-element__label" htmlFor="multi-select">
              Modules
              <MultiSelect
                multiSelectPlaceholder={returnMultiSelectPlaceholder()}
                value={moduleIds}
                data={modules}
                setData={handleSetModulesAndOrders}
              />
            </label>

            {moduleIds?.length ?
              moduleIds.map(mod => (
                <div className="slds-form-element" key={mod}>
                  <label className="slds-form-element__label" htmlFor={mod}>
                    Order for
                    {' '}
                    {Util.returnPropertyNameById(modules, mod)}
                    <div className="slds-form-element__control">
                      <input
                        type="number"
                        id={mod}
                        placeholder="Enter the order"
                        className="slds-input"
                        onChange={event => handleChangeOrders(event, mod)}
                        onBlur={event => event.target.value < 1 ?
                          handleChangeOrders(event, mod) :
                          event.preventDefault()}
                        value={orders.find(o => o.moduleId === mod)?.order ||
                          returnNextOrdinalNumber(mod)}
                        min="1"
                        step="1"
                      />
                    </div>
                  </label>
                </div>
              )) :
              null}
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-videoLink">
                Video Link
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-videoLink"
                    placeholder="Video Link"
                    className="slds-input"
                    onChange={event => setVideoLink(event.target.value)}
                    value={videoLink}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="slds-form-element__label" htmlFor="form-element-content">
                Content
                <br />
                <label
                  className="slds-checkbox_toggle"
                  id="toggle-button-label"
                  htmlFor="contentToggle"
                >
                  <div className="on-off-checkbox">
                    {/* Toggle button - user can turn on or off to set up content */}
                    <input
                      type="checkbox"
                      id="contentToggle"
                      name="contentToggle"
                      aria-describedby="contentToggle"
                      onChange={() => setEnableContent(!enableContent)}
                      checked={enableContent}
                    />
                    <span
                      id="checkbox-toggle-topic-content"
                      className="slds-checkbox_faux_container"
                      aria-live="assertive"
                    >
                      <span className="slds-checkbox_faux" />
                      <span className="slds-checkbox_on">On</span>
                      <span className="slds-checkbox_off">Off</span>
                    </span>
                  </div>
                </label>
                <div className="slds-form-element__control">
                  <SunEditor
                    id="form-element-content"
                    setContents={content}
                    onChange={setContent}
                    autoFocus
                    width="100%"
                    height="300px"
                    setOptions={{
                      buttonList: [
                        ['undo', 'redo'],
                        ['font', 'align'],
                        ['fontSize', 'formatBlock', 'fontColor'],
                        ['bold', 'underline', 'italic', 'strike'],
                        ['link'],
                        ['lineHeight', 'list', 'table', 'fullScreen'],
                      ],
                      font: [
                        'Arial',
                        'Calibri',
                        'Comic Sans',
                        'Courier',
                        'Garamond',
                        'Georgia',
                        'Impact',
                        'Lucida Console',
                        'Palatino Linotype',
                        'Segoe UI',
                        'Tahoma',
                        'Times New Roman',
                        'Trebuchet MS',
                      ],
                    }}
                    onImageUploadBefore={onImageUploadBefore}
                    disable={!enableContent}
                    disableToolbar={!enableContent}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <AddRelatedLinks relatedLinks={relatedLinks} setRelatedLinks={setRelatedLinks} />
            </div>

          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights || saving}
              className={`slds-button slds-button_brand ${saving && 'save-topic'}`}
            >
              {saving ?
                (
                  <div className="preview-loader-container">
                    <div
                      role="status"
                      className="slds-spinner slds-spinner_x-small"
                    >
                      <div className="slds-spinner__dot-a" />
                      <div className="slds-spinner__dot-b" />
                    </div>
                    <span className="when-pressed">Saving...</span>
                  </div>
                ) :
                'Save'}
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

TopicModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** Label for module name */
  name: PropTypes.string.isRequired,
  /** sets module name in state */
  setName: PropTypes.func.isRequired,
  /** link for video displayed in topic section */
  videoLink: PropTypes.string.isRequired,
  /** sets link for video in state */
  setVideoLink: PropTypes.func.isRequired,
  /** array with related links that will be displayed for the Topic section below the questions - optional */
  relatedLinks: PropTypes.instanceOf(Array).isRequired,
  /** sets related links in state */
  setRelatedLinks: PropTypes.func.isRequired,
  /** array with module Ids for which we assign the topic */
  moduleIds: PropTypes.instanceOf(Array).isRequired,
  /** sets array with module Ids in state */
  setModuleIds: PropTypes.func.isRequired,
  /** an array with objects that contain an order and training id */
  orders: PropTypes.instanceOf(Array).isRequired,
  /** sets array with objects containing order and training id  */
  setOrders: PropTypes.func.isRequired,
  /** an array with topics created in self-learning section */
  topics: PropTypes.instanceOf(Array).isRequired,
  /** an array with modules created in self-learning section */
  modules: PropTypes.instanceOf(Array).isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string,
  /** the topic content added in a text editor */
  content: PropTypes.string.isRequired,
  /** function that sets the content of the topic */
  setContent: PropTypes.func.isRequired,
  /** defines if the topic is in the process of being saved */
  saving: PropTypes.bool.isRequired,
  /** defines whether the content is enabled */
  enableContent: PropTypes.bool.isRequired,
  /** sets enableContent in state */
  setEnableContent: PropTypes.func.isRequired,
};

export default TopicModal;
