import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import TrainingModal from './Modals/TrainingModal';
import './style.scss';
import OrganisationsAPI from '../../../../api/organisations';
import TrainingsAPI from '../../../../api/academyTrainings/trainings';
import ModulesAPI from '../../../../api/academyTrainings/modules';
import Spinner from './atoms/Spinner';

const Trainings = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    trainings, searchTextForTrainings, organisationsData, topics, topicQuestions,
  } = results;

  // Training variables
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [hiddenToNoOrgUsers, setHiddenToNoOrgUsers] = useState(true);
  const [organisationIds, setOrganisationIds] = useState([]);

  // variables for search bar
  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTrainings, setSearchTrainings] = useState([]);
  const [searchBy, setSearchBy] = useState(Constants.SEARCH_BY__TRAINING_NAME);

  const [deletedId, setDeletedId] = useState(null);

  /**
   * Get all trainings from database
   * @returns {void}
   */
  const getTrainings = async () => {
    try {
      // get all trainings
      const res = await TrainingsAPI.getAllTrainings(null);

      // define organisations data
      let orgs = { data: organisationsData };

      if (!organisationsData?.length) {
        // get all organisations only if they have not been fetched
        orgs = await OrganisationsAPI.getOrganisations(null);
      }

      setState({
        ...state,
        trainings: res.data,
        searchTextForTrainings: '',
        organisationsData: orgs.data,
      });

      // set a variable to search for data
      setSearchTrainings(res.data);

      const valuesToUpdate = {};
      valuesToUpdate.trainings = res.data;
      valuesToUpdate.organisationsData = orgs.data;
      valuesToUpdate.searchTextForTrainings = '';
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    /*
     * fetch data for the first time or
     * when the field is not empty and there will be a switch between windows
     */
    if (searchTextForTrainings !== '' || !trainings?.length || !organisationsData?.length) {
      getTrainings();
    } else if (trainings?.length) {
      setSearchTrainings(trainings);
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearchTrainings = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === '' && isSearchFieldUsed) {
      // get all trainings if the search field is empty and if it's used
      getTrainings();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      let filteredTrainings;

      /**
       * Search for trainings by organisation name
       * @param {array} array - array to filter results
       * @returns {array} filtered array with search results
       */
      const searchByOrganisationName = array => (
        array.filter((tr) => {
          // get organisation selected in the training
          const selectedOrgInTraining = organisationsData.filter((org) => {
            const orgInTraining = tr.organisationIds.find(orgId => orgId === org._id);
            if (orgInTraining) return org;

            return null;
          });

          if (selectedOrgInTraining?.length) {
            // match the name of the organisation assigned to the training with the search results
            const findOrgByName = selectedOrgInTraining.find(org => org.name.toString().toLowerCase().includes(
              searchText.toString().toLowerCase(),
            ));

            if (findOrgByName) { return tr; }
          }

          return null;
        }));

      // Filter the trainings, find search data
      if (searchBy === Constants.SEARCH_BY__TRAINING_NAME) {
        filteredTrainings = searchTrainings?.length ?
          Util.searchByName(searchTrainings, searchText) :
          Util.searchByName(trainings, searchText);
      } else {
        // Filter the trainings by organisation name
        filteredTrainings = searchTrainings?.length ?
          searchByOrganisationName(searchTrainings) :
          searchByOrganisationName(trainings);
      }

      // Set filtered trainings and search text
      setState({
        ...state,
        trainings: filteredTrainings,
        searchTextForTrainings: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};
      valuesToUpdate.trainings = filteredTrainings;
      valuesToUpdate.searchTextForTrainings = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText, searchBy]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setName('');
    setHiddenToNoOrgUsers(true);
    setOrganisationIds([]);
    setStartDate('');
  };

  /**
   * Handler called when saving training
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      name,
      organisationIds,
      startDate,
      hiddenToNoOrgUsers,
    };

    /**
     * Function that checks the validity of the entered data
     * @returns {void}
     */
    const validateResult = () => {
      const errors = [];

      // validate training name label
      const validateForTrainingLabel = name.trim() === '';
      // eslint-disable-next-line no-unused-expressions
      validateForTrainingLabel && errors.push('Please select training name');

      // validate organisationIds
      const validateOrg = organisationIds.length === 0;
      // eslint-disable-next-line no-unused-expressions
      validateOrg && errors.push('Please select at least one organisation');

      // validate start date
      const validateStartDate = startDate === '';
      // eslint-disable-next-line no-unused-expressions
      validateStartDate && errors.push('Please select start date');

      return errors;
    };

    if (validateResult().length > 0) {
      Swal.fireWarning('', validateResult()[0]);
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // create new training
          const res = await TrainingsAPI.createTraining(null, newData);

          // set the data from response
          setResults({
            ...results,
            trainings: [
              ...trainings,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            trainings: [
              ...trainings,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The training has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // update the training
          const res = await TrainingsAPI.updateTraining(null, state.id, newData);

          // set the data for the selected index
          const newArray = [...trainings];
          newArray[state.index] = res.data;
          setResults({ ...results, trainings: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            trainings: newArray,
          });
          Swal.fireSuccess('Updated!', 'The training has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing a training
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { target: { dataset: { index, id } } } = event;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = trainings.filter(e => e._id === id)[0];

    setName(singleItem.name);
    setHiddenToNoOrgUsers(singleItem.hiddenToNoOrgUsers);
    setOrganisationIds(singleItem.organisationIds);

    // set start date in yyyy-mm-dd format
    setStartDate(new Date(singleItem.startDate).toLocaleDateString('fr-CA'));
  };

  /**
   * Handler called when deleting training
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html
    const { target: { dataset: { index, id } } } = event;

    // set id of the deleted training to start loading data
    setDeletedId(id);

    // fetch and get modules to checks which module is linked to removed training
    const fetchedModules = await ModulesAPI.getAllModules(null);

    // get data for removed training
    const dataForRemovedTraining = await Util.getAndRemoveOrUpdateModules(id, fetchedModules.data);

    // stop loading data
    setDeletedId(null);

    // create an array with modules name linked to removed training
    const modulesName = fetchedModules.data.reduce((array, mod) => {
      // if removed training has linked modules
      if (dataForRemovedTraining?.linkedModules?.length) {
        // find module and return its name in the array
        const findLinkedModuleInTraining = dataForRemovedTraining.linkedModules.find(m => m._id === mod._id);
        if (findLinkedModuleInTraining) { return [...array, mod.name]; }

        return array;
      }

      return array;
    }, []);

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure you want to delete this training?',
        html: dataForRemovedTraining?.linkedModules?.length > 0 ?
          `This training is selected in the modules <b class="swal_bold">${modulesName.map(
            moduleName => ' ' + moduleName,
          )}</b>.
        If you remove it, these modules will be updated or removed along with the topics and questions.` :
          'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        // remove the training
        await TrainingsAPI.deleteTraining(null, id);

        // Delete results
        const newTrainings = [...trainings];
        newTrainings.splice(index, 1);

        // if there are modules assigned to the removed training
        if (dataForRemovedTraining?.linkedModules?.length > 0) {
          // get all modules data, remove or update modules, topics and questions for removed training
          const data = await Util.getAndRemoveOrUpdateModules(id, fetchedModules.data, true);

          let newModules = fetchedModules.data;
          let newTopics = data?.topicsData[0]?.topics || topics;
          let newQuestions = data?.topicsData[0]?.questionsData[0]?.questions || topicQuestions;
          const removedModules = [];
          const updatedModules = [];
          const removedTopics = [];
          const updatedTopics = [];

          // if modules have been removed
          if (data?.removedModules?.length) {
            // get array without removed modules
            newModules = newModules.filter((mod) => {
              const removedModule = data.removedModules.find(remMod => remMod._id === mod._id);

              // if module is removed, then do not return it, push its name into the removedModules array
              if (removedModule) { removedModules.push(mod.name); return null; }

              return mod;
            });
          }

          // if modules have been updated
          if (data?.updatedModules?.length) {
            // get array with updated modules
            newModules = newModules.map((mod) => {
              // get updated module data from updatedModules property
              const updatedModule = data.updatedModules.find(updated => updated._id === mod._id);

              // return updated data if exists, push module name into the updatedModules array
              if (updatedModule) { updatedModules.push(mod.name); return updatedModule; }

              return mod;
            });
          }

          // if topicsData exists
          if (data?.topicsData?.length) {
            // for each topic data
            data.topicsData.forEach((topicData) => {
              // if topic has been removed
              if (topicData?.removedTopics?.length) {
                newTopics = newTopics.filter((top) => {
                  const removedTopic = topicData.removedTopics.find(remTop => remTop._id === top._id);

                  // if topic is removed, then do not return it, push its name into the removedTopics array
                  if (removedTopic) { removedTopics.push(top.name); return null; }

                  return top;
                });
              }

              // if topic has been updated
              if (topicData?.updatedTopics?.length) {
                // get array with updated topics
                newTopics = newTopics.map((top) => {
                  // get updated topic data from updatedTopics property
                  const updatedTopic = topicData.updatedTopics.find(updated => updated._id === top._id);

                  // return updated data if exists, push topic name into the updatedTopics array
                  if (updatedTopic) { updatedTopics.push(top.name); return updatedTopic; }

                  return top;
                });
              }

              // if there are questions that are assigned to the removed topic
              if (topicData?.questionsData?.length) {
                // loop through each question data
                topicData.questionsData.forEach((questionData) => {
                  newQuestions = newQuestions.filter((question) => {
                    // find the removed question and don't return it in newQuestions
                    const removedQuestion = questionData.removedQuestions.find(q => q._id === question._id);

                    if (removedQuestion) { return null; }

                    return question;
                  });
                });
              }
            });
          }

          // Update trainings, modules, topic and topic questions
          setResults({
            ...results, topicQuestions: newQuestions, topics: newTopics, modules: newModules, trainings: newTrainings,
          });
          setState({
            ...state, topicQuestions: newQuestions, topics: newTopics, modules: newModules, trainings: newTrainings,
          });

          /**
           * Returns proper message after removing the module
           * @param {boolean} isModule - indicates whether the removed object is a module
           * @param {array} removedData - array with removed object names
           * @param {boolean} isRemoved - indicates whether the object is deleted or not
           * @returns {string} message showing in swal after removing the module
           */
          const returnMessage = (isModule, removedData, isRemoved) => {
            const modulesOrTopics = isModule ? 'Modules' : 'Topics';
            const removedOrUpdated = isRemoved ? 'removed' : 'updated';
            return (
              removedData?.length ?
                (
                  `<p>
                  ${modulesOrTopics}: <b class="swal_bold">${removedData.map(removedName => ' ' + removedName)}</b>
                  ${removedData?.length === 1 ? 'has' : 'have'} been ${removedOrUpdated} ${(isRemoved && !isModule) ?
                    'along with the questions' :
                    ''}.
              </p>`
                ) :
                ' '
            );
          };

          // show swal message about removed/updated data (trainings, modules and topics)
          // eslint-disable-next-line max-len
          Swal.fireSuccess({
            icon: 'success',
            title: 'Deleted!',
            html: `<p>The training has been deleted.</p>
            ${returnMessage(true, removedModules, true)}
            ${returnMessage(true, updatedModules)}
            ${returnMessage(false, removedTopics, true)}
            ${returnMessage(false, updatedTopics)}
            `,
          });
        } else {
          // update training
          setResults({ ...results, trainings: newTrainings });
          setState({ ...state, trainings: newTrainings });

          // show swal message about removed training
          Swal.fireSuccess('Deleted!', 'The training has been deleted.');
        }
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling Training modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  return (
    <div className="self-learning-trainings">
      <div className="self-learning-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={handleSearchTrainings}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by"
        />
        <div className="slds-form-element">
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select
                className="slds-select"
                onChange={event => setSearchBy(event.target.value)}
                id="select-type"
                value={searchBy}
              >
                <option value={Constants.SEARCH_BY__TRAINING_NAME}>Training name</option>
                <option value={Constants.SEARCH_BY__ORGANISATION_NAME}>Organisation name</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-certifications-questions">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Organisations">
                Organisations
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title=" Start Date">
                Start Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="certifications-questions-body-table">
          {trainings?.length > 0 ?
            trainings.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    <a
                      href="/#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Name">
                  <div className="slds-truncate" title={el.name}>
                    {el.name}
                  </div>
                </td>

                <td data-label="Organisations">
                  {el?.organisationIds?.length ?
                    (
                      <>
                        {el?.organisationIds?.length > 1 ?
                          (
                            <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                              <button
                                type="button"
                                className="slds-button slds-button_icon slds-button_icon-border-filled"
                                aria-haspopup="true"
                              >
                                <svg className="slds-button__icon" aria-hidden="true">
                                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                                </svg>
                                <span className="slds-assistive-text">Show Organisation</span>
                              </button>
                              <div className="slds-dropdown slds-dropdown_left">
                                <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                                  {el.organisationIds.map(org => (
                                    <li key={org} className="slds-dropdown__item" role="presentation">
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                      <a href="#" role="menuitem">
                                        <span
                                          className="slds-truncate slds-p-right--x-small"
                                          title={Util.returnPropertyNameById(organisationsData, org)}
                                        >
                                          {Util.returnPropertyNameById(organisationsData, org)}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) :
                          (
                            <div
                              className="slds-truncate"
                              title={Util.returnPropertyNameById(
                                organisationsData, el.organisationIds[0],
                              )}
                            >
                              {Util.returnPropertyNameById(organisationsData, el.organisationIds[0])}
                            </div>
                          )}
                      </>
                    ) :
                    null}
                </td>

                <td data-label="Start Date">
                  <div className="slds-truncate" title={new Date(el.startDate).toLocaleDateString()}>
                    {new Date(el.startDate).toLocaleDateString()}
                  </div>
                </td>
                <td>
                  <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                    {new Date(el.updatedAt).toLocaleString()}
                  </div>
                </td>

                <td data-label="Actions">
                  <div className="slds-truncate" title="Delete">
                    {deletedId === el._id ?
                      <Spinner size="x-small" additionalClassName="delete-self-learning-item" /> :
                      (
                        <svg
                          className="slds-icon slds-icon-text-error trash-delete-icon"
                          aria-hidden="true"
                          data-id={el._id}
                          data-index={i}
                          onClick={handleDelete}
                        >
                          <use
                            xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                            data-id={el._id}
                            data-index={i}
                          />
                        </svg>
                      )}
                  </div>
                </td>
              </tr>
            )) :
            null}
        </tbody>
      </table>
      <TrainingModal
        isOpen={state.isOpen}
        name={name}
        setName={setName}
        hiddenToNoOrgUsers={hiddenToNoOrgUsers}
        setHiddenToNoOrgUsers={setHiddenToNoOrgUsers}
        organisationIds={organisationIds}
        setOrganisationIds={setOrganisationIds}
        startDate={startDate}
        setStartDate={setStartDate}
        handleCancel={handleCancel}
        handleSave={handleSave}
        eventType={state.eventType}
        organisationsData={organisationsData}
      />
    </div>
  );
};

export default Trainings;
