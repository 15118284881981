import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PasswordField = ({ placeholder, handleChange }) => {
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('/assets/icons/eye1.svg');

  /**
   * Renders the eye icon
   * @returns {void}
   */
  const eyeIconHandle = () => {
    if (type === 'password') {
      setType('text');
      setIcon('/assets/icons/eye2.svg');
    } else if (type === 'text') {
      setType('password');
      setIcon('/assets/icons/eye1.svg');
    }
  };

  return (
    <div className="icon-text password-icon">
      <img src="/assets/icons/lock.svg" alt="username-icon" className="username-icon" />
      <input
        type={type}
        id="password"
        className="form-control password"
        placeholder={placeholder}
        onChange={handleChange}
      />
      <img
        src={icon}
        alt="password-icon"
        className="eye-icon"
        data-type="password"
        onClick={eyeIconHandle}
      />
    </div>
  );
};

PasswordField.propTypes = {
  /**
   * Placeholder for the field
   */
  placeholder: PropTypes.string,
  /**
   * Function to handle changes in the field
   */
  handleChange: PropTypes.func.isRequired,
};

export default PasswordField;
