import React from 'react';
import PropTypes from 'prop-types';
import Util from '../../../../../util';
import '../style.scss';
import Constants from '../../../../../constants/constants';

const TrainingResultsModal = ({
  isOpen, userId, orgId, startDate,
  handleCancel, trainings, trainingId, certificationsUsersData,
  organisationsData, moduleResults, modules, totalScore, completionStatus,
  expirationDate, setExpirationDate, handleSave,
}) => {
  /**
   * Returns the result and completion status for a particular module (by moduleId)
   * @param {string} moduleId - module Id
   * @returns {object} object containing score and completionStatus for module
   */
  const returnCompletionStatusForModule = (moduleId) => {
    // initial values - score and completion status
    let score = null;
    let completion = 0;

    // check if module exists in results
    const findModuleInResults = moduleResults.find(mod => mod._id === moduleId);

    if (findModuleInResults?.topics?.length) {
      // go over all topics
      findModuleInResults.topics.forEach((topic) => {
        // for completed topic
        if (topic.completed) {
          // calculate completion status
          completion += ((1 / findModuleInResults.topics.length) * 100);

          // calculate score
          if (topic.score || topic.score === 0) {
            // show score only when topic is complete
            if (score === null) { score = 0; }
            score += topic.score;
          }
        }
      });
    }

    return { score, completionStatus: completion };
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="training-results-self-learning-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Training Results Form
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-self-learning"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Training
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    className="slds-input"
                    value={Util.returnPropertyNameById(trainings, trainingId)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-userId">
                User Id
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-userId"
                    className="slds-input"
                    value={userId}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-username">
                Username
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-username"
                    className="slds-input"
                    value={Util.returnPropertyNameById(certificationsUsersData, userId, true)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-orgId">
                Organisation Id
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-orgId"
                    className="slds-input"
                    value={orgId}
                    readOnly
                  />

                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-orgName">
                Organisation Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-orgName"
                    className="slds-input"
                    value={Util.returnPropertyNameById(organisationsData, orgId)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="date">
                Start Date
                <div>
                  <input
                    type="date"
                    value={new Date(startDate).toLocaleDateString('en-CA')}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="expirationDate">
                Expiration Date
                <div>
                  <input
                    type="date"
                    value={expirationDate ? new Date(expirationDate).toLocaleDateString('en-CA') : ''}
                    onChange={e => setExpirationDate(e.target.value)}
                    min={new Date().toLocaleDateString('en-CA')}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label
                className="slds-form-element__label module-results-label"
                htmlFor="form-element-module-results"
                style={{ width: '100%' }}
              >
                Module Results
                <div id="form-element-module-results">
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '30%' }}>Module Id</th>
                        <th style={{ width: '45%' }}>Module Name</th>
                        <th style={{ width: '15%' }}>Status [%]</th>
                        <th style={{ width: '10%' }}>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {moduleResults.map(mod => (
                        <tr key={mod._id}>
                          <td>{mod._id}</td>
                          <td>
                            {Util.returnPropertyNameById(modules, mod._id)}
                          </td>
                          <td>
                            {returnCompletionStatusForModule(mod._id)?.completionStatus}
                          </td>
                          <td>
                            {returnCompletionStatusForModule(mod._id)?.score}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tbody className="total-results">
                      <tr>
                        <td>Total Results</td>
                        <td />
                        <td>{completionStatus || 0}</td>
                        <td>{totalScore}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </label>
            </div>
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

TrainingResultsModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** user Id */
  userId: PropTypes.string.isRequired,
  /** Id of the organization in which the user is assigned */
  orgId: PropTypes.string.isRequired,
  /** Id of the training for which the results are presented */
  trainingId: PropTypes.string.isRequired,
  /** starting date of the training */
  startDate: PropTypes.string.isRequired,
  /** an array will all certification users  */
  certificationsUsersData: PropTypes.instanceOf(Array).isRequired,
  /** an array with organizations data */
  organisationsData: PropTypes.instanceOf(Array).isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** an array with trainings created in self-learning section */
  trainings: PropTypes.instanceOf(Array).isRequired,
  /** an array with results for modules in training */
  moduleResults: PropTypes.instanceOf(Array).isRequired,
  /** an array with modules created in self-learning section */
  modules: PropTypes.instanceOf(Array).isRequired,
  /** the final score obtained from all modules completed in the training */
  totalScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  /** the final completion status of all modules in the training */
  completionStatus: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /**
   * expiration date of the training for the user - after this date,
   * the user will not receive any email notifications
   */
  expirationDate: PropTypes.string.isRequired,
  /** sets expiration date for training  */
  setExpirationDate: PropTypes.func.isRequired,
};

export default TrainingResultsModal;
