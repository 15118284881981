/* eslint-disable spellcheck/spell-checker */
import React, { } from 'react';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import SFMCOnboardingAPI from '../../../../api/sfmcOnboarding';
import Util from '../../../../util';

import './style.scss';

const SFMCUserOnboarding = () => {
  const [environment, setEnvironment] = React.useState('demo');
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [showProgressBar, setShowProgressBar] = React.useState('none');
  const [disableControl, setDisableControl] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [progressValue, setProgressValue] = React.useState(33);

  const progessSteps = [
    'Creating business unit...',
    'Creating user...',
    'Finished!',
    'Oops, something went wrong. Please try again.',
  ];

  /**
   * Clean user input
   * @param {String} input User input
   * @returns {String} Cleaned user input
   */
  const cleanUserInput = input => input.toLowerCase().replaceAll(' ', '');

  /**
   * Handle environment change
   * @param {Object} event Click event
   * @param {String} newEnvironment New environment
   * @returns {void}
   */
  const handleEnvironmentChange = (event, newEnvironment) => {
    setEnvironment(newEnvironment);
  };

  /**
   * Toggle disable control
   * @returns {void}
   */
  const toggleDisableControl = () => {
    if (disableControl) {
      setDisableControl(false);
    } else {
      setDisableControl(true);
    }
  };

  /**
   * Toggle progress bar
   * @returns {void}
   */
  const toggleProgressBar = () => {
    setCurrentStep(0);
    if (showProgressBar === 'none') {
      setShowProgressBar('block');
    } else {
      setShowProgressBar('none');
    }
  };

  /**
   * Handle submit action and create new user
   * @param {Object} e Event object
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    toggleProgressBar();
    toggleDisableControl();
    setLoading(true);

    try {
      // Creating new BU
      const resultNewBU = await SFMCOnboardingAPI.createBusinessUnit(
        firstName,
        lastName,
        `${cleanUserInput(firstName)}.${cleanUserInput(lastName)}@deselect.com`,
        environment,
      );

      if (resultNewBU.success) {
        setProgressValue(66);
        setCurrentStep(1);
      } else {
        throw new Error('Error creating business unit');
      }

      // Creating new user
      const resultNewUser = await SFMCOnboardingAPI.createUserAccount(
        firstName,
        lastName,
        `${cleanUserInput(firstName)}.${cleanUserInput(lastName)}@deselect.com`,
        resultNewBU.id,
        environment,
      );
      if (resultNewUser.success) {
        setProgressValue(100);
        setCurrentStep(2);
      } else {
        throw new Error('Error creating user');
      }
    } catch (error) {
      Util.handleError(error);
      setCurrentStep(3);
      setLoading(false);
    } finally {
      setCurrentStep(2);
      setLoading(false);
    }
  };

  return (
    <>
      <FormControl id="form" sx={{ width: '30%' }}>
        <TextField
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          color="warning"
          onChange={e => setFirstName(e.target.value)}
          margin="normal"
          required
          disabled={disableControl}
        />
        <TextField
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          onChange={e => setLastName(e.target.value)}
          margin="normal"
          required
          disabled={disableControl}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={firstName === undefined || lastName === undefined ?
            '' :
            cleanUserInput(firstName) + '.' + cleanUserInput(lastName) + '@deselect.com'}
          margin="normal"
          disabled
        />

        <ToggleButtonGroup
          color="primary"
          value={environment}
          exclusive
          onChange={handleEnvironmentChange}
          aria-label="Platform"
          size="small"
          sx={{ margin: '16px 0' }}
          fullWidth
          disabled={disableControl}

        >
          <ToggleButton value="demo">SFMC Demo</ToggleButton>
          <ToggleButton value="dev">SFMC Dev</ToggleButton>
        </ToggleButtonGroup>

        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
        >
          Submit
          {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: 'primary',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
          )}
        </Button>

        <Box sx={{ display: showProgressBar, margin: '64px 0 0 0' }}>
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
          >
            {progessSteps[currentStep]}
          </Typography>
          <LinearProgress
            sx={{ margin: '8px 0 0 0' }}
            variant="determinate"
            value={Math.floor(progressValue)}
          />
        </Box>
      </FormControl>
    </>
  );
};

export default SFMCUserOnboarding;
