import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import moment from 'moment-timezone';

import CertificationsUserAPI from '../../../../api/academyCertifications/certifications-users';
import Constants from '../../../../constants/constants';
import Swal from '../../../../helpers/Swal';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import UserModal from './Modals/UserModal';
import AccessToCertificationModal from './Modals/AccessToCertificationModal';
import MultiSelect from '../AcademyTrainings/atoms/MultiSelect';

const CertificationsUsers = ({ validate }) => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const { certificationsUsersData, organisationsData, exams } = results;

  // User Certifications variables
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [orgId, setOrgId] = useState('');
  const [paidExamIds, setPaidExamIds] = useState([]);
  const [paidForCertification, setPaidForCertification] = useState([]);
  const [isPaid, setIsPaid] = useState(true);
  const [group, setGroup] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);

  // Access to certification variables
  const [examId, setExamId] = useState('');
  const [usersToSelect, setUsersToSelect] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // For filtering by organisation
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setUserName('');
    setPassword('');
    setName('');
    setIsAdmin(false);
    setOrgId('');
    setPaidExamIds([]);
    setPaidForCertification([]);
    setExamId('');
    setUsersToSelect([]);
    setSelectedUsers([]);
    setIsPaid(true);
    setGroup('');
    setExpirationDate(null);
  };

  /**
   * Returns placeholder for multi select component, depending on the number of selected options
   * @returns {String} placeholder text
   */
  const returnMultiSelectPlaceholder = () => {
    if (selectedOrganisations?.length === 1) {
      return Util.returnPropertyNameById(organisationsData, selectedOrganisations[0]);
    } if (selectedOrganisations?.length > 1) {
      return `${selectedOrganisations.length} Organisations Selected`;
    } if (!selectedOrganisations?.length) {
      return 'Select Organisation';
    }

    return '';
  };

  useEffect(() => {
    // if we filter user by organisation
    if (selectedOrganisations?.length) {
      // filter users linked to selected organisation
      const users = certificationsUsersData.filter(user => selectedOrganisations.find(org => org === user.orgId) ?
        user :
        null);

      // set filtered users
      setFilteredUsers(users);
    } else {
      // in other case set all users
      setFilteredUsers(certificationsUsersData);
    }
  }, [selectedOrganisations, setSelectedOrganisations, certificationsUsersData]);

  /**
   * Handler called to set states when selecting examId in Access to Certification modal
   * @returns {void}
   */
  const setUsersDataAboutCertificationAccess = () => {
    const newArrayForSelectedUsers = [];
    const newArrayForUsersToSelect = [];

    // for each certification user
    certificationsUsersData.forEach((user) => {
      // if examId is selected
      if (examId) {
        // check if user is assigned to an exam
        const getUserAccess = user?.paidForCertification?.find(exam => exam.examId === examId);

        if (getUserAccess) {
          // push into the array the user who is assigned to the exam
          newArrayForSelectedUsers.push(user);
        } else {
          // push into the array the user who is not assigned to the exam
          newArrayForUsersToSelect.push(user);
        }

        return null;
      }

      return null;
    });

    // set selected questions
    setUsersToSelect(newArrayForUsersToSelect);
    setSelectedUsers(newArrayForSelectedUsers);
  };

  useEffect(() => {
    // set users who have access to the selected exam based on user data
    setUsersDataAboutCertificationAccess();

    // eslint-disable-next-line
  }, [examId]);

  /**
   * Handler called when saving created user
   * @returns {void}
   */
  const handleSave = async () => {
    const newPaidForCertification = [];

    // loop through selected exams
    paidExamIds.forEach((id) => {
      // find exam in paidForCertification property
      const findExamInPaidForCertification = paidForCertification.find(exam => exam.examId === id);

      if (findExamInPaidForCertification) {
        // push the object from paidFromCertification
        newPaidForCertification.push(findExamInPaidForCertification);
      } else {
        // push the new object, with assigned exam id
        newPaidForCertification.push({ examId: id, dateOfPurchase: new Date() });
      }
    });

    const newData = {
      username,
      password,
      name,
      isAdmin,
      paidForCertification: newPaidForCertification,
      orgId: orgId && orgId !== '' ? orgId : null,
      isPaid,
      group,
      expirationDate,
    };

    const omit = (prop, { [prop]: _, ...rest }) => rest;

    /**
     * omit the OrgId which we do not validate
     * omit group which is optional
     * omit expirationDate which is optional
     */
    const dataForValidation = omit('expirationDate', omit('group', omit('paidForCertification', newData)));

    // validate input data without orgId
    const validateResult = validate(dataForValidation);
    const checkEmail = Util.validateEmail(username);
    const validationFields = validateResult.map((message) => {
      const words = message.split(' ');
      return words[words.length - 1];
    });

    if (validateResult.length > 0) {
      Swal.fireWarning('', 'Please fill out ' + validationFields.join(', ') + '.');
    } else if (checkEmail === false) {
      Swal.fireWarning('', 'Username is invalid - email format is required');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // we are creating a new user
          const res = await CertificationsUserAPI.createCertificationUser(null, newData);

          // set the data from response
          setResults({
            ...results,
            certificationsUsersData: [
              ...certificationsUsersData,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            certificationsUsersData: [
              ...certificationsUsersData,
              res.data,
            ],
          });

          // display information
          Swal.fireSuccess('Created!', 'The user has been created.');
          cleanFields();
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // when we are updating certification user
          const res = await CertificationsUserAPI.updateCertificationUser(null, state.id, newData);

          // set the data for the selected index
          const newArray = certificationsUsersData.map(user => user._id === state.id ? res.data : user);

          setResults({ ...results, certificationsUsersData: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            certificationsUsersData: newArray,
          });

          Swal.fireSuccess('Updated!', 'The user has been updated.');
        }

        // clear state after updating
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  const handleCertificationAccessSave = async () => {
    const confirmation = await Swal.fireWarning({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Do you want to update multiple users? ',
      confirmButtonText: 'Yes, update!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    });

    if (confirmation.value) {
      // define post data
      const postData = {
        certificationUsersIds: selectedUsers.map(user => user._id),
        examId,
      };

      // update many users
      const res = await CertificationsUserAPI.updateManyCertificationUsers(null, postData);

      // set updated certificationsUsersData
      setResults({ ...results, certificationsUsersData: res.data });

      setState({
        ...state,
        isOpen: false,
        eventType: '',
        certificationsUsersData: res.data,
      });

      // display information
      Swal.fireSuccess('Updated!', 'The users have been updated.');
      cleanFields();
    }
  };

  /**
   * Handler called when editing a certification user
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = certificationsUsersData.filter(e => e._id === id)[0];

    // define array with examIds for paidForCertification property
    const arrayForPaidExamIds = singleItem?.paidForCertification?.length ?
      singleItem.paidForCertification.map(exam => exam.examId) :
      [];

    // set data
    setUserName(singleItem.username);
    setPassword(singleItem.password);
    setName(singleItem.name);
    setIsAdmin(singleItem.isAdmin);
    setOrgId(singleItem?.orgId || '');
    setPaidExamIds(arrayForPaidExamIds);
    setPaidForCertification(singleItem?.paidForCertification || []);
    setIsPaid(singleItem.isPaid);
    setGroup(singleItem.group);
    setExpirationDate(moment(singleItem.expirationDate || new Date('9999-12-31'))?.toISOString()?.split('T')[0]);
  };

  /**
   * Handler called when canceling a certifications user modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  const handleChangePassword = async () => {
    setState({ ...state, isOpen: false });
    cleanFields();
    const newPassword = Util.generateRandomString();
    await CertificationsUserAPI.updateCertificationUser(null, state.id, { password: newPassword }, true);
    Swal.fireSuccess('New password generated!', 'This is the new password of the user: ' + newPassword);
  };

  return (
    <div className="certifications-users">
      <div className="certifications-organisation-filter-container">
        <MultiSelect
          multiSelectPlaceholder={returnMultiSelectPlaceholder()}
          value={selectedOrganisations}
          data={organisationsData}
          setData={setSelectedOrganisations}
          filterLabel
        />
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Username">
                Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Organisation Id / Name">
                Organisation Id / Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Access to certification">
                Access to certification
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate">
                Is Admin
                <Popup
                  content="Please note this Admin flag only refers to certification management.
                  Certification admin users can see exams marked with the onlyForAdmin flag in the application"
                  // eslint-disable-next-line spellcheck/spell-checker
                  trigger={<Icon name="info circle" className="isAdmin-info-tooltip" />}
                  on={['hover']}
                  className="isAdmin-popup"
                  position="top center"
                />
              </div>
            </th>

            <th scope="col" title="Is Self-Sign-Up">
              <div className="slds-truncate">
                Is Self-Sign-Up
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate">
                Is Paid
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div className="slds-truncate" title={el._id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Username">
                <div className="slds-truncate" title={el.username}>
                  {el.username}
                </div>
              </td>

              <td data-label="Name">
                <div className="slds-truncate" title={el.name}>
                  {el.name}
                </div>
              </td>

              <td data-label="Organisation Id / Name / Group">
                <div
                  className="slds-truncate"
                  title={`${el.orgId} / ${Util.returnPropertyNameById(
                    organisationsData, el.orgId,
                  )}`}
                >
                  {el?.orgId ?
                    `${el.orgId} / ${Util.returnPropertyNameById(organisationsData, el.orgId)} / ${el.group}` :
                    ''}
                </div>
              </td>

              <td data-label="Access to Certification">
                {el?.paidForCertification?.length ?
                  (
                    <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                      <button
                        type="button"
                        className="slds-button slds-button_icon slds-button_icon-border-filled"
                        aria-haspopup="true"
                      >
                        <svg className="slds-button__icon" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                        </svg>
                        <span className="slds-assistive-text">Show Exams</span>
                      </button>
                      <div className="slds-dropdown slds-dropdown_left">
                        <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                          {el.paidForCertification.map(exam => (
                            <li key={exam.examId} className="slds-dropdown__item" role="presentation">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, spellcheck/spell-checker */}
                              <a href="#" role="menuitem">
                                <span
                                  className="slds-truncate slds-p-right--x-small"
                                  title={`${Util.returnPropertyNameById(exams, exam.examId)}`}
                                >
                                  {Util.abbreviate(Util.returnPropertyNameById(exams, exam.examId), 20)}
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) :
                  (
                    Util.returnPropertyNameById(exams, el?.paidForCertification[0]?.examId)
                  )}
              </td>

              <td data-label="Is Admin">
                <div className="slds-truncate">
                  {el.isAdmin ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td data-label="Is Self-Sign-Up">
                <div className="slds-truncate">
                  {el.isSelfSignUp ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td data-label="Is Paid">
                <div className="slds-truncate">
                  {el.isPaid ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                  {new Date(el.updatedAt).toLocaleString()}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.eventType === Constants.EVENT__TYPE__UPDATE_MANY ?
        (
          <AccessToCertificationModal
            exams={exams}
            examId={examId}
            setExamId={setExamId}
            isOpen={state.isOpen}
            handleCancel={handleCancel}
            usersToSelect={usersToSelect}
            selectedUsers={selectedUsers}
            allUsers={certificationsUsersData}
            setSelectedUsers={setSelectedUsers}
            setUsersToSelect={setUsersToSelect}
            handleSave={handleCertificationAccessSave}
          />
        ) :
        (
          <UserModal
            isOpen={state.isOpen}
            eventType={state.eventType}
            username={username}
            setUserName={setUserName}
            password={password}
            setPassword={setPassword}
            name={name}
            setName={setName}
            handleCancel={handleCancel}
            handleChangePassword={handleChangePassword}
            handleSave={handleSave}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            orgId={orgId}
            setOrgId={setOrgId}
            organisations={organisationsData}
            paidExamIds={paidExamIds}
            setPaidExamIds={setPaidExamIds}
            exams={exams}
            isPaid={isPaid}
            setIsPaid={setIsPaid}
            group={group}
            setGroup={setGroup}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
          />
        )}
    </div>
  );
};

CertificationsUsers.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
};

export default CertificationsUsers;
