import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SettingsApi = {
  /**
   * Updates settings
   * @param {object} cancelToken - The cancel token from Axios
   * @param {boolean} processingEnabled - Cookie to be set
   * @return {object} An object with `result` property - always true - and `success`
   */
  update: async (cancelToken, processingEnabled) => {
    const body = {
      processingEnabled,
    };
    const res = await axios.put(
      `${apiUrl}/admin/settings/`,
      body,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Get settings
   * @param {object} cancelToken - The cancel token from Axios
   * @return {object} Settings Object
   */
  get: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/settings/`,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default SettingsApi;
