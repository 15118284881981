import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Icon,
  Button,
  Dropdown,
  Loader,
  Modal,
} from 'semantic-ui-react';

import Constants from '../../../../constants/constants';

import './style.scss';

const Filter = ({
  handleStopFiltering,
  filterResultsHandler,
  loading,
  filter,
}) => {
  // Dropdown state
  const [showFilterInputs, setShowFilterInputs] = useState(false);
  const [filterBy, setFilterBy] = useState(Constants.FILTER_BY__ORG);
  const [searchText, setSearchText] = useState('');

  /*
   * Change of dropdown
   */
  const handleOnChange = (e, { value }) => {
    setFilterBy(value);
  };

  return (
    <>
      <div
        className="filter"
      >
        {filter ?
          (
            <Label className="selected-filter-label">
              {filter}
              <Icon
                name="delete"
                onClick={() => {
                  handleStopFiltering();
                  setSearchText('');
                }}
              />
            </Label>
          ) :
          null}
        <Button
          onClick={() => setShowFilterInputs(!showFilterInputs)}
          icon
          labelPosition="left"
          className="filter-button"
        >
          <Icon name="filter" />
          Filter
        </Button>
      </div>
      <div>
        {
          showFilterInputs && (
            <div className="filter-inputs">
              <Input
                labelPosition="right"
                type="text"
                placeholder="Search..."
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
              >
                <Label basic>Filter by</Label>
                <input />
                <Label>
                  <Dropdown
                    options={[
                      {
                        key: Constants.FILTER_BY__ORG,
                        text: 'Organisation Id',
                        value: Constants.FILTER_BY__ORG,
                      },
                      {
                        key: Constants.FILTER_BY__SELECTION,
                        text: 'Selection Id',
                        value: Constants.FILTER_BY__SELECTION,
                      },
                    ]}
                    defaultValue={Constants.FILTER_BY__ORG}
                    onChange={handleOnChange}
                  />
                </Label>
              </Input>
              <Button
                primary
                onClick={e => filterResultsHandler(e, filterBy, searchText)}
                disabled={!searchText}
              >
                Submit
              </Button>
            </div>
          )
        }
        <Modal
          open={loading}
          size="tiny"
          centered
          className="modal"
        >
          <Loader size="large">Loading...</Loader>
        </Modal>
      </div>
    </>
  );
};

Filter.propTypes = {
  /* Stops the filtering of results */
  handleStopFiltering: PropTypes.func.isRequired,
  /** Handles the filtering of results */
  filterResultsHandler: PropTypes.func.isRequired,
  /** Loading state of the modal */
  loading: PropTypes.bool.isRequired,
  /** The string the results are filtered by */
  filter: PropTypes.string,
};

export default Filter;
