import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Util from '../../../../../util';
import '../style.scss';
import TransferList from '../atoms/TransferList';
import Constants from '../../../../../constants/constants';

const ExamModal = ({
  isOpen, name, setName, numberOfQuestions, setNumberOfQuestions, time, setTime,
  handleCancel, handleSave, setAvailableQuestions, availableQuestions, allQuestions,
  setOpenQuestions, openQuestions, threshold, setThreshold, eventType, endDate, setEndDate,
  onlyForAdmin, setOnlyForAdmin, setIsARefresherExam, isARefresherExam, exams, mainExamId, setMainExamId,
  numberOfRetries, setNumberOfRetries, generatesCertificate, setGeneratesCertificate,
}) => {
  // used for select questions in Select Question Modal
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsForSelect, setQuestionsForSelect] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setSelectedQuestions([]);
    setQuestionsForSelect([]);
    setLoadingData(false);
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    /**
     * Handler called to set states when opening a modal for editing
     * @returns {void}
     */
    const setSavedData = () => {
      let newArrayForAvailableQuestions = [];

      /*
       * from the backend we get table with id numbers - based on these numbers,
       * we have to create arrays with objects such as appear in all questions
       */
      if (availableQuestions.some(value => typeof value === 'object')) {
        newArrayForAvailableQuestions = availableQuestions;
      } else {
        availableQuestions.map((av) => {
          // look for selected questions for this exam
          const arrayWithSelectedQuestions = allQuestions.find(question => question._id === av);
          if (arrayWithSelectedQuestions) {
            newArrayForAvailableQuestions.push(arrayWithSelectedQuestions);
          }
          return false;
        });
      }

      // set selected questions
      setSelectedQuestions(newArrayForAvailableQuestions);

      // find other available questions that have not been selected
      const newArrayForQuestionsForSelect = allQuestions.filter((aq) => {
        if (newArrayForAvailableQuestions.includes(aq)) {
          return false;
        }
        return aq;
      });

      // set questions for the left column (questions to choose)
      setQuestionsForSelect(newArrayForQuestionsForSelect);
    };

    if (openQuestions) {
      if (availableQuestions.length > 0) {
        // set the initial state for the saved data
        setSavedData();
      } else if (allQuestions.length && !availableQuestions.length) {
        // otherwise make all questions selectable
        setQuestionsForSelect(allQuestions);
      }
    }
  }, [isOpen, openQuestions, allQuestions, availableQuestions]);

  /**
   * Handler called when submit the Select Questions Modal
   * @returns {void}
   */
  const handleSubmit = () => {
    // set the selected questions as availableQuestions and close the modal
    setAvailableQuestions(selectedQuestions);
    setOpenQuestions(false);

    // clean the state
    cleanFields();
  };

  /**
   * Handler called when return from the Select Questions Modal to the Exam Modal
   * @returns {void}
   */
  const handleReturn = () => {
    setOpenQuestions(false);
    cleanFields();
  };

  /**
   * Handler called when cancel from the Exam Modal
   * @returns {void}
   */
  const handleCancelForQuestions = () => {
    cleanFields();
    handleCancel();
  };

  /**
   * Handler called when when changing a field value
   * @param {object} e - JS Event
   * @param {number} maxNumber - maximum number from which it is possible to change
   * @param {function} changeFn - function that changes the state (depending on field value)
   * @returns {void}
   */
  const handleChangeFieldValue = (e, maxNumber, changeFn) => {
    if (e.target.value > -1 && e.target.value < maxNumber) { changeFn(e.target.value); }
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={(isOpen && !openQuestions) ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {eventType === Constants.EVENT__TYPE__NEW ? 'Create Exam' : 'Edit Exam'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-exam-name">
                Exam name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-exam-name"
                    placeholder="Exam name"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-number-of-questions">
                Number of questions
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    min="1"
                    max="999"
                    id="form-element-number-of-questions"
                    placeholder="Number of questions"
                    className="slds-input"
                    onChange={e => handleChangeFieldValue(e, 1000, setNumberOfQuestions)}
                    value={numberOfQuestions}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-number-of-retries">
                Number of retries allowed
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    min="1"
                    max="999"
                    id="form-element-number-of-retries"
                    placeholder="Number of retries allowed"
                    className="slds-input"
                    onChange={e => handleChangeFieldValue(e, 1000, setNumberOfRetries)}
                    value={numberOfRetries}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-time">
                Time [min]
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    min="1"
                    max="999"
                    id="form-element-time"
                    placeholder="Time [min]"
                    className="slds-input"
                    onChange={e => handleChangeFieldValue(e, 1000, setTime)}
                    value={time}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-threshold">
                Threshold [%]
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    min="1"
                    max="100"
                    id="form-element-threshold"
                    placeholder="Threshold [%]"
                    className="slds-input"
                    onChange={e => handleChangeFieldValue(e, 101, setThreshold)}
                    value={threshold}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="endDate">
                End date
                <div className="slds-form-element__control">
                  <input
                    type="date"
                    onChange={(event) => {
                      if (moment(event.target.value).year() > 9999) return;
                      setEndDate(event.target.value);
                    }}
                    value={endDate}
                    name="endDate"
                    id="endDate"
                    min={moment().toISOString().split('T')[0]}
                    max="9999-12-31"
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <div className="slds-form-element__label">
                Only for admin
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setOnlyForAdmin(!onlyForAdmin)}
                      type="checkbox"
                      name="onlyForAdmin"
                      id="onlyForAdmin"
                      checked={onlyForAdmin || false}
                    />
                    <label className="slds-checkbox__label" htmlFor="onlyForAdmin">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {onlyForAdmin ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="slds-form-element">
              <div className="slds-form-element__label">
                Is a refresher exam
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setIsARefresherExam(!isARefresherExam)}
                      type="checkbox"
                      name="isARefresherExam"
                      id="isARefresherExam"
                      checked={isARefresherExam || false}
                    />
                    <label className="slds-checkbox__label" htmlFor="isARefresherExam">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isARefresherExam ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="slds-form-element">
              <div className="slds-form-element__label">
                Generates Certificate
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setGeneratesCertificate(!generatesCertificate)}
                      type="checkbox"
                      name="generatesCertificate"
                      id="generatesCertificate"
                      checked={generatesCertificate || false}
                    />
                    <label className="slds-checkbox__label" htmlFor="generatesCertificate">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {generatesCertificate ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {
              isARefresherExam && (
                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="select-main-exam">
                    Select Main Exam
                    <div className="slds-form-element__control">
                      <div className="slds-select_container">
                        <select
                          className="slds-select"
                          onChange={event => setMainExamId(event.target.value)}
                          id="select-main-exam"
                          value={mainExamId}
                        >
                          <option value="" hidden>
                            Please select an exam
                          </option>
                          {exams.map((top, index) => (
                            <option
                              value={top._id}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${index}-${top._id}`}
                              title={Util.returnPropertyNameById(exams, top._id)}
                            >
                              {Util.abbreviate(Util.returnPropertyNameById(exams, top._id), 100)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </label>
                </div>
              )
            }

            <div className="slds-form-element select-question-box">
              {loadingData ?
                (
                  <div
                    role="status"
                    className="slds-spinner slds-spinner_x-small"
                  >
                    <div className="slds-spinner__dot-a" />
                    <div className="slds-spinner__dot-b" />
                  </div>
                ) :
                (
                  <>
                    <label
                      className={`slds-form-element__label select-questions
                  ${availableQuestions.length > 0 ? 'active' : ''}`}
                      htmlFor="select-type"
                      onClick={() => setOpenQuestions(true)}
                    >
                      {availableQuestions.length > 0 ?
                        `Selected Questions : ${availableQuestions.length}` :
                        'Select Questions'}
                    </label>
                  </>
                )}
              <div className="slds-form-element__control" />
            </div>
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={(isOpen && !openQuestions) ? 'slds-backdrop slds-backdrop_open' : ''} />

      {/* Select questions modal */}
      {(openQuestions && isOpen) && (
        <>
          <section
            role="dialog"
            tabIndex="-1"
            aria-labelledby="modal-heading-01"
            aria-modal="true"
            aria-describedby="modal-content-id-1"
            className={(isOpen && openQuestions) ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
          >
            <div className="slds-modal__container select-question-modal">
              <header className="slds-modal__header">
                <button
                  type="button"
                  className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
                  title="Close"
                >
                  <svg
                    className="slds-button__icon slds-button__icon_large"
                    aria-hidden="true"
                  />
                  <span className="slds-assistive-text">Close</span>
                </button>
                <h2
                  id="modal-heading-01"
                  className="slds-modal__title slds-hyphenate"
                >
                  {`Select ${numberOfQuestions} Question${numberOfQuestions > 1 ? 's' : ''}`}
                </h2>
              </header>
              <TransferList
                isOpen={isOpen}
                openMultiSelect={openQuestions}
                loadingData={loadingData}
                selectedData={selectedQuestions}
                dataForSelect={questionsForSelect}
                handleCancel={handleCancelForQuestions}
                destination={Constants.TRANSFER_LIST__DESTINATION__QUESTIONS}
                handleSubmit={handleSubmit}
                setSelectedData={setSelectedQuestions}
                setDataForSelect={setQuestionsForSelect}
                allData={allQuestions}
              />
              <footer className="slds-modal__footer">
                <button
                  type="button"
                  className="slds-button slds-button_neutral"
                  onClick={handleReturn}
                >
                  Return
                </button>
                <button
                  type="button"
                  className="slds-button slds-button_neutral"
                  onClick={handleCancelForQuestions}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={!userHasEditRights}
                  className="slds-button slds-button_brand"
                >
                  Submit
                </button>
              </footer>
            </div>
          </section>
          <div className={(openQuestions && isOpen) ? 'slds-backdrop slds-backdrop_open' : ''} />
        </>

      )}
    </>
  );
};

ExamModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** Label for exam name */
  name: PropTypes.string.isRequired,
  /** set name for exam */
  setName: PropTypes.func.isRequired,
  /** number of questions that may be present for this exam */
  numberOfQuestions: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** set state for numberOfQuestions */
  setNumberOfQuestions: PropTypes.func.isRequired,
  /**
   * Number of retries allowed for this exam
   */
  numberOfRetries: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A function that sets the allowed number of retries for an exam
   */
  setNumberOfRetries: PropTypes.func,
  /** time to complete the exam */
  time: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** set state for time */
  setTime: PropTypes.func.isRequired,
  /** array with questions assigned to the exam */
  availableQuestions: PropTypes.instanceOf(Array).isRequired,
  /** set state for availableQuestions */
  setAvailableQuestions: PropTypes.func.isRequired,
  /** all created questions from the database */
  allQuestions: PropTypes.instanceOf(Array).isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /* informs if the select questions modal is open */
  openQuestions: PropTypes.bool.isRequired,
  /* opens the select questions modal */
  setOpenQuestions: PropTypes.func.isRequired,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string,
  /** threshold for passing the exam */
  threshold: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** function that sets the threshold */
  setThreshold: PropTypes.func.isRequired,
  /** date when the exam expires */
  endDate: PropTypes.string.isRequired,
  /** function for setting endDate state */
  setEndDate: PropTypes.func.isRequired,
  /** defines if the exam is available only from the admin */
  onlyForAdmin: PropTypes.bool,
  /** function for setting onlyForAdmin state */
  setOnlyForAdmin: PropTypes.func.isRequired,
  /** Indicates whether the exam is a refresher exam */
  isARefresherExam: PropTypes.bool,
  /** function for setting isARefresherExam state */
  setIsARefresherExam: PropTypes.func.isRequired,
  /**
   * An array containing all exams
   */
  exams: PropTypes.instanceOf(Array).isRequired,
  /**
   * The Id of the main exam the current exam is a refresher to
   */
  mainExamId: PropTypes.string,
  /**
   * function for setting the mainExamId state
   */
  setMainExamId: PropTypes.func.isRequired,
  /** defines if the exam should generate a certificate or not */
  generatesCertificate: PropTypes.bool,
  /** function for setting generatesCertificate state */
  setGeneratesCertificate: PropTypes.func.isRequired,
};

export default ExamModal;
