import React, { useState, useEffect, useContext } from 'react';
import ApexChart from 'react-apexcharts';
import classNames from 'classnames';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';
import { MenuContext } from '../../../../MenuContext';

import StatsAPI from '../../../../api/stats';

import './style.scss';
import './animation.scss';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import Filter from './Filter';
import NPSReport from './NPSReport';
import Swal from '../../../../helpers/Swal';
import SupportTicketReport from './SupportTicketReport';

const EngagementDashboard = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [resultsList, setResultsList] = useState([]);
  const [unmappedTicketsList, setUnmappedTicketsList] = useState([]);
  const [state, setState] = useContext(UtilsContext);
  const [loading, setLoading] = useState(true);

  const { engagementDashboardData, filteredResults, unmappedTicketsData } = results;
  const { showingFilteredResults, filter } = state;

  const menuContext = useContext(MenuContext);
  const [, setMenuSelected] = menuContext.menuSelected;
  const [, setMenuParameters] = menuContext.menuParameters;

  useEffect(() => {
    // If filtering...
    if ((filteredResults && filteredResults.length) || showingFilteredResults) {
      setResultsList(filteredResults);
    } else {
      // Show unfiltered data
      setResultsList(engagementDashboardData);
    }

    setUnmappedTicketsList(unmappedTicketsData);

    // eslint-disable-next-line
  }, [engagementDashboardData, filteredResults, unmappedTicketsData]);

  // Data from result
  const {
    customerOrgs = [],
  } = resultsList;

  useEffect(() => {
    // We only stop loading when we have the customer data ready
    if (customerOrgs.length) {
      // Order customer orgs by Tier and Churn Prob
      customerOrgs.sort((a, b) => {
        if (a.tierOrder === b.tierOrder) {
          if (a.churnProbOrder === b.churnProbOrder) {
            return b.ARR - a.ARR; // arr descending
          }

          return (a.churnProbOrder - b.churnProbOrder); // churn prob ascending
        }

        return (a.tierOrder - b.tierOrder); // tier ascending
      });

      setLoading(false);
    }
  }, [customerOrgs]);

  const averageHealthScore =
    customerOrgs.reduce((acc, cur) => acc + cur.sprintHealthScore, 0) / customerOrgs.length;

  // Health Score Chart
  const customerHealthScoreChart = {
    series: [
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__HEALTHY_VALUE && cur.ARR ? cur.ARR : 0), 0),
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__NEUTRAL_VALUE && cur.ARR ? cur.ARR : 0), 0),
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__AT_RISK_VALUE && cur.ARR ? cur.ARR : 0), 0),
    ],
    options: {
      labels: ['Healthy', 'Neutral', 'At Risk'],
      colors: [Constants.HS__HEALTHY_COLOR, Constants.HS__NEUTRAL_COLOR, Constants.HS__AT_RISK_COLOR],
      legend: {
        show: false,
      },
    },
  };

  // NPS Responses
  const npsResponses = customerOrgs.reduce((acc, cur) => {
    if (cur.npsOverview.responses.length) {
      return [...acc, ...cur.npsOverview.responses.map(response => ({ ...response, org: cur.name }))];
    }

    return acc;
  }, []).sort((a, b) => b.dataWeek - a.dataWeek);

  // Support Portal Tickets
  const supportTickets = customerOrgs.reduce((acc, cur) => {
    if (cur.supportTicketsOverview.length) {
      return [...acc, ...cur.supportTicketsOverview.map(response => ({ ...response, org: cur.name }))];
    }

    return acc;
  }, []);

  const allSupportTickets =
    [...supportTickets, ...unmappedTicketsList].sort((a, b) => new Date(b.openDate) - new Date(a.openDate));

  const openBugs = allSupportTickets.reduce((acc, cur) => acc +
    ((cur.type === Constants.ZENDESK_TICKET__TYPE__BUG && cur.status === Constants.ZENDESK_TICKET__STATUS__OPEN ?
      1 :
      0
    )),
  0);

  // Format ARR
  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    notation: 'compact', compactDisplay: 'short', currency: 'EUR',
  });

  const renderLicensesUsage = (value) => {
    const className = classNames(
      'slds-badge',
      { 'slds-theme_error': value > 100 },
      { 'slds-theme_success': value === '100' },
    );
    if (value) {
      return (
        <span className={className}>
          {value}
          %
        </span>
      );
    }

    return '-';
  };

  /**
   * Filter handler
   *  @param {Event} e - Event
   *  @param {String} filterBy - either CSM or AE
   *  @param {String} searchText - text to be searched
   *  @returns {Object} Resulting orgs based on the filter
   */
  const filterResultsHandler = async (e, filterBy, searchText) => {
    setLoading(true);
    let filterCriteria = {
      edition: { $ne: Constants.DESELECT__EDITION__ESSENTIALS },
    };

    // If filtering by enterprise id
    if (filterBy === Constants.FILTER_BY__CUSTOMER_SUCCESS_MANAGER) {
      filterCriteria = {
        'orgCSM.Name': { $regex: searchText, $options: 'i' },
        edition: { $ne: Constants.DESELECT__EDITION__ESSENTIALS },
      };
    } else if (filterBy === Constants.FILTER_BY__ACCOUNT_EXECUTIVE) {
      // Filtering by name of the organisation
      filterCriteria = {
        'orgAE.Name': { $regex: searchText, $options: 'i' },
        edition: { $ne: Constants.DESELECT__EDITION__ESSENTIALS },
      };
    }

    try {
      // Get filtered results
      const res = await StatsAPI.getCustomerEngagementData(null, filterCriteria);

      // Set state
      setState({ ...state, showingFilteredResults: true, filter: (filterBy + ': ' + searchText) });

      // Show filtered results
      setResults({ ...results, filteredResults: res.data });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `Something unexpected happened: ${error.message || error}`,
      );
    }
  };

  /**
   * Stops the filtering of results
   * @returns {void}
   */
  const handleStopFiltering = () => {
    // Reset values
    setResultsList(engagementDashboardData);
    setState({ ...state, showingFilteredResults: false, filter: null });
    setResults({ ...results, filteredResults: [] });
  };

  /**
   * handler to open link to Adoption Dashboard
   * @param {object} event - Event object
   *  @returns {void}
   *
   */
  const openAdoptionDashboard = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    const orgId = event.target.dataset.id;
    setMenuSelected(linkName);
    setMenuParameters({ orgId });
  };

  return (
    <div className="dashboard">
      <Filter
        handleStopFiltering={handleStopFiltering}
        showingFilteredItems={showingFilteredResults}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filter}
      />
      <div className="slds-grid">
        <div className="slds-size_12-of-12" style={{ display: 'grid' }}>
          <div className="slds-box">
            <div className="box-content list" style={{ height: '300px', overflowX: 'scroll' }}>
              <table
                className="slds-table slds-table_bordered slds-table_fixed-layout"
                style={{ fontSize: '12px', minWidth: '1000px' }}
              >
                <thead>
                  <tr className="slds-line-height_reset">

                    <th scope="col" className="idColumn" style={{ width: '40px' }}>
                      <div
                        className="slds-truncate"
                        title="Id"
                      >
                        Id
                      </div>
                    </th>

                    <th scope="col" style={{ width: '180px' }}>
                      <div className="slds-truncate" title="Name">
                        Name
                      </div>
                    </th>

                    <th scope="col" style={{ width: '50px' }}>
                      <div className="slds-truncate" title="ACV">
                        Tier
                      </div>
                    </th>

                    <th scope="col" style={{ width: '50px' }}>
                      <div className="slds-truncate" title="ACV">
                        ACV
                      </div>
                    </th>

                    <th scope="col" style={{ width: '40px' }}>
                      <div className="slds-truncate" title="Edition" />
                    </th>

                    <th scope="col" style={{ width: '80px' }}>
                      <div className="slds-truncate" title="Health Score">
                        Sprint HS
                      </div>
                    </th>

                    <th scope="col" style={{ width: '80px' }}>
                      <div className="slds-truncate" title="Touchpoint">
                        Last TP
                      </div>
                    </th>

                    <th scope="col" style={{ width: '60px' }}>
                      <div className="slds-truncate" title="NPS">
                        NPS
                      </div>
                    </th>

                    <th scope="col" style={{ width: '70px' }}>
                      <div className="slds-truncate" title="BUs">
                        % BUs
                      </div>
                    </th>

                    <th scope="col" style={{ width: '90px' }}>
                      <div
                        className="slds-truncate"
                        title="% of Available Licenses assigned"
                      >
                        % Licenses
                      </div>
                    </th>

                    <th scope="col" style={{ width: '75px' }}>
                      <div className="slds-truncate" title="Late Payment">
                        Payment
                      </div>
                    </th>

                    <th scope="col" style={{ width: '90px' }}>
                      <div className="slds-truncate" title="AE">
                        AE
                      </div>
                    </th>

                    <th scope="col" style={{ width: '90px' }}>
                      <div className="slds-truncate" title="CSM">
                        CSM
                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {customerOrgs ?
                    customerOrgs.map(o => (
                      <tr key={o._id} className="slds-hint-parent">

                        <th className="id" data-label="Id" scope="row">
                          <div className="slds-truncate" title={o._id}>
                            {o._id}
                          </div>
                        </th>

                        <th data-label="Name" scope="row">
                          <div className="slds-truncate" title={o.name}>
                            {Util.renderAdoptionDashboardLink(o.name, o._id, openAdoptionDashboard)}
                          </div>
                        </th>

                        <th data-label="Tier" scope="row">
                          <div className="slds-truncate" title={o?.tierOrder}>
                            {Util.renderTier(o?.tierOrder)}
                          </div>
                        </th>

                        <th data-label="ARR" scope="row">
                          <div className="slds-truncate" title={currencyFormatter.format(o.ARR)}>
                            {currencyFormatter.format(o.ARR) + ' €'}
                          </div>
                        </th>

                        <th data-label="Edition" scope="row">
                          <div className="slds-truncate" title={o?.edition}>
                            {Util.renderEdition(o?.edition)}
                          </div>
                        </th>

                        <th data-label="Sprint Health Score" scope="row">
                          <div className="slds-truncate" title={o?.sprintHealthScore}>
                            {(o?.sprintHealthScore === null) ?
                              null :
                              Util.renderHealthScoreBlock(
                                o?.sprintHealthScore?.toFixed(0),
                                o?.healthScoreTrend?.toFixed(2),
                                o?.churnProbability,
                                Constants.HS__MAX_SCORE,
                              )}
                          </div>
                        </th>

                        <th data-label="Last Update" scope="row">
                          <div className="slds-truncate" title={o?.lastTouchpoint}>
                            {Util.renderLastTouchpoint(
                              o?.lastTouchpoint?.toFixed(0),
                              o?.sprintHealthScore?.toFixed(2),
                            )}
                          </div>
                        </th>

                        <th data-label="NPS" scope="row">
                          <div className="slds-truncate" title={o?.healthScore?.healthScore}>
                            {o?.npsOverview ?
                              Util.renderNPSRange(Util.calculateAverageNPS(o.npsOverview)) :
                              null}
                          </div>
                        </th>

                        <th data-label="BUs" scope="row">
                          <div className="slds-truncate" title={o?.activeBUsVsLicense}>
                            {renderLicensesUsage(o.activeBUsVsLicense)}
                          </div>
                        </th>

                        <th data-label="% Licenses" scope="row">
                          <div className="slds-truncate" title={o.activeUsersVsLicense}>
                            {renderLicensesUsage(o.activeUsersVsLicense)}
                            {(o?.userLicensesMatch === false) ?
                              (
                                <span
                                  className="slds-icon_container"
                                  title="Admin App and Hubspot licenses mismatch"
                                >
                                  <svg className="slds-icon slds-icon_x-small slds-high-churn-prob">
                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning" />
                                  </svg>
                                </span>
                              ) :
                              null}
                          </div>
                        </th>

                        <th data-label="LatePayment" scope="row">
                          <div className="slds-truncate" title={o?.latePayment}>
                            {(o?.latePayment) ?
                              <span className="slds-badge slds-theme_error">Overdue</span> :
                              null}
                          </div>
                        </th>

                        <th data-label="AE" scope="row">
                          <div className="slds-truncate" title={o.orgAE}>
                            {o.orgAE?.Id ? o.orgAE?.Name.split(' ').shift() : '-'}
                          </div>
                        </th>

                        <th data-label="CSM" scope="row">
                          <div className="slds-truncate" title={o.orgCSM}>
                            {o.orgCSM?.Id ? o.orgCSM?.Name.split(' ').shift() : '-'}
                          </div>
                        </th>

                      </tr>
                    )) :
                    null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="slds-grid">
        <div className="slds-size_1-of-3">
          <div className="slds-box">
            <span className="box title slds-has-flexi-truncate">Health Score</span>
            <div className="box-content chart" style={{ height: '200px' }}>
              {
                  customerHealthScoreChart.series[0] !== 0 ||
                  customerHealthScoreChart.series[1] !== 0 ||
                  customerHealthScoreChart.series[2] !== 0 ?
                    (
                      <ApexChart
                        series={customerHealthScoreChart.series}
                        options={customerHealthScoreChart.options}
                        type="donut"
                        width="100%"
                        height="200px"
                      />
                    ) :
                      <span className="slds-align_absolute-center no-data">No data available</span>
                }
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-3">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate"># Orgs</span>
            <div
              className="box-content number"
              style={{ height: '100px', fontSize: '80px' }}
            >
              {customerOrgs.length}
                &nbsp;
              {Util.renderHealthScoreColor(averageHealthScore?.toFixed(2), Constants.HS__MAX_SCORE)}
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-3">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Bugs</span>
            <div
              className="box-content number"
              style={{ height: '100px', fontSize: '80px' }}
            >
              {openBugs}
            </div>
          </div>
        </div>
      </div>
      <SupportTicketReport
        context={Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD}
        supportTickets={allSupportTickets}
      />
      <NPSReport
        context={Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD}
        promoters={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.promoters, 0)}
        passives={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.passives, 0)}
        detractors={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.detractors, 0)}
        npsResponses={npsResponses}
      />
    </div>
  );
};

export default EngagementDashboard;
