import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const QuestionsAPI = {
  /**
   * Retrieves all questions
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved questions - and `success`
   */
  getQuestions: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/questions`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates a question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} question - question label
   * @param {array} answers - Array with answers
   * @param {number} correctAnswer - index of correct answer
   * @return {object} An object with `data` property - the created question - and `success`
   */
  createQuestion: async (
    cancelToken,
    {
      question,
      answers,
      correctAnswer,
      relatedFeatures,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/questions`, {
        question,
        answers,
        correctAnswer,
        relatedFeatures,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the question you want to update
   * @param {string} question - question label
   * @param {array} answers - Array with answers
   * @param {number} correctAnswer - index of correct answer
   * @return {object} An object with `data` property - the updated question - and `success`
   */
  updateQuestion: async (
    cancelToken,
    id,
    {
      question,
      answers,
      correctAnswer,
      relatedFeatures,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/questions/${id}`, {
        question,
        answers,
        correctAnswer,
        relatedFeatures,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes an exam
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the question you want to delete
   * @returns {object} An object with `data` property - the deleted question - and `success`
   */
  deleteQuestion: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/questions/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default QuestionsAPI;
