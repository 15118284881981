import React, { useState, useContext, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import GuidanceTipsAPI from '../../../../api/guidance-tips';

import Swal from '../../../../helpers/Swal';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import ToggleButton from '../../../shared/ToggleButton/ToggleButton';
import listOfGuidanceTips from '../../../../constants/data/listOfGuidanceTips';

const Tips = ({ validate, setNewButtonDisabled }) => {
  const [results, setResults] = useContext(ResultsContext);
  const { guidanceTips } = results;
  const [state, setState] = useContext(UtilsContext);

  // Guidance Tip State
  const [guidanceTip, setGuidanceTip] = useState({
    tipId: '',
    title: '',
    text: '',
    isEnabled: true,
  });

  const guidanceTipsOptions = listOfGuidanceTips.filter((tip) => {
    const editedTip = guidanceTips?.find(tipElem => tipElem._id === guidanceTip._id);
    if (guidanceTips?.find(t => t.tipId === tip.tipId) && tip.tipId !== editedTip?.tipId) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    if (!guidanceTipsOptions.length) {
      setNewButtonDisabled(true);
    }
    return () => setNewButtonDisabled(false);
  }, [guidanceTipsOptions, setNewButtonDisabled]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setGuidanceTip({
      tipId: '',
      title: '',
      text: '',
      isEnabled: true,
    });
  };

  /**
   * Handle Guidance Tip property update
   * @param {Event} event - Update Event
   * @param {String} property - Property to update
   * @return {void}
   */
  const handleUpdateGuidanceTip = (event, property) => {
    if (event?.persist) {
      event.persist();
    }
    setGuidanceTip(prevState => ({ ...prevState, [property]: event?.target ? event.target.value : event }));
  };

  /**
   * Handler called when saving a Guidance Tip
   * @returns {void}
   */
  const handleSave = async () => {
    const validateResult = validate({
      tipId: guidanceTip.tipId,
      title: guidanceTip.title,
      text: guidanceTip.text,
      isEnabled: guidanceTip.isEnabled,
    });

    if (validateResult.length) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          const newTip = await GuidanceTipsAPI.createGuidanceTip(null, guidanceTip);

          setResults({
            ...results,
            guidanceTips: [
              ...guidanceTips,
              newTip,
            ],
          });

          setState({ ...state, isOpen: false, eventType: '' });
          Swal.fireSuccess('Created!', 'The Tip has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          const updatedTip = await GuidanceTipsAPI.updateTip(null, guidanceTip?._id, guidanceTip);
          setResults({
            ...results,
            guidanceTips: guidanceTips?.map((tip) => {
              if (tip?._id === guidanceTip?._id) {
                return updatedTip;
              }

              return tip;
            }),
          });
          setState({ ...state, isOpen: false, eventType: '' });
          Swal.fireSuccess('Updated!', 'The Tip has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when canceling a Guidance Tip
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing a Guidance Tip
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = guidanceTips.filter(e => e._id === id)[0];
    setGuidanceTip(singleItem);
  };

  /**
   * Handler called when deleting a Guidance Tip
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure to delete this Tip?',
        text: 'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        const { id } = event.target.dataset;
        const { index } = event.target.dataset;

        await GuidanceTipsAPI.deleteGuidanceTip(null, id);

        // Delete results
        const newArray = [...guidanceTips];
        newArray.splice(index, 1);
        setResults({ ...results, guidanceTips: newArray });

        Swal.fireSuccess('Deleted!', 'The Tip has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  const userHasDeleteRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__PRODUCT,
      Constants.USER__PERMISSION__WRITE,
    );

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
      Constants.USER__PERMISSION__WRITE,
    ) ||
    Util.userHasPermission(
      Constants.USER__PERMISSION__PRODUCT,
      Constants.USER__PERMISSION__WRITE,
    ) ||
    Util.userHasPermission(
      Constants.USER__PERMISSION__MARKETING,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <div className="guidance-tip">
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Selector Id">
                Selector Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Title">
                Title
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Status">
                Status
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            {userHasDeleteRights ?
              (
                <th scope="col">
                  <div className="slds-truncate" title="Actions">
                    Actions
                  </div>
                </th>
              ) :
              null}
          </tr>
        </thead>
        <tbody>
          {guidanceTips?.length > 0 ?
            guidanceTips.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Selector Id">
                  <div className="slds-truncate" title={el.tipId}>
                    {el.tipId}
                  </div>
                </td>

                <td data-label="Title">
                  <div className="slds-truncate" title={el.title}>
                    {el.title}
                  </div>
                </td>

                <td data-label="Status">
                  <div className="slds-truncate" title={String(el.isEnabled)}>
                    <span className={classNames('slds-badge', {
                      'slds-theme_error': !el.isEnabled,
                      'slds-theme_success': el.isEnabled,
                    })}
                    >
                      {el.isEnabled ? 'Enabled' : 'Disabled'}
                    </span>
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.createdAt}>
                    {el.createdAt}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.updatedAt}>
                    {el.updatedAt}
                  </div>
                </td>

                <td data-label="Actions">
                  {userHasDeleteRights ?
                    (
                      <div className="slds-truncate" title="Delete">
                        <svg
                          className="slds-icon slds-icon-text-error"
                          aria-hidden="true"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <use
                            xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                            data-id={el._id}
                            data-index={i}
                            onClick={handleDelete}
                            style={{ cursor: 'pointer' }}
                          />
                        </svg>
                      </div>
                    ) :
                    null}
                </td>
              </tr>
            )) :
            (
              <tr>
                <td colSpan="8" className="no-results-found">No results found</td>
              </tr>
            )}
        </tbody>
      </table>
      {/* Dataset Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={state.isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container feature-advert-modal" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {state.eventType === Constants.EVENT__TYPE__NEW ? 'New Tip' : 'Edit Tip'}
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="status-container">
              <span className="status-label">
                {`${guidanceTip?.isEnabled ? 'Enabled' : 'Disabled'}`}
              </span>
              <ToggleButton
                disabled={!userHasEditRights}
                checked={guidanceTip?.isEnabled}
                onChange={() => setGuidanceTip(prevState => ({ ...prevState, isEnabled: !prevState?.isEnabled }))}
              />
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Selector Id
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="select-type"
                      onChange={e => handleUpdateGuidanceTip(e, 'tipId')}
                      value={guidanceTip?.tipId}
                      disabled={!userHasEditRights}
                    >
                      <option defaultValue value="">Select Id</option>
                      {guidanceTipsOptions.map(tip => (
                        <option value={tip.tipId} key={tip.tipId}>{tip.tipId}</option>))}
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-title">
                Title
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-title"
                    placeholder="Subject"
                    className="slds-input"
                    onChange={e => handleUpdateGuidanceTip(e, 'title')}
                    value={guidanceTip?.title}
                    disabled={!userHasEditRights}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-message">
                Text

                <div className="slds-form-element__control">
                  <SunEditor
                    id="form-element-answer"
                    setContents={guidanceTip?.text}
                    onChange={e => handleUpdateGuidanceTip(e, 'text')}
                    autoFocus
                    width="100%"
                    height="300px"
                    setOptions={{
                      buttonList: [
                        ['undo', 'redo'],
                        ['font', 'align'],
                        ['fontSize', 'formatBlock', 'fontColor'],
                        ['bold', 'underline', 'italic', 'strike'],
                        ['link'],
                        ['lineHeight', 'list', 'table', 'fullScreen'],
                      ],
                      linkTargetNewWindow: true,
                    }}
                    readOnly={!userHasEditRights}
                  />
                </div>
              </label>
            </div>

          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              className="slds-button slds-button_brand"
              disabled={!userHasEditRights}
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      {/* Modal Section */}
      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
      {/* Modal Section */}
    </div>
  );
};

Tips.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
  /**
   * Helps to disable the 'New' button
   */
  setNewButtonDisabled: PropTypes.func.isRequired,
};

export default Tips;
