import React from 'react';
import ApexChart from 'react-apexcharts';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

import './style.scss';

const SupportTicketReport = ({
  context,
  supportTickets,
}) => {
  // Ticket Sentiment Chart
  const customerTicketSentimentChart = {
    series: [
      supportTickets.reduce((acc, cur) => acc +
        (cur.sentiment === Constants.ZENDESK_TICKET__SENTIMENT_POSITIVE ? 1 : 0), 0),
      supportTickets.reduce((acc, cur) => acc +
        (!cur.sentiment || cur.sentiment === Constants.ZENDESK_TICKET__SENTIMENT_NEUTRAL ? 1 : 0), 0),
      supportTickets.reduce((acc, cur) => acc +
        (cur.sentiment === Constants.ZENDESK_TICKET__SENTIMENT_NEGATIVE ? 1 : 0), 0),
    ],
    options: {
      labels: ['Positive', 'Neutral', 'Negative'],
      legend: {
        show: false,
      },
      colors: ['#04844B', '#FFB75D', '#C23934'],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: { show: false },
              value: { show: true },
            },
          },
        },
      },
    },
  };

  return (
    <div className="slds-grid">
      <div className="slds-size_1-of-4">
        <div className="slds-box">
          <span className="box title slds-has-flexi-truncate">Ticket Sentiment</span>
          <div className="box-content chart" style={{ height: '200px' }}>
            {
              customerTicketSentimentChart.series[0] !== 0 ||
              customerTicketSentimentChart.series[1] !== 0 ||
              customerTicketSentimentChart.series[2] !== 0 ?
                (
                  <ApexChart
                    series={customerTicketSentimentChart.series}
                    options={customerTicketSentimentChart.options}
                    type="donut"
                    width="100%"
                    height="200px"
                  />
                ) :
                  <span className="slds-align_absolute-center no-data">No data available</span>
            }
          </div>
        </div>
      </div>
      <div className="slds-size_3-of-4">
        <div className="slds-box">
          <span className="box-title slds-has-flexi-truncate">Support Tickets</span>
          <div className="box-content list" style={{ height: '200px', overflowY: 'scroll', marginTop: '0.5rem' }}>
            <table
              className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
              style={{ fontSize: '12px' }}
            >
              <thead>
                <tr className="slds-line-height_reset">
                  <th scope="col" style={{ width: '50px' }}>
                    <div className="slds-truncate" title="Status" />
                  </th>
                  <th scope="col" style={{ width: '40px' }}>
                    <div className="slds-truncate" title="Sentiment" />
                  </th>
                  <th scope="col" style={{ width: '350px' }}>
                    <div className="slds-truncate" title="Subject">
                      Subject
                    </div>
                  </th>
                  {
                    (
                      context === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD ||
                      context === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD
                    ) ?
                      (
                        <th scope="col">
                          <div className="slds-truncate" title="Org">
                            Org
                          </div>
                        </th>
                      ) :
                      null
                  }
                  <th scope="col">
                    <div className="slds-truncate" title="Org">
                      Visitor
                    </div>
                  </th>
                  <th scope="col">
                    <div className="slds-truncate" title="Date">
                      Date
                    </div>
                  </th>
                  <th scope="col">
                    <div className="slds-truncate" title="Type">
                      Type
                    </div>
                  </th>
                  <th scope="col">
                    <div className="slds-truncate" title="Type">
                      Product
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  supportTickets ?
                    supportTickets.map(ticket => (
                      <tr key={ticket.ticketId} className="slds-hint-parent">
                        <th data-label="Status" score="row">
                          <div className="slds-truncate" title={ticket.status}>
                            {Util.renderSupportTicketStatus(ticket.status)}
                          </div>
                        </th>
                        <th data-label="Sentiment" score="row">
                          <div className="slds-truncate" title={ticket.sentiment}>
                            {Util.renderSupportTicketSentiment(ticket.sentiment)}
                          </div>
                        </th>
                        <th data-label="Subject" scope="row">
                          <div className="slds-truncate" title={ticket.subject}>
                            <a
                              href={'https://deselect.zendesk.com/agent/tickets/' + ticket.ticketId}
                              target="_blank"
                              // eslint-disable-next-line spellcheck/spell-checker
                              rel="noreferrer"
                            >
                              {ticket.subject}
                            </a>
                          </div>
                        </th>
                        {
                          (
                            context === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD ||
                            context === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD
                          ) ?
                            (
                              <th data-label="Org" scope="row">
                                <div className="slds-truncate" title={ticket.org}>
                                  {Util.renderSupportTicketRequester(ticket.org)}
                                </div>
                              </th>
                            ) :
                            null
                        }
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={ticket.visitorName}>
                            {Util.renderSupportTicketRequester(ticket.visitorName)}
                          </div>
                        </th>
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={ticket.openDate}>
                            {Util.beautifyDate(ticket.openDate)}
                          </div>
                        </th>
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={ticket.type}>
                            {Util.renderSupportTicketType(ticket.type)}
                          </div>
                        </th>
                        <th data-label="Visitor" scope="row">
                          <div className="slds-truncate" title={ticket.type}>
                            {Util.renderSupportTicketProduct(ticket.product)}
                          </div>
                        </th>
                      </tr>
                    )) :
                    null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketReport;
