import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const NotificationsAPI = {
  /**
   * Retrieves all notifications
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved notifications - and `success`
   */
  getNotifications: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/notifications`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Creates a notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} title - Title of the notification
   * @param {string} message - Message of the notification
   * @param {string} type - Type of the notification
   * @param {boolean} isDismissible - True is the notification should be dismissible
   * @param {object} startDate - When should the notification start to show up for the users
   * @param {object} endDate - When should the notification not show up anymore for the users
   * @return {object} An object with `data` property - the created notification - and `success`
   */
  createNotification: async (
    cancelToken,
    {
      title,
      message,
      type,
      isDismissible,
      startDate,
      endDate,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/notifications`, {
        title,
        type,
        message,
        isDismissible,
        startDate,
        endDate,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates a notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the notification you want to update
   * @param {string} title - Title of the notification
   * @param {string} message - Message of the notification
   * @param {string} type - Type of the notification
   * @param {boolean} isDismissible - True is the notification should be dismissible
   * @param {object} startDate - When should the notification start to show up for the users
   * @param {object} endDate - When should the notification not show up anymore for the users
   * @return {object} An object with `data` property - the updated notification - and `success`
   */
  updateNotification: async (
    cancelToken,
    id,
    {
      title,
      message,
      type,
      isDismissible,
      startDate,
      endDate,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/notifications/${id}`, {
        title,
        message,
        type,
        isDismissible,
        startDate,
        endDate,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes a notification
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the notification you want to delete
   * @returns {object} An object with `data` property - the deleted notification - and `success`
   */
  deleteNotification: async (cancelToken, id) => {
    const res = await axios.post(
      `${apiUrl}/admin/notifications/${id}/delete`, null, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default NotificationsAPI;
