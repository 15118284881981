import React, { useEffect, useContext } from 'react';
import ApexChart from 'react-apexcharts';

import { ResultsContext } from '../../../../ResultsContext';
import StatsAPI from '../../../../api/stats';
import Util from '../../../../util';

const SFMCQuickSearch = () => {
  const [results, setResults] = useContext(ResultsContext);
  const { dashboardData } = results;

  // Dashboard variable
  const UPDATE_EVERY_X_SECONDS = 300; // Update the stats every X seconds -> 5 minutes

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    // Setup interval
    const interval = setInterval(async () => {
      try {
        const res = await StatsAPI.getChromeExtensionStats(null);
        setResults({ ...results, dashboardData: res.data });
      } catch (error) {
        clearInterval(interval); // Once an error happens, stop searching anymore
        Util.handleError(error);
      }
    }, UPDATE_EVERY_X_SECONDS * 1000);
      // When this component is not needed anymore, destroy the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResults]);

  // Data from result
  const {
    // Chrome Extension
    newPluginUsers7Days = 0,
    newPluginUsers14Days = 0,
    newPluginUsers30Days = 0,
    numberOfActivePluginUsers = 0,
    numberOfSearchesPerDay = [],
    numberOfSearchesPerWeek = [],
    numberOfActiveUsersPerDay = [],
    numberOfActiveUsersPerWeek = [],
  } = dashboardData;

  /**
   * Generate all needed data for the charts in the Plugin section
   * @param {string} label - Label to display in the graph, will displayed like 'label Per Day/Week'
   * @param {array<object>} dataPerDay - The data per day, must have two properties
   *  - `_id`: { `day`, `month`, `year` }, all three are either string or number
   *  - `count` a number
   * @param {array<object>} dataPerWeek - The data per week, must have two properties
   *  - `_id`: { `week` }, `week` is either a string of number, ranged from 0 to 53
   *  - `count` a number
   * @returns {object} An object with the `seriesDay`, `seriesWeek` and `optionsDay`,`optionsWeek` properties
   */
  const pluginChartOption = (label, dataPerDay, dataPerWeek) => ({
    seriesDay: [{
      name: `${label}`,
      data: dataPerDay.map(d => ({
        x: new Date(d._id.year, d._id.month - 1, d._id.day).getTime(),
        y: d.count,
      })),
    }],
    optionsDay: {
      // eslint-disable-next-line spellcheck/spell-checker
      chart: { zoom: { autoScaleYaxis: true } },
      dataLabels: { enabled: false },
      stroke: { width: 2 },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      // eslint-disable-next-line spellcheck/spell-checker
      yaxis: { title: { text: `${label} Per Day` } },
      // eslint-disable-next-line spellcheck/spell-checker
      xaxis: { type: 'datetime' },
    },
    seriesWeek: [{
      data: dataPerWeek.map(({ count }) => count),
      name: label,
    }],
    optionsWeek: {
      plotOptions: { bar: { distributed: true, columnWidth: dataPerWeek.length > 1 ? '70%' : '35%' } },
      dataLabels: { enabled: false },
      legend: { show: false },
      // eslint-disable-next-line spellcheck/spell-checker
      xaxis: { categories: dataPerWeek.map(({ _id }) => `Week ${_id.week + 1}`) },
    },
  }
  );

  const pluginSearch = pluginChartOption('Searches', numberOfSearchesPerDay, numberOfSearchesPerWeek);
  const pluginActiveUser = pluginChartOption('Active Users', numberOfActiveUsersPerDay, numberOfActiveUsersPerWeek);

  return (
    <div className="dashboard">
      <div className="slds-grid">
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active users</span>
            <div className="box-content number">{numberOfActivePluginUsers}</div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">New sign-ups in last 7 days</span>
            <div className="box-content number">{newPluginUsers7Days}</div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">New sign-ups in last 14 days</span>
            <div className="box-content number">{newPluginUsers14Days}</div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">New sign-ups in last 30 days</span>
            <div className="box-content number">{newPluginUsers30Days}</div>
          </div>
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Searches Per Day</span>
            <div className="box-content chart">
              {
                pluginSearch.seriesDay[0].data.length ?
                  (
                    <ApexChart
                      series={pluginSearch.seriesDay}
                      options={pluginSearch.optionsDay}
                      type="area"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Users Per Day</span>
            <div className="box-content chart">
              {
                pluginActiveUser.seriesDay[0].data.length ?
                  (
                    <ApexChart
                      series={pluginActiveUser.seriesDay}
                      options={pluginActiveUser.optionsDay}
                      type="area"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Searches Per Week</span>
            <div className="box-content chart">
              {
                pluginSearch.seriesWeek[0].data.length ?
                  (
                    <ApexChart
                      series={pluginSearch.seriesWeek}
                      options={pluginSearch.optionsWeek}
                      type="bar"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Users Per Week</span>
            <div className="box-content chart">
              {
                pluginActiveUser.seriesWeek[0].data.length ?
                  (
                    <ApexChart
                      series={pluginActiveUser.seriesWeek}
                      options={pluginActiveUser.optionsWeek}
                      type="bar"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SFMCQuickSearch;
