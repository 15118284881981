import React, {
  useState,
  useEffect,
} from 'react';

import asanaApi from '../../../../api/asana';
import adminusersApi from '../../../../api/adminusers';
import Util from '../../../../util';

import './style.scss';
import Constants from '../../../../constants/constants';

const AsanaTasks = () => {
  const [customerName, setCustomerName] = useState();
  const [csm, setCsm] = useState();
  const [csmUsers, setCsmUsers] = useState([]);
  const [product, setProduct] = useState();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const availableProducts = [
    Constants.DESELECT_SEGMENT,
    Constants.DESELECT_ENGAGE,
  ];

  /**
   * Get all available CSM
   * @returns {void}
   */
  const getCsmUsers = async () => {
    const res = await adminusersApi.getCsmUsers();
    setCsmUsers(res.data);
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    try {
      getCsmUsers();
    } catch (error) {
      Util.handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCsmUsers]);

  /**
   * Handle changing Name input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeCustomerName = (e) => {
    setCustomerName(e.target.value);
  };

  /**
   * Handle changing CSM input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeCSM = (e) => {
    setCsm(e.target.value);
  };

  /**
   * Handle changing Product input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeProduct = (e) => {
    setProduct(e.target.value);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);

    try {
      asanaApi.createCsmTasks(customerName, csm, product);
      // eslint-disable-next-line no-alert
      alert('Tasks created started successfully. Check Asana to confirm creation of the tasks.');
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error creating tasks: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  return (
    <>
      Create all Customer Success template tasks for a new customer
      <br />

      <form id="form" onSubmit={submit}>

        <div className="slds-form-element">
          <div className="slds-form-element__label" htmlFor="criteria">
            Fill out the name of the customer and the CSM below, and all CSM related tasks
            will be created in Asana on the board of the CSM.
          </div>

          <label className="slds-form-element__label" htmlFor="name">Customer Name</label>
          <div className="slds-form-element__control">
            <input id="name" onChange={e => handleChangeCustomerName(e)} size="50" required />
          </div>

          <label className="slds-form-element__label" htmlFor="csm"> CSM</label>
          <div className="slds-form-element__control">
            <select
              id="csm"
              style={{ width: '300px' }}
              className="slds-select"
              onChange={e => handleChangeCSM(e)}
              required
            >
              <option value="" defaultValue>Please select</option>
              {csmUsers ?
                (csmUsers || []).map(user => (
                  <option key={user.username} value={user.username}>{user.username}</option>
                )) :
                null}
            </select>
          </div>

          <label className="slds-form-element__label" htmlFor="csm"> Product</label>
          <div className="slds-form-element__control">
            <select
              id="product"
              style={{ width: '300px' }}
              className="slds-select"
              onChange={e => handleChangeProduct(e)}
              required
            >
              <option value="" defaultValue>Please select</option>
              {availableProducts ?
                (availableProducts || []).map(productOption => (
                  <option key={productOption} value={productOption}>{productOption}</option>
                )) :
                null}
            </select>
          </div>
        </div>

        <br />

        <button type="submit" className="slds-button slds-button_brand" id="submit" disabled={submitButtonDisabled}>
          Submit
        </button>

      </form>
    </>
  );
};

export default AsanaTasks;
