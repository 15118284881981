import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const InstallDemoDataAPI = {
  /**
   * Updates a user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} settings - Installation settings
   * @param {string} settings.dateFormat - format in which dates need to be uploaded
   * @param {boolean} settings.installTrainingDEs - whether training DEs should also be installed
   * @param {string} settings.userId - Id of the user in which name the upload will happen
   * @param {string} settings.product - Are we installing demo data package for Segment or Engage?
   * @param {array} settings.businessUnits - Business Units to install demo data to
   * @return {object} An object with `result` property - always true - and `success`
   */
  install: async (cancelToken, settings) => {
    const body = {
      ...settings,
    };
    const res = await axios.post(
      `${apiUrl}/admin/install-demo-data`,
      body,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default InstallDemoDataAPI;
