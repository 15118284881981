import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const FeatureAdvertsAPI = {
  /**
   * Retrieves all feature adverts
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved feature adverts - and `success`
   */
  getAdverts: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/feature-adverts`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Creates a feature advert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} title - Title of the feature advert
   * @param {string} message - Message of the feature advert
   * @param {string} type - Type of the feature advert
   * @param {string} recipient - Recipient of the feature advert (all or specific organization)
   * @param {array} organisations - Specific organizations to send email to
   * @return {object} An object with `data` property - the created feature advert - and `success`
   */
  createAdvert: async (
    cancelToken,
    {
      title,
      media,
      mediaType,
      description,
      feature,
      status,
    },
  ) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('media', media);
    formData.append('mediaType', mediaType);
    formData.append('description', description);
    formData.append('feature', feature);
    formData.append('status', status);
    const res = await axios.post(
      `${apiUrl}/admin/feature-adverts`, formData, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates a feature advert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the feature advert you want to update
   * @param {string} title - Title of the feature advert
   * @param {string} message - Message of the feature advert
   * @param {string} type - Type of the feature advert
   * @return {object} An object with `data` property - the updated feature advert - and `success`
   */
  updateAdvert: async (
    cancelToken,
    id,
    {
      title,
      media,
      mediaType,
      description,
      feature,
      status,
    },
  ) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('media', media);
    formData.append('mediaType', mediaType);
    formData.append('description', description);
    formData.append('feature', feature);
    formData.append('status', status);
    const res = await axios.put(
      `${apiUrl}/admin/feature-adverts/${id}`, formData, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Deletes an feature advert
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the feature advert you want to delete
   * @returns {object} An object with `data` property - the deleted feature advert - and `success`
   */
  deleteAdvert: async (cancelToken, id) => {
    const res = await axios.post(
      `${apiUrl}/admin/feature-adverts/${id}/delete`, null, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default FeatureAdvertsAPI;
