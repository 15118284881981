import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ChangeLogsAPI = {
  /**
   * Get changelogs
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - Criteria to filter on
   * @returns {object} An object with `data` property - the retrieved changelogs - and `success`
   */
  getChangeLogs: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/changelogs`, {
        criteria,
      },
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default ChangeLogsAPI;
