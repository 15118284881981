import React, { useContext, useState } from 'react';

import { MenuContext } from '../../../MenuContext';
import { UtilsContext } from '../../../UtilsContext';
import { ResultsContext } from '../../../ResultsContext';

import DashboardDataset from '../DataSets/Dashboard/DashboardDataset';
import AdoptionDashboard from '../DataSets/AdoptionDashboard/AdoptionDashboard';
import ChangeLogsDataset from '../DataSets/ChangeLogs/ChangeLogsDataset';
import SelectionRunLogsDataSet from '../DataSets/SelectionRunLogs/SelectionRunLogsDataSet';
import OrganisationsDataset from '../DataSets/Organisations/OrganisationsDataset';
import AdminUsersDataset from '../DataSets/AdminUsers/AdminUsersDataset';
import UsersDataset from '../DataSets/Users/UsersDataset';
import NotificationsDataset from '../DataSets/Notifications/NotificationsDataset';
import Alerts from '../DataSets/Alerts/Alerts';
import CertificatesDataset from '../DataSets/Certificates/CertificatesDataset';
import UserNotificationsDataset from '../DataSets/UserNotifications/UserNotificationsDataset';
import DemoDataPackage from '../DataSets/DemoDataPackage/DemoDataPackage';
import SFMCQuickSearch from '../DataSets/SFMCQuickSearch/SFMCQuickSearch';
import SearchHomeMessage from '../DataSets/SFMCQuickSearch/SearchHomeMessage';
import Features from '../DataSets/Features/Features';
import SetTestToken from '../DataSets/SetTestToken/SetTestToken';
import AsanaTasks from '../DataSets/AsanaTasks/AsanaTasks';
import CreateInvoice from '../DataSets/CreateInvoice/CreateInvoice';
import SFMCUserOnboarding from '../DataSets/SFMCUserOnboarding/SFMCUserOnboarding';
import SFMCUserOffboarding from '../DataSets/SFMCUserOffboarding/SFMCUserOffboarding';
import StampDeal from '../DataSets/StampDeal/StampDeal';
import CurrencyRates from '../DataSets/CurrencyRates/CurrencyRates';
import Settings from '../DataSets/Settings/Settings';
import Tips from '../DataSets/Tips/Tips';

import AdminUsersAPI from '../../../api/adminusers';
import UsersAPI from '../../../api/users';
import OrganisationsAPI from '../../../api/organisations';
import ChangeLogsAPI from '../../../api/changelogs';
import SelectionRunLogsAPI from '../../../api/selectionRunLogs';
import NotificationsAPI from '../../../api/notifications';
import UserNotificationsAPI from '../../../api/user-notifications';

import Constants from '../../../constants/constants';

import './style.scss';
import Util from '../../../util';
import CertificationsUsers from '../DataSets/AcademyCertifications/CertificationsUsers';
import Exams from '../DataSets/AcademyCertifications/Exams';
import Questions from '../DataSets/AcademyCertifications/Questions';
import QuestionsAPI from '../../../api/academyCertifications/questions';
import ExamsAPI from '../../../api/academyCertifications/exams';
import CertificationsUserAPI from '../../../api/academyCertifications/certifications-users';
import GeneratedExamsAPI from '../../../api/academyCertifications/generatedExams';
import GeneratedExams from '../DataSets/AcademyCertifications/GeneratedExams';
import TopicQuestions from '../DataSets/AcademyTrainings/TopicQuestions';
import Trainings from '../DataSets/AcademyTrainings/Trainings';
import Modules from '../DataSets/AcademyTrainings/Modules';
import Topics from '../DataSets/AcademyTrainings/Topics';
import CustomerQuestions from '../DataSets/AcademyTrainings/CustomerQuestions';
import Overview from '../DataSets/AcademyTrainings/Overview';
import TrainingsAPI from '../../../api/academyTrainings/trainings';
import TrainingResultsAPI from '../../../api/academyTrainings/trainingResults';
import ModulesAPI from '../../../api/academyTrainings/modules';
import TopicsAPI from '../../../api/academyTrainings/topics';
import TopicQuestionsAPI from '../../../api/academyTrainings/topicQuestions';
import CustomerQuestionsAPI from '../../../api/academyTrainings/customerQuestions';
import EngagementDashboard from '../DataSets/Engagement Dashboard/EngagementDashboard';
import EssentialsDashboard from '../DataSets/Engagement Dashboard/EssentialsDashboard';

const RightNav = () => {
  const menuContext = useContext(MenuContext);
  const [menuSelected] = menuContext.menuSelected;
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const [refreshing, setRefreshing] = useState(false);
  const [newButtonDisabled, setNewButtonDisabled] = useState(false);

  /**
   * Validate an object so ensure no property is empty
   * @param {object} properties - The object to check
   * @returns {array} - All the errors `Please enter PROPERTY_NAME` as string
   */
  const validate = (properties) => {
    const errors = [];
    Object.entries(properties).forEach((property) => {
      if (String(property[1]).trim() === '') errors.push(`Please enter ${property[0]}`);
    });
    return errors;
  };

  /**
   * Handler called when click on the New button
   * @returns {void}
   */
  const handleNewButtonClicked = () => {
    setState({ ...state, isOpen: true, eventType: Constants.EVENT__TYPE__NEW });
  };

  /**
   * Handler called when click on the Access to Certification button
   * @returns {void}
   */
  const handleAccessToCertificationButtonClicked = () => {
    setState({ ...state, isOpen: true, eventType: Constants.EVENT__TYPE__UPDATE_MANY });
  };

  /**
   * Handler called when click on the Features Picklist button
   * @returns {void}
   */
  const handleFeaturesPicklistButtonClicked = () => {
    setState({ ...state, isOpen: true, eventType: Constants.EVENT__TYPE__FEATURES_PICKLIST });
  };

  /**
   * Handles the refreshing of DataSets
   * @returns {void}
   */
  const handleRefreshButtonClicked = async () => {
    // Set refresh state to true
    setRefreshing(true);
    const valuesToUpdate = {};
    if (menuSelected === Constants.MENU__NAME__CHANGELOG) {
      // Get data
      const res = await ChangeLogsAPI.getChangeLogs(null);
      valuesToUpdate.changeLogsData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__SELECTION_RUN_LOG) {
      // Get data
      const res = await SelectionRunLogsAPI.getSelectionRunLogs(null);
      valuesToUpdate.selectionRunLogsData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__ORGANISATION) {
      // Get data
      const res = await OrganisationsAPI.getOrganisations(null);
      valuesToUpdate.organisationsData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__ADMIN_USERS) {
      // Get data
      const res = await AdminUsersAPI.getAdminUsers(null);
      valuesToUpdate.adminUsersData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__USERS) {
      // Get data
      const res = await UsersAPI.getUsers(null);
      const orgs = await OrganisationsAPI.getOrganisations(null);
      valuesToUpdate.usersData = res.users;
      valuesToUpdate.organisationsData = orgs.data;
    } else if (menuSelected === Constants.MENU__NAME__NOTIFICATION) {
      // Get data
      const res = await NotificationsAPI.getNotifications(null);
      valuesToUpdate.notificationsData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__ALERTS) {
      // Get data
      const res = await NotificationsAPI.getNotifications(null);
      valuesToUpdate.notificationsData = res.data;
    } else if (menuSelected === Constants.MENU__NAME__USER_NOTIFICATION) {
      // Get data
      const res = await UserNotificationsAPI.getUserNotifications(null);
      const notifications = await NotificationsAPI.getNotifications(null);
      const users = await UsersAPI.getUsers(null);
      valuesToUpdate.userNotificationsData = res.data;
      valuesToUpdate.notificationsData = notifications.data;
      valuesToUpdate.usersData = users.users;
      setState({
        ...state,
        notifications: notifications.data,
        users: users.users,
      });
    } else if (menuSelected === Constants.MENU__NAME__TIPS) {
      // Get data
      const res = await UserNotificationsAPI.getUserNotifications(null);
      const notifications = await NotificationsAPI.getNotifications(null);
      const users = await UsersAPI.getUsers(null);
      valuesToUpdate.userNotificationsData = res.data;
      valuesToUpdate.notificationsData = notifications.data;
      valuesToUpdate.usersData = users.users;
      setState({
        ...state,
        notifications: notifications.data,
        users: users.users,
      });
    } else if (menuSelected === Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS) {
      // Get data
      const res = await QuestionsAPI.getQuestions(null);
      valuesToUpdate.questions = res.data;
      setState({
        ...state,
        questions: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__CERTIFICATIONS_EXAMS) {
      // Get data
      const res = await ExamsAPI.getExams(null);
      valuesToUpdate.exams = res.data;
      setState({
        ...state,
        exams: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__CERTIFICATIONS_USERS) {
      // Get data
      const res = await CertificationsUserAPI.getCertificationUsers(null);
      valuesToUpdate.certificationsUsersData = res.data;
      setState({
        ...state,
        certificationsUsersData: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__CERTIFICATIONS_GENERATED_EXAMS) {
      // Get data
      const res = await GeneratedExamsAPI.getGeneratedExams(null);

      valuesToUpdate.certificationsGeneratedExams = res.data;
      setState({
        ...state,
        certificationsGeneratedExams: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_OVERVIEW) {
      // get training results
      const res = await TrainingResultsAPI.getTrainingResults(null, {});

      valuesToUpdate.trainingResults = res.data;
      setState({
        ...state,
        trainingResults: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_TRAININGS) {
      // get trainings
      const res = await TrainingsAPI.getAllTrainings(null);

      valuesToUpdate.trainings = res.data;
      setState({
        ...state,
        trainings: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_MODULES) {
      // get modules
      const res = await ModulesAPI.getAllModules(null);

      valuesToUpdate.modules = res.data;
      setState({
        ...state,
        modules: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPICS) {
      // get topics
      const res = await TopicsAPI.getAllTopics(null);

      valuesToUpdate.topics = res.data;
      setState({
        ...state,
        topics: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPIC_QUESTIONS) {
      // get topic questions
      const res = await TopicQuestionsAPI.getAllTopicQuestions(null);

      valuesToUpdate.topicQuestions = res.data;
      setState({
        ...state,
        topicQuestions: res.data,
      });
    } else if (menuSelected === Constants.MENU__NAME__SELF_LEARNING_CUSTOMER_QUESTIONS) {
      // get customer questions
      const res = await CustomerQuestionsAPI.getCustomerQuestions(null, {});

      valuesToUpdate.customerQuestions = res.data;
      setState({
        ...state,
        customerQuestions: res.data,
      });
    }

    // Set data
    setResults({ ...results, ...valuesToUpdate });
    // Set refresh state to false
    setRefreshing(false);
  };

  /**
   * Render the NEW button if this function returns true
   * @returns {boolean} True if the NEW button should be rendered, false otherwise
   */
  const shouldRenderNewButton = () => (
    // CSM write permissions
    (
      Util.userHasPermission(
        Constants.USER__PERMISSION__CSM,
        Constants.USER__PERMISSION__WRITE,
      ) && (
        menuSelected === Constants.MENU__NAME__ORGANISATION ||
        menuSelected === Constants.MENU__NAME__NOTIFICATION ||
        menuSelected === Constants.MENU__NAME__USER_NOTIFICATION ||
        menuSelected === Constants.MENU__NAME__TIPS ||
        menuSelected === Constants.MENU__NAME__ALERTS
      )) ||
    // Product write permissions
    (
      Util.userHasPermission(
        Constants.USER__PERMISSION__PRODUCT,
        Constants.USER__PERMISSION__WRITE,
      ) && (
        menuSelected === Constants.MENU__NAME__TIPS
      )) ||
    // Marketing write permissions
    (
      Util.userHasPermission(
        Constants.USER__PERMISSION__MARKETING,
        Constants.USER__PERMISSION__WRITE,
      ) && (
        menuSelected === Constants.MENU__NAME__TIPS
      )) ||
    // Setup write permissions
    (
      Util.userHasPermission(
        Constants.USER__PERMISSION__SETUP,
        Constants.USER__PERMISSION__WRITE,
      ) &&
      menuSelected === Constants.MENU__NAME__ADMIN_USERS
    ) ||
    // Academy write permissions
    (Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    ) &&
      (menuSelected === Constants.MENU__NAME__CERTIFICATIONS_USERS ||
        menuSelected === Constants.MENU__NAME__CERTIFICATIONS_EXAMS ||
        menuSelected === Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS ||
        menuSelected === Constants.MENU__NAME__SELF_LEARNING_TRAININGS ||
        menuSelected === Constants.MENU__NAME__SELF_LEARNING_MODULES ||
        menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPICS ||
        menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPIC_QUESTIONS)
    )
  );

  /**
   * Render the Refresh button if this function returns true
   * @returns {boolean} True if the Refresh button should be rendered, false otherwise
   */
  const shouldRenderRefreshButton = () => (
    menuSelected !== Constants.MENU__NAME__DEMO_DATA_PACKAGE &&
    menuSelected !== Constants.MENU__NAME__FEATURE &&
    menuSelected !== Constants.MENU__NAME__SET_TEST_TOKEN &&
    menuSelected !== Constants.MENU__NAME__DASHBOARD &&
    menuSelected !== Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD &&
    menuSelected !== Constants.MENU__NAME__ADOPTION_DASHBOARD &&
    menuSelected !== Constants.MENU__NAME__SFMC_QUICK_SEARCH &&
    menuSelected !== Constants.MENU__NAME__CERTIFICATES &&
    menuSelected !== Constants.MENU__NAME__INTEGRATIONS &&
    menuSelected !== Constants.MENU__NAME__RELATIONS &&
    menuSelected !== Constants.MENU__NAME__ASANA_TASKS &&
    menuSelected !== Constants.MENU__NAME__SFMC_USER_SETUP &&
    menuSelected !== Constants.MENU__NAME__SFMC_OFFBOARDING &&
    menuSelected !== Constants.MENU__NAME__CREATE_INVOICE &&
    menuSelected !== Constants.MENU__NAME__STAMP_DEAL &&
    menuSelected !== Constants.MENU__NAME__SETTINGS &&
    menuSelected !== Constants.MENU__NAME__CURRENCY_RATES
  );

  return (
    <div style={{ display: menuSelected === '' ? 'none' : '' }}>
      {/* Menu Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered"
        style={{ marginBottom: '10px' }}
      >
        <thead>
          <tr>
            <th style={{ height: '40px' }}>{menuSelected}</th>
            <th style={{ textAlign: 'right', fontSize: '18px' }}>
              {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_USERS && (
                <button
                  type="button"
                  onClick={handleAccessToCertificationButtonClicked}
                  className="slds-button slds-button_brand"
                  style={{ width: '180px' }}
                >
                  Access to Certification
                </button>
              )}
              {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS && (
                <button
                  type="button"
                  onClick={handleFeaturesPicklistButtonClicked}
                  className="slds-button slds-button_brand"
                  style={{ width: '180px' }}
                >
                  Features Picklist
                </button>
              )}
              {shouldRenderNewButton() && (
                <button
                  type="button"
                  onClick={handleNewButtonClicked}
                  className="slds-button slds-button_brand"
                  style={{ width: '150px' }}
                  disabled={newButtonDisabled}
                >
                  {menuSelected === Constants.MENU__NAME__ALERTS ? 'Create Alert' : 'New'}
                </button>
              )}
              {shouldRenderRefreshButton() && (
                <button
                  type="button"
                  className="slds-button slds-button_icon slds-button_icon-brand"
                  id="refresh-button"
                  onClick={handleRefreshButtonClicked}
                  title={`Refresh ${menuSelected}`}
                >
                  <svg
                    aria-hidden="true"
                    className={`slds-button__icon ${refreshing ?
                      'fa-spin' :
                      ''
                    }`}
                  >
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
                  </svg>
                </button>
              )}
            </th>
          </tr>
        </thead>
      </table>

      {/* Menu Section */}

      {/* Dataset Section */}
      {menuSelected === Constants.MENU__NAME__DASHBOARD && <DashboardDataset />}
      {menuSelected === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD && <EngagementDashboard />}
      {menuSelected === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD && <EssentialsDashboard />}
      {menuSelected === Constants.MENU__NAME__ADOPTION_DASHBOARD && <AdoptionDashboard />}
      {menuSelected === Constants.MENU__NAME__CHANGELOG && <ChangeLogsDataset />}
      {menuSelected === Constants.MENU__NAME__SELECTION_RUN_LOG && <SelectionRunLogsDataSet />}
      {menuSelected === Constants.MENU__NAME__ORGANISATION && <OrganisationsDataset />}
      {menuSelected === Constants.MENU__NAME__ADMIN_USERS && <AdminUsersDataset validate={validate} />}
      {menuSelected === Constants.MENU__NAME__USERS && <UsersDataset />}
      {menuSelected === Constants.MENU__NAME__NOTIFICATION && <NotificationsDataset validate={validate} />}
      {menuSelected === Constants.MENU__NAME__ALERTS && <Alerts validate={validate} />}
      {menuSelected === Constants.MENU__NAME__USER_NOTIFICATION && <UserNotificationsDataset validate={validate} />}
      {menuSelected === Constants.MENU__NAME__TIPS && (
      <Tips
        validate={validate}
        setNewButtonDisabled={setNewButtonDisabled}
      />
      )}
      {menuSelected === Constants.MENU__NAME__SETTINGS && <Settings />}
      {menuSelected === Constants.MENU__NAME__DEMO_DATA_PACKAGE && <DemoDataPackage />}
      {menuSelected === Constants.MENU__NAME__CERTIFICATES && <CertificatesDataset />}
      {menuSelected === Constants.MENU__NAME__SET_TEST_TOKEN && <SetTestToken />}
      {menuSelected === Constants.MENU__NAME__FEATURE && <Features />}
      {menuSelected === Constants.MENU__NAME__ASANA_TASKS && <AsanaTasks />}
      {menuSelected === Constants.MENU__NAME__CREATE_INVOICE && <CreateInvoice />}
      {menuSelected === Constants.MENU__NAME__SFMC_USER_SETUP && <SFMCUserOnboarding />}
      {menuSelected === Constants.MENU__NAME__SFMC_OFFBOARDING && <SFMCUserOffboarding />}
      {menuSelected === Constants.MENU__NAME__STAMP_DEAL && <StampDeal />}
      {menuSelected === Constants.MENU__NAME__CURRENCY_RATES && <CurrencyRates />}
      {menuSelected === Constants.MENU__NAME__SFMC_QUICK_SEARCH && <SFMCQuickSearch />}
      {menuSelected === Constants.MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE && <SearchHomeMessage />}
      {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_USERS && <CertificationsUsers validate={validate} />}
      {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_EXAMS && <Exams validate={validate} />}
      {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS && <Questions validate={validate} />}
      {menuSelected === Constants.MENU__NAME__CERTIFICATIONS_GENERATED_EXAMS && <GeneratedExams />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPIC_QUESTIONS && <TopicQuestions />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_TRAININGS && <Trainings />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_MODULES && <Modules />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_TOPICS && <Topics />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_CUSTOMER_QUESTIONS && <CustomerQuestions />}
      {menuSelected === Constants.MENU__NAME__SELF_LEARNING_OVERVIEW && <Overview />}
      {/* Dataset Section */}
    </div>
  );
};
export default RightNav;
