import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ExactApi = {
  /**
   * Create Invoice for deal
   * @param {number} dealId - Hubspot Id of Deal to create Invoice for in Exact
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved certificates - and `success`
   */
  createInvoice: async (dealId, cancelToken) => {
    const res = await axios.post(
      `${apiUrl}/admin/exact/create-invoice/${dealId}`,
      {},
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.invoice;
  },

  /**
   * Check if there is an active session with ExactOnline for the current adminUser
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved certificates - and `success`
   */
  checkActiveUserSession: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/exact/check-active-user-session`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.validSession;
  },

  /**
   * Check if there is an active session with ExactOnline for the current adminUser
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved certificates - and `success`
   */
  getOAuth2LoginURL: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/exact/oauth2-login-url`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.url;
  },

};

export default ExactApi;
