/* eslint-disable spellcheck/spell-checker */
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { MenuContext } from '../../../MenuContext';
import { ResultsContext } from '../../../ResultsContext';
import { UtilsContext } from '../../../UtilsContext';

import AuthAPI from '../../../api/auth';
import StatsAPI from '../../../api/stats';
import ChangeLogsAPI from '../../../api/changelogs';
import OrganisationsAPI from '../../../api/organisations';
import NotificationsAPI from '../../../api/notifications';
import AlertsAPI from '../../../api/alert-users';
import UserNotificationsAPI from '../../../api/user-notifications';
import CertificatesAPI from '../../../api/certificates';
import IntegrationsAPI from '../../../api/integrations';
import RelationsAPI from '../../../api/relations';
import AdminUsersAPI from '../../../api/adminusers';
import UsersAPI from '../../../api/users';
import SelectionRunLogsAPI from '../../../api/selectionRunLogs';
import SettingsApi from '../../../api/settings';
import ExamsAPI from '../../../api/academyCertifications/exams';
import CertificationsUserAPI from '../../../api/academyCertifications/certifications-users';
import GeneratedExamsAPI from '../../../api/academyCertifications/generatedExams';

import './style.scss';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import Nav from '../../../nav';
import FeatureAdvertsAPI from '../../../api/feature-adverts';
import GuidanceTipsAPI from '../../../api/guidance-tips';

const LeftNav = () => {
  const menuContext = useContext(MenuContext);
  const [menuSelected, setMenuSelected] = menuContext.menuSelected;
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);

  const history = useHistory();
  /**
   * Handle logout process
   * @param {object} event - A JS Event
   * @returns {void}
   */
  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      await AuthAPI.logout();
    } catch (error) {
      Cookies.remove(Constants.AUTH__COOKIE_NAME);
    }
    history.push(Constants.ROUTE__LOGIN);
  };

  /**
   * Handle click on link
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleLink = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    setMenuSelected(linkName);
    const {
      dashboardData,
      engagementDashboardData,
      essentialsDashboardData,
      adminUsersData,
      usersData,
      organisationsData,
      changeLogsData,
      notificationsData,
      guidanceTips,
      alertsData,
      userNotificationsData,
      certificatesData,
      integrationsData,
      relationsData,
      selectionRunLogsData,
      certificationsUsersData,
      certificationsGeneratedExams,
      exams,
    } = results;

    try {
      if (linkName === Constants.MENU__NAME__DASHBOARD) {
        // If data has not been obtained before
        if (dashboardData && !dashboardData.length) {
          // Get data
          const res = await StatsAPI.getOrganisationsStats(null);

          // Set data
          setResults({ ...results, dashboardData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD) {
        // If data has not been obtained before
        if (engagementDashboardData && !engagementDashboardData.length) {
          // Get data
          const res = await StatsAPI.getCustomerEngagementData(
            null,
            {
              edition: { $ne: Constants.DESELECT__EDITION__ESSENTIALS },
            },
          );
          const resTickets = await StatsAPI.getUnmappedSegmentTickets(null);

          // Set data
          setResults(
            {
              ...results,
              engagementDashboardData: res.data,
              unmappedTicketsData: resTickets.data.unmappedTickets,
            },
          );
        }
      } else if (linkName === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD) {
        // If data has not been obtained before
        if (essentialsDashboardData && !essentialsDashboardData.length) {
          // Get data
          const res = await StatsAPI.getFreeCustomersData(
            null,
          );

          const resTickets = await StatsAPI.getUnmappedSegmentTickets(null);

          // Set data
          setResults(
            {
              ...results,
              essentialsDashboardData: res.data,
              unmappedTicketsData: resTickets.data.unmappedTickets,
            },
          );
        }
      } else if (linkName === Constants.MENU__NAME__SFMC_QUICK_SEARCH) {
        // If data has not been obtained before
        if (dashboardData && !dashboardData.length) {
          // Get data
          const res = await StatsAPI.getChromeExtensionStats(null);
          // Set data
          setResults({ ...results, dashboardData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__CHANGELOG) {
        // If data has not been obtained before
        if (changeLogsData && !changeLogsData.length) {
          // Get data
          const res = await ChangeLogsAPI.getChangeLogs(null);
          // Set data
          setResults({ ...results, changeLogsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SELECTION_RUN_LOG) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (selectionRunLogsData && !selectionRunLogsData.length) {
          // Get data
          const res = await SelectionRunLogsAPI.getSelectionRunLogs(null);
          // Set data
          valuesToUpdate.selectionRunLogsData = res.data;
        }

        // If data has not been obtained before
        if (organisationsData && !organisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getOrganisations(null);
          // Set data
          valuesToUpdate.organisationsData = orgs.data;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) setResults({ ...results, ...valuesToUpdate });
      } else if (linkName === Constants.MENU__NAME__ORGANISATION) {
        // If data has not been obtained before
        if (organisationsData && !organisationsData.length) {
          // Get data
          const res = await OrganisationsAPI.getOrganisations(null);
          const featureAdverts = await FeatureAdvertsAPI.getAdverts(null);
          // Set data
          setResults({ ...results, organisationsData: res.data, featureAdvertsData: featureAdverts.data });
        }
      } else if (linkName === Constants.MENU__NAME__ADMIN_USERS) {
        const valuesToUpdate = {};

        // If data has not been obtained before
        if (adminUsersData && !adminUsersData.length) {
          // Get data
          const res = await AdminUsersAPI.getAdminUsers(null);
          // Set data
          valuesToUpdate.adminUsersData = res.data;

          setResults({ ...results, valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__USERS) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (usersData && !usersData.length) {
          // Get data
          const res = await UsersAPI.getUsers(null);
          // Set data
          valuesToUpdate.usersData = res.users;
        }
        // If data has not been obtained before
        if (organisationsData && !organisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getOrganisations(null);
          // Set data
          valuesToUpdate.organisationsData = orgs.data;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) setResults({ ...results, ...valuesToUpdate });
      } else if (linkName === Constants.MENU__NAME__NOTIFICATION) {
        // If data has not been obtained before
        if (notificationsData && !notificationsData.length) {
          // Get data
          const res = await NotificationsAPI.getNotifications(null);
          // Set data
          setResults({ ...results, notificationsData: res.data });
          setState({
            ...state,
            notificationsData: res.data,
          });
        }
      } else if (linkName === Constants.MENU__NAME__ALERTS) {
        // If data has not been obtained before
        if (!alertsData?.length) {
          // Get data
          const res = await AlertsAPI.getAlerts(null);
          // Set data
          setResults({ ...results, alertsData: res.data });
          setState({
            ...state,
            alertsData: res.data,
          });
        }
      } else if (linkName === Constants.MENU__NAME__USER_NOTIFICATION) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (userNotificationsData && !userNotificationsData.length) {
          // Get data
          const res = await UserNotificationsAPI.getUserNotifications(null);
          valuesToUpdate.userNotificationsData = res.data;
        }
        // If data has not been obtained before
        if (notificationsData && !notificationsData.length) {
          // Get data
          const notifications = await NotificationsAPI.getNotifications(null);
          valuesToUpdate.notificationsData = notifications.data;
        }
        // If data has not been obtained before
        if (usersData && !usersData.length) {
          // Get data
          const users = await UsersAPI.getUsers(null);
          valuesToUpdate.usersData = users.users;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) {
          setState({
            ...state,
            ...valuesToUpdate,
          });
          setResults({ ...results, ...valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__TIPS) {
        if (guidanceTips && !guidanceTips.length) {
          // Get data
          const retrievedTips = await GuidanceTipsAPI.getGuidanceTips(null);
          setResults(({ ...results, guidanceTips: retrievedTips }));
        }
      } else if (linkName === Constants.MENU__NAME__CERTIFICATES) {
        // If data has not been obtained before
        if (certificatesData && !certificatesData.length) {
          // Get data
          const res = await CertificatesAPI.getCertificates(null);
          // Set data
          setResults({ ...results, certificatesData: res.data });
          setState({
            ...state,
            certificatesData: res.data,
          });
        }
      } else if (linkName === Constants.MENU__NAME__DEMO_DATA_PACKAGE) {
        // If data has not been obtained before
        if (organisationsData && !organisationsData.length) {
          // Get data
          const res = await OrganisationsAPI.getOrganisations(null);
          // Set data
          setResults({ ...results, organisationsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SFMC_USER_SETUP) {
        console.log('MENU__NAME__SFMC_USER_SETUP');
      } else if (linkName === Constants.MENU__NAME__SFMC_OFFBOARDING) {
        console.log('MENU__NAME__SFMC_OFFBOARDING');
      } else if (linkName === Constants.MENU__NAME__CERTIFICATIONS_USERS) {
        const valuesToUpdate = {};

        // If users have not been obtained before
        if (!certificationsUsersData?.length) {
          // Get users
          const res = await CertificationsUserAPI.getCertificationUsers(null);
          valuesToUpdate.certificationsUsersData = res.data;
        }

        // If organisations have not been obtained before
        if (!organisationsData?.length) {
          const orgs = await OrganisationsAPI.getOrganisations(null);
          valuesToUpdate.organisationsData = orgs.data;
        }

        // If exams have not been obtained before
        if (!exams?.length) {
          const examsData = await ExamsAPI.getExams(null);
          valuesToUpdate.exams = examsData.data;
        }

        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) {
          setState({
            ...state,
            ...valuesToUpdate,
          });
          setResults({ ...results, ...valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__INTEGRATIONS) {
        // If data has not been obtained before
        if (integrationsData && !integrationsData.length) {
          // Get data
          const res = await IntegrationsAPI.getIntegrations(null);
          // Set data
          setResults({ ...results, integrationsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__RELATIONS) {
        // If data has not been obtained before
        if (relationsData && !relationsData.length) {
          // Get data
          const res = await RelationsAPI.getRelations(null);
          // Set data
          setResults({ ...results, relationsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__CERTIFICATIONS_GENERATED_EXAMS) {
        const valuesToUpdate = {};

        // If generated exams have not been obtained before
        if (!certificationsGeneratedExams?.length) {
          // Get data
          const res = await GeneratedExamsAPI.getGeneratedExams(null);
          valuesToUpdate.certificationsGeneratedExams = res.data;
        }

        // If users have not been obtained before
        if (!certificationsUsersData?.length) {
          // Get users
          const res = await CertificationsUserAPI.getCertificationUsers(null);
          valuesToUpdate.certificationsUsersData = res.data;
        }

        // If exams have not been obtained before
        if (!exams?.length) {
          const examsData = await ExamsAPI.getExams(null);
          valuesToUpdate.exams = examsData.data;
        }

        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) {
          setState({
            ...state,
            ...valuesToUpdate,
          });
          setResults({ ...results, ...valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__SETTINGS) {
        const valuesToUpdate = {};
        // Get data
        const res = await SettingsApi.get(null);
        valuesToUpdate.settings = res.data;

        setState({
          ...state,
          ...valuesToUpdate,
        });
        setResults({ ...results, settings: res.data });
      } else if (linkName === Constants.MENU__NAME__LOGOUT) {
        history.push('/login');
      } else {
        // eslint-disable-next-line no-console
        console.log('unexpected menu link:' + linkName);
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Check if user has permission to see a link from Nav
   * @param {String} link - Link to check
   * @returns {Boolean} True if user has permission to view the link
   */
  const userHasPermissionToSeeLink = link => link.permissions.includes(true);

  /**
   * Renders a Navigation Item
   * @param {string} name - The name of the item
   * @param {string} link - Where to redirect to
   * @param {function} onClick - Handler for the onClick event, default to handleLink
   * @returns {object} HTML for the Navigation Item
   */
  const renderNavLink = (name, link, onClick = handleLink) => (
    <li
      className={`slds-nav-vertical__item${menuSelected === name ? ' slds-is-active' : ''}`}
      key={name}
    >
      <Link
        className="slds-nav-vertical__action"
        to={link}
        onClick={onClick}
        data-link={name}
      >
        {name}
      </Link>
    </li>
  );

  return (
    <div className="nav-vertical_container" style={{ width: '320px' }}>
      <nav className="slds-nav-vertical slds-nav-vertical_shade" aria-label="Sub page">
        <div className="slds-nav-vertical__section">
          <h2 id="nav-item-deselect" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            DESelect Segment
          </h2>
          <ul aria-describedby="nav-item-deselect">
            {Nav.getPermissionsSegment().map((n) => {
              if (userHasPermissionToSeeLink(n)) {
                return renderNavLink(n.name, n.path);
              }
              return false;
            })}
          </ul>
        </div>

        {
          Util.userHasPermission(Constants.USER__PERMISSION__REPORTS) ||
          Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ?
            (

              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-quicksearch"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  DESelect Search
                </h2>
                {Util.userHasPermission(Constants.USER__PERMISSION__REPORTS) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-quicksearch">
                      {renderNavLink(Constants.MENU__NAME__SFMC_QUICK_SEARCH, Constants.MENU__LINK__SFMC_QUICK_SEARCH)}
                    </ul>
                  ) :
                  null}

                {Util.userHasPermission(Constants.USER__PERMISSION__MARKETING) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-quicksearch">
                      {renderNavLink(Constants.MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE,
                        Constants.MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE)}
                    </ul>
                  ) :
                  null}
              </div>
            ) :
            null
}

        <div>
          {(Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ||
           Util.userHasPermission(Constants.USER__PERMISSION__SETUP, Constants.USER__PERMISSION__WRITE)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-demodatapackage"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  SFMC Onboarding
                </h2>
                <ul aria-describedby="nav-item-sfmc-demodatapackage">
                  {renderNavLink(Constants.MENU__NAME__DEMO_DATA_PACKAGE, Constants.MENU__LINK__DEMO_DATA_PACKAGE)}
                </ul>
                {Util.userHasPermission(Constants.USER__PERMISSION__SETUP, Constants.USER__PERMISSION__WRITE) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-user-onboarding-offboarding">
                      {renderNavLink(Constants.MENU__NAME__SFMC_USER_SETUP, Constants.MENU__LINK__SFMC_USER_SETUP)}
                      {renderNavLink(Constants.MENU__NAME__SFMC_OFFBOARDING, Constants.MENU__LINK__SFMC_OFFBOARDING)}
                    </ul>
                  ) :
                  null}
              </div>
            ) :
            null}

          {Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER, Constants.USER__PERMISSION__WRITE) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-demodatapackage"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Testing
                </h2>
                <ul aria-describedby="nav-item-set-test-token">
                  {renderNavLink(Constants.MENU__NAME__SET_TEST_TOKEN, Constants.MENU__NAME__SET_TEST_TOKEN)}
                </ul>
              </div>
            ) :
            null}
        </div>

        {Util.userHasPermission(Constants.USER__PERMISSION__ACADEMY) ?
          (
            <>
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-certifications-app"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Academy: Certifications
                </h2>
                <ul aria-describedby="nav-item-certifications-users">
                  {renderNavLink(Constants.MENU__NAME__CERTIFICATIONS_USERS,
                    Constants.MENU__NAME__CERTIFICATIONS_USERS)}
                </ul>
                <ul aria-describedby="nav-item-certifications-exams">
                  {renderNavLink(Constants.MENU__NAME__CERTIFICATIONS_EXAMS,
                    Constants.MENU__NAME__CERTIFICATIONS_EXAMS)}
                </ul>
                <ul aria-describedby="nav-item-certifications-questions">
                  {renderNavLink(Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS,
                    Constants.MENU__NAME__CERTIFICATIONS_QUESTIONS)}
                </ul>
                <ul aria-describedby="nav-item-certifications-generated-exams">
                  {renderNavLink(Constants.MENU__NAME__CERTIFICATIONS_GENERATED_EXAMS,
                    Constants.MENU__NAME__CERTIFICATIONS_GENERATED_EXAMS)}
                </ul>
                <ul aria-describedby="nav-item-certificates">
                  {renderNavLink(Constants.MENU__NAME__CERTIFICATES, Constants.MENU__NAME__CERTIFICATES)}
                </ul>
              </div>

              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-self-learning-portal"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Academy: Trainings
                </h2>
                <ul aria-describedby="nav-item-self-learning-overview">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_OVERVIEW,
                    Constants.MENU__NAME__SELF_LEARNING_OVERVIEW)}
                </ul>
                <ul aria-describedby="nav-item-self-learning-trainings">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_TRAININGS,
                    Constants.MENU__NAME__SELF_LEARNING_TRAININGS)}
                </ul>
                <ul aria-describedby="nav-item-self-learning-modules">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_MODULES,
                    Constants.MENU__NAME__SELF_LEARNING_MODULES)}
                </ul>
                <ul aria-describedby="nav-item-self-learning-topics">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_TOPICS,
                    Constants.MENU__NAME__SELF_LEARNING_TOPICS)}
                </ul>
                <ul aria-describedby="nav-item-self-learning-questions-and-answers">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_TOPIC_QUESTIONS,
                    Constants.MENU__NAME__SELF_LEARNING_TOPIC_QUESTIONS)}
                </ul>
                <ul aria-describedby="nav-item-self-learning-customer-questions">
                  {renderNavLink(Constants.MENU__NAME__SELF_LEARNING_CUSTOMER_QUESTIONS,
                    Constants.MENU__NAME__SELF_LEARNING_CUSTOMER_QUESTIONS)}
                </ul>
              </div>
            </>
          ) :
          null}

        {Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ?
          (
            <div className="slds-nav-vertical__section">
              <h2
                id="nav-item-certifications-app"
                className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
              >
                Customer Success
              </h2>
              <ul aria-describedby="nav-item-asana-tasks">
                {renderNavLink(Constants.MENU__NAME__ASANA_TASKS,
                  Constants.MENU__NAME__ASANA_TASKS)}
              </ul>
            </div>
          ) :
          null}

        {/* <div className="slds-nav-vertical__section">
          <h2 id="nav-item-connect" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            DESelect Connect
          </h2>
          <ul aria-describedby="nav-item-integrations">
            {renderNavLink(Constants.MENU__NAME__INTEGRATIONS, Constants.MENU__NAME__INTEGRATIONS)}
          </ul>
        </div> */}

        {
          (Util.userHasPermission(Constants.USER__PERMISSION__FINANCE, Constants.USER__PERMISSION__WRITE)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-finance"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Finance
                </h2>
                <ul aria-describedby="nav-item-create-invoice">
                  {renderNavLink(Constants.MENU__NAME__CREATE_INVOICE, Constants.MENU__NAME__CREATE_INVOICE)}
                </ul>
                <ul aria-describedby="nav-item-stamp-deal">
                  {renderNavLink(Constants.MENU__NAME__STAMP_DEAL, Constants.MENU__NAME__STAMP_DEAL)}
                </ul>
                <ul aria-describedby="nav-item-currency-rates">
                  {renderNavLink(Constants.MENU__NAME__CURRENCY_RATES, Constants.MENU__NAME__CURRENCY_RATES)}
                </ul>
              </div>
            ) :
            null
        }

        {
          (Util.userHasPermission(Constants.USER__PERMISSION__SETUP)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-admin-app"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Admin App
                </h2>
                <ul aria-describedby="nav-item-admin-users">
                  {renderNavLink(Constants.MENU__NAME__ADMIN_USERS, '/admin-users')}
                </ul>
              </div>
            ) :
            null
        }

        <div className="slds-nav-vertical__section">
          <h2 id="nav-item-account" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            Account
          </h2>
          <ul aria-describedby="nav-item-account">
            {renderNavLink(Constants.MENU__NAME__LOGOUT, Constants.ROUTE__LOGOUT, handleLogout)}
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default LeftNav;
