import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import './style.scss';
import CertificationsUserAPI from '../../../../api/academyCertifications/certifications-users';
import OrganisationsAPI from '../../../../api/organisations';
import Filter from './atoms/Filter';
import TrainingResultsModal from './Modals/TrainingResultsModal';
import TrainingsAPI from '../../../../api/academyTrainings/trainings';
import TrainingResultsAPI from '../../../../api/academyTrainings/trainingResults';
import ModulesAPI from '../../../../api/academyTrainings/modules';

const Overview = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    trainings, organisationsData, modules, trainingResults, filteredTrainingResults,
    filterCriteriaForTrainingResults, certificationsUsersData,
  } = results;

  // training results variables
  const [trainingId, setTrainingId] = useState('');
  const [userId, setUserId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [moduleResults, setModuleResults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [totalScore, setTotalScore] = useState('');
  const [completionStatus, setCompletionStatus] = useState('');
  const [expirationDate, setExpirationDate] = useState('');

  // variables for filter
  const [loading, setLoading] = useState(false);
  const [resultsList, setResultsList] = useState([]);

  /**
   * Handles filtering of results
   * @param {object} e - JS Event
   * @param {string} filterBy - the name of the criterion by which we filter
   * @param {string} searchText - the search text entered in the search input
   * @param {date} dateFrom - start date selected in input date
   * @param {date} dateTo - end date selected in input date
   * @returns {void}
   */
  const filterResultsHandler = async (e, filterBy, searchText, dateFrom, dateTo) => {
    setLoading(true);
    const filterCriteria = {};

    // if filtering by organisation Id
    if (filterBy === Constants.FILTER_BY__ORGANISATION_ID) {
      filterCriteria.orgId = searchText.trim();
    } else if (filterBy === Constants.FILTER_BY__USER_ID) {
      // if filtering by user Id
      filterCriteria.userId = searchText.trim();
    } else if (filterBy === Constants.FILTER_BY__DATE) {
      // Filtering by start and end date
      filterCriteria.startDate = {
        $gte: new Date(dateFrom),
        $lte: new Date(dateTo),
      };
    }

    try {
      // Get filtered training results
      const res = await TrainingResultsAPI.getTrainingResults(null, filterCriteria);

      // Show filtered results
      setResults({
        ...results,
        filteredTrainingResults: res.data,
        filterCriteriaForTrainingResults: filterCriteria,
      });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `${error?.response?.data?.error || error}`,
      );
    }
  };

  /**
   * Stops the filtering of results
   * @returns {void}
   */
  const handleStopFiltering = () => {
    setLoading(true);

    // reset values
    setResults({
      ...results,
      filteredTrainingResults: trainingResults,
      filterCriteriaForTrainingResults: null,
    });

    setLoading(false);
  };

  /**
   * Get all data from database
   * @returns {void}
   */
  const fetchAllData = async () => {
    try {
      /**
       * Function returns new Promise after retrieving organisation data
       * @returns {Promise<Void>} void
       */
      const getOrganisations = () => new Promise((resolve) => {
        if (organisationsData?.length) { resolve({ data: organisationsData }); } else {
          resolve(OrganisationsAPI.getOrganisations(null));
        }
      });

      /**
       * Function returns new Promise after retrieving certification users data
       * @returns {Promise<Void>} void
       */
      const getCertificationUsers = () => new Promise((resolve) => {
        if (certificationsUsersData?.length) { resolve({ data: certificationsUsersData }); } else {
          resolve(CertificationsUserAPI.getCertificationUsers(null));
        }
      });

      /**
       * Function returns new Promise after retrieving trainings data
       * @returns {Promise<Void>} void
       */
      const getTrainingsData = () => new Promise((resolve) => {
        if (trainings?.length) { resolve({ data: trainings }); } else {
          resolve(TrainingsAPI.getAllTrainings(null));
        }
      });

      /**
       * Function returns new Promise after retrieving modules data
       * @returns {Promise<Void>} void
       */
      const getModulesData = () => new Promise((resolve) => {
        if (modules?.length) { resolve({ data: modules }); } else {
          resolve(ModulesAPI.getAllModules(null));
        }
      });

      /**
       * Function returns new Promise after retrieving training results data
       * @returns {Promise<Void>} void
       */
      const getTrainingResultsData = () => new Promise((resolve) => {
        if (trainingResults?.length) { resolve({ data: trainingResults }); } else {
          resolve(TrainingResultsAPI.getTrainingResults(null, {}));
        }
      });

      // invoke fetching functions asynchronously
      const fetchedData = await Promise.all([
        getOrganisations(),
        getCertificationUsers(),
        getTrainingsData(),
        getModulesData(),
        getTrainingResultsData(),
      ]);

      // get fetched data
      const orgData = fetchedData[0];
      const usersData = fetchedData[1];
      const trainingsData = fetchedData[2];
      const moduleData = fetchedData[3];
      const trainingResultsData = fetchedData[4];

      // set fetched and mocked data in state and results
      setState({
        ...state,
        modules: moduleData.data,
        trainingResults: trainingResultsData.data,
        organisationsData: orgData.data,
        trainings: trainingsData.data,
        certificationsUsersData: usersData.data,
      });

      const valuesToUpdate = {};
      valuesToUpdate.modules = moduleData.data;
      valuesToUpdate.trainingResults = trainingResultsData.data;
      valuesToUpdate.organisationsData = orgData.data;
      valuesToUpdate.trainings = trainingsData.data;
      valuesToUpdate.certificationsUsersData = usersData.data;

      setResults({ ...results, ...valuesToUpdate });
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    // get all necessary data when loading a view
    fetchAllData();

    /* eslint-disable-next-line */
  }, []);

  // UseEffect for setting results lists
  useEffect(() => {
    // If filtering...
    if ((filteredTrainingResults?.length) || filterCriteriaForTrainingResults) {
      setResultsList(filteredTrainingResults);
    } else {
      // Show unfiltered data
      setResultsList(trainingResults);
    }

    // eslint-disable-next-line
  }, [trainingResults, filteredTrainingResults, filterCriteriaForTrainingResults]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setTrainingId('');
    setUserId('');
    setOrgId('');
    setModuleResults([]);
    setStartDate('');
    setExpirationDate('');
  };

  /**
   * Handler called when canceling modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when saving module
   * @returns {void}
   */
  const handleSave = async () => {
    try {
      const data = {
        id: state.id,
        expirationDate,
      };

      // update the training for the user
      const res = await TrainingResultsAPI.updateTrainingResults(null, data);

      // set the data for the selected index
      const newArray = [...trainingResults];
      newArray[state.index] = res.data;
      setResults({ ...results, trainingResults: newArray });

      setState({
        ...state,
        isOpen: false,
        eventType: '',
        trainingResults: newArray,
      });

      Swal.fireSuccess('Updated!', 'The training has been updated.');

      // clear fields
      cleanFields();
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when editing a module
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = trainingResults.filter(m => m._id === id)[0];

    setTrainingId(singleItem.trainingId);
    setUserId(singleItem.userId);
    setOrgId(singleItem.orgId);
    setStartDate(singleItem.startDate);
    setModuleResults(singleItem.results);
    setTotalScore(singleItem.totalScore);
    setCompletionStatus(singleItem.completionStatus);
    setExpirationDate(singleItem.expirationDate);
  };

  // define options for Filter component
  const optionsForFilter = [
    {
      key: Constants.FILTER_BY__ORGANISATION_ID,
      text: 'Organisation Id',
      value: Constants.FILTER_BY__ORGANISATION_ID,
    },
    {
      key: Constants.FILTER_BY__USER_ID,
      text: 'User Id',
      value: Constants.FILTER_BY__USER_ID,
    },
    {
      key: Constants.FILTER_BY__DATE,
      text: 'Start Date',
      value: Constants.FILTER_BY__DATE,
    },
  ];

  return (
    <div className="self-learning-overview">
      <Filter
        handleStopFiltering={handleStopFiltering}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filterCriteriaForTrainingResults}
        options={optionsForFilter}
      />
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-self-learning-overview">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Training">
                Training
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="User Id">
                User Id / Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Organisation Id">
                Organisation Id / Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Completion Status [%]">
                Completion Status [%]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Total Score">
                Total Score
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Start Date">
                Start Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Expiration Date">
                Expiration Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="self-learning-overview-body-table">
          {resultsList?.length > 0 ?
            resultsList.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    <a
                      data-id={el._id}
                      data-index={i}
                      href="/#"
                      onClick={handleEdit}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Training">
                  <div className="slds-truncate" title={Util.returnPropertyNameById(trainings, el.trainingId)}>
                    {Util.returnPropertyNameById(trainings, el.trainingId)}
                  </div>
                </td>

                <td data-label="User Id">
                  <div
                    className="slds-truncate"
                    title={`${el.userId}/${
                      Util.returnPropertyNameById(certificationsUsersData, el.userId, true)}`}
                  >
                    {el.userId}
                    {' '}
                    /
                    {' '}
                    {Util.returnPropertyNameById(certificationsUsersData, el.userId, true)}
                  </div>
                </td>

                <td data-label="Organisation Id">
                  <div
                    className="slds-truncate"
                    title={`${el.orgId}/${Util.returnPropertyNameById(
                      organisationsData, el.orgId,
                    )}`}
                  >
                    {el.orgId}
                    {' '}
                    /
                    {' '}
                    {Util.returnPropertyNameById(organisationsData, el.orgId)}
                  </div>
                </td>

                <td data-label="Overall Status">
                  <div className="slds-truncate" title={el.completionStatus}>
                    {el.completionStatus}
                  </div>
                </td>

                <td data-label="Total Score">
                  <div className="slds-truncate" title={el.totalScore}>
                    {el.totalScore}
                  </div>
                </td>

                <td data-label="Start Date">
                  <div className="slds-truncate" title={new Date(el.startDate).toLocaleString()}>
                    {new Date(el.startDate).toLocaleString()}
                  </div>
                </td>

                <td data-label="Expiration Date">
                  <div
                    className="slds-truncate"
                    title={el.expirationDate ?
                      new Date(el.expirationDate).toLocaleDateString() :
                      ''}
                  >
                    {el.expirationDate ? new Date(el.expirationDate).toLocaleDateString() : ''}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                    {new Date(el.updatedAt).toLocaleString()}
                  </div>
                </td>
              </tr>
            )) :
            (
              <tr>
                {trainingResults?.length ?
                  (
                    <td colSpan="8" className="no-results-found">No results found</td>
                  ) :
                  null}
              </tr>
            )}
        </tbody>
      </table>
      <TrainingResultsModal
        isOpen={state.isOpen}
        trainingId={trainingId}
        userId={userId}
        orgId={orgId}
        startDate={startDate}
        handleCancel={handleCancel}
        moduleResults={moduleResults}
        certificationsUsersData={certificationsUsersData}
        organisationsData={organisationsData}
        trainings={trainings}
        modules={modules}
        totalScore={totalScore}
        completionStatus={completionStatus}
        expirationDate={expirationDate}
        setExpirationDate={setExpirationDate}
        handleSave={handleSave}
      />
    </div>
  );
};

export default Overview;
