import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AuthAPI = {
  /**
   * Authenticate
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} username - The user's username
   * @param {string} password - The user's password
   * @returns {object} An object with the property `success`
   */
  authenticate: async (cancelToken, username, password) => {
    const res = await axios.post(
      `${apiUrl}/admin/login`, {
        username,
        password,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Logout
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with the property `success`
   */
  logout: async (cancelToken) => {
    const res = await axios.post(
      `${apiUrl}/admin/logout`, null, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default AuthAPI;
