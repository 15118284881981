import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const ModulesAPI = {
  /**
   * Retrieves all modules
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved modules - and `success`
   */
  getAllModules: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/modules`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates module
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} moduleData - object with module data
   * @param {string} moduleData.name - module name
   * @param {Array} moduleData.trainingIds - array with training ids
   * @param {Array} moduleData.orders - array with objects: trainingId and order
   * @return {object} An object with `data` property - the created module - and `success`
   */
  createModule: async (
    cancelToken,
    {
      name,
      trainingIds,
      orders,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/modules`, {
        name,
        trainingIds,
        orders,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates module
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the module you want to update
   * @param {object} moduleData - object with module data
   * @param {string} moduleData.name - module name
   * @param {Array} moduleData.trainingIds - array with training ids
   * @param {Array} moduleData.orders - array with objects: trainingId and order
   * @return {object} An object with `data` property - the updated module - and `success`
   */
  updateModule: async (
    cancelToken,
    id,
    {
      name,
      trainingIds,
      orders,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/modules/${id}`, {
        name,
        trainingIds,
        orders,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Deletes module
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the module you want to delete
   * @returns {object} An object with `data` property - the deleted module - and `success`
   */
  deleteModule: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/modules/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default ModulesAPI;
