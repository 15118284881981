import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';

import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import QuestionModal from '../AcademyCertifications/Modals/QuestionModal';
import TopicQuestionsAPI from '../../../../api/academyTrainings/topicQuestions';
import TopicsAPI from '../../../../api/academyTrainings/topics';
import Spinner from './atoms/Spinner';
import ModulesAPI from '../../../../api/academyTrainings/modules';

const TopicQuestions = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    topicQuestions, searchTextForTopicQuestions, topics, modules,
  } = results;

  // Questions variables
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [topicId, setTopicId] = useState('');

  // variables for search bar
  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchQuestions, setSearchQuestions] = useState([]);
  const [searchBy, setSearchBy] = useState(Constants.SEARCH_BY__QUESTION);

  const [loadingData, setLoadingData] = useState(false);

  /**
   * Get all topic questions from database
   * @returns {void}
   */
  const getQuestions = async () => {
    try {
      // set loading status of fetching data
      setLoadingData(true);

      // get topic questions
      const res = await TopicQuestionsAPI.getAllTopicQuestions(null);

      // define topics and modules data
      let topicRes = { data: topics };

      let moduleRes = { data: modules };

      if (!topics?.length) {
        // get all topics only if they have not been fetched
        topicRes = await TopicsAPI.getAllTopics(null);
      }

      if (!modules.length) {
        // get all modules only if they have not been fetched
        moduleRes = await ModulesAPI.getAllModules(null);
      }

      // stop loading data
      setLoadingData(false);

      setState({
        ...state,
        topicQuestions: res.data,
        searchTextForTopicQuestions: '',
        topics: topicRes.data,
        modules: moduleRes.data,
      });

      // set a variable to search for data
      setSearchQuestions(res.data);

      const valuesToUpdate = {};

      valuesToUpdate.topicQuestions = res.data;
      valuesToUpdate.topics = topicRes.data;
      valuesToUpdate.searchTextForTopicQuestions = '';
      valuesToUpdate.modules = moduleRes.data;
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    /*
     * fetch data for the first time or
     * when the field is not empty and there will be a switch between windows
     */
    if (searchTextForTopicQuestions !== '' || !topicQuestions?.length || !topics?.length) {
      getQuestions();
    } else if (topicQuestions?.length) {
      setSearchQuestions(topicQuestions);
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearchQuestions = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === '' && isSearchFieldUsed) {
      // get all questions if the search field is empty and if it's used
      getQuestions();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      /**
       * Search for topic questions by topic name
       * @param {array} array - array to filter results
       * @returns {array} filtered array with search results
       */
      const searchByTopicName = array => (
        array.filter((el) => {
          // get topic selected in the module
          const selectedTopicsInQuestion = topics.find(top => el.topicId === top._id);

          if (selectedTopicsInQuestion) {
            // match the name of the topic assigned to the topic question with the search results
            const findTopicByName = selectedTopicsInQuestion.name.toString().toLowerCase().includes(
              searchText.toString().toLowerCase(),
            );

            if (findTopicByName) { return el; }
          }

          return null;
        })
      );

      let filteredQuestions;

      // depends of searchBy
      if (searchBy === Constants.SEARCH_BY__QUESTION) {
        // Filter the questions, find search data
        filteredQuestions = searchQuestions.length ?
          (
            searchQuestions.filter(c => c.question.toString().toLowerCase().includes(
              searchText.toString().toLowerCase(),
            ))
          ) :
          (
            topicQuestions.filter(c => c.question.toString().toLowerCase().includes(
              searchText.toString().toLowerCase(),
            ))
          );
      } else {
        // Filter the questions by topic name
        filteredQuestions = searchQuestions?.length ?
          searchByTopicName(searchQuestions) :
          searchByTopicName(topicQuestions);
      }

      // Set filtered questions and search text
      setState({
        ...state,
        topicQuestions: filteredQuestions,
        searchTextForTopicQuestions: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};

      valuesToUpdate.topicQuestions = filteredQuestions;
      valuesToUpdate.searchTextForTopicQuestions = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText, searchBy]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setQuestion('');
    setAnswers([]);
    setCorrectAnswer('');
    setTopicId('');
  };

  /**
   * Handler called when saving question
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      question,
      answers,
      correctAnswer,
      topicId,
    };

    /**
     * Function that checks the validity of the entered data
     * @returns {void}
     */
    const validateResult = () => {
      const errors = [];

      // validate question label
      const validateForQuestionLabel = question.trim() === '';

      // eslint-disable-next-line no-unused-expressions
      validateForQuestionLabel && errors.push('Please enter the question');

      // validate answers
      const validateAnswers = answers.length === 0;

      // eslint-disable-next-line no-unused-expressions
      validateAnswers && errors.push('Please enter the answer');

      // validate correct answer
      const validateCorrectAnswer = correctAnswer === '';

      // eslint-disable-next-line no-unused-expressions
      validateCorrectAnswer && errors.push('Please select correct answer');

      // validate topic
      const validateTopicId = !topicId || topicId === '';

      // eslint-disable-next-line no-unused-expressions
      validateTopicId && errors.push('Please select the topic');

      return errors;
    };

    if (validateResult().length > 0) {
      Swal.fireWarning('', validateResult()[0]);
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // create new question
          const res = await TopicQuestionsAPI.createTopicQuestion(null, newData);

          // set the data from response
          setResults({
            ...results,
            topicQuestions: [
              ...topicQuestions,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            topicQuestions: [
              ...topicQuestions,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The question has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // update question
          const res = await TopicQuestionsAPI.updateTopicQuestion(null, state.id, newData);

          // set the data for the selected index
          const newArray = [...topicQuestions];

          newArray[state.index] = res.data;
          setResults({ ...results, topicQuestions: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            topicQuestions: newArray,
          });
          Swal.fireSuccess('Updated!', 'The question has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing a question
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { target: { dataset: { index, id } } } = event;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = topicQuestions.filter(e => e._id === id)[0];

    setQuestion(singleItem.question);
    setAnswers(singleItem.answers);
    setCorrectAnswer(singleItem.correctAnswer);
    setTopicId(singleItem.topicId);
  };

  /**
   * Handler called when deleting a question
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure you want to delete this question?',
        text: 'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        const { target: { dataset: { index, id } } } = event;

        // delete the question
        await TopicQuestionsAPI.deleteTopicQuestion(null, id);

        // Delete results and update the state
        const newArray = [...topicQuestions];

        newArray.splice(index, 1);
        setResults({ ...results, topicQuestions: newArray });
        setState({ ...state, topicQuestions: newArray });

        Swal.fireSuccess('Deleted!', 'The question has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling a question modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  return (
    <div className="self-learning-topics-questions">
      <div className="self-learning-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={e => handleSearchQuestions(e)}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by"
        />
        <div className="slds-form-element">
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select
                className="slds-select"
                onChange={event => setSearchBy(event.target.value)}
                id="select-type"
                value={searchBy}
              >
                <option value={Constants.SEARCH_BY__QUESTION}>Question</option>
                <option value={Constants.SEARCH_BY__TOPIC_NAME}>Topic name</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        {loadingData ? <Spinner size="large" assistiveText="Loading..." /> : null}
        <thead>
          <tr className="slds-line-height_reset tr-topic-questions">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Topic Question">
                Topic Question
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Answers">
                Answers
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Correct Answer">
                Correct Answer
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Topic">
                Topic
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="certifications-questions-body-table">
          {!loadingData && topicQuestions.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  <a
                    href="/#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Topic Question">
                <div className="slds-truncate" title={el.question}>
                  {el.question}
                </div>
              </td>

              <td data-label="Answers">
                <div className="slds-truncate">
                  {el.answers && el.answers.length &&
                    el.answers.map((answer, index) => (
                      <li
                        className="answers-list-in-questions"
                        title={answer}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${index}-${answer}`}
                      >
                        {answer}
                      </li>
                    ))}
                </div>
              </td>

              <td data-label="Correct Answer">
                <div
                  className="slds-truncate"
                  title={el?.answers?.length ?
                    el.answers[el.correctAnswer] :
                    ''}
                >
                  {el?.answers?.length ? el.answers[el.correctAnswer] : ''}
                </div>
              </td>

              <td data-label="Topic">
                <div className="slds-truncate" title={Util.returnPropertyNameById(topics, el.topicId)}>
                  {Util.returnPropertyNameById(topics, el.topicId)}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                  {new Date(el.updatedAt).toLocaleString()}
                </div>
              </td>

              <td data-label="Actions">
                <div className="slds-truncate" title="Delete">
                  <svg
                    className="slds-icon slds-icon-text-error trash-delete-icon"
                    aria-hidden="true"
                    onClick={handleDelete}
                    data-id={el._id}
                    data-index={i}
                  >
                    <use
                      xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                      data-id={el._id}
                      data-index={i}
                    />
                  </svg>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <QuestionModal
        isOpen={state.isOpen}
        question={question}
        setQuestion={setQuestion}
        answers={answers}
        setAnswers={setAnswers}
        correctAnswer={correctAnswer}
        setCorrectAnswer={setCorrectAnswer}
        topicId={topicId}
        setTopicId={setTopicId}
        handleCancel={handleCancel}
        handleSave={handleSave}
        eventType={state.eventType}
        topicSection
        topics={topics}
        modules={modules}
      />
    </div>
  );
};

export default TopicQuestions;
