import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const GeneratedExamsAPI = {
  /**
   * Retrieves all exams
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved exams - and `success`
   */
  getGeneratedExams: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/generated-exams`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

};

export default GeneratedExamsAPI;
