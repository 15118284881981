import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './style.scss';
import PropTypes from 'prop-types';
import Swal from '../../../../../helpers/Swal';

const AddQuestions = ({ answers, setAnswers }) => {
  const [singleAnswer, setSingleAnswer] = useState('');
  const [singleAnswerEdit, setSingleAnswerEdit] = useState('');
  const [currentlyEditedAnswer, setCurrentlyEditedAnswer] = useState(false);
  const [currentlyEditedID, setCurrentlyEditedID] = useState(false);

  /**
   * Function for adding answers
   * @returns {void}
   */
  const addAnswer = () => {
    if (singleAnswer.trim() !== '') {
      // check if the singleAnswer is not duplicated
      const duplicateAnswer = answers.find(ans => ans.trim() === singleAnswer.trim());
      if (duplicateAnswer) {
        // display the message
        const message = 'This answer already exists. Please enter another one.';

        /*
         * show swal message and don't let to add a duplicate answer
         * do delay on showing modal so that the modal will not close after pressing enter
         */
        setTimeout(() => {
          Swal.fireWarning('', message);
        }, 50);
      } else {
        // add a single answer to the answer table
        setAnswers([...answers, singleAnswer]);
        setSingleAnswer('');
      }
    }
    return false;
  };

  /**
   * Function for editing the added answer
   * @param {string} editedAnswer - currently edited answer
   * @param {number} answerIndex - answer index
   * @returns {void}
   */
  const handleEditClick = (editedAnswer, answerIndex) => {
    // don't let to enter an empty value
    if (singleAnswerEdit.trim() === '') {
      setSingleAnswerEdit('');
      setCurrentlyEditedAnswer(null);
      setCurrentlyEditedID(null);
    } else {
      // create new answer
      const newAnswer = singleAnswerEdit;

      // edit answer in array with answers
      setAnswers(
        answers.map((answer, index) => {
          if (answer !== editedAnswer || index !== answerIndex) {
            return answer;
          }
          return newAnswer;
        }),
      );

      // clear the state
      setSingleAnswerEdit('');
      setCurrentlyEditedAnswer(null);
      setCurrentlyEditedID(null);
    }
  };

  /**
   * Function for removing answer
   * @param {string} answer - removed answer
   * @param {number} index - answer index
   * @returns {void}
   */
  const removeAnswer = (answer, index) => {
    setAnswers(answers.filter((a, i) => a !== answer || i !== index));
  };

  /**
   * Function that shows the input after pressing the icon to edit
   * @param {string} answer - removed answer
   * @param {number} index - answer index
   * @returns {void}
   */
  const showInput = (answer, index) => (
    <>
      <input
        className="slds-input add-answer-input add-questions-input"
        id={answer}
        placeholder="Create and add your answer"
        value={singleAnswerEdit}
        onKeyDown={e => (e.key === 'Enter' && handleEditClick(answer, index))}
        onChange={e => setSingleAnswerEdit(e.target.value)}
      />
      <button
        type="button"
        className="slds-button slds-button_icon slds-button_neutral
        add-questions-button"
        onClick={() => handleEditClick(answer, index)}
      >
        Edit
      </button>
    </>
  );

  /**
   * Function which sets the state before editing the answer
   * @param {string} answer - edited answer
   * @param {number} index - answer index
   * @returns {void}
   */
  const editIconFunction = (answer, index) => {
    // set the edited answer to be shown in the input field
    setCurrentlyEditedAnswer(answer);
    setCurrentlyEditedID(index);
    setSingleAnswerEdit(answer);
  };

  return (
    <div className="add-questions-wrapper">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="slds-form-element__label" htmlFor="Answers">
        Answers
      </label>
      <div className="slds-form-element__control questions-list">
        {answers.map((answer, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className="question-item" key={`${index}-${answer}`}>
            <div className="question-icon-button-box">
              {currentlyEditedAnswer === answer && currentlyEditedID === index ?
                (
                  showInput(answer, index)
                ) :
                (
                  <p>
                    {index + 1}
                    .
                    {' '}
                    {answer}
                  </p>
                )}
              {currentlyEditedID !== index && (
                <button
                  type="button"
                  className="icon-button-add-questions"
                  onClick={() => editIconFunction(answer, index)}
                >
                  <Icon name="edit" />
                </button>
              )}
              <button
                type="button"
                className="icon-button-add-questions"
                onClick={() => removeAnswer(answer, index)}
              >
                <Icon name="trash" />
              </button>
            </div>
          </li>
        ))}
      </div>
      <div className="slds-form-element input-button-add-question">
        <input
          className="slds-input add-answer-input placeholder add-questions-input"
          type="text"
          placeholder="Create and add your answer"
          onKeyDown={e => (e.key === 'Enter' && addAnswer())}
          value={singleAnswer}
          title="Create and add your answer"
          onChange={(e) => { setSingleAnswer(e.target.value); }}
        />
        <button
          type="button"
          onClick={addAnswer}
          className="slds-button slds-button_icon slds-button_neutral add-answer-button"
          title="Add Answer"
        >
          Add Answer
        </button>
      </div>
    </div>
  );
};

AddQuestions.propTypes = {
  /* the answers given */
  answers: PropTypes.instanceOf(Array).isRequired,
  /* handling of answers state change */
  setAnswers: PropTypes.func.isRequired,
};

export default AddQuestions;
