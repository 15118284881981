import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const CustomerQuestionsAPI = {
  /**
   * Get customer questions - all or based on filter criteria
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - The filter criteria (optional - passed when user use Filter)
   * @returns {object} An object with `data` property - the retrieved customerQuestions - and `success`
   */
  getCustomerQuestions: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/customer-questions`,
      criteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Updates customer question
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the customer question you want to update
   * @param {object} customerQuestionData - object with customer question data
   * @param {string} customerQuestionData.orgId - organisation Id of the user who asked the question
   * @param {string} customerQuestionData.userId - Id of the user who asked the question
   * @param {string} customerQuestionData.question - question content
   * @param {boolean} customerQuestionData.answered - indicates whether the question has been answered
   * @param {string} customerQuestionData.topicId - id of the topic in which the question was asked
   * @return {object} An object with `data` property - the updated customer question - and `success`
   */
  updateCustomerQuestion: async (
    cancelToken,
    id,
    {
      orgId,
      userId,
      question,
      answered,
      topicId,
      answer,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/customer-questions/${id}`, {
        orgId,
        userId,
        question,
        answered,
        topicId,
        answer,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default CustomerQuestionsAPI;
