import React, { useState, useEffect, useContext } from 'react';
import ApexChart from 'react-apexcharts';

import { ResultsContext } from '../../../../ResultsContext';
import { MenuContext } from '../../../../MenuContext';

import StatsAPI from '../../../../api/stats';

import './style.scss';
import './animation.scss';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';

const DashboardDataset = () => {
  const [results, setResults] = useContext(ResultsContext);
  const { dashboardData } = results;

  // Dashboard variable
  const UPDATE_EVERY_X_SECONDS = 300; // Update the stats every X seconds -> 5 minutes
  const [listLabel, setListLabel] = useState('');
  const [orgToShow, setOrgToShow] = useState('');
  const [showLoggedUsers, setShowLoggedUsers] = useState(false);
  const [showList, setShowList] = useState(false);

  const menuContext = useContext(MenuContext);
  const [, setMenuSelected] = menuContext.menuSelected;
  const [, setMenuParameters] = menuContext.menuParameters;

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    // Setup interval
    const interval = setInterval(async () => {
      try {
        const res = await StatsAPI.getOrganisationsStats(null);
        setResults({ ...results, dashboardData: res.data });
      } catch (error) {
        clearInterval(interval); // Once an error happens, stop searching anymore
        Util.handleError(error);
      }
    }, UPDATE_EVERY_X_SECONDS * 1000);
    // When this component is not needed anymore, destroy the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResults]);

  // Data from result
  const {
    // DESelect
    partnerOrgs = [],
    customerOrgs = [],
    internalOrgs = [],
    selectionsPerWeek = [],
    loggedInUsers = [],
  } = dashboardData;

  // Format ARR
  const currencyFormatter = new Intl.NumberFormat('en-BE', {
    style: 'currency',
    currency: 'EUR',
  });

  const usersChart = {
    series: [customerOrgs.length, partnerOrgs.length, internalOrgs.length],
    options: {
      labels: ['Customer users', 'Partner users', 'Internal users'],
    },
  };
  const customerEditionChart = {
    series: [
      customerOrgs.reduce((acc, cur) => acc + (cur.edition === Constants.DESELECT__EDITION__ADVANCED ? 1 : 0), 0),
      customerOrgs.reduce((acc, cur) => acc + (cur.edition === Constants.DESELECT__EDITION__PLUS ? 1 : 0), 0),
      customerOrgs.reduce((acc, cur) => acc + (cur.edition === Constants.DESELECT__EDITION__ENABLE ? 1 : 0), 0),
    ],
    options: {
      labels: ['Advanced', 'Plus', 'Enable'],
    },
  };
  const selectionChart = {
    series: [
      customerOrgs.reduce((acc, cur) => acc + cur.numberOfSelections, 0),
      partnerOrgs.reduce((acc, cur) => acc + cur.numberOfSelections, 0),
      internalOrgs.reduce((acc, cur) => acc + cur.numberOfSelections, 0),
    ],
    options: {
      labels: ['Customer selections', 'Partner selections', 'Internal selections'],
    },
  };
  const selectionsPerWeekChart = {
    series: [{
      data: selectionsPerWeek.map(({ count }) => count),
      name: 'Selections',
    }],
    options: {
      plotOptions: { bar: { distributed: true, columnWidth: selectionsPerWeek.length > 1 ? '70%' : '35%' } },
      dataLabels: { enabled: false },
      legend: { show: false },
      // eslint-disable-next-line spellcheck/spell-checker
      xaxis: { categories: selectionsPerWeek.map(({ _id }) => `Week ${_id + 1}`) },
    },
  };

  /**
   * Renders a number, normally if different from 0, otherwise in red badge
   * @param {number} value - Number to print
   * @returns {object} HTML for the button
   */
  const render0InRed = (value) => {
    if (value === 0) {
      return (<span className="slds-badge slds-theme_error">{value}</span>);
    }
    return value;
  };

  /**
   * Renders the extends button
   * @param {string} label - Label to display for the list
   * @param {array|undefined} org - Array of organisations, if none is defined, then it will render the
   * logged in users instead
   * @returns {object} HTML for the button
   */
  const renderExtendsButton = (label, org) => (
    <>
      {showList ?
        (
          <button
            type="button"
            className="slds-button extends hvr-bob"
            onClick={() => setShowList(false)}
          >
            <span className="slds-icon_container">
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_top" />
              </svg>
            </span>
          </button>
        ) :
        (
          <button
            type="button"
            className="slds-button extends hvr-bob"
            onClick={() => {
              if (org) { setOrgToShow(org); setShowLoggedUsers(false); } else setShowLoggedUsers(true);
              setListLabel(label);
              setShowList(true);
            }}
          >
            <span className="slds-icon_container">
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_bottom" />
              </svg>
            </span>
          </button>
        )}
    </>
  );

  /**
   * handler to open link to Adoption Dashboard
   * @param {object} event - Event object
   *  @returns {void}
   *
   */
  const openAdoptionDashboard = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    const orgId = event.target.dataset.id;
    setMenuSelected(linkName);
    setMenuParameters({ orgId });
  };

  /**
   * Renders the list of logged in users
   * @returns {object} HTML for the list
   */
  const renderUserList = () => {
    if (!loggedInUsers || !loggedInUsers.length) {
      return (
        <span className="slds-align_absolute-center no-data">
          No data available
        </span>
      );
    }

    return (
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>
            <th scope="col">
              <div className="slds-truncate" title="Username">
                Username
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loggedInUsers ?
            loggedInUsers.map(u => (
              <tr key={u._id} className="slds-hint-parent">
                <th data-label="Name" scope="row">
                  <div className="slds-truncate" title={u.name}>
                    {u.name}
                  </div>
                </th>

                <th data-label="Username" scope="row">
                  <div className="slds-truncate" title={u.username}>
                    {u.username}
                  </div>
                </th>
              </tr>
            )) :
            null}
        </tbody>
      </table>
    );
  };

  /**
   * Renders a list of organisations
   * @param {array} org - Array of organisations
   * @returns {object} HTML for the list
   */
  const renderOrgList = (org) => {
    if (!org || !org.length) {
      return (
        <span className="slds-align_absolute-center no-data">
          No data available
        </span>
      );
    }

    return (
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th scope="col" style={{ width: '250px' }}>
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Number of users that have the isActive flag set">
                Active Users
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Licenses">
                Licenses
              </div>
            </th>

            <th scope="col">
              <div
                className="slds-truncate"
                title="Active Users / Licenses. Subtracts one user assuming it's DESelect's user"
              >
                Active Users / Licenses
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Number of users that have opened DESelect in last 30 days">
                Logged-in Users in 30 d.
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="AE">
                AE
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="CSM">
                CSM
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Health Score">
                Sprint Health Score
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Health Score">
                Week Health Score
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="ARR">
                ARR
              </div>
            </th>

          </tr>
        </thead>
        <tbody>
          {org ?
            org.map(o => (
              <tr key={o._id} className="slds-hint-parent">
                <th data-label="Name" scope="row">
                  <div className="slds-truncate" title={o.name}>
                    {Util.renderAdoptionDashboardLink(o.name, o._id, openAdoptionDashboard)}
                  </div>
                </th>

                <th data-label="Active Users" scope="row">
                  <div className="slds-truncate" title={o.numberOfActiveUsers}>
                    {render0InRed(o.numberOfActiveUsers)}
                  </div>
                </th>

                <th data-label="Licenses" scope="row">
                  <div className="slds-truncate" title={o.licenses}>
                    {o.licenses}
                  </div>
                </th>

                <th data-label="Active Users / Licenses" scope="row">
                  <div className="slds-truncate" title={o.activeUsersVsLicense}>
                    <span
                      className={`slds-badge slds-theme_${
                        o.activeUsersVsLicense > 100 ?
                          'error' :
                          'success'
                      }`}
                    >
                      {o.activeUsersVsLicense}
                      %
                    </span>
                  </div>
                </th>

                <th data-label="Logged-In Users" scope="row">
                  <div className="slds-truncate" title={o.numberOfLoggedInUsers}>
                    {render0InRed(o.numberOfLoggedInUsers)}
                  </div>
                </th>

                <th data-label="AE" scope="row">
                  <div className="slds-truncate" title={o.orgAE.Name}>
                    {o.orgAE.Name}
                  </div>
                </th>

                <th data-label="CSM" scope="row">
                  <div className="slds-truncate" title={o.orgCSM.Name}>
                    {o.orgCSM.Name}
                  </div>
                </th>

                <th data-label="Sprint Health Score" scope="row">
                  <div className="slds-truncate" title={o?.sprintHealthScore}>
                    {(o?.sprintHealthScore === null) ?
                      null :
                      Util.renderHealthScoreColor(o?.sprintHealthScore?.toFixed(2), Constants.HS__MAX_SCORE)}
                  </div>
                </th>

                <th data-label="Week Health Score" scope="row">
                  <div className="slds-truncate" title={o?.healthScore?.healthScore}>
                    {o?.healthScore ?
                      Util.renderHealthScoreColor(o?.healthScore?.healthScore?.toFixed(2), Constants.HS__MAX_SCORE) :
                      null}
                  </div>
                </th>

                <th data-label="ARR" scope="row">
                  <div className="slds-truncate" title={currencyFormatter.format(o.ARR)}>
                    {currencyFormatter.format(o.ARR)}
                  </div>
                </th>
              </tr>
            )) :
            null}
        </tbody>
      </table>
    );
  };

  return (
    <div className="dashboard">
      <div className="slds-grid category">
        <div className="slds-size_12-of-12 slds-m-left_x-small">
          DESelect
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box title slds-has-flexi-truncate">Users</span>
            <div className="box-content chart">
              {
                customerOrgs.length ||
                partnerOrgs.length ||
                internalOrgs.length ?
                  (
                    <ApexChart
                      series={usersChart.series}
                      options={usersChart.options}
                      // eslint-disable-next-line spellcheck/spell-checker
                      type="donut"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box title slds-has-flexi-truncate">Customers Edition</span>
            <div className="box-content chart">
              {
                customerEditionChart.series[0] !== 0 ||
                customerEditionChart.series[1] !== 0 ||
                customerEditionChart.series[2] !== 0 ?
                  (
                    <ApexChart
                      series={customerEditionChart.series}
                      options={customerEditionChart.options}
                      // eslint-disable-next-line spellcheck/spell-checker
                      type="donut"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box title slds-has-flexi-truncate">Selections</span>
            <div className="box-content chart">
              {
                selectionChart.series[0] !== 0 ||
                selectionChart.series[1] !== 0 ||
                selectionChart.series[2] !== 0 ?
                  (
                    <ApexChart
                      series={selectionChart.series}
                      options={selectionChart.options}
                      // eslint-disable-next-line spellcheck/spell-checker
                      type="donut"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-2">
          <div className="slds-box">
            <span className="box title slds-has-flexi-truncate">Selections Per Week</span>
            <div className="box-content chart">
              {
                selectionsPerWeek.length ?
                  (
                    <ApexChart
                      series={selectionsPerWeekChart.series}
                      options={selectionsPerWeekChart.options}
                      type="bar"
                      width="100%"
                      height="200px"
                    />
                  ) :
                    <span className="slds-align_absolute-center no-data">No data available</span>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Logged In Users</span>
            <div className="box-content number">{loggedInUsers.length}</div>
            <div className="box-footer">
              {renderExtendsButton('List of Active Users')}
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Customer Organisations</span>
            <div className="box-content number">{customerOrgs.length}</div>
            <div className="box-footer">
              {renderExtendsButton('List of Active Customer Organisations', customerOrgs)}
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Partner Organisations</span>
            <div className="box-content number">{partnerOrgs.length}</div>
            <div className="box-footer">
              {renderExtendsButton('List of Active Partner Organisations', partnerOrgs)}
            </div>
          </div>
        </div>
        <div className="slds-size_1-of-4">
          <div className="slds-box">
            <span className="box-title slds-has-flexi-truncate">Active Internal Organisations</span>
            <div className="box-content number">{internalOrgs.length}</div>
            <div className="box-footer">
              {renderExtendsButton('List of Active Internal Organisations', internalOrgs)}
            </div>
          </div>
        </div>
      </div>

      <div className="slds-grid">
        <div className="slds-size_12-of-12">
          <div className={`slds-box expandable${showList ? ' expanded' : ''}`}>
            <span className="box-title slds-has-flexi-truncate">{listLabel}</span>
            <div className="box-content list">
              {showLoggedUsers ?
                renderUserList() :
                renderOrgList(orgToShow)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDataset;
