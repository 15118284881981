import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import ExamsAPI from '../../../../api/academyCertifications/exams';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import ExamModal from './Modals/ExamModal';
import QuestionsAPI from '../../../../api/academyCertifications/questions';

const Exams = ({ validate }) => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const { exams, searchTextForExams, questions } = results;

  const [openQuestions, setOpenQuestions] = useState(false);

  // User Certifications variables
  const [name, setName] = useState('');
  const [numberOfQuestions, setNumberOfQuestions] = useState('');
  const [numberOfRetries, setNumberOfRetries] = useState('');
  const [time, setTime] = useState('');
  const [threshold, setThreshold] = useState('');
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [onlyForAdmin, setOnlyForAdmin] = useState(false);
  const [isARefresherExam, setIsARefresherExam] = useState(false);
  const [mainExamId, setMainExamId] = useState('');
  const [mainExams, setMainExams] = useState(exams);
  const [generatesCertificate, setGeneratesCertificate] = useState(true);

  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchExams, setSearchExams] = useState([]);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearchExams = (e) => {
    setSearchText(e.target.value);
  };

  /**
   * The function of fetching exams
   * @returns {void}
   */
  const getExams = async () => {
    try {
      const res = await ExamsAPI.getExams(null);

      const filteredExams = res.data.filter(e => !e.isARefresherExam);

      setMainExams(filteredExams);

      // set the state from response
      setState({
        ...state,
        exams: res.data,
        searchTextForExams: '',
      });

      // set state for search bar
      setSearchExams(res.data);

      // define exams to update
      const valuesToUpdate = {};
      valuesToUpdate.exams = res.data;
      valuesToUpdate.searchTextForExams = '';
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    /**
     * Handler called to get all questions from database if they have not been fetched yet
     * @returns {void}
     */
    const getAllQuestions = async () => {
      if (!allQuestions.length) {
        try {
          // fetch all questions
          const res = await QuestionsAPI.getQuestions(null);
          if (res && res.data) {
            setAllQuestions(res.data);

            // Set questions state
            setState({
              ...state,
              questions: res.data,

            });
            setResults({ ...results, questions: res.data });
          }
        } catch (error) {
          Util.handleError(error);
        }
      }
    };

    if (state.isOpen && !questions.length) {
      // after open the modal get all questions
      getAllQuestions();
    } else {
      setAllQuestions(questions);
    }

    if (state.isOpen && !state.id) {
      const filteredExams = exams.filter(e => !e.isARefresherExam);

      setMainExams(filteredExams);
    }

    /* eslint-disable-next-line */
  }, [state.isOpen, allQuestions.length, questions?.length]);

  useEffect(() => {
    // when the field is not empty and there will be a switch between windows then get exams
    if (searchTextForExams !== '' || !exams?.length) {
      getExams();
    } else if (exams?.length) {
      setSearchExams(exams);
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    // get all exams if the search field is empty and if it's used
    if (searchText === '' && isSearchFieldUsed) {
      // Set all exams
      getExams();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      // Filter the exam, find search data
      const filteredExams = searchExams.length ?
        (
          Util.searchByName(searchExams, searchText)
        ) :
        (
          Util.searchByName(exams, searchText)
        );

      // Set filtered exams and search text
      setState({
        ...state,
        exams: filteredExams,
        searchTextForExams: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};
      valuesToUpdate.exams = filteredExams;
      valuesToUpdate.searchTextForExams = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText, isSearchFieldUsed]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setName('');
    setNumberOfQuestions('');
    setNumberOfRetries('');
    setTime('');
    setThreshold('');
    setAvailableQuestions([]);
    setAllQuestions([]);
    setOpenQuestions(false);
    setEndDate('');
    setOnlyForAdmin(false);
    setIsARefresherExam(false);
    setMainExamId('');
    setMainExams([]);
    setGeneratesCertificate(true);
  };

  /**
   * Function to check the validity of the results
   * @returns {bool/object} - swal message or true
   */
  const validateResults = () => {
    const inputData = {
      name,
      numberOfQuestions,
      time,
      threshold,
      availableQuestions,
      endDate,
    };

    // validate input data
    const validateResult = validate(inputData);

    if (validateResult.length > 0) {
      // when fields are empty
      return Swal.fireWarning('', 'Please, fill out all the fields.');
    } if (availableQuestions.length === 0) {
      // when there are no selected questions
      return Swal.fireWarning('', 'Please, select questions to the exam.');
    } if (time < 1 || threshold < 1 || numberOfQuestions < 1) {
      // when the entered value is equal to 0
      return Swal.fireWarning('', 'Values must be greater than 0.');
    } if (isARefresherExam && !mainExamId) {
      // when main exam has not been selected
      return Swal.fireWarning('', 'You must choose a main exam to continue.');
    } if (!moment(endDate).isValid()) {
      // Prevent invalid date from being selected
      return Swal.fireWarning('', 'Invalid Date. Please choose a valid date to continue.');
    } if (moment(endDate).isBefore(moment())) {
      // Prevent past dates from being selected
      return Swal.fireWarning('', 'End Date cannot be in the past. Please choose a date in the future to continue.');
    } if (numberOfQuestions > allQuestions.length) {
      // Prevent number of questions from being greater than number of available questions.
      return Swal.fireWarning('', 'Number of questions cannot exceed number of available questions.');
    } if (numberOfQuestions > availableQuestions.length) {
      // Prevent number of selected questions from being less than number of questions for the exam.
      return Swal.fireWarning('', 'Number of selected questions must be equal to number of questions.');
    }

    return true;
  };

  /**
   * Handler called when saving an exam
   * @returns {void}
   */
  const handleSave = async () => {
    // send an array with the available questions ID to the backend
    const availableQuestionsIds = [];
    availableQuestions.forEach(aq => aq._id ?
      availableQuestionsIds.push(aq._id) :
      availableQuestionsIds.push(aq));

    const newData = {
      name,
      numberOfQuestions,
      numberOfRetries,
      time,
      threshold,
      availableQuestions: availableQuestionsIds,
      endDate: new Date(endDate),
      onlyForAdmin,
      isARefresherExam,
      ...mainExamId && { mainExamId },
      generatesCertificate,
    };

    if (validateResults() === true) {
      try {
        // when we are creating exam
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          const res = await ExamsAPI.createExam(null, newData);

          // set the data from response
          setResults({
            ...results,
            exams: [
              ...exams,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            exams: [
              ...exams,
              res.data,
            ],
            id: '',
          });
          Swal.fireSuccess('Created!', 'The exam has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // when we are updating an exam
          const res = await ExamsAPI.updateExam(null, state.id, newData);

          // set the data for the selected index
          const newArray = [...exams];
          newArray[state.index] = res.data;
          setResults({ ...results, exams: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            exams: newArray,
            id: '',
          });
          Swal.fireSuccess('Updated!', 'The exam has been updated.');
        }
        // clear state after updating
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing an exam
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = exams.filter(e => e._id === id)[0];

    // define endDate in proper format
    const _endDate = singleItem.endDate ?
      new Date(singleItem.endDate).toISOString().split('T')[0] :
      '';

    const filteredExams = exams.filter(e => e._id !== id && !e.isARefresherExam);

    // set the data
    setEndDate(_endDate);
    setName(singleItem.name);
    setNumberOfQuestions(singleItem.numberOfQuestions);
    setNumberOfRetries(singleItem.numberOfRetries);
    setTime(singleItem.time);
    setThreshold(singleItem.threshold);
    setOnlyForAdmin(singleItem.onlyForAdmin);
    setIsARefresherExam(singleItem.isARefresherExam);
    setMainExamId(singleItem.mainExamId);
    setMainExams(filteredExams);
    setAvailableQuestions(singleItem.availableQuestions);
    setGeneratesCertificate(singleItem.generatesCertificate);
  };

  /**
   * Handler called when deleting an exam
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure to delete this exam?',
        text: 'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        const { id } = event.target.dataset;
        const { index } = event.target.dataset;

        await ExamsAPI.deleteExam(null, id);

        // Delete results
        const newArray = [...exams];
        newArray.splice(index, 1);
        setResults({ ...results, exams: newArray });
        setState({ ...state, exams: newArray, id: '' });

        Swal.fireSuccess('Deleted!', 'The exam has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling an Exam user modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, id: '', isOpen: false });
    cleanFields();
  };

  return (
    <div className="certifications-exams">
      <div className="certifications-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={e => handleSearchExams(e)}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by Exam Name"
        />
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Exam Name">
                Exam Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Number of Questions">
                Number of Questions
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Number of Retries">
                Number of Retries
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Time [min]">
                Time [min]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Threshold [%]">
                Threshold [%]
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Questions">
                Questions
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="End Date">
                End Date
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Only for Admin">
                Only for Admin
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Only for Admin">
                Generates Certificate
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {exams.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Exam Name">
                <div className="slds-truncate" title={el.name}>
                  {el.name}
                </div>
              </td>

              <td data-label="Number of Questions">
                <div className="slds-truncate" title={el.numberOfQuestions}>
                  {el.numberOfQuestions}
                </div>
              </td>

              <td data-label="Number of Questions">
                <div className="slds-truncate" title={el.numberOfRetries}>
                  {el.numberOfRetries}
                </div>
              </td>

              <td data-label="Time">
                <div className="slds-truncate" title={el.time}>
                  {el.time}
                </div>
              </td>

              <td data-label="Threshold">
                <div className="slds-truncate" title={el.threshold}>
                  {el.threshold}
                </div>
              </td>

              <td data-label="Questions">
                <div title={el.availableQuestions}>
                  <a
                    href="/#"
                    onClick={(event) => { setOpenQuestions(true); handleEdit(event); }}
                    data-id={el._id}
                    data-index={i}
                  >
                    Questions
                  </a>
                </div>
              </td>

              <td>
                <div
                  className="slds-truncate"
                  title={el.endDate ?
                    new Date(el.endDate).toLocaleDateString(
                      { year: 'numeric', month: 'numeric', day: 'numeric' },
                    ) :
                    ''}
                >
                  {el.endDate ?
                    new Date(el.endDate).toLocaleDateString({ year: 'numeric', month: 'numeric', day: 'numeric' }) :
                    ''}
                </div>
              </td>

              <td data-label="Only for Admin">
                <div className="slds-truncate">
                  {el.onlyForAdmin ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td data-label="Generates Certificate">
                <div className="slds-truncate">
                  {el.generatesCertificate ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                  {new Date(el.updatedAt).toLocaleString()}
                </div>
              </td>

              <td data-label="Actions">
                <div className="slds-truncate" title="Delete">
                  <svg
                    className="slds-icon slds-icon-text-error"
                    aria-hidden="true"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <use
                      xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                      data-id={el._id}
                      data-index={i}
                      onClick={handleDelete}
                      style={{ cursor: 'pointer' }}
                    />
                  </svg>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ExamModal
        isOpen={state.isOpen}
        name={name}
        setName={setName}
        numberOfQuestions={numberOfQuestions}
        setNumberOfQuestions={setNumberOfQuestions}
        numberOfRetries={numberOfRetries}
        setNumberOfRetries={setNumberOfRetries}
        time={time}
        setTime={setTime}
        handleCancel={handleCancel}
        handleSave={handleSave}
        setAvailableQuestions={setAvailableQuestions}
        availableQuestions={availableQuestions}
        allQuestions={allQuestions}
        setOpenQuestions={setOpenQuestions}
        openQuestions={openQuestions}
        threshold={threshold}
        setThreshold={setThreshold}
        eventType={state.eventType}
        endDate={endDate}
        setEndDate={setEndDate}
        setOnlyForAdmin={setOnlyForAdmin}
        setIsARefresherExam={setIsARefresherExam}
        onlyForAdmin={onlyForAdmin}
        isARefresherExam={isARefresherExam}
        exams={mainExams}
        mainExamId={mainExamId}
        setMainExamId={setMainExamId}
        generatesCertificate={generatesCertificate}
        setGeneratesCertificate={setGeneratesCertificate}
      />
    </div>
  );
};

Exams.propTypes = {
  /**
   * Function to validate the input data
   */
  validate: PropTypes.func.isRequired,
};

export default Exams;
