import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { MenuContext } from '../../MenuContext';

import AuthAPI from '../../api/auth';

import PasswordField from './PasswordField';
import './Login.scss';

import Constants from '../../constants/constants';
import Util from '../../util';

const Login = () => {
  const menuContext = useContext(MenuContext);
  const [, setMenuSelected] = menuContext.menuSelected;

  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  /**
   * Handler called when trying to login
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await AuthAPI.authenticate(null, username, password);

      // Set few states to ensure a good display
      setMenuSelected('');

      history.push(Constants.ROUTE__OVERVIEW);
    } catch (error) {
      Util.handleError(error);
    }
  };

  const handleUnsupportedLogon = () => {
    Util.informationalDialog(
      'Coming Soon!',
      `Currently you are only able to login with a user name provided by the IT team.
        SSO is coming soon!`,
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 login-box">
          <div className="login">
            <div className="login-logo">
              <img src="/assets/logo/DESelect-Logo-Website.png" alt="logo" />
            </div>
            <div className="login-form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="icon-text">
                    <img
                      src="/assets/icons/avatar.svg"
                      alt="avatar"
                      className="username-icon"
                    />
                    <input
                      type="text"
                      className="form-control email"
                      placeholder="Enter username"
                      onChange={event => setUsername(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <PasswordField
                    placeholder="Password"
                    handleChange={event => setPassword(event.target.value)}
                  />
                </div>
                <button type="submit" className="login-btn">
                  SIGN IN
                </button>
                <div className="row">
                  <button type="button" onClick={handleUnsupportedLogon} className="social-btn">
                    OKTA
                  </button>
                  <button type="button" onClick={handleUnsupportedLogon} className="social-btn">
                    GOOGLE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
