import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const HubspotApi = {
  /**
   * Get Deals with certain Finance stage
   * @param {String} field - Field value to filter on
   * @param {Array} values - Acceptable values for field to filter on
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {Array} List of deals
   */
  getDeals: async (field, values, cancelToken) => {
    const body = {
      field,
      values,
    };

    const res = await axios.post(
      `${apiUrl}/admin/hubspot/deals/`,
      body,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.deals;
  },

  /**
   * Get Deal and Company details given a dealId
   * @param {String} dealId - Id of the deal for which we want to receive the Deal + Company details
   * @param {Object} cancelToken - The cancel token from Axios
   * @returns {Object} Object with deal and company properties
   */
  getDealAndCompany: async (dealId, cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/hubspot/deal-and-company/${dealId}`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Update Company details and stamp details on Deal
   * @param {Number} dealId - Id of the hubspot deal
   * @param {Boolean} stampCompany - Should company be stamped?
   * @param {Object} cancelToken - The cancel token from Axios
   * @returns {Array} An object with `success` property
   */
  stampDeal: async (dealId, stampCompany, cancelToken) => {
    const res = await axios.post(
      `${apiUrl}/admin/hubspot/deal/${dealId}/stamp`,
      {
        stampCompany,
      },
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default HubspotApi;
