import React from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import '../style.scss';

const CustomerQuestionModal = ({
  isOpen, question, userId, orgId, date,
  handleCancel, handleSave, organisationsData, certificationsUsersData, setAnswered, answered,
  topicId, topics, answer, setAnswer,
}) => {
  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__ACADEMY,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
        id="customer-questions-self-learning-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Customer Question Form
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-self-learning"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-question">
                Question
                <div className="slds-form-element__control">
                  <textarea
                    type="text"
                    id="form-element-question"
                    className="slds-input"
                    value={question}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-userId">
                User Id
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-userId"
                    className="slds-input"
                    value={userId}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-username">
                Username
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-username"
                    className="slds-input"
                    value={Util.returnPropertyNameById(certificationsUsersData, userId, true)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-orgId">
                Organisation Id
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-orgId"
                    className="slds-input"
                    value={orgId}
                    readOnly
                  />

                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-orgName">
                Organisation Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-orgName"
                    className="slds-input"
                    value={Util.returnPropertyNameById(organisationsData, orgId)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-topicId">
                TopicId
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-topicId"
                    className="slds-input"
                    value={topicId}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-topicId">
                Topic Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-topicId"
                    className="slds-input"
                    value={Util.returnPropertyNameById(topics, topicId)}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="date">
                Date
                <div>
                  <input
                    type="date"
                    value={date}
                    readOnly
                  />
                </div>
              </label>
            </div>

            <label className="slds-form-element__label answered-checkbox" htmlFor="answered">
              Answered
              <div className="slds-form-element__control">
                <div className="slds-checkbox">
                  <input
                    onChange={() => setAnswered(!answered)}
                    type="checkbox"
                    name="Answered"
                    id="answered"
                    checked={answered}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="slds-checkbox__label" htmlFor="answered">
                    <span className="slds-checkbox_faux" />
                  </label>
                </div>
              </div>
            </label>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="slds-form-element__label answer" htmlFor="form-element-answer">
              Reply
              <div className="slds-form-element__control send-reply">
                <SunEditor
                  id="form-element-answer"
                  setContents={answer}
                  onChange={setAnswer}
                  autoFocus
                  width="100%"
                  height="300px"
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'align'],
                      ['fontSize', 'formatBlock', 'fontColor'],
                      ['bold', 'underline', 'italic', 'strike'],
                      ['link'],
                      ['lineHeight', 'list', 'table', 'fullScreen'],
                    ],
                  }}
                />
              </div>
            </label>
          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={isOpen ? 'slds-backdrop slds-backdrop_open' : ''} />
    </>
  );
};

CustomerQuestionModal.propTypes = {
  /* informs if a modal is open */
  isOpen: PropTypes.bool.isRequired,
  /** Label for question */
  question: PropTypes.string.isRequired,
  /** user Id */
  userId: PropTypes.string.isRequired,
  /** id of the topic in which the question was asked */
  topicId: PropTypes.string.isRequired,
  /** Id of the organization in which the user is assigned */
  orgId: PropTypes.string.isRequired,
  /** date of sending the question */
  date: PropTypes.string.isRequired,
  /** an array with organizations data */
  organisationsData: PropTypes.instanceOf(Array).isRequired,
  /** an array with all certification users data */
  certificationsUsersData: PropTypes.instanceOf(Array).isRequired,
  /** set state for answered property */
  setAnswered: PropTypes.func.isRequired,
  /** defines whether the question has been answered */
  answered: PropTypes.bool.isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** an array with topics */
  topics: PropTypes.instanceOf(Array).isRequired,
  /** response for the customer question */
  answer: PropTypes.string.isRequired,
  /** set state for answer property */
  setAnswer: PropTypes.func.isRequired,
};

export default CustomerQuestionModal;
