import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const FeaturesAPI = {
  /**
   * Retrieves all features
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved features - and `success`
   */
  getFeatures: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/features`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates a feature
   * @param {string} feature - feature label
   * @param {object} cancelToken - The cancel token from Axios
   * @return {object} An object with `data` property - the created feature - and `success`
   */
  createFeature: async (
    feature,
    cancelToken,
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/features`, {
        feature,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.data;
  },

  /**
   * Updates a feature
   * @param {string} id - ID of the feature you want to update
   * @param {string} feature - feature label
   * @param {object} cancelToken - The cancel token from Axios
   * @return {object} An object with `data` property - the updated feature - and `success`
   */
  updateFeature: async (
    id,
    feature,
    cancelToken,
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/features/${id}`, {
        feature,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.data;
  },

  /**
   * Deletes an exam
   * @param {string} id - ID of the feature you want to delete
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the deleted question - and `success`
   */
  deleteFeature: async (id, cancelToken) => {
    const res = await axios.delete(
      `${apiUrl}/admin/features/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default FeaturesAPI;
