const listOfGuidanceTips = [
  {
    tipId: 'selection-search-tip',
  },
  {
    tipId: 'run-preview-tip',
  },
  {
    tipId: 'available-filters-tip',
  },
  {
    tipId: 'available-fields-tip',
  },
  {
    tipId: 'related-records-tip',
  },
  {
    tipId: 'custom-relations-tip',
  },
  {
    tipId: 'dataextension-folders-tip',
  },
  {
    tipId: 'new-selection-tip',
  },
  {
    tipId: 'run-logs-tip',
  },
  {
    tipId: 'selection-tab-tip',
  },
  {
    tipId: 'available-data-sources-tip',
  },
  {
    tipId: 'selection-criteria-tip',
  },
  {
    tipId: 'target-definition-tip',
  },
  {
    tipId: 'preview-page-tip',
  },
  {
    tipId: 'run-button-tip',
  },
  {
    tipId: 'overview-tip',
  },
  {
    tipId: 'create-dataextension-tip',
  },
  {
    tipId: 'data-action-tip',
  },
  {
    tipId: 'sort-limit-tip',
  },
];

export default listOfGuidanceTips;
