import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import ExamsAPI from '../../../../api/academyCertifications/exams';
import QuestionsAPI from '../../../../api/academyCertifications/questions';
import FeaturesAPI from '../../../../api/academyCertifications/features';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import QuestionModal from './Modals/QuestionModal';
import FeaturesModal from './Modals/FeaturesModal';

const Questions = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const { questions, searchTextForQuestions, exams } = results;
  const [features, setFeatures] = useState([]);

  // Questions variables
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [relatedFeatures, setRelatedFeatures] = useState([]);

  // variables for search bar
  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchQuestions, setSearchQuestions] = useState([]);

  /**
   * Get all questions from database
   * @returns {void}
   */
  const getQuestions = async () => {
    try {
      // get questions and set state
      const res = await QuestionsAPI.getQuestions(null);
      setState({
        ...state,
        questions: res.data,
        searchTextForQuestions: '',
      });

      // set a variable to search for data
      setSearchQuestions(res.data);

      const valuesToUpdate = {};
      valuesToUpdate.questions = res.data;
      valuesToUpdate.searchTextForQuestions = '';
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Get all features from database
   * @returns {void}
   */
  const getFeatures = async () => {
    try {
      // Get features
      const res = await FeaturesAPI.getFeatures();

      // Update state with fetched features
      setFeatures(res.data);
    } catch (error) {
      Util.handleError(error);
    }
  };

  const saveFeature = async (value) => {
    const newFeature = await FeaturesAPI.createFeature(value);

    return newFeature;
  };

  const updateFeature = async (featureId, newValue) => {
    const updatedFeature = await FeaturesAPI.updateFeature(featureId, newValue);

    return updatedFeature;
  };

  const deleteFeature = async (featureId) => {
    await FeaturesAPI.deleteFeature(featureId);
  };

  /**
   * Handler called when exiting a features picklist modal
   * @returns {void}
   */
  const handleExit = () => {
    setState({ ...state, isOpen: false });
  };

  useEffect(() => {
    // when the field is not empty and there will be a switch between windows then get questions and features list
    if (searchTextForQuestions !== '' || !questions?.length) {
      getQuestions();
      getFeatures();
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearchQuestions = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === '' && isSearchFieldUsed) {
      // get all questions if the search field is empty and if it's used
      getQuestions();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      // Filter the questions, find search data
      const filteredQuestions = searchQuestions.length ?
        (
          searchQuestions.filter(c => c.question.toString().toLowerCase().includes(
            searchText.toString().toLowerCase(),
          ))
        ) :
        (
          questions.filter(c => c.question.toString().toLowerCase().includes(
            searchText.toString().toLowerCase(),
          ))
        );

      // Set filtered questions and search text
      setState({
        ...state,
        questions: filteredQuestions,
        searchTextForQuestions: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};
      valuesToUpdate.questions = filteredQuestions;
      valuesToUpdate.searchTextForQuestions = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setQuestion('');
    setAnswers([]);
    setCorrectAnswer('');
  };

  /**
   * Builds difficulty level text and badge
   * @param {string} difficulty - Difficulty of the question
   * @returns {HTMLElement} - The built difficulty level text and badge
   */
  const getDifficultyTextAndBadge = (difficulty) => {
    switch (difficulty) {
      case Constants.DIFFICULTY_LEVEL__EASY:
        return (
          <span className="slds-badge slds-theme_success">Easy</span>
        );
      case Constants.DIFFICULTY_LEVEL__MEDIUM:
        return (
          <span className="slds-badge slds-theme_warning">Medium</span>
        );
      case Constants.DIFFICULTY_LEVEL__HARD:
        return (
          <span className="slds-badge slds-theme_error">Hard</span>
        );
      case Constants.DIFFICULTY_LEVEL__UNCLEAR:
        return (
          <span className="slds-badge">Unclear</span>
        );
      default:
        return (
          <span className="slds-badge">Unclear</span>
        );
    }
  };

  /**
   * Handler called when saving question
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      question,
      answers,
      correctAnswer,
      relatedFeatures,
    };

    /**
     * Function that checks the validity of the entered data
     * @returns {void}
     */
    const validateResult = () => {
      const errors = [];

      // validate question label
      const validateForQuestionLabel = question.trim() === '';
      // eslint-disable-next-line no-unused-expressions
      validateForQuestionLabel && errors.push(validateForQuestionLabel);

      // validate answers
      const validateAnswers = answers.length === 0;
      // eslint-disable-next-line no-unused-expressions
      validateAnswers && errors.push('Please enter answer');

      // validate correct answer
      const validateCorrectAnswer = correctAnswer === '';
      // eslint-disable-next-line no-unused-expressions
      validateCorrectAnswer && errors.push('Please select correct answer');

      return errors;
    };

    if (validateResult().length > 0) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // when we are creating a question
          const res = await QuestionsAPI.createQuestion(null, newData);

          // set the data from response
          setResults({
            ...results,
            questions: [
              ...questions,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            questions: [
              ...questions,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The question has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // when we are updating a question
          const res = await QuestionsAPI.updateQuestion(null, state.id, newData);

          // set the data for the selected index
          const newArray = [...questions];
          newArray[state.index] = res.data;
          setResults({ ...results, questions: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            questions: newArray,
          });
          Swal.fireSuccess('Updated!', 'The question has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing a question
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = questions.filter(e => e._id === id)[0];
    setQuestion(singleItem.question);
    setAnswers(singleItem.answers);
    setCorrectAnswer(singleItem.correctAnswer);

    // Find all existing feature ids
    const featureIds = features.map(feature => feature._id);

    // Only show features that have not been deleted
    setRelatedFeatures(singleItem.relatedFeatures.filter(relatedFeature => featureIds.includes(relatedFeature)));
  };

  /**
   * Show the message content regarding to the exams, when the question is removed
   * @param {array} msgForUpdated - array with the names of the exams to be updated
   * @param {array} msgForDeleted - array with the names of the exams to be deleted
   * @returns {string} message content
   */
  const showSwalMsgForExams = (msgForUpdated, msgForDeleted) => {
    let updateMsg = '';
    let deleteMsg = '';

    // message content for the updated one exam
    if (msgForUpdated.length === 1) {
      updateMsg = `The exam: ${msgForUpdated.join(' , ')} has been updated. `;
    } else if (msgForUpdated.length > 1) {
      // message content for the updated exams
      updateMsg = `The exams: ${msgForUpdated.join(' , ')} have been updated. `;
    }

    // message content for the deleted one exam
    if (msgForDeleted.length === 1) {
      deleteMsg = `In the exam: ${msgForDeleted.join(' , ')}
      there are no selected questions, so it will be removed.`;
    }
    if (msgForDeleted.length > 1) {
      // message content for the deleted exams
      deleteMsg = `In the exams: ${msgForDeleted.join(' , ')}
      there are no selected questions, so they will be removed.`;
    }

    // return both messages
    return updateMsg + deleteMsg;
  };

  /**
   * Returns an array with the exams that need to be updated
   * @param {string} deletedQuestionID - The id of the deleted question
   * @returns {array} array with exams that need to be updated
   */
  const findAndUpdateExams = async (deletedQuestionID) => {
    let examsForUpdate;

    if (exams.length && exams.length > 0) {
      // if the exams were previously fetched, then find in exams
      examsForUpdate = exams.filter(ex => ex.availableQuestions.find(aq => aq === deletedQuestionID));
    } else {
      // if not, fetch all exams
      const fetchedExams = await ExamsAPI.getExams(null);

      if (fetchedExams && fetchedExams.data) {
        // search in fetched exams
        examsForUpdate = fetchedExams.data.filter(ex => ex.availableQuestions.find(aq => aq === deletedQuestionID));
      }
    }

    // return array with exams to be updated
    return examsForUpdate;
  };

  /**
   * Update or delete exam and return exams data
   * @param {object} exam - exam object
   * @param {array} newAvailableQuestions - new array with availableQuestions for exam
   * @returns {object} object with exam data
   */
  const fetchAndUpdateExams = async (exam, newAvailableQuestions) => {
    try {
      // if the exam still has selected questions
      if (newAvailableQuestions.length > 0) {
        // set new data to update the exam
        const newData = {
          name: exam.name,
          numberOfQuestions: (exam.numberOfQuestions > newAvailableQuestions.length) ?
            newAvailableQuestions.length :
            exam.numberOfQuestions,
          time: exam.time,
          threshold: exam.threshold,
          availableQuestions: newAvailableQuestions,
        };

        // update the exam
        const res = await ExamsAPI.updateExam(null, exam._id, newData);

        if (res && res.data) {
          // return object with updated data
          return { update: 'success', data: res.data };
        }
      } else {
        try {
          // The exam has no selected questions and it will be deleted
          await ExamsAPI.deleteExam(null, exam._id);

          return {
            // return object with deleted exam data
            delete: 'success', deletedExamName: exam.name, deletedExamId: exam._id,
          };
        } catch (error) {
          Util.handleError(error);
        }
      }
    } catch (error) {
      Util.handleError(error);
    }

    return false;
  };

  /**
   * Handler called when deleting a question
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const { id } = event.target.dataset;
      const { index } = event.target.dataset;

      // check if the question is already exists in the exam, return the exam array for updating
      const updatedExams = await findAndUpdateExams(id);

      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure to delete this question?',
        text: updatedExams && updatedExams.length > 0 ?
          `This question is selected in the exams.
        If you remove it, the exams will be updated.` :
          'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        // delete the question
        await QuestionsAPI.deleteQuestion(null, id);

        // Delete results for question
        const newArray = [...questions];
        newArray.splice(index, 1);

        // for exams that require updates
        if (updatedExams && updatedExams.length) {
          const promises = [];

          updatedExams.forEach((exam) => {
            // for each exam create a new array with the selected questions, without the question being deleted
            const newAvailableQuestions = exam.availableQuestions.filter(questionID => questionID !== id);

            /**
             * Function returns new Promise after update or delete the exam
             * @returns {Promise<Void>} void
             */
            const promiseForExamUpdate = () => new Promise((resolve) => {
              resolve(fetchAndUpdateExams(exam, newAvailableQuestions));
            });

            // add a promise function to the array with promises
            promises.push(promiseForExamUpdate());
          });

          if (promises.length > 0) {
            // if the async functions are resolved, then do some action
            Promise.all(promises).then(async (values) => {
              // create messages about exams to show in the modal
              const msgForUpdated = [];
              const msgForDeleted = [];

              // if the exams have already been fetched
              if (exams.length > 0) {
                // based on the obtained values, update the exams
                const newExams = exams.map((exam) => {
                  // check if the exam was updated or deleted
                  const findUpdateExam = values.find(value => value.update && value.data._id === exam._id);
                  const findDeletedExam = values.find(value => value.delete && value.deletedExamId === exam._id);
                  if (findUpdateExam) {
                    // if the exam was updated, add its name to the array
                    msgForUpdated.push(findUpdateExam.data.name);

                    // return updated exam
                    return findUpdateExam.data;
                  } if (findDeletedExam) {
                    // if the exam was deleted, add its name to the array
                    msgForDeleted.push(findDeletedExam.deletedExamName);

                    // do not return this exam
                    return false;
                  }

                  // return the remaining exams
                  return exam;
                });

                // filter new Exams
                const newFilteredExams = newExams.filter(exam => exam !== false);

                // update state for questions and exams
                setState({ ...state, exams: newFilteredExams, questions: newArray });
                setResults({ ...results, exams: newFilteredExams, questions: newArray });
              } else {
                // if exams have not been fetched, set the messages to display based on the updatedExams array
                updatedExams.map((exam) => {
                  // check if the exam was updated or deleted
                  const findUpdateExam = values.find(value => value.update && value.data._id === exam._id);
                  const findDeletedExam = values.find(value => value.delete && value.deletedExamId === exam._id);
                  if (findUpdateExam) {
                    // if the exam was updated, add its name to the array
                    msgForUpdated.push(findUpdateExam.data.name);
                  } if (findDeletedExam) {
                    // if the exam was deleted, add its name to the array
                    msgForDeleted.push(findDeletedExam.deletedExamName);
                  }

                  return false;
                });

                // update only questions
                setState({ ...state, questions: newArray });
                setResults({ ...results, questions: newArray });
              }

              // show proper swal message
              Swal.fireSuccess('Deleted!', `The question has been deleted.
            ${showSwalMsgForExams(msgForUpdated, msgForDeleted)}`);
            });
          }
        }

        // update only questions
        setState({ ...state, questions: newArray });
        setResults({ ...results, questions: newArray });

        // show proper swal message
        Swal.fireSuccess('Deleted!', 'The question has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling a question modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  return (
    <div className="certifications-questions">
      <div className="certifications-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={e => handleSearchQuestions(e)}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by Question"
        />
      </div>
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-certifications-questions">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Question">
                Question
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Answers">
                Answers
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Correct Answer">
                Correct Answer
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Difficulty">
                Difficulty
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="certifications-questions-body-table">
          {questions && questions.length > 0 && questions.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  <a
                    href="/#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Question">
                <div className="slds-truncate" title={el.question}>
                  {el.question}
                </div>
              </td>

              <td data-label="Answers">
                <div className="slds-truncate">
                  {el.answers && el.answers.length &&
                    el.answers.map((answer, index) => (
                      <li
                        className="answers-list-in-questions"
                        title={answer}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${index}-${answer}`}
                      >
                        {answer}
                      </li>
                    ))}
                </div>
              </td>

              <td data-label="Correct Answer">
                <div className="slds-truncate" title={el.answers[el.correctAnswer]}>
                  {el.answers[el.correctAnswer]}
                </div>
              </td>

              <td data-label="Difficulty">
                <div className="slds-truncate" title={el.difficulty}>
                  {getDifficultyTextAndBadge(el.difficulty)}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                  {new Date(el.createdAt).toLocaleString()}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                  {new Date(el.updatedAt).toLocaleString()}
                </div>
              </td>

              <td data-label="Actions">
                <div className="slds-truncate" title="Delete">
                  <svg
                    className="slds-icon slds-icon-text-error"
                    aria-hidden="true"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <use
                      xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                      data-id={el._id}
                      data-index={i}
                      onClick={handleDelete}
                      style={{ cursor: 'pointer' }}
                    />
                  </svg>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.eventType === Constants.EVENT__TYPE__FEATURES_PICKLIST ?
        (
          <FeaturesModal
            isOpen={state.isOpen}
            features={features}
            setFeatures={setFeatures}
            handleExit={handleExit}
            saveFeature={saveFeature}
            updateFeature={updateFeature}
            deleteFeature={deleteFeature}
          />
        ) :
        (
          <QuestionModal
            isOpen={state.isOpen}
            question={question}
            setQuestion={setQuestion}
            answers={answers}
            setAnswers={setAnswers}
            correctAnswer={correctAnswer}
            setCorrectAnswer={setCorrectAnswer}
            handleCancel={handleCancel}
            handleSave={handleSave}
            eventType={state.eventType}
            features={features}
            setRelatedFeatures={setRelatedFeatures}
            relatedFeatures={relatedFeatures}
          />
        )}
    </div>
  );
};

export default Questions;
