import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import Constants from '../../../../constants/constants';
import { ResultsContext } from '../../../../ResultsContext';
import Util from '../../../../util';
import { UtilsContext } from '../../../../UtilsContext';
import TopicModal from './Modals/TopicModal';
import './style.scss';
import ModulesAPI from '../../../../api/academyTrainings/modules';
import TopicsAPI from '../../../../api/academyTrainings/topics';
import TopicQuestionsAPI from '../../../../api/academyTrainings/topicQuestions';
import Spinner from './atoms/Spinner';

const Topics = () => {
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const {
    topics, searchTextForTopics, modules,
  } = results;

  // Topics variables
  const [name, setName] = useState('');
  const [orders, setOrders] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [moduleIds, setModuleIds] = useState([]);
  const [relatedLinks, setRelatedLinks] = useState([]);
  const [content, setContent] = useState('');
  const [enableContent, setEnableContent] = useState(false);

  // variables for search bar
  const [isSearchFieldUsed, setIsSearchFieldUsed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTopics, setSearchTopics] = useState([]);
  const [searchBy, setSearchBy] = useState(Constants.SEARCH_BY__TOPIC_NAME);

  // loading status when topic is saving
  const [saving, setSaving] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  /**
   * Get all topics from database
   * @returns {void}
   */
  const getTopics = async () => {
    try {
      // set loading data
      setLoadingData(true);

      // get all topics
      const res = await TopicsAPI.getAllTopics(null);

      // define modules data
      let moduleRes = { data: modules };

      if (!modules?.length) {
      // get all modules only if they have not been fetched
        moduleRes = await ModulesAPI.getAllModules(null);
      }

      // stop loading data
      setLoadingData(false);

      setState({
        ...state,
        topics: res.data,
        searchTextForTopics: '',
        modules: moduleRes.data,
      });

      // set a variable to search for data
      setSearchTopics(res.data);

      const valuesToUpdate = {};
      valuesToUpdate.topics = res.data;
      valuesToUpdate.modules = moduleRes.data;
      valuesToUpdate.searchTextForTopics = '';
      setResults({ ...results, ...valuesToUpdate });

      // clear searchText and SearchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    } catch (error) {
      Util.handleError(error);
    }
  };

  useEffect(() => {
    // when the field is not empty and there will be a switch between windows then get topics
    if (searchTextForTopics !== '' || !topics?.length || !modules?.length) {
      getTopics();
    } else if (topics?.length) {
      setSearchTopics(topics);
    }

    return () => {
      // clear searchState and searchFieldUsed state
      setSearchText('');
      setIsSearchFieldUsed(false);
    };
    /* eslint-disable-next-line */
  }, []);

  /**
   * Handler called while searching
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === '' && isSearchFieldUsed) {
      // get all topics if the search field is empty and if it's used
      getTopics();
    } else if (searchText !== '') {
      // set isSearchFieldUsed to true
      setIsSearchFieldUsed(true);

      let filteredTopics;

      /**
       * Search for topics by module name
       * @param {array} array - array to filter results
       * @returns {array} filtered array with search results
       */
      const searchByModuleName = array => (
        array.filter((top) => {
          // get all topics selected in the module
          const selectedModulesInTopic = modules.filter((mod) => {
            // get module by ID
            const moduleInTopic = top.moduleIds.find(moduleId => moduleId === mod._id);

            // if module is found - return it
            if (moduleInTopic) return mod;

            return null;
          });

          // match the name of the module assigned to the topic with the search results
          if (selectedModulesInTopic?.length) {
            const findModuleByName = selectedModulesInTopic.find(selectedModule => (
              selectedModule.name.toString().toLowerCase().includes(
                searchText.toString().toLowerCase(),
              )));

            if (findModuleByName) { return top; }
          }

          return null;
        }));

      // depends of searchBy
      if (searchBy === Constants.SEARCH_BY__TOPIC_NAME) {
        // Filter the topics by topic name
        filteredTopics = searchTopics?.length ?
          Util.searchByName(searchTopics, searchText) :
          Util.searchByName(topics, searchText);
      } else {
        // Filter the topics by module name
        filteredTopics = searchTopics?.length ?
          searchByModuleName(searchTopics) :
          searchByModuleName(topics);
      }

      // Set filtered topics and search text
      setState({
        ...state,
        topics: filteredTopics,
        searchTextForTopics: searchText,
      });

      // as a result, set the search results
      const valuesToUpdate = {};
      valuesToUpdate.topics = filteredTopics;
      valuesToUpdate.searchTextForTopics = searchText;
      setResults({ ...results, ...valuesToUpdate });
    }

    /* eslint-disable-next-line */
  }, [searchText, searchBy]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setName('');
    setModuleIds([]);
    setOrders([]);
    setVideoLink('');
    setRelatedLinks([]);
    setContent('');
    setEnableContent(false);
  };

  /**
   * Handler called when saving module
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      name,
      moduleIds,
      orders,
      videoLink,
      relatedLinks,
      content: enableContent ? content : '',
    };

    /**
     * Function that checks the validity of the entered data
     * @returns {void}
     */
    const validateResult = () => {
      const errors = [];

      // validate topic name label
      const validateForTopicLabel = name.trim() === '';
      // eslint-disable-next-line no-unused-expressions
      validateForTopicLabel && errors.push('Please select topic name');

      // validate modules
      const validateModule = moduleIds.length === 0;
      // eslint-disable-next-line no-unused-expressions
      validateModule && errors.push('Please select at least one module');

      // validate orders
      const validateOrder = orders.length !== moduleIds?.length;
      // eslint-disable-next-line no-unused-expressions
      validateOrder && errors.push('Please select order');

      let moduleId;

      // for each selected module
      moduleIds.forEach((tr) => {
        // get reduced array with order numbers
        const orderNumbersForModule = topics.reduce((array, top) => {
          // find order object for module
          const findModuleOrder = top.orders.find(o => o.moduleId === tr);

          // order number
          const order = findModuleOrder?.order;

          if (order) {
            // get selected module Id
            moduleId = findModuleOrder?.moduleId;

            // put order number to an array
            return [...array, order];
          }

          return array;
        }, []);

        if (orderNumbersForModule?.length) {
          // if a new module is being created
          if (state.eventType === Constants.EVENT__TYPE__NEW) {
            // push new order number to order numbers
            const newOrder = orders.find(el => el.moduleId === tr)?.order;
            orderNumbersForModule.push(newOrder);
          }

          // check if there is duplicate number in orders
          const duplicate = orderNumbersForModule.some((element, index) => (
            orderNumbersForModule.indexOf(element) !== index));

          if (duplicate) {
            // show swal message that this order exists for selected module
            errors.push(`This order already exists for ${Util.returnPropertyNameById(modules, moduleId)}`);
          }
        }
      });

      return errors;
    };

    if (validateResult().length > 0) {
      Swal.fireWarning('', validateResult()[0]);
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          // set loading status
          setSaving(true);

          // create topic
          const res = await TopicsAPI.createTopic(null, newData);

          // stop loading
          setSaving(false);

          // set the data from response
          setResults({
            ...results,
            topics: [
              ...topics,
              res.data,
            ],
          });

          // close the modal and set the state
          setState({
            ...state,
            isOpen: false,
            eventType: '',
            topics: [
              ...topics,
              res.data,
            ],
          });
          Swal.fireSuccess('Created!', 'The topic has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          // set loading status
          setSaving(true);

          // update topic
          const res = await TopicsAPI.updateTopic(null, state.id, newData);

          // stop loading
          setSaving(false);

          // set the data for the selected index
          const newArray = [...topics];
          newArray[state.index] = res.data;
          setResults({ ...results, topics: newArray });

          setState({
            ...state,
            isOpen: false,
            eventType: '',
            topics: newArray,
          });
          Swal.fireSuccess('Updated!', 'The topic has been updated.');
        }

        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when editing a module
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { target: { dataset: { index, id } } } = event;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = topics.filter(m => m._id === id)[0];

    setName(singleItem.name);
    setModuleIds(singleItem.moduleIds);
    setOrders(singleItem.orders);
    setVideoLink(singleItem.videoLink);
    setRelatedLinks(singleItem.relatedLinks || []);
    setContent(singleItem?.content ? Buffer.from(singleItem.content).toString() : '');
    setEnableContent(!!(singleItem?.content && Buffer.from(singleItem.content).toString() !== ''));
  };

  /**
   * Handler called when deleting training
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html
    const { target: { dataset: { index, id } } } = event;

    // fetch questions to check which question is linked to removed topic
    const questions = await TopicQuestionsAPI.getAllTopicQuestions(null);

    // get data for removed topic
    const dataForRemovedTopic = await Util.getAndRemoveQuestions(id, questions.data);

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure you want to delete this topic?',
        text: dataForRemovedTopic?.linkedQuestions?.length > 0 ?
          `This topic is selected in the questions.
        If you remove it, these questions will be removed as well.` :
          'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        // Delete topic
        await TopicsAPI.deleteTopic(null, id);

        // Delete results
        const newTopics = [...topics];
        newTopics.splice(index, 1);

        // for questions that require updates
        if (dataForRemovedTopic?.linkedQuestions?.length > 0) {
          // remove all questions that are linked to removed topic
          const data = await Util.getAndRemoveQuestions(id, questions.data, true);
          let newQuestions = questions.data;

          // if questions have been removed
          if (data?.removedQuestions?.length) {
            newQuestions = newQuestions.filter((question) => {
              // find the removed question and don't return it in newQuestions
              const removedQuestion = data.removedQuestions.find(q => q._id === question._id);

              if (removedQuestion) { return null; }

              return question;
            });
          }

          // update topics and topic questions
          setResults({ ...results, topics: newTopics, topicQuestions: newQuestions });
          setState({ ...state, topics: newTopics, topicQuestions: newQuestions });

          // show swal message about removed questions
          Swal.fireSuccess('Deleted!', 'The topic and linked questions have been deleted.');
        } else {
          // update topics
          setResults({ ...results, topics: newTopics });
          setState({ ...state, topics: newTopics });

          Swal.fireSuccess('Deleted!', 'The topic has been deleted.');
        }
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  return (
    <div className="self-learning-topics">
      <div className="self-learning-input-search-container">
        <Input
          onClick={(e) => {
            // Stop the dropdown's click handle from running when input is clicked
            e.stopPropagation();
          }}
          value={searchText}
          onChange={e => handleSearch(e)}
          icon="search"
          iconPosition="left"
          className="search"
          placeholder="Search by"
        />
        <div className="slds-form-element">
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select
                className="slds-select"
                onChange={event => setSearchBy(event.target.value)}
                id="select-type"
                value={searchBy}
              >
                <option value={Constants.SEARCH_BY__TOPIC_NAME}>Topic name</option>
                <option value={Constants.SEARCH_BY__MODULE_NAME}>Module name</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {loadingData ? <Spinner size="large" assistiveText="Loading..." /> : null}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset tr-topics">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Modules">
                Modules
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Video Link">
                Video Link
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Related Links">
                Related Links
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Order">
                Order
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="self-learning-topics-body-table">
          {!loadingData && topics?.length > 0 ?
            topics.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    <a
                      href="/#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Name">
                  <div className="slds-truncate" title={el.name}>
                    {el.name}
                  </div>
                </td>

                <td data-label="Trainings">
                  {el?.moduleIds?.length ?
                    (
                      <>
                        {el?.moduleIds?.length > 1 ?
                          (
                            <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                              <button
                                type="button"
                                className="slds-button slds-button_icon slds-button_icon-border-filled"
                                aria-haspopup="true"
                              >
                                <svg className="slds-button__icon" aria-hidden="true">
                                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                                </svg>
                                <span className="slds-assistive-text">Show Trainings</span>
                              </button>
                              <div className="slds-dropdown slds-dropdown_left">
                                <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                                  {el.moduleIds.map(mod => (
                                    <li key={mod} className="slds-dropdown__item" role="presentation">
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                      <a href="#" role="menuitem">
                                        <span
                                          className="slds-truncate slds-p-right--x-small"
                                          title={Util.returnPropertyNameById(modules, mod)}
                                        >
                                          {Util.returnPropertyNameById(modules, mod)}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) :
                          (
                            <div
                              className="slds-truncate"
                              title={Util.returnPropertyNameById(modules, el.moduleIds[0])}
                            >
                              {Util.returnPropertyNameById(modules, el.moduleIds[0])}
                            </div>
                          )}
                      </>
                    ) :
                    null}
                </td>

                <td data-label="Video Link">
                  <div className="slds-truncate" title={el.videoLink}>
                    {el.videoLink}
                  </div>
                </td>

                <td data-label="Related Links">
                  {el?.relatedLinks?.length ?
                    (
                      <>
                        {el.relatedLinks.length > 1 ?
                          (
                            <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                              <button
                                type="button"
                                className="slds-button slds-button_icon slds-button_icon-border-filled"
                                aria-haspopup="true"
                              >
                                <svg className="slds-button__icon" aria-hidden="true">
                                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                                </svg>
                                <span className="slds-assistive-text">Show Related Links</span>
                              </button>
                              <div className="slds-dropdown slds-dropdown_left">
                                <ul className="slds-dropdown__list" role="menu" aria-label="Show Related Link">
                                  {el.relatedLinks.map(link => (
                                    <li key={link.url} className="slds-dropdown__item" role="presentation">
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                      <a href="#" role="menuitem">
                                        <span
                                          className="slds-truncate slds-p-right--x-small"
                                          title={link.description}
                                        >
                                          {link.description}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) :
                          (
                            <div className="slds-truncate" title={el.relatedLinks[0].description}>
                              {el.relatedLinks[0].description}
                            </div>
                          )}
                      </>
                    ) :
                    null}
                </td>

                <td data-label="Orders">
                  {el?.orders?.length > 1 ?
                    (
                      <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                        <button
                          type="button"
                          className="slds-button slds-button_icon slds-button_icon-border-filled"
                          aria-haspopup="true"
                        >
                          <svg className="slds-button__icon" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                          </svg>
                          <span className="slds-assistive-text">Show Orders</span>
                        </button>
                        <div className="slds-dropdown slds-dropdown_left">
                          <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                            {el.orders.map(order => (
                              <li key={order.moduleId} className="slds-dropdown__item" role="presentation">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" role="menuitem">
                                  <span
                                    className="slds-truncate slds-p-right--x-small"
                                    title={`${Util.returnPropertyNameById(modules, order.moduleId)} - ${order.order}`}
                                  >
                                    {Util.abbreviate(Util.returnPropertyNameById(modules, order.moduleId), 35)}
                                    {' '}
                                    -
                                    {' '}
                                    <strong>{order.order}</strong>
                                  </span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) :
                    (
                      el?.orders[0]?.order?.toString()
                    )}
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.createdAt).toLocaleString()}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={new Date(el.updatedAt).toLocaleString()}>
                    {new Date(el.updatedAt).toLocaleString()}
                  </div>
                </td>

                <td data-label="Actions">
                  <div className="slds-truncate" title="Delete">
                    <svg
                      className="slds-icon slds-icon-text-error trash-delete-icon"
                      aria-hidden="true"
                      onClick={handleDelete}
                      data-id={el._id}
                      data-index={i}
                    >
                      <use
                        xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                        data-id={el._id}
                        data-index={i}
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            )) :
            null}
        </tbody>
      </table>
      <TopicModal
        isOpen={state.isOpen}
        name={name}
        setName={setName}
        moduleIds={moduleIds}
        setModuleIds={setModuleIds}
        orders={orders}
        setOrders={setOrders}
        videoLink={videoLink}
        setVideoLink={setVideoLink}
        handleCancel={handleCancel}
        handleSave={handleSave}
        eventType={state.eventType}
        modules={modules}
        topics={topics}
        relatedLinks={relatedLinks}
        setRelatedLinks={setRelatedLinks}
        content={content}
        setContent={setContent}
        saving={saving}
        enableContent={enableContent}
        setEnableContent={setEnableContent}
      />
    </div>
  );
};

export default Topics;
