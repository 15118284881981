import React, { useState, useContext, useEffect } from 'react';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import UsersAPI from '../../../../api/users';

import Swal from '../../../../helpers/Swal';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

import FilterByOrg from './FilterByOrg/FilterByOrg';

const UsersDataset = () => {
  const [results, setResults] = useContext(ResultsContext);
  const { usersData, organisationsData } = results;
  const [orgs, setOrgList] = useState(organisationsData);
  const [state, setState] = useContext(UtilsContext);
  const { showingFilteredUsers, selectedOrg } = state;

  // User properties
  const [usersList, setUsersList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setActive] = useState(false);
  const [zendeskID, setZendeskID] = useState('');
  const role = Util.getUserRole();

  // Map org id to org name
  const [OrgsName, setOrgsName] = useState({});
  /**
   * Stops the filtering of users
   * @returns {void}
   */
  const handleStopFiltering = () => {
    setUsersList(usersData);
    setState({ ...state, showingFilteredUsers: false, selectedOrg: null });
  };
  /**
   * Cleans all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setActive(false);
    setIsAdmin(false);
    setZendeskID('');
  };

  /**
   * Handler called when updating a user
   * @returns {void}
   */
  const handleSave = async () => {
    const newData = {
      isActive,
      isAdmin,
      zendeskID,
    };

    try {
      if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
        const res = await UsersAPI.updateUser(null, state.id, newData);

        const newArray = [...usersData];
        // Find the index of the updated user
        const index = newArray.findIndex(user => user._id === state.id);
        newArray[index] = res.data;
        setResults({ ...results, usersData: newArray });

        setState({ ...state, isOpen: false, eventType: '' });
        Swal.fireSuccess('Updated!', 'The user has been updated.');
      }
      cleanFields();
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling the editing of a user
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing an organisation
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });
    // Prefilled textbox
    const singleItem = usersData.filter(e => e._id === id)[0];
    setActive(singleItem.isActive);
    setIsAdmin(singleItem.isAdmin);
    setZendeskID(singleItem.zendeskID);
  };

  /**
   * Handles the filtering of users by organisation when an organisation
   * in the dropdown is clicked.
   * @param {object} data - The properties of the selected organisation
   * @returns {void}
   */
  const handleChangeSelectedOrg = (data) => {
    // Filter users by org
    const filteredUsersList = [...usersData].filter(org => org.orgId === data.value);

    // Find selected organisation
    const filteredOrganisation = [...organisationsData].find(org => org._id === data.value);

    // Set data
    setUsersList(filteredUsersList);

    // Set selected organisation
    setState({ ...state, showingFilteredUsers: true, selectedOrg: filteredOrganisation });
  };

  // UseEffect for setting users' and orgs' lists
  useEffect(() => {
    /**
     * usersData and usersList is basically the same data, except when we filter users by org
     * Here, we check if usersData is not empty and usersList is not the same as it, set usersList to it.
     * Same goes for organisationsData and orgsList.
     * This useEffect function is called again when usersData or organisationsData is changed
     */
    if (usersData && usersData.length && usersList !== usersData) {
      // Filter users
      if (showingFilteredUsers) {
        handleChangeSelectedOrg({ value: selectedOrg._id });
      } else {
        setUsersList(usersData);
      }
    }
    if (organisationsData && organisationsData.length) {
      const organisationsName = {};
      // Map organisation's id to its name
      organisationsData.forEach((org) => {
        organisationsName[org._id] = org.name;
      });
      setOrgsName(organisationsName);
      setOrgList(organisationsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData, organisationsData]);

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <div className="Users">
      {/* Dataset Section */}
      {usersList && usersList.length > 0 ?
        (
          <FilterByOrg
            orgs={orgs}
            handleChangeSelectedOrg={handleChangeSelectedOrg}
            handleStopFiltering={handleStopFiltering}
            showingFilteredUsers={showingFilteredUsers}
            selectedOrg={selectedOrg}
          />
        ) :
        null}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Username">
                Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Organisation">
                Organisation
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Last Active">
                Last Active
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is Admin">
                Is Admin
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is Active">
                Is Active
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="ZID">
                ZID
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {usersList.map((el, i) => (
            <tr key={el._id} className="slds-hint-parent">
              <td data-label="_id">
                <div title={el._id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={handleEdit}
                    data-id={el._id}
                    data-index={i}
                  >
                    {el._id}
                  </a>
                </div>
              </td>

              <td data-label="Name">
                <div className="slds-truncate" title={el.name}>
                  {el.name}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  {el.username}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  {OrgsName[el.orgId]}
                </div>
              </td>

              <td data-label="Last Active">
                <div className="slds-truncate">
                  {el.lastActive}
                </div>
              </td>

              <td data-label="Is Admin">
                <div className="slds-truncate">
                  {el.isAdmin ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td>
                <div className="slds-truncate">
                  {el.isActive ?
                    <span className="slds-badge slds-theme_success">True</span> :
                    <span className="slds-badge slds-theme_error">False</span>}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={el.createdAt}>
                  {el.createdAt}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={el.updatedAt}>
                  {el.updatedAt}
                </div>
              </td>

              <td>
                <div className="slds-truncate" title={el.zendeskID}>
                  {el.zendeskID}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={state.isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Users Form
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="isAdmin">
                Is admin
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setIsAdmin(!isAdmin)}
                      type="checkbox"
                      name="isAdmin"
                      id="isAdmin"
                      checked={isAdmin}
                      disabled={!userHasEditRights}
                    />
                    <label className="slds-checkbox__label" htmlFor="isAdmin">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isAdmin ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="isActive">
                Is active
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => setActive(!isActive)}
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      checked={isActive}
                      disabled={!userHasEditRights}
                    />
                    <label className="slds-checkbox__label" htmlFor="isActive">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isActive ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="ZendeskID">
                Zendesk ID
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      type="text"
                      id="form-element-name"
                      placeholder="ZendeskID"
                      className="slds-input"
                      onChange={event => setZendeskID(event.target.value)}
                      value={zendeskID}
                      disabled={role === Constants.ROLE__READ_ONLY}
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
    </div>
  );
};

UsersDataset.propTypes = {

};

export default UsersDataset;
