import React, {
  useState,
} from 'react';

import setTestTokensApi from '../../../../api/setTestTokens';

import './style.scss';

const SetTestTokens = () => {
  const [cookie, setCookie] = useState();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  /**
   * Handle changing Criteria input
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeCookie = (e) => {
    setCookie(e.target.value);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    e.preventDefault();

    // Disable submit button
    setSubmitButtonDisabled(true);
    try {
      setTestTokensApi.setCookie(null, cookie);
      // eslint-disable-next-line no-alert
      alert('Setting test token successful!');
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error setting cookie: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonDisabled(false);
  };

  return (
    <>
      Set cookie for automated test user for Plugin tests
      <br />

      <form id="form" onSubmit={submit}>

        <div className="slds-form-element">
          <div className="slds-form-element__label" htmlFor="criteria">
            <b>Note: Cookie set here will be used to refresh the access token for the user session.</b>
            <br />
            1) Open console log in browser on the Network tab, and have it Preserve log.
            <br />
            2) Login to SFMC with Automated Test User
            <br />
            3) In console in browser, look at any of the XHR requests done for Marketing Cloud
            (careful, some requests are for Google Analytics and so on).
            You can probably see an update-token.json or session-status.json request.
            Under Headers, check the Request Header, and copy the value for Cookie.
            <br />
          </div>
          <div className="slds-form-element__control">
            <textarea
              id="cookie"
              placeholder=""
              className="slds-textarea"
              onChange={e => handleChangeCookie(e)}
            />
          </div>
        </div>

        <br />

        <button type="submit" className="slds-button slds-button_brand" id="submit" disabled={submitButtonDisabled}>
          Submit
        </button>

      </form>
    </>
  );
};

export default SetTestTokens;
