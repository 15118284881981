import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const CertificationsUserAPI = {
  /**
   * Retrieves all certifications user
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved users - and `success`
   */
  getCertificationUsers: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/certification-users`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates certification user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} userData - object with certification user data
   * @param {string} userData.username - username
   * @param {string} userData.password - user password
   * @param {string} userData.name - user name
   * @param {string} userData.isAdmin - defines whether it can manage certificates
   * @param {string} userData.orgId - organisation id link to the user
   * @param {boolean} userData.isPaid - defines whether the user is free or not
   * @param {string} userData.group - similar to organisation name, defines the group the user belongs to
   * @param {string} userData.expirationDate - optional, date access to certifications should expire
   * @return {object} An object with `data` property - the created user - and `success`
   */
  createCertificationUser: async (
    cancelToken,
    {
      username,
      password,
      name,
      isAdmin,
      orgId,
      paidForCertification,
      isPaid,
      group,
      expirationDate,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/certification-users`, {
        username,
        password,
        name,
        isAdmin,
        orgId,
        paidForCertification,
        isPaid,
        group,
        expirationDate,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates certification user
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user you want to update
   * @param {object} userData - object with certification user data
   * @param {string} userData.username - username
   * @param {string} userData.password - user password
   * @param {string} userData.name - user name
   * @param {string} userData.orgId - organisation Id link to the user
   * @param {boolean} userData.isPaid - defines whether the user is free or not
   * @param {string} userData.group - similar to organisation name, defines the group the user belongs to
   * @param {boolean} changePassword - defines whether the password should be changed
   * @return {object} An object with `data` property - the updated user - and `success`
   */
  updateCertificationUser: async (
    cancelToken,
    id,
    {
      username,
      password,
      name,
      isAdmin,
      orgId,
      paidForCertification,
      isPaid,
      group,
      expirationDate,
    },
    changePassword = false,
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/certification-users/${id}`, {
        username,
        password,
        name,
        isAdmin,
        orgId,
        paidForCertification,
        isPaid,
        group,
        expirationDate,
        changePassword,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates multiple certification users
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user you want to update
   * @param {object} postData - object with post data
   * @param {array} postData.certificationUsersIds - array with user IDs
   * @param {string} postData.examId - id of the exam
   * @return {object} An object with `data` property - the updated users - and `success`
   */
  updateManyCertificationUsers: async (
    cancelToken,
    {
      certificationUsersIds,
      examId,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/certification-users/update-many`, {
        certificationUsersIds,
        examId,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default CertificationsUserAPI;
