import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_CERTIFICATION_URL;

const TopicsAPI = {
  /**
   * Retrieves all topics
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved topics - and `success`
   */
  getAllTopics: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/topics`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Creates topic
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} topicData - object with topic data
   * @param {string} topicData.name - topic name
   * @param {string} topicData.videoLink - video URL link
   * @param {array} topicData.moduleIds - array with module ids
   * @param {array} topicData.orders - array with objects: trainingId and order
   * @param {array} topicData.relatedLinks - array with related links array
   * @return {object} An object with `data` property - the created topic - and `success`
   */
  createTopic: async (
    cancelToken,
    {
      name,
      videoLink,
      moduleIds,
      orders,
      relatedLinks,
      content,
    },
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/topics`, {
        name,
        videoLink,
        moduleIds,
        orders,
        relatedLinks,
        content,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Updates topic
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the topic you want to update
   * @param {object} topicData - object with topic data
   * @param {string} topicData.name - topic name
   * @param {string} topicData.videoLink - video URL link
   * @param {array} topicData.moduleIds - array with module ids
   * @param {array} topicData.orders - array with objects: trainingId and order
   * @param {array} topicData.relatedLinks - array with related links array
   * @return {object} An object with `data` property - the updated topic - and `success`
   */
  updateTopic: async (
    cancelToken,
    id,
    {
      name,
      videoLink,
      moduleIds,
      orders,
      relatedLinks,
      content,
    },
  ) => {
    const res = await axios.put(
      `${apiUrl}/admin/topics/${id}`, {
        name,
        videoLink,
        moduleIds,
        orders,
        relatedLinks,
        content,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },

  /**
   * Deletes topic
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the topic you want to delete
   * @returns {object} An object with `data` property - the deleted topic - and `success`
   */
  deleteTopic: async (cancelToken, id) => {
    const res = await axios.delete(
      `${apiUrl}/admin/topics/${id}`, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default TopicsAPI;
