import React, { useState, useEffect, useContext } from 'react';
import ApexChart from 'react-apexcharts';
import {
  Loader,
  Modal,
} from 'semantic-ui-react';

import { ResultsContext } from '../../../../ResultsContext';
import { MenuContext } from '../../../../MenuContext';

import './style.scss';
import './animation.scss';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import NPSReport from './NPSReport';
import SupportTicketReport from './SupportTicketReport';

const EssentialsDashboard = () => {
  const [results] = useContext(ResultsContext);
  const [resultsList, setResultsList] = useState([]);
  const [unmappedTicketsList, setUnmappedTicketsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { essentialsDashboardData, unmappedTicketsData } = results;

  const menuContext = useContext(MenuContext);
  const [, setMenuSelected] = menuContext.menuSelected;
  const [, setMenuParameters] = menuContext.menuParameters;

  useEffect(() => {
    // Show unfiltered data
    setResultsList(essentialsDashboardData);

    setUnmappedTicketsList(unmappedTicketsData);

    // eslint-disable-next-line
  }, [essentialsDashboardData, unmappedTicketsData]);

  // Data from result
  const {
    customerOrgs = [],
  } = resultsList;

  useEffect(() => {
    // We only stop loading when we have the customer data ready
    if (customerOrgs.length) {
      setLoading(false);
    }
  }, [customerOrgs]);

  const averageHealthScore =
    customerOrgs.reduce((acc, cur) => acc + cur.sprintHealthScore, 0) / customerOrgs.length;

  // Health Score Chart, based on number of customers instead of acv
  const customerHealthScoreChart = {
    series: [
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__HEALTHY_VALUE ? 1 : 0), 0),
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__NEUTRAL_VALUE ? 1 : 0), 0),
      customerOrgs.reduce((acc, cur) => acc +
        (Util.getHealthScoreRank(cur.sprintHealthScore) === Constants.HS__AT_RISK_VALUE ? 1 : 0), 0),
    ],
    options: {
      labels: ['Healthy', 'Neutral', 'At Risk'],
      colors: [Constants.HS__HEALTHY_COLOR, Constants.HS__NEUTRAL_COLOR, Constants.HS__AT_RISK_COLOR],
      legend: {
        show: false,
      },
    },
  };

  // NPS Responses
  const npsResponses = customerOrgs.reduce((acc, cur) => {
    if (cur.npsOverview.responses.length) {
      return [...acc, ...cur.npsOverview.responses.map(response => ({ ...response, org: cur.name }))];
    }

    return acc;
  }, []).sort((a, b) => b.dataWeek - a.dataWeek);

  // Support Portal Tickets
  const supportTickets = customerOrgs.reduce((acc, cur) => {
    if (cur.supportTicketsOverview.length) {
      return [...acc, ...cur.supportTicketsOverview.map(response => ({ ...response, org: cur.name }))];
    }

    return acc;
  }, []);

  const allSupportTickets =
    [...supportTickets, ...unmappedTicketsList].sort((a, b) => new Date(b.openDate) - new Date(a.openDate));

  const openBugs = allSupportTickets.reduce((acc, cur) => acc +
    ((cur.type === Constants.ZENDESK_TICKET__TYPE__BUG && cur.status === Constants.ZENDESK_TICKET__STATUS__OPEN ?
      1 :
      0
    )),
  0);

  /**
   * handler to open link to Adoption Dashboard
   * @param {object} event - Event object
   *  @returns {void}
   *
   */
  const openAdoptionDashboard = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    const orgId = event.target.dataset.id;
    setMenuSelected(linkName);
    setMenuParameters({ orgId });
  };

  return loading ?
    (
      <Modal
        open={loading}
        size="tiny"
        centered
        className="modal"
      >
        <Loader size="large">Loading...</Loader>
      </Modal>
    ) :
    (
      <div className="dashboard">
        <div className="slds-grid">
          <div className="slds-size_12-of-12" style={{ display: 'grid' }}>
            <div className="slds-box">
              <div className="box-content list" style={{ height: '300px', overflowX: 'scroll' }}>
                <table
                  className="slds-table slds-table_bordered slds-table_fixed-layout"
                  style={{ fontSize: '12px', minWidth: '1000px' }}
                >
                  <thead>
                    <tr className="slds-line-height_reset">

                      <th scope="col" className="idColumn" style={{ width: '40px' }}>
                        <div
                          className="slds-truncate"
                          title="Id"
                        >
                          Id
                        </div>
                      </th>

                      <th scope="col" style={{ width: '180px' }}>
                        <div className="slds-truncate" title="Name">
                          Name
                        </div>
                      </th>

                      <th scope="col" style={{ width: '80px' }}>
                        <div className="slds-truncate" title="Health Score">
                          Sprint HS
                        </div>
                      </th>

                      <th scope="col" style={{ width: '60px' }}>
                        <div className="slds-truncate" title="NPS">
                          NPS
                        </div>
                      </th>

                      <th scope="col" style={{ width: '90px' }}>
                        <div
                          className="slds-truncate"
                          title="Number of Active users"
                        >
                          Active Users
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerOrgs ?
                      customerOrgs.map(o => (
                        <tr key={o._id} className="slds-hint-parent">

                          <th className="id" data-label="Id" scope="row">
                            <div className="slds-truncate" title={o._id}>
                              {o._id}
                            </div>
                          </th>

                          <th data-label="Name" scope="row">
                            <div className="slds-truncate" title={o.name}>
                              {Util.renderAdoptionDashboardLink(o.name, o._id, openAdoptionDashboard)}
                            </div>
                          </th>

                          <th data-label="Sprint Health Score" scope="row">
                            <div className="slds-truncate" title={o?.sprintHealthScore}>
                              {(o?.sprintHealthScore === null) ?
                                null :
                                Util.renderHealthScoreBlock(
                                  o?.sprintHealthScore?.toFixed(0),
                                  o?.healthScoreTrend?.toFixed(2),
                                  o?.churnProbability,
                                  Constants.HS__MAX_SCORE,
                                )}
                            </div>
                          </th>

                          <th data-label="NPS" scope="row">
                            <div className="slds-truncate" title={o?.healthScore?.healthScore}>
                              {o?.npsOverview ?
                                Util.renderNPSRange(Util.calculateAverageNPS(o.npsOverview)) :
                                null}
                            </div>
                          </th>

                          <th data-label="% Licenses" scope="row">
                            <div className="slds-truncate" title={o.numberOfActiveUsers}>
                              {(o.numberOfActiveUsers)}
                            </div>
                          </th>
                        </tr>
                      )) :
                      null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="slds-grid">
          <div className="slds-size_1-of-3">
            <div className="slds-box">
              <span className="box title slds-has-flexi-truncate">Health Score</span>
              <div className="box-content chart" style={{ height: '200px' }}>
                {
                  customerHealthScoreChart.series[0] !== 0 ||
                  customerHealthScoreChart.series[1] !== 0 ||
                  customerHealthScoreChart.series[2] !== 0 ?
                    (
                      <ApexChart
                        series={customerHealthScoreChart.series}
                        options={customerHealthScoreChart.options}
                        // eslint-disable-next-line spellcheck/spell-checker
                        type="donut"
                        width="100%"
                        height="200px"
                      />
                    ) :
                      <span className="slds-align_absolute-center no-data">No data available</span>
                }
              </div>
            </div>
          </div>
          <div className="slds-size_1-of-3">
            <div className="slds-box">
              <span className="box-title slds-has-flexi-truncate"># Orgs</span>
              <div
                className="box-content number"
                style={{ height: '100px', fontSize: '80px' }}
              >
                {customerOrgs.length}
                &nbsp;
                {Util.renderHealthScoreColor(averageHealthScore?.toFixed(2), Constants.HS__MAX_SCORE)}
              </div>
            </div>
          </div>
          <div className="slds-size_1-of-3">
            <div className="slds-box">
              <span className="box-title slds-has-flexi-truncate">Active Bugs</span>
              <div
                className="box-content number"
                style={{ height: '100px', fontSize: '80px' }}
              >
                {openBugs}
              </div>
            </div>
          </div>
        </div>
        <SupportTicketReport
          context={Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD}
          supportTickets={allSupportTickets}
        />
        <NPSReport
          context={Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD}
          promoters={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.promoters, 0)}
          passives={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.passives, 0)}
          detractors={customerOrgs.reduce((acc, cur) => acc + cur.npsOverview.detractors, 0)}
          npsResponses={npsResponses}
        />
      </div>
    );
};

export default EssentialsDashboard;
