import React, { useContext, useState, useEffect } from 'react';

import ReactJson from 'react-json-view';
import { UtilsContext } from '../../../../UtilsContext';
import Constants from '../../../../constants/constants';

import { ResultsContext } from '../../../../ResultsContext';

import ChangeLogsAPI from '../../../../api/changelogs';
import Swal from '../../../../helpers/Swal';

import Filter from './Filter';

const ChangeLogsDataset = () => {
  const [results, setResults] = useContext(ResultsContext);
  const { changeLogsData, filteredResults } = results;
  const [logsList, setLogsList] = useState([]);
  const [state, setState] = useContext(UtilsContext);

  const { showingFilteredResults, filter } = state;

  const [isOpen, setOpen] = useState(false);
  const [JSONResOld, setJSONOld] = useState(Object);
  const [JSONResNew, setJSONNew] = useState(Object);
  const [loading, setLoading] = useState(false);

  // Format some properties
  changeLogsData.forEach((r) => {
    // For both old and new value, we format them in a different way
    let { oldValue, newValue } = r;

    if (typeof oldValue === 'boolean') {
      oldValue = oldValue.toString();
    }

    if (typeof newValue === 'boolean') {
      newValue = newValue.toString();
    }

    Object.assign(r, {
      oldValue,
      newValue,
    });
  });

  const filterResultsHandler = async (e, filterBy, searchText) => {
    setLoading(true);
    const filterCriteria = {};

    // If filtering by documentId
    if (filterBy === Constants.FILTER_BY__DOCUMENT_ID) {
      filterCriteria.documentId = searchText;
    }

    try {
      // Get filtered logs
      const res = await ChangeLogsAPI.getChangeLogs(null, filterCriteria);

      // Set state
      setState({ ...state, showingFilteredResults: true, filter: searchText });

      // Show filtered logs
      setResults({ ...results, filteredResults: res.data });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `Something unexpected happened: ${error.message || error}`,
      );
    }
  };

  const handleJSONResult = (id) => {
    const res = changeLogsData.find(r => r._id === id);
    setJSONOld(res.oldValue);
    setJSONNew(res.newValue);
    setOpen(true);
  };

  /**
   * Stops the filtering of logs
   * @returns {void}
   */
  const handleStopFiltering = () => {
    // Reset values
    setLogsList(changeLogsData);
    setState({ ...state, showingFilteredResults: false, filter: null });
    setResults({ ...results, filteredResults: [] });
  };

  // UseEffect for setting logs' lists
  useEffect(() => {
    // If filtering...
    if ((filteredResults && filteredResults.length) || showingFilteredResults) {
      setLogsList(filteredResults);
    } else {
      // Show unfiltered data
      setLogsList(changeLogsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLogsData, filteredResults]);

  return (
    <div className="changeLog">
      <Filter
        handleStopFiltering={handleStopFiltering}
        showingFilteredItems={showingFilteredResults}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filter}
      />
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="Modified document Id">
                Modified document Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Document type">
                Document type
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Modified by">
                Modified by
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Property changed">
                Property changed
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Old value">
                Old value
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="New value">
                New value
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Description">
                Description
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {logsList && logsList.length > 0 ?
            logsList.map(el => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="Modified document Id">
                  <div title={el.documentId}>
                    {el.documentId}
                  </div>
                </td>

                <td data-label="Document type">
                  <div className="slds-truncate">
                    <span className="slds-badge">{el.documentType}</span>
                  </div>
                </td>

                <td data-label="Modified by">
                  <div className="slds-truncate" title={`${el.modifiedByName} (${el.modifiedById})`}>
                    {`${el.modifiedByName} (${el.modifiedById})`}
                  </div>
                </td>

                <td data-label="Property changed">
                  <div className="slds-truncate" title={el.property}>
                    <span className="slds-badge">{el.property}</span>
                  </div>
                </td>

                <td data-label="Old value">
                  <div
                    className="slds-truncate"
                    title={
                    el.oldValue && (typeof el.oldValue === 'object' ?
                      'Click to Open JSON Results' :
                      el.oldValue.toString())
                    }
                  >
                    {el.oldValue && (typeof el.oldValue === 'object' ?
                      <a href="/#" onClick={() => handleJSONResult(el._id, 'oldValue')}>Click to Open JSON Results</a> :
                      el.oldValue)}
                  </div>
                </td>

                <td data-label="New value">
                  <div
                    className="slds-truncate"
                    title={
                    el.newValue && (typeof el.newValue === 'object' ?
                      'Click to Open JSON Results' :
                      el.newValue.toString())
                    }
                  >
                    {el.newValue && (typeof el.newValue === 'object' ?
                      <a href="/#" onClick={() => handleJSONResult(el._id, 'newValue')}>Click to Open JSON Results</a> :
                      el.newValue)}
                  </div>
                </td>

                <td data-label="Description">
                  <div className="slds-truncate" title={el.description}>
                    {el.description}
                  </div>
                </td>

                <td data-label="Created at">
                  <div className="slds-truncate" title={el.createdAt}>
                    {new Date(el.createdAt).toLocaleString()}
                  </div>
                </td>
              </tr>
            )) :
            (
              <tr>
                <td colSpan="9" className="no-results-found">No results found</td>
              </tr>
            )}
        </tbody>
      </table>

      {isOpen ?
        (
          <div className="slds-grid">
            {typeof JSONResOld === 'object' ?
              (
                <div className="slds-size_1-of-2">
                  <div className="slds-box" style={{ backgroundColor: 'white' }}>
                    <span className="box title slds-has-flexi-truncate">Old Value</span>
                    <div className="box-content">
                      <ReactJson
                        src={typeof JSONResOld === 'object' ? JSONResOld : {}}
                        style={{ fontSize: 'small' }}
                        name={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataSize={false}
                        displayDataTypes={false}
                        collapsed={false}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                      />
                    </div>
                  </div>
                </div>
              ) :
              null}
            {typeof JSONResNew === 'object' ?
              (
                <div className="slds-size_1-of-2">
                  <div className="slds-box" style={{ backgroundColor: 'white' }}>
                    <span className="box title slds-has-flexi-truncate">New Value</span>
                    <div className="box-content">
                      <ReactJson
                        src={typeof JSONResNew === 'object' ? JSONResNew : {}}
                        style={{ fontSize: 'small' }}
                        name={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataSize={false}
                        displayDataTypes={false}
                        collapsed={false}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                      />
                    </div>
                  </div>
                </div>
              ) :
              null}
          </div>
        ) :
        null}

    </div>
  );
};

export default ChangeLogsDataset;
