import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import '../../AcademyCertifications/atoms/style.scss';
import PropTypes from 'prop-types';
import Swal from '../../../../../helpers/Swal';

const AddRelatedLinks = ({ relatedLinks, setRelatedLinks }) => {
  const [urlLink, setUrlLink] = useState('');
  const [urlLinkEdit, setUrlLinkEdit] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEdit, setDescriptionEdit] = useState('');
  const [currentlyEditedContent, setCurrentlyEditedContent] = useState(false);
  const [currentlyEditedID, setCurrentlyEditedID] = useState(false);

  /**
   * Function for adding related link object
   * @returns {void}
   */
  const addRelatedLink = () => {
    if (urlLink.trim() !== '') {
      let desc = description;

      // if description is empty
      if (description.trim() === '') {
        // assign value from url link
        setDescription(urlLink);
        desc = urlLink;
      }

      // check if the urlLink or description is not duplicated
      const duplicateURL = relatedLinks.find(ans => ans.url.trim() === urlLink.trim());
      const duplicateDescription = relatedLinks.find(ans => ans.description.trim() === desc.trim());
      if (duplicateURL || duplicateDescription) {
        // depending on the props display the message
        const message = duplicateURL ?
          'This link already exists. Please enter another one.' :
          'This description already exists. Please enter another one.';

        /*
         * show swal message and don't let to add a duplicate url
         * do delay on showing modal so that the modal will not close after pressing enter
         */
        setTimeout(() => {
          Swal.fireWarning('', message);
        }, 50);
      } else {
        // add link to the related links array
        setRelatedLinks([...relatedLinks, {
          url: urlLink,
          description: desc,
        }]);

        // clear the data
        setUrlLink('');
        setDescription('');
      }
    }

    return false;
  };

  /**
   * Function for editing the property the added to related link - url or description
   * @param {string} editedProperty - currently edited property
   * @param {number} indexContent - related link index
   * @param {bool} url - indicates whether the property being changed is an url
   * @returns {void}
   */
  const handleEditClick = (editedProperty, indexContent, url) => {
    // define property to be edit
    const propertyToEdit = url ? urlLinkEdit : descriptionEdit;

    // don't let to enter an empty value
    if (propertyToEdit.trim() === '') {
      // depending on what value is changed, clear the value to edit
      if (url) {
        setUrlLinkEdit('');
      } else {
        setDescriptionEdit('');
      }

      // clear states related to edit
      setCurrentlyEditedContent(null);
      setCurrentlyEditedID(null);
    } else {
      // create new content for edited property
      const newPropertyContent = propertyToEdit;

      // edit object in array with related links
      setRelatedLinks(
        relatedLinks.map((linkObject, index) => {
          // get the property of the new value
          const link = url ? linkObject.url : linkObject.description;

          // define edited object with new value
          const objectWithNewContent = url ?
            {
              ...linkObject,
              url: newPropertyContent,
            } :
            {
              ...linkObject,
              description: newPropertyContent,
            };

          if (link !== editedProperty || index !== indexContent) {
            // if the value does not differ from the previous one, set the old value
            return linkObject;
          }

          // return object with changed value
          return objectWithNewContent;
        }),
      );

      // clear the state related to edit
      if (url) { setUrlLinkEdit(''); } else { setDescriptionEdit(''); }

      setCurrentlyEditedContent(null);
      setCurrentlyEditedID(null);
    }
  };

  /**
   * Function for removing object from relatedLinks array
   * @param {object} link - removed link object
   * @param {number} index - object index
   * @returns {void}
   */
  const removeLinks = (link, index) => {
    setRelatedLinks(relatedLinks.filter((a, i) => (a.url !== link.url &&
        a.description !== link.description) || i !== index));
  };

  /**
   * Function that shows the input after pressing the icon to edit
   * @param {object} link - removed link object
   * @param {number} index - object index
   * @param {bool} url - indicates whether the property being changed is an url
   * @returns {void}
   */
  const showInput = (link, index, url) => (
    <>
      <input
        className="slds-input add-answer-input add-questions-input link"
        id={link}
        placeholder={url ? 'Type and add the URL Link' : 'Type and add the description'}
        value={url ? urlLinkEdit : descriptionEdit}
        onKeyDown={e => (e.key === 'Enter' && handleEditClick(link, index, url))}
        onChange={e => url ? setUrlLinkEdit(e.target.value) : setDescriptionEdit(e.target.value)}
      />
      <button
        type="button"
        className="slds-button slds-button_icon slds-button_neutral
        add-questions-button edit-link-button"
        onClick={() => handleEditClick(link, index, url)}
      >
        Edit
      </button>
    </>
  );

  /**
   * Function which sets the state before editing the related link
   * @param {string} content - edited content
   * @param {number} index - index of the edited content
   * @param {bool} url - indicates whether the property being changed is an url
   * @returns {void}
   */
  const editIconFunction = (content, index, url) => {
    // set the edited property to be shown in the input field
    setCurrentlyEditedContent(content);

    // create ID for edited content using prefix consisting of a property name
    const IdPrefix = url ? 'url' : 'description';
    setCurrentlyEditedID(`${IdPrefix}-${index}`);

    // set the value of the edited content
    if (url) { setUrlLinkEdit(content); } else { setDescriptionEdit(content); }
  };

  return (
    <div className="add-questions-wrapper">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="slds-form-element__label" htmlFor="Answers">
        Related Links (optional)
        {relatedLinks?.length ?
          (
            <div className="related-links-label">
              <span>URL</span>
              <span>Description</span>
            </div>
          ) :
          null}
      </label>
      <div className="slds-form-element__control questions-list">
        {relatedLinks.map((link, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className="question-item" key={`${index}-${link}`}>
            <div className="question-icon-button-box">
              {currentlyEditedContent === link.url && currentlyEditedID === (`url-${index}`) ?
                (
                  showInput(link.url, index, true)
                ) :
                (
                  <p>
                    {index + 1}
                    .
                    {' '}
                    {link.url}
                  </p>
                )}
              {currentlyEditedID !== (`url-${index}`) && (
                <button
                  type="button"
                  className="icon-button-add-related-links"
                  onClick={() => editIconFunction(link.url, index, true)}
                >
                  <Icon name="edit" />
                </button>
              )}
              {currentlyEditedContent === link.description && currentlyEditedID === (`description-${index}`) ?
                (
                  showInput(link.description, index)
                ) :
                (
                  <p>
                    {index + 1}
                    .
                    {' '}
                    {link.description}
                  </p>
                )}
              {currentlyEditedID !== (`description-${index}`) && (
                <button
                  type="button"
                  className="icon-button-add-related-links"
                  onClick={() => editIconFunction(link.description, index)}
                >
                  <Icon name="edit" />
                </button>
              )}
              <button
                type="button"
                className="icon-button-add-related-links remove"
                onClick={() => removeLinks(link, index)}
              >
                <Icon name="trash" />
              </button>
            </div>
          </li>
        ))}
      </div>
      <div className="slds-form-element input-button-add-question">
        <input
          className="slds-input add-answer-input placeholder add-questions-input link"
          type="text"
          placeholder="Type and add the URL link"
          onKeyDown={e => (e.key === 'Enter' && addRelatedLink())}
          value={urlLink}
          title="Type and add the URL link"
          onChange={(e) => { setUrlLink(e.target.value); }}
        />
        <input
          className="slds-input add-answer-input placeholder add-questions-input link"
          type="text"
          placeholder="Type and add the description"
          onKeyDown={e => (e.key === 'Enter' && addRelatedLink())}
          value={description}
          title="Type and add the description"
          onChange={(e) => { setDescription(e.target.value); }}
        />
        <button
          type="button"
          onClick={addRelatedLink}
          className="slds-button slds-button_icon slds-button_neutral add-answer-button"
          title="Add Link"
        >
          Add Link
        </button>
      </div>
    </div>
  );
};

AddRelatedLinks.propTypes = {
  /* handling of related links state change */
  setRelatedLinks: PropTypes.func.isRequired,
  /* array with related links */
  relatedLinks: PropTypes.instanceOf(Array).isRequired,
};

export default AddRelatedLinks;
