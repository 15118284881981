/* eslint-disable spellcheck/spell-checker */
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SFMCOffboardingAPI = {

  /**
   * Get all BUs
   * @returns {Array[]} Array of BUs
   */
  getAllBUs: async () => {
    const res = await axios.post(
      `${apiUrl}/admin/sfmc-offboarding/business-units/list`,
      {},
      { withCredentials: true },
    );
    return res.data;
  },

  /**
   * Delete a BU
   * @param {string} buCustomerKey BU Customer Key
   * @returns {boolean} True if successful
   */
  deleteBU: async (buCustomerKey) => {
    const res = await axios.post(
      `${apiUrl}/admin/sfmc-offboarding/business-units/delete`,
      { buCustomerKey },
      { withCredentials: true },
    );
    return res.data;
  },

  /**
   * Get all users associated with a BU
   * @param {*} buId BU ID
   * @returns {Array[]} Array of associated users
   */
  getAssociatedUsers: async (buId) => {
    const res = await axios.post(
      `${apiUrl}/admin/sfmc-offboarding/business-units/associated-users`,
      { buId },
      { withCredentials: true },
    );
    return res.data;
  },

  /**
   * Dissociate users from a BU
   * @param {*} buId BU ID
   * @param {*} userList List of user IDs
   * @returns {boolean} True if successful
   */
  dissociateUsers: async (buId, userList) => {
    const res = await axios.post(
      `${apiUrl}/admin/sfmc-offboarding/business-units/dissociate-users`,
      { buId, userList },
      { withCredentials: true },
    );
    return res.data;
  },
};

export default SFMCOffboardingAPI;
